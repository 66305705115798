import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchRoles = () => {
	const url = `${apiRoot}/roles`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchRolesByName = (name) => {
	const url = `${apiRoot}/roles/byrole?role=${name}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createRole = (values) => {
	const url = `${apiRoot}/roles`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateRole = (roleId, role) => {
	const url = `${apiRoot}/roles/${roleId}`
	let method = HTTP_METHODS.PUT
	let body = role
	return callApi(url, method, body)
}

export const deleteRole = (roleId) => {
	const url = `${apiRoot}/roles/${roleId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}