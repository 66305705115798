import React from 'react'
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const CandidateDetails = ({ candidate, isAdmin, isManager, isRecruiter, findJob, findItem }) => {
	var timeStamp = + new Date()
	return (
		<>
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Recruiter Name:</span> {candidate.recruiterName || 'N/A'}
				</Col>
			</Row>
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Candidate Name:</span> {candidate.name || 'N/A'}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold">	Job Title:</span> {findJob(candidate.job) || 'N/A'}
				</Col>
			</Row>
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Phone Number:</span> {candidate.phoneNumber || 'N/A'}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold">Email:</span> {candidate.email || 'N/A'}
				</Col>
			</Row>
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Skills:</span> {candidate.skills ? candidate.skills.map(e => findItem(e.value)).join(', ') : 'N/A'}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold"> Total Experience:</span> {candidate.totalExperience || 'N/A'} years
				</Col>
			</Row>
			<Row className="p-2">
				<Col className="text-left">
					{/* findItem(candidate.currentLocation) || 'N/A' */}
					<span className="font-weight-bold">Current Location:</span> {candidate.currentLocation}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold"> Preferred Locations:</span>{candidate.preferredLocations || 'N/A'}
					 {/* {candidate.preferredLocations ? candidate.preferredLocations.map(e => findItem(e.value)).join(', ') : 'N/A'} */}
				</Col>
			</Row>
			{(isAdmin || isRecruiter || isManager) && <Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Current CTC:</span> {`${candidate.currentCTC / 100000} lakhs` || 'N/A'}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold">Expected CTC:</span> {`${candidate.expectedCTC / 100000} lakhs` || 'N/A'}
				</Col>
			</Row>}
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Current Company Name:</span> {candidate.currentCompanyName || 'N/A'}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold">Notice Period:</span> {candidate.noticePeriod || 'N/A'}
				</Col>
			</Row>
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Gender:</span> {candidate.gender || 'N/A'}
				</Col>
				<Col className="text-left">
					<span className="font-weight-bold">Status:</span> {candidate.status || 'N/A'}
				</Col>
			</Row>
			<Row className="p-2">
				<Col className="text-left">
					<span className="font-weight-bold">Open for hybrid:</span> {candidate.openForHybrid ? "YES" : 'NO'}
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					{candidate.fileUploadKeys.length > 0 &&
						<Row>
							<Col className="text-left">
								<a
									target="_blank"
									href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${candidate.fileUploadKeys}?v=${timeStamp.toString()}`}
									color="link"
									rel="noopener noreferrer"
									title="View Resume"
								>
									<FontAwesomeIcon icon={faEye} /> View Candidate Resume
								</a>
							</Col>
						</Row>}
				</Col>
				<Col className="text-left">
					<Button color="link" id="feedback" style={{ marginBottom: '1rem' }}>
						<FontAwesomeIcon icon={faEye} /> Show/Hide Penalist Feedbacks
					</Button>
				</Col>
			</Row>

			{candidate.feedback.length > 0 && <UncontrolledCollapse toggler="#feedback">
				<Row className="p-2">
					<Col>
						<h4 className="text-center">Penalist Feedbacks</h4>
					</Col>
				</Row>
				{
					candidate.feedback.map(user => (
						<>
							<Row className="p-2">
								<Col className="font-weight-bold">	By {user.userName || 'N/A'}</Col>
							</Row>
							<Table>
								<thead>
									<tr>
										<td>Skill</td>
										<td>Rating</td>
										<td>Questions Asked</td>
										<td>Feedback</td>
										<td>Anything Worth Mentioning</td>
									</tr>
								</thead>
								<tbody>
									{
										user.feedback.map(feed => (
											<tr>
												<td>{feed.skill}</td>
												<td>{feed.rating}</td>
												<td>{feed.questionAsked}</td>
												<td>{feed.feedback}</td>
												<td>{feed.other}</td>
											</tr>
										))
									}
								</tbody>
							</Table>
						</>
					))
				}
			</UncontrolledCollapse>}
		</>
	)
}

export default CandidateDetails