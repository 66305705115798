import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchJobs = () => {
	const url = `${apiRoot}/jobs`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchManagerJobs = (id) => {
	const url = `${apiRoot}/jobs/managerjobs/${id}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}


export const fetchJob = (id) => {
	const url = `${apiRoot}/jobs/${id}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createJob = (values) => {
	const url = `${apiRoot}/jobs`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateJob = (jobId, job) => {
	const url = `${apiRoot}/jobs/${jobId}`
	let method = HTTP_METHODS.PUT
	let body = job
	return callApi(url, method, body)
}

export const deleteJob = (jobId) => {
	const url = `${apiRoot}/jobs/${jobId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}