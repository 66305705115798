import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import CheckboxDropdown from '../content/CheckboxDropdown'
import {
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col
} from 'reactstrap'
import {
	FormFeedbackAdapter,
	InputAdapter,
	SelectAdapter,
} from '../ffadapters'
import {
	SubmitErrorAlerts
} from '../ffhelpers'
import {
	composeValidators,
	validateEmail,
	validateRequired,
	validatePhoneNumber
} from '../../lib/validators'
import { experienceData } from '../../data/recruitmentData'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}

const AddEditReferralForm = (
	// form states that trigger render need to be in container subscription
	{
		handleSubmit,
		submitError,
		submitSucceeded,
		submitting,
		toggle,
		handleSelectAll,
		selectedSkills,
		handleSelectOption,
		allOptions,
		selectAll,
		isAdmin
	}
) => {
	return (
		<Form onSubmit={handleSubmit} className="p-4" id="roleform">
			<Row>
				{isAdmin && <Col md>
					<FormGroup>
						<Label htmlFor={`status`}>Status</Label>
						<Field
							name="status"
							type="select"
							bsSize="lg"
							validate={composeValidators(validateRequired())}
							component={SelectAdapter}
							options={[{ label: 'Submitted', value: 'Submitted' },
							{ label: 'Not Relevent', value: 'Not Relevent' },
							{ label: 'Interview Scheduled', value: 'Interview Scheduled' },
							{ label: 'Offered', value: 'Offered' },
							{ label: 'Joined', value: 'Joined' },
							{ label: 'Declined', value: 'Declined' }]} />
						<FormFeedbackAdapter name="status" />
					</FormGroup>
				</Col>}

				<Col md>
					<FormGroup>
						<Label htmlFor={`name`}>Name</Label>
						<Field
							name="name"
							type="text"
							bsSize="lg"
							validate={composeValidators(validateRequired())}
							component={InputAdapter} />
						<FormFeedbackAdapter name="name" />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`email`}>Email</Label>
						<Field
							name="email"
							type="email"
							bsSize="lg"
							validate={composeValidators(validateRequired(), validateEmail())}
							component={InputAdapter} />
						<FormFeedbackAdapter name="email" />
					</FormGroup>
				</Col>
				<Col md>
					<FormGroup>
						<Label htmlFor={`phone`}>Contact Number</Label>
						<Field
							name="phone"
							type="text"
							bsSize="lg"
							validate={composeValidators(validateRequired(), validatePhoneNumber())}
							component={InputAdapter} />
						<FormFeedbackAdapter name="phone" />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col>
					<Label htmlFor={`skills`}>Skills</Label>
					<CheckboxDropdown
						onChangeSelectAll={handleSelectAll}
						onChangeOption={handleSelectOption}
						selectAllChecked={selectAll}
						allOptions={allOptions.map(option => ({ value: option.value, label: option.label }))}
						showSelectAll={true}
						arrayToUpdate={selectedSkills}
						title="Select Skills"
					/>
				</Col>
				<Col md>
					<FormGroup>
						<Label htmlFor={`experience`}>Total Experience</Label>
						<Field
							name="experience"
							type="select"
							bsSize="lg"
							validate={composeValidators(validateRequired())}
							component={SelectAdapter}
							options={experienceData} />
						<FormFeedbackAdapter name="experience" />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormGroup>
						<Label>Upload Resume</Label>
						<Field name={`file`}>
							{({ input: { value, onChange, ...input } }) => (
								<input
									{...input}
									type="file"
									accept=".pdf"
									style={{ padding: '3px' }}
									className="border-info form-control"
									name={`file`}
									onChange={({ target }) => onChange(target.files)}
								/>
							)}
						</Field>

						<FormFeedbackAdapter name={`file`} />
					</FormGroup>
				</Col>
				<Col md>
					<FormGroup>
						<Label htmlFor={`howKnow`}>How do you know him/her?</Label>
						<Field
							name="howKnow"
							type="textarea"
							bsSize="lg"
							component={InputAdapter} />
						<FormFeedbackAdapter name="howKnow" />
					</FormGroup>
				</Col>
			</Row>
			<SubmitErrorAlerts submitError={submitError} />
			<Row>
				<Col md>
					<FormGroup>
						<Button
							block
							color="primary"
							className="p-3 mr-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							type="submit">
							{"Save"}</Button>
					</FormGroup>
				</Col>

				<Col md>
					<FormGroup>
						<Button
							block
							color="outline-primary"
							className="p-3 ml-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							onClick={() => toggle(null)}>Cancel</Button>
					</FormGroup>
				</Col>
			</Row>
		</Form>
	)
}

AddEditReferralForm.propTypes = propTypes

export default AddEditReferralForm
