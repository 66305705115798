import React, { useState } from 'react'
import { months, years } from './monthData'
import { Button, Container, Row, Col, Input, Alert } from 'reactstrap'
import AWS from 'aws-sdk'
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_BUCKET_NAME } from '../../config'
import { uploadPayslip } from '../../middleware/payslips'
import { toast } from 'react-toastify'

const UploadSlipForm = ({ toggle }) => {
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null)

  AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: 'ap-south-1',
  })

  const s3 = new AWS.S3();

  const handleClose = () => {
    setMonth(null)
    setYear(null)
    setFiles([])
    toggle()
  }
  console.log(month)
  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const validateAndExtractInfo = (fileName) => {
    const regex = /^([0-9]+)_([a-zA-Z0-9]+)\.[a-zA-Z0-9]+$/;
    const match = fileName.match(regex);
    if (match) {
      return { employeeId: match[1], employeeName: match[2] };
    }
    return null;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (files.length === 0) {
      setError('Please select a folder first.');
      return;
    }
    if (!month || !year) {
      setError('Please select a month/year.');
      return;
    }

    const validFiles = [];
    const invalidFiles = [];

    Array.from(files).forEach((file) => {
      const info = validateAndExtractInfo(file.name);
      if (info) {
        validFiles.push({ file, ...info });
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      setError(`Invalid file names:\n${invalidFiles.join('\n')}`);
      return;
    }
    const fileMetadata = [];

    const uploadPromises = validFiles.map(async ({ file, employeeId, employeeName }) => {
      console.log(employeeId, employeeName)
      const key = `salary-slip/${employeeId}/${year}/${month}/${file.name}`
      const params = {
        Bucket: AWS_BUCKET_NAME,
        Key: key,
        Expires: 120, // URL expiry time in seconds
        ContentType: file.type,
      };

      return await s3.getSignedUrlPromise('putObject', params).then((url) => {
        fileMetadata.push({
          key: key,
          url: url,
          name: file.name,
          size: file.size,
          type: file.type,
          empId: employeeId,
          empName: employeeName,
          month,
          year
        });

        return fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });
      });
    });

    try {
      await Promise.all(uploadPromises);

      // Send file metadata to backend
      const response = await uploadPayslip(fileMetadata)
      if (response.ok) {
        toast.success('Files uploaded and metadata saved successfully!');
        handleClose();
      } else {
        const error = await response.json();
        console.log(error, error.error, error.error.message)
        setError(error?.error?.message || 'Error saving slips');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setError('Error uploading files');
    }
  };

  return (
    <Container fluid>
      <form>
        <Row>
          <Col>
            <Input
              type="select"
              name="month"
              required
              selected={month}
              defaultValue=""
              onChange={(event) => setMonth(event.target.value)} >
              <option value="" disabled>Select Month</option>
              {
                months && months.map((month, index) => (
                  <option key={month} value={index}>{month}</option>
                ))
              }
            </Input>
          </Col>
          <Col>
            <Input
              type="select"
              defaultValue=""
              name="month"
              required
              onChange={(event) => setYear(event.target.value)} >
              <option value="" disabled>Select Year</option>
              {
                years && years.map((year, index) => (
                  <option key={year} value={year}>{year}</option>
                ))
              }
            </Input>
          </Col>

        </Row>
        <Row>
          <Col>
            <div className="directory-input-container">
              <label htmlFor="directory-input" className="directory-input-label">
                Please select a directory from your device. It should have file names in format EmployeeId_EmployeeName.pdf only. Other formats are not accepted.
              </label>
              <input
                type="file"
                id="directory-input"
                className="directory-input"
                webkitdirectory="true"
                onChange={handleFileChange}
              />

            </div>
            <div className="file-list">
              {files && files.length > 0 ? (
                <ul>
                  {Array.from(files).map((file, index) => {
                    const info = validateAndExtractInfo(file.name)
                    return (
                      <li key={index}>{file.name} <span className='text-warning'>{!info ? '(Invalid file name)' : ''}</span></li>
                    )
                  })}
                </ul>
              ) : (
                <p>No files selected</p>
              )}
            </div>
          </Col>
        </Row>
        {error &&
          <Row>
            <Col>
              <Alert color="danger">{error}</Alert>
            </Col>
          </Row>
        }
        <Row className="mt-3 border-top">
          <Col className="mt-3 text-right">
            <Button type="button" onClick={onSubmit} color="primary" >Upload</Button>
            <Button type="button" className="ml-3" onClick={handleClose} color="secondary" outline >Cancel</Button>
          </Col>
        </Row>
      </form>
    </Container>
  )
}

export default UploadSlipForm
