import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { updateTask } from '../middleware/api'
import { cryptInfo } from '../config'
import TableComponent from './elements/TableComponent'

class Tasks extends Component {
    constructor() {
        super()
        this.state = {
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            searchTerm: ""
        }
    }

    approveTask(task, type) {
        const { fetchTasks } = this.props
        task.status = type === 'approve' ? 'active' : 'rejected'
        updateTask(task._id, task)
            .then(async (response) => {
                await fetchTasks()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { tasks } = this.props
        if (!tasks || (tasks && tasks.length === 0))
            return []

        return tasks && tasks.map((task, index) => {
            return (
                {
                    id: index + 1,
                    taskName: task.taskname,
                    description: task.description,
                    status: task.status,
                    data: task
                }
            )
        })
    }
    render() {
        const { tasks, toggleEditModal } = this.props
        const { isAdmin, searchTerm } = this.state
        const formattedData = tasks && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.taskName && row.taskName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.status && row.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.description && row.description.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'taskName',
                label: 'Task Name',
            },
            {
                id: 'description',
                label: 'Description',
            },
            {
                id: 'status',
                label: 'Status',
            }
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                {
                    isAdmin &&
                    <>
                        {data.status !== 'active' &&
                            <Button color="link" className="action no-wrap"
                                onClick={() => this.approveTask(data, 'approve')}>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="mr-2" />Activate
                            </Button>
                        }
                        {
                            data.status === 'active' &&
                            <Button color="link" className="action no-wrap"
                                onClick={() => this.approveTask(data, 'deactive')}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className="mr-2" />Deactivate
                            </Button>
                        }
                        <Button color="link" className="action no-wrap"
                            onClick={() => toggleEditModal(data)}>
                            <FontAwesomeIcon
                                icon={faPencilAlt}
                                className="mr-2" />Edit
                        </Button>
                    </>
                }
            </React.Fragment>
        )

        return (
            <>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            defaultSort="taskName"
                            action={isAdmin ? actionMarkup : null}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
            </>
        )
    }
}

export default Tasks