import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import CheckboxDropdown from '../content/CheckboxDropdown'
// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col,
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
    composeValidators,
    validateEmail,
    validateRequired,
    validatePhoneNumber
} from '../../lib/validators'
import { candidateStatuses, experienceData, noticePeriodData } from '../../data/recruitmentData'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AddEditCandidateForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        locationsDropdownData,
        handleSelectAll,
        selectedSkills,
        handleSelectOption,
        allOptions,
        selectAll,
        handleSelectAllLocations,
        handleSelectLocationOption,
        selectedLocations,
        selectAllLocations,
        formattedJobs
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4" id="roleform">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`recruiterName`}>Recruiter Name</Label>
                        <Field
                            name="recruiterName"
                            type="text"
                            bsSize="lg"
                            readOnly={true}
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="recruiterName" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`job`}>Select Job</Label>
                        <Field
                            name="job"
                            type="select"
                            bsSize="lg"
                            // validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={formattedJobs} />
                        <FormFeedbackAdapter name="job" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`name`}>Candidate Name</Label>
                        <Field
                            name="name"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="name" />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`email`}>Email</Label>
                        <Field
                            name="email"
                            type="email"
                            bsSize="lg"
                            validate={composeValidators(validateRequired(), validateEmail())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="email" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`phoneNumber`}>Contact Number</Label>
                        <Field
                            name="phoneNumber"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired(), validatePhoneNumber())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="phoneNumber" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label htmlFor={`interviewPanel`}>Skills</Label>
                    <CheckboxDropdown
                        onChangeSelectAll={handleSelectAll}
                        onChangeOption={handleSelectOption}
                        selectAllChecked={selectAll}
                        allOptions={allOptions.map(option => ({ value: option.value, label: option.label }))}
                        showSelectAll={true}
                        arrayToUpdate={selectedSkills}
                        title="Select Skills"
                    />
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`totalExperience`}>Total Experience</Label>
                        <Field
                            name="totalExperience"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={experienceData} />
                        <FormFeedbackAdapter name="totalExperience" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`currentLocation`}>Current Location</Label>
                        <Field
                            name="currentLocation"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter}
                        // options={locationsDropdownData}
                        />
                        <FormFeedbackAdapter name="currentLocation" />
                    </FormGroup>
                </Col>
                <Col>
                    <Label htmlFor={`preferredLocations`}>Preferred Locations <span style={{ fontSize: "12px" }}>(Enter comma seperated values)</span></Label>
                    <Field
                        name="preferredLocations"
                        type="text"
                        bsSize="lg"
                        // validate={composeValidators(validateRequired())}
                        component={InputAdapter}
                    // options={locationsDropdownData} 
                    />
                    {/* <CheckboxDropdown
                        onChangeSelectAll={handleSelectAllLocations}
                        onChangeOption={handleSelectLocationOption}
                        selectAllChecked={selectAllLocations}
                        allOptions={locationsDropdownData.map(option => ({ value: option.value, label: option.label }))}
                        showSelectAll={true}
                        arrayToUpdate={selectedLocations}
                        title="Select Locations"
                    /> */}
                </Col>
            </Row>
            <Row>

                <Col md>
                    <FormGroup>
                        <Label htmlFor={`currentCTC`}>Current CTC
                            {/* <span style={{ fontSize: '10px' }}>(*in lakhs)</span> */}
                        </Label>
                        <Field
                            name="currentCTC"
                            type="number"
                            min={0}
                            step="0.1"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="currentCTC" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`expectedCTC`}>Expected CTC
                            {/* <span style={{ fontSize: '10px' }}>(*in lakhs)</span> */}
                        </Label>
                        <Field
                            name="expectedCTC"
                            type="number"
                            min={0}
                            step="0.1"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="expectedCTC" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`currentCompanyName`}>Current Company Name</Label>
                        <Field
                            name="currentCompanyName"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="currentCompanyName" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`noticePeriod`}>Notice Period</Label>
                        <Field
                            name="noticePeriod"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={noticePeriodData} />
                        <FormFeedbackAdapter name="noticePeriod" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`gender`}>Gender</Label>
                        <Field
                            name="gender"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Other', value: 'Other' }]} />
                        <FormFeedbackAdapter name="gender" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`status`}>Status</Label>
                        <Field
                            name="status"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={candidateStatuses} />
                        <FormFeedbackAdapter name="status" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Upload Resume</Label>
                        <Field name={`file`}>
                            {({ input: { value, onChange, ...input } }) => (
                                <input
                                    {...input}
                                    type="file"
                                    accept="application/msword,application/pdf"
                                    style={{ padding: '3px' }}
                                    className="border-info form-control"
                                    name={`file`}
                                    onChange={({ target }) => onChange(target.files)}
                                />
                            )}
                        </Field>

                        <FormFeedbackAdapter name={`file`} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup inline check>
                        {/* <Label>Open for hybrid</Label> */}
                        <Field
                            name="openForHybrid"
                            type="checkbox"
                            bsSize="lg"
                            label="Open for hybrid"
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name={`file`} />
                    </FormGroup>
                </Col>

            </Row>
            <SubmitErrorAlerts submitError={submitError} />
            <Row>
                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="primary"
                            className="p-3 mr-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            type="submit">
                            {"Save"}</Button>
                    </FormGroup>
                </Col>

                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={() => toggle(null)}>Cancel</Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

AddEditCandidateForm.propTypes = propTypes

export default AddEditCandidateForm
