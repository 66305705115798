import React, { Fragment } from 'react';
import LandingPageContainer from '../containers/LandingPageContainer'
const LandingPage = () => {
    return (
        <Fragment>
            <LandingPageContainer />
        </Fragment>
    );
}

export default LandingPage;