export const billMonth = {
    apr: `April`,
    may: `May`,
    jun: `June`,
    jul: `July`,
    aug: `August`,
    sep: `September`,
    oct: `October`,
    nov: `November`,
    dec: `December`,
    jan: `January`,
    feb: `February`,
    mar: `March`,

}

export const billMonthArray = Object.keys(billMonth).map(value => ({
    value,
    label: billMonth[value]
}))

const financialYears = [
    `${new Date().getFullYear() - 1} - ${new Date().getFullYear() }`,
    `${new Date().getFullYear()} - ${new Date().getFullYear() + 1 }`,
    `${new Date().getFullYear() + 1} - ${new Date().getFullYear() + 2}`
]

export const financialYearArray = financialYears.map(item => ({
    value:item,
    label:item
}))

export const  getCurrentFinancialYear = () => {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
  }

