import React, { Fragment } from 'react';
import ForgotPasswordPageContainer from '../containers/ForgotPasswordPageContainer'
const ForgotPasswordPage = (props) => {
    console.log(props)
    return (
        <Fragment>
            <ForgotPasswordPageContainer userId={props.match.params.userId} token={props.match.params.token} />
        </Fragment>
    );
}

export default ForgotPasswordPage;