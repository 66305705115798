import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FieldArray } from 'react-final-form-arrays'
import {
    Button,
    Form,
    FormGroup,
    Table,
    Col
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const ReimbursementTypeForm = (
    {
        values,
        handleSubmit,
        submitError,
        submitting,
        toggle,
        toggleConfirmationModal
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="pt-4">
            <Table className="f-15 table table-striped">
                <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                    <tr>
                        <th>Reimbursement Type</th>
                        <th>Status</th>
                        <th>Monthly Limit</th>
                        <th>Bill Required</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="reimbursements">
                        {({ fields }) => <>
                            {fields.map((name, index) => (
                                <tr className="w-100" key={`${name}_${index}`}>
                                    <td key={`name${name}_${index}`}>
                                        <Field
                                            name={`${name}.name`}
                                            type="text"
                                            bsSize="lg"
                                            validate={validateRequired()}
                                            component={InputAdapter} />
                                        <FormFeedbackAdapter name={`${name}.name`} />
                                    </td>
                                    <td key={`status${name}_${index}`}>
                                        <Field
                                            name={`${name}.status`}
                                            type="select"
                                            bsSize="lg"
                                            validate={validateRequired()}
                                            component={SelectAdapter}
                                            options={[{ value: 'active', label: 'Active' }, { value: 'inactive', label: 'Inactive' }]} />
                                        <FormFeedbackAdapter name={`${name}.status`} />
                                    </td>
                                    <td key={`amount${name}_${index}`} className="w-10">
                                        <Field
                                            name={`${name}.amount`}
                                            type="number"
                                            bsSize="lg"
                                            component={InputAdapter}
                                            required="required"
                                            min="0" />
                                        <FormFeedbackAdapter name={`${name}.amount`} />
                                    </td>
                                    <td key={`billRequired${name}_${index}`}>
                                        <Field
                                            name={`${name}.billRequired`}
                                            type="select"
                                            bsSize="lg"
                                            validate={validateRequired()}
                                            component={SelectAdapter}
                                            options={[{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]} />
                                        <FormFeedbackAdapter name={`${name}.billRequired`} />
                                    </td>
                                    <td key={`delete_${name}_${index}.`}>
                                        <Button
                                            color="link"
                                            onClick={() => fields.push()}
                                            disabled={submitting}>
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        </Button>
                                        {values && values.reimbursements && values.reimbursements.length > 1 &&
                                            <Button
                                                onClick={() => {
                                                    if (fields && fields.value && fields.value[index] && fields.value[index]._id) {
                                                        toggleConfirmationModal(fields.value[index])
                                                    } else {
                                                        fields.remove(index)
                                                    }
                                                }
                                                }
                                                color="link"
                                                className="text-danger"
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                            </Button>}

                                    </td>
                                </tr>
                            ))}
                        </>}
                    </FieldArray>
                </tbody>
            </Table>

            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting}
                        size="lg"
                        type="submit"
                    >Save</Button>
                </Col>
                <Col md>
                    <Button
                        block
                        color="outline-primary"
                        className="p-3 mt-3"
                        disabled={submitting}
                        size="lg"
                        onClick={toggle}
                    >Cancel</Button>
                </Col>

            </FormGroup>

        </Form>

    )
}

ReimbursementTypeForm.propTypes = propTypes

export default ReimbursementTypeForm
