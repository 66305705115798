import React from 'react'
import classNames from 'classnames'
import Navbar from './WelcomeNavbar'
import { Container } from 'reactstrap'
class Content extends React.Component {
  render() {
    const { isOpen, toggle, children, setTimings, refreshUserLogs } = this.props
    return (
      <Container fluid className={classNames('content p-0 pb-5', { 'is-open': isOpen })}>
        <Navbar setTimings={setTimings}
          toggle={toggle}
          isOpen={isOpen}
          refreshUserLogs={refreshUserLogs}
        />
        {children}
        {
          isOpen && <div id="overlay" onClick={toggle} className={classNames('overlay', { 'is-open': isOpen })}></div>
        }
      </Container>
    );
  }
}

export default Content;
