import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Table, ModalHeader } from 'reactstrap';
import Moment from 'react-moment'
import { fetchProjectName, fetchTaskName } from '../../data/dataFromApis'
import { updateTimesheet } from '../../middleware/api'

class TimesheetModal extends React.Component {

    approveTimesheet = async (timesheet, type) => {
        const { toggleModal } = this.props
        if (type === 'approve') {
            timesheet.status = "Approved"
        } else if (type === 'reject') {
            let promptValue = prompt("Add Some Remark/Reason", "***remark***")
            if (promptValue) {
                timesheet.remark = promptValue
                timesheet.status = "Rejected"
            }
        }
        if (timesheet && timesheet._id) {
            await updateTimesheet(timesheet)
                .then(async (response) => {
                    await toggleModal()
                })
        }
    }
    render() {
        const { isOpen, toggleModal, currentTimesheet, projects, tasks } = this.props

        return (
            <div>
                <Modal isOpen={isOpen} size="lg" toggle={toggleModal} className={this.props.className}>
                    <ModalHeader tag="h3" toggle={toggleModal}>Timesheet Details ({currentTimesheet && currentTimesheet.username})
                        Week: <Moment format="MM/DD">{currentTimesheet && currentTimesheet.startOfWeek}</Moment>
                        -
                        <Moment format="MM/DD" add={{ days: 4 }}>{currentTimesheet && currentTimesheet.startOfWeek}</Moment>
                    </ModalHeader>
                    <ModalBody>
                        {
                            currentTimesheet && currentTimesheet.timesheet &&
                            <div className="container-fluid scroll-table p-0">
                                <Table className="f-15" id="timesheet-modal">
                                    <thead>
                                        <tr>
                                            <th>Project</th>
                                            <th>Task</th>
                                            <th>Description</th>
                                            <th>Mon</th>
                                            <th>Tue</th>
                                            <th>Wed</th>
                                            <th>Thu</th>
                                            <th>Fri</th>
                                        </tr>
                                    </thead>
                                    <tbody>{
                                        currentTimesheet.timesheet.map((timesheet, index) => (
                                            <tr key={index}>
                                                <td>{fetchProjectName(projects, timesheet)}</td>
                                                <td className="w-20 word-break">{fetchTaskName(tasks, timesheet)}</td>
                                                <td>{timesheet.description}</td>
                                                <td>{timesheet.mon}</td>
                                                <td>{timesheet.tue}</td>
                                                <td>{timesheet.wed}</td>
                                                <td>{timesheet.thu}</td>
                                                <td>{timesheet.fri}</td>

                                            </tr>
                                        ))
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            size="lg"
                            disabled={currentTimesheet && (currentTimesheet.status === 'Approved' || currentTimesheet.status === 'Rejected')}
                            onClick={() => this.approveTimesheet(currentTimesheet, 'approve')}>Approve
                        </Button>{' '}
                        <Button
                            color="danger"
                            size="lg"
                            disabled={currentTimesheet && (currentTimesheet.status === 'Approved' || currentTimesheet.status === 'Rejected')}
                            onClick={() => this.approveTimesheet(currentTimesheet, 'reject')}>Reject
                        </Button>{' '}
                        <Button
                            outline
                            color="primary"
                            size="lg"
                            onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default TimesheetModal;