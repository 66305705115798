import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import Clients from '../components/Clients'
import AddClientModal from '../components/AddClientModal'
import { fetchClients, loggingOut } from '../middleware/api'
import { cryptInfo } from '../config'
import { Container, Row, Col, Button } from 'reactstrap'

class ClientContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddProjectModal: false,
            clients: null,
            client: null,
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')),
            timers: "00:00:00",
            isOpen: true
        }
        this.getClients = this.getClients.bind(this)
        this.setTimings = this.setTimings.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    toggleModal = () => {
        this.setState(state => ({
            openAddClientModal: !state.openAddClientModal,
            client: null
        }))
    }

    toggleEditModal = (client) => {
        this.toggleModal()
        this.setState({
            client: client
        })
    }
    getClients() {
        fetchClients()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    clients: result
                })
            })
    }
    componentDidMount() {
        if (cryptInfo.decrypt(localStorage.getItem('role')) !== 'admin' && cryptInfo.decrypt(localStorage.getItem('role')) !== 'manager') {
            localStorage.clear()
            window.location.reload()
        }
        loggingOut()
        this.getClients()
    }
    render() {
        const { openAddClientModal, client, clients, isOpen } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                       <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Our Clients</span>
                                </Col>
                                <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleModal}>
                                        Add Client
                        </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Clients
                            clients={clients}
                            fetchClients={this.getClients}
                            toggleEditModal={this.toggleEditModal}
                        />
                        <AddClientModal
                            isOpen={openAddClientModal}
                            toggle={this.toggleModal}
                            fetchClients={this.getClients}
                            client={client}
                        />
                    </Content>
                </div>
            </>
        );
    }
}

export default ClientContainer;