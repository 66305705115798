import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'
import {
    Button,
    Form,
    FormGroup,
    Table,
    Row,
    Col,
    Badge,
    Container
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AddKRAFeedback = (
    {
        handleSubmit,
        submitError,
        submitting,
        user,
        project,
        values,
        onSubmitFeedback,
        onCancel,
        appraisal_rating,
        assigned,
        status
    }
) => (
    <Container>
        <Row className="mt-4">
            <Col md>
                <h4 className="lfloat">Employee Name : <Badge color="success">
                    {user && user.fname + " " + user.lname}
                </Badge></h4>
            </Col>
            <Col md>
                <h4 className="lfloat">Project Name : <Badge color="success">
                    {project}
                </Badge></h4>
            </Col>
        </Row>

        <Row className="mt-2">
            <Col md>
                <h4 className="lfloat">Date of Joining : <Badge color="warning">
                    {user && user.doj}
                </Badge></h4>
            </Col>
            <Col md>
                <h4 className="lfloat">Last Appraisal Rating : <Badge color="warning">
                    {appraisal_rating && appraisal_rating.toFixed(1)}
                </Badge></h4>
            </Col>
        </Row>
        <Row className="mt-2 mb-3">
            <Col md>
                <h4 className="lfloat">Status : <Badge color="info">
                    {status && status}
                </Badge></h4>
            </Col>
            <Col md>
                <h4 className="lfloat">Assigned To : <Badge color="danger">
                    {assigned && assigned === 'User' ? (user && user.fname + " " + user.lname) : "Manager"}
                </Badge></h4>
            </Col>
        </Row>

        <Form onSubmit={handleSubmit} className="p-2 mt-5">
            <div className=" scroll-table">
                <Table className="f-15 table">
                    <thead style={{backgroundColor:'#a9b5c3'}}>
                        <tr>
                            <th>KRA Summary</th>
                            <th>Description</th>
                            <th>Allocation %</th>
                            <th>Accomplishment</th>
                            <th>Employee Input</th>
                            <th>Manager Feedback</th>
                            <th>Suggested Improvement</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody>
                        <FieldArray name="kras">
                            {({ fields }) => <Fragment>
                                {fields.map((name, index) => (

                                    <tr className="w-100 success" key={`${name}_${index}`}>
                                        <td key={`summary_${name}_${index}`}>
                                            <Field
                                                name={`${name}.summary`}
                                                type="textarea"
                                                bsSize="lg"
                                                rows={4}
                                                readOnly={true}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name="summary" />
                                        </td>
                                        <td key={`description_${name}_${index}.`}>
                                            <Field
                                                name={`${name}.description`}
                                                type="textarea"
                                                bsSize="lg"
                                                rows={4}
                                                readOnly={true}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.description`} />
                                        </td>
                                        <td key={`allocation_${name}_${index}.`}>
                                            <Field
                                                name={`${name}.allocation`}
                                                type="number"
                                                bsSize="lg"
                                                min={0}
                                                max={100}
                                                readOnly={true}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.allocation`} />
                                        </td>
                                        <td key={`accomplishment_${name}_${index}`}>
                                            <Field
                                                name={`${name}.accomplishment`}
                                                type="textarea"
                                                bsSize="lg"
                                                rows={4}
                                                readOnly={true}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.accomplishment`} />
                                        </td>
                                        <td key={`comment_${name}_${index}`}>
                                            <Field
                                                name={`${name}.comment`}
                                                type="textarea"
                                                bsSize="lg"
                                                rows={4}
                                                validate={composeValidators(validateRequired())}
                                                readOnly={true}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.comment`} />
                                        </td>
                                        <td key={`feedback_${name}_${index}`}>
                                            <Field
                                                name={`${name}.feedback`}
                                                type="textarea"
                                                bsSize="lg"
                                                rows={4}
                                                readOnly={(values['kras'][index] && values['kras'][index].status === 'Submitted') ||
                                                    (values['kras'][index] && values['kras'][index].status === 'Rejected')
                                                    || (values['kras'][index] && values['kras'][index].status === 'Accepted')}
                                                required={true}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.feedback`} />
                                        </td>
                                        <td key={`improvement_${name}_${index}`}>
                                            <Field
                                                name={`${name}.improvement`}
                                                type="textarea"
                                                bsSize="lg"
                                                rows={4}
                                                required={true}
                                                readOnly={(values['kras'][index] && values['kras'][index].status === 'Submitted')
                                                    || (values['kras'][index] && values['kras'][index].status === 'Rejected')
                                                    || (values['kras'][index] && values['kras'][index].status === 'Accepted')}
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.improvement`} />
                                        </td>
                                        <td key={`rating_${name}_${index}`} className="pl-0">
                                            <Field
                                                name={`${name}.rating`}
                                                type="number"
                                                min={1}
                                                max={4}
                                                required={true}
                                                readOnly={(values['kras'][index] && values['kras'][index].status === 'Submitted')
                                                    || (values['kras'][index] && values['kras'][index].status === 'Rejected')
                                                    || (values['kras'][index] && values['kras'][index].status === 'Accepted')}
                                                bsSize="lg"
                                                component={InputAdapter} />

                                            <FormFeedbackAdapter name={`${name}.rating`} />
                                        </td>

                                        {/* <td key={`delete_${name}_${index}.`}>
                                                <Button
                                                    className="delete-button"
                                                    onClick={() => fields.remove(index)}
                                                    color="link"
                                                    title="Remove KRA if you don't want to add it's feedback">
                                                    <FontAwesomeIcon icon={faMinus} className="mr-2" />
                                                </Button>
                                                <Button
                                                    className="add-button"
                                                    style={{ display: "none" }}
                                                    color="link"
                                                    onClick={() => fields.push()} size="lg">
                                                    <FontAwesomeIcon icon={faPlus} className="mr-2" /></Button>
                                            </td> */}
                                    </tr>

                                ))}

                            </Fragment>}
                        </FieldArray>
                    </tbody>
                </Table>
            </div>
            <SubmitErrorAlerts submitError={submitError} />
            <Row className="to-end-start pr-4">
                <FormGroup>
                    <Button
                        color="primary"
                        className="mr-2"
                        size="lg"
                        disabled={submitting || (status && (status === 'Wating for Submission' || status === 'Submitted'))}
                        type="button"
                        title={status && status === 'Wating for Submission' ?
                            "Employee has provided his/her feedback for all KRA(s). You can submit now." :
                            ""}
                        onClick={() => onSubmitFeedback(values)}>
                        Save as Draft</Button>
                </FormGroup>
                <FormGroup>
                    <Button
                        color="primary"
                        className="mr-2"
                        size="lg"
                        disabled={submitting || (status && status === 'Submitted')}
                        type="submit">
                        Submit</Button>
                </FormGroup>

                <FormGroup>
                    <Button
                        color="primary"
                        className="mr-2"
                        size="lg"
                        disabled={submitting}
                        type="button"
                        onClick={() => onCancel()}>
                        Cancel</Button>
                </FormGroup>
            </Row>
        </Form>
    </Container>)

AddKRAFeedback.propTypes = propTypes

export default AddKRAFeedback
