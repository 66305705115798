import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import 'react-table-filter/lib/styles.css'
import TableComponent from '../elements/TableComponent'

class LeaveAvailed extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            searchTerm: ""
        }
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { leaveData } = this.props
        if (!leaveData || (leaveData && leaveData.length === 0))
            return []

        return leaveData && leaveData.map((leave, index) => {
            return (
                {
                    id: index + 1,
                    employeeName: leave.empname,
                    earnedLeaveBalance: leave.earnedLeaveBalance || 0,
                    earnedLeaveTaken: leave.earned,
                    lwp: leave.lwp || 0,
                    wfh: leave.wfh || 0,
                    onduty: leave.onduty || 0,
                    casualLeaveTaken: leave.casual || 0,
                    casualLeaveBalance: leave.casualLeaveBalance,
                    userId: leave.userId,
                    data: leave
                }
            )
        })
    }
    render() {
        const { leaveData, toggleEmployeeDetail } = this.props
        console.log(leaveData)
        const { searchTerm } = this.state
        const formattedData = leaveData && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.employeeName && row.employeeName.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })
        const columns = [
            {
                id: 'employeeName',
                label: 'Employee Name',
                paramToPass: 'userId',
                onClick: toggleEmployeeDetail
            },
            {
                id: 'earnedLeaveTaken',
                label: 'EL Taken',
            },
            {
                id: 'earnedLeaveBalance',
                label: 'EL Balance',
            },
            {
                id: 'lwp',
                label: 'LWP',
            },
            {
                id: 'wfh',
                label: 'WFH',
            },
            {
                id: 'onduty',
                label: 'On Duty',
            },
            {
                id: 'casualLeaveTaken',
                label: 'CL Taken',
            },
            {
                id: 'casualLeaveBalance',
                label: 'CL Balance',
            },
        ]

        return (
            <Container fluid>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            defaultSort="employeeName"
                            action={null}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default LeaveAvailed