import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { leavesArray, leaveStatus } from '../../data/leaveTypeData'

// Reactstrap
import {
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
	FormFeedbackAdapter,
	SelectAdapter,
	DatePickerAdapter
} from '../ffadapters'
// Final-Form Helpers
import {
	SubmitErrorAlerts
} from '../ffhelpers'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}

const LeaveFilterForm = (
	// form states that trigger render need to be in container subscription
	{
		handleSubmit,
		submitError,
		submitSucceeded,
		submitting,
		toggle,
		formError,
		users,
		values
	}
) => {
	return (
		<Form onSubmit={handleSubmit} className="p-4">
			<Row>
				<Col className="col-md-6">
					<FormGroup>
						<Label htmlFor={`user`}>Employee</Label>
						<Field
							name="user"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							options={users && users.map(user => ({ label: user.fname + " " + user.lname, value: user._id }))}
						/>
						<FormFeedbackAdapter name="user" />
					</FormGroup>
				</Col>

				<Col className="col-md-6">
					<FormGroup>
						<Label htmlFor={`type`}>Leave Type</Label>
						<Field
							name="type"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							options={leavesArray} />

						<FormFeedbackAdapter name="type" />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`from`}>From</Label>
						<Field
							name="from"
							type="date"
							bsSize="lg"
							component={DatePickerAdapter} />

						<FormFeedbackAdapter name="from" />
					</FormGroup>
				</Col>
				<Col md>
					<FormGroup>
						<Label htmlFor={`to`}>To</Label>
						<Field
							name="to"
							type="date"
							bsSize="lg"
							component={DatePickerAdapter} />

						<FormFeedbackAdapter name="to" />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className="col-md-6">
					<FormGroup>
						<Label htmlFor={`status`}>Leave Status</Label>
						<Field
							name="status"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							options={leaveStatus} />

						<FormFeedbackAdapter name="status" />
					</FormGroup>
				</Col>
			</Row>
			<SubmitErrorAlerts submitError={submitError || formError} />
			<Row>
				<Col md>
					<FormGroup row>
						<Button
							block
							color="primary"
							className="p-3 mr-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							type="submit">
							{"Submit"}</Button>
					</FormGroup>
				</Col>
				<Col md>
					<FormGroup row>
						<Button
							block
							color="outline-primary"
							className="p-3 ml-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							onClick={toggle}>Cancel</Button>
					</FormGroup>
				</Col>
			</Row>

		</Form>
	)
}

LeaveFilterForm.propTypes = propTypes

export default LeaveFilterForm
