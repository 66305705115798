import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import InvestmentsRequestForm from './InvestmentsRequestForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

const AddInvestmentModal = ({ isOpen, toggle, onSubmit, selectedType }) => {
    return (
        <>
            <Modal size="lg" isOpen={isOpen} toggle={toggle}>
                <ModalHeader
                    tag="h2"
                    toggle={toggle}>Add/Edit Investment</ModalHeader>
                <ModalBody>
                    <Form
                        component={InvestmentsRequestForm}
                        toggle={toggle}
                        onSubmit={onSubmit}
                        subscription={{
                            pristine: true,
                            submitError: true,
                            submitting: true,
                            hasValidationErrors: true,
                            values: true
                        }}
                        mutators={{
                            ...arrayMutators,
                        }}
                        initialValues={selectedType && { ...selectedType }}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddInvestmentModal;