import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../elements/TableComponent'
import { fetchCandidates, fetchRecruiterCandidates, updateCandidate, createCandidate, deleteCandidate } from '../../middleware/candidates'
import { fetchJobs } from '../../middleware/jobs'
import { fetchAllData, fetchTypes } from '../../middleware/metadata'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddEditCandidateModal from './AddEditCandidateModal'
import { any, bool, func } from 'prop-types'
import ConfirmationModal from '../content/ConfirmationModal'
import AWS from 'aws-sdk'
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_BUCKET_NAME, CANDIDATE_FOLDER, cryptInfo } from '../../config'
import { FORM_ERROR } from 'final-form'
import { candidateColumns } from '../../data/columnsData'
import { HOSTNAME } from '../../config'
import CandidateFilterModal from './CandidateFilterModal'

const isRecruiter = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'recruiter'
const isAdmin = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'
const isManager = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'

const userId = localStorage.getItem('user_id')

AWS.config.update({
	accessKeyId: AWS_ACCESS_KEY_ID,
	secretAccessKey: AWS_SECRET_ACCESS_KEY,
	region: 'ap-south-1',
})

const CandidateManagement = ({
	openAddEditModal,
	toggleAddEditCandidatesModal,
	selectedItem,
	openConfirmationPopup,
	toggleConfirmationModal
}) => {

	const [searchTerm, setSearchTerm] = useState("")
	const [candidates, setCandidates] = useState([])
	const [jobs, setJobs] = useState([])
	const [allDataForForm, setAllDataForForm] = useState([])
	const [allMetadataTypes, setAllMetadataTypes] = useState([])
	const [selectAll, setSelectAll] = useState(false)
	const [selectedSkills, setSkills] = useState([])
	const [selectAllLocations, setSelectAllLocations] = useState(false)
	const [selectedLocations, setLocations] = useState([])
	const [openFilterPopup, setOpenFilterPopup] = useState(false)
	const [filterQuery, setFilterQuery] = useState({
		currentCTC: null,
		expectedCTC: null,
		noticePeriod: null,
		status: null,
		skill: null
	})

	const refreshContent = useCallback(() => {
		if (isRecruiter) {
			fetchRecruiterCandidates(userId)
				.then(async (res) => {
					const result = await res.json()
					setCandidates(result)
				})
		} else {
			fetchCandidates()
				.then(async (res) => {
					const result = await res.json()
					setCandidates(result)
				})
		}

		if (selectedItem) {
			setSkills(selectedItem.skills)
			setLocations(selectedItem.preferredLocations)
		} else {
			setSkills([])
			setLocations([])
		}
	}, [setSkills, setLocations, selectedItem])


	const getData = useCallback(() => {
		fetchAllData()
			.then(async (res) => {
				const result = await res.json()
				setAllDataForForm(result)
			})
	}, [])

	const getJobs = useCallback(() => {
		fetchJobs()
			.then(async (res) => {
				const result = await res.json()
				setJobs(result)
			})
	}, [])

	const getAllTypes = useCallback(() => {
		fetchTypes()
			.then(async (res) => {
				const result = await res.json()
				setAllMetadataTypes(result)
			})
	}, [])

	useEffect(() => {
		refreshContent()
		getData()
		getAllTypes()
		getJobs()
	}, [refreshContent, getData, getAllTypes, getJobs])


	const onDelete = useCallback(() => {
		deleteCandidate(selectedItem._id)
			.then(async (response) => {
				refreshContent()
				toggleConfirmationModal(null)
			})
	}, [selectedItem, toggleConfirmationModal, refreshContent])

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}

	const onSubmit = async (values) => {
		if (selectedSkills.length === 0) {
			return { [FORM_ERROR]: "Please select some skills." }
		} else {
			values.skills = selectedSkills
		}
		// if (selectedLocations.length === 0) {
		// 	return { [FORM_ERROR]: "Please select some locations." }
		// } else {
		// 	values.preferredLocations = selectedLocations
		// }

		await sendingCertificateFiles(values, CANDIDATE_FOLDER, uploadFinalDocument)
	}

	const uploadFinalDocument = useCallback((values) => {
		try {
			if (values && values._id) {
				updateCandidate(values._id, values)
					.then(async (response) => {
						refreshContent()
						toggleAddEditCandidatesModal(null)
					})
			} else {
				createCandidate(values)
					.then(async (response) => {
						refreshContent()
						toggleAddEditCandidatesModal(null)
					})
			}
		}
		catch (error) {
			values.fileUploadKeys && values.fileUploadKeys.map(file => {
				deleteS3Object(file)
				return { [FORM_ERROR]: "Error: Something went wrong. Please try after sometime." }

			})
		}
	}, [toggleAddEditCandidatesModal, refreshContent])

	const sendingCertificateFiles = async (values, fileType, callback) => {
		var payloadArray = []
		var files = values.fileUploadKeys && values.fileUploadKeys
		if (values.fileUploadKeys && values.fileUploadKeys.length > 0 && values.file && values.fileUploadKeys.length > values.file.length) {
			files.slice(0, values.file.length)
			var extraFiles = files.slice(values.file.length, values.fileUploadKeys.length)
			extraFiles.map(file => {
				deleteS3Object(file)
				return false
			})
		} else {
			files = values.fileUploadKeys
		}
		if (values.file) {
			Array.from(values.file).forEach(async (file, index) => {
				let payload
				if (files && files.length > 0 && index < files.length) {
					payload = await s3Signature(files[index], fileType)
				} else {
					payload = await s3Signature(null, fileType)
				}
				if (values.file) {
					let uploadSuccess = await sendFile(file, payload)
					if (uploadSuccess.ok === true) {
						payloadArray.push(payload['fields'].key)
						values.fileUploadKeys = payloadArray
					} else {
						console.log(uploadSuccess)
					}
				}
				index === values.file.length - 1 && setTimeout(async () => {
					await callback(values)
				}, 2000)
			})
		} else {
			// values.fileUploadKeys = values.fileUploadKeys
			await callback(values)
		}
	}

	const deleteS3Object = async (fileKey) => {
		return new Promise((resolve, reject) => {
			try {
				let s3bucket = new AWS.S3({
					accessKeyId: AWS_ACCESS_KEY_ID,
					secretAccessKey: AWS_SECRET_ACCESS_KEY,
				});
				var params = { Bucket: AWS_BUCKET_NAME, Key: fileKey };
				s3bucket.deleteObject(params, function (err, data) {
					if (err) reject(err);
					// an error occurred
					else resolve(data); // successful response
				});
			} catch (e) {
				reject(e);
			}
		});
	};

	const s3Signature = async (fileKey, type) => {
		const timeStamp = + new Date() + + new Date()
		return new Promise((resolve, reject) => {
			const s3 = new AWS.S3({
				accessKeyId: AWS_ACCESS_KEY_ID,
				secretAccessKey: AWS_SECRET_ACCESS_KEY,
			});
			const myBucket = AWS_BUCKET_NAME
			const params = {
				Bucket: myBucket,
				Fields: {
					key: fileKey ? `${fileKey}` : `${type}/${timeStamp.toString()}` // Any Unique Identifier
				},
				Conditions: [
					['content-length-range', 0, 10000000], // 10 Mb
					{ 'acl': 'public-read' },
					["starts-with", "$Content-Type", ""]
				]
			};
			s3.createPresignedPost(params, function (err, data) {
				if (err) {
					console.error('Presigning post data encountered an error', err);
				} else {
					resolve(data);
				}
			});
		});
	}

	const sendFile = async (file, payload) => {
		var form = new FormData();
		form.append('acl', 'public-read');
		for (const field in payload.fields) {
			form.append('Content-Type', file.type)
			form.append(field, payload.fields[field]);
		}
		form.append('file', file)
		const response = await fetch(payload.url, {
			method: 'POST',
			body: form,
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		})
		return response
	};

	const fetchDataByType = useCallback((type) => {
		const typeId = allMetadataTypes && allMetadataTypes.filter(e => e.name === type)
		const selectedType = typeId[0] || []
		if (!allDataForForm || (allDataForForm && allDataForForm.length === 0 && typeId.length === 0))
			return []
		return selectedType && allDataForForm && allDataForForm.filter(e => e.type === selectedType._id).map((d) => {
			return (
				{
					value: d._id,
					label: d.name
				}
			)
		})
	}, [allDataForForm, allMetadataTypes])

	const formatJobs = useCallback((type) => {
		if (!jobs || (jobs && jobs.length === 0))
			return []
		return jobs && jobs.filter(j => j.status === 'Open').map((d) => {
			return (
				{
					value: d._id,
					label: d.title,
					job: d
				}
			)
		})
	}, [jobs])

	const skillsDropdownData = fetchDataByType('skill')
	const locationsDropdownData = fetchDataByType('location')

	const findItem = useCallback((id) => {
		if (!allDataForForm || (allDataForForm && allDataForForm.length === 0))
			return null
		return allDataForForm && allDataForForm.filter(item => item._id === id).map((e) => {
			return e.name + " "
		}).join(', ')
	}, [allDataForForm])

	const findJob = useCallback((id) => {
		if (!jobs || (jobs && jobs.length === 0))
			return null
		return jobs && jobs.filter(item => item._id === id).map((e) => {
			return e.title
		}).join(', ')
	}, [jobs])

	const formattedJobs = formatJobs()

	const formatData = useCallback(() => {
		if (!candidates || (candidates && candidates.length === 0))
			return []

		return candidates && candidates.map((candidate, index) => {
			return (
				{
					id: index + 1,
					recruiterName: candidate.recruiterName,
					name: candidate.name,
					phoneNumber: candidate.phoneNumber,
					email: candidate.email,
					job: findJob(candidate.job),
					currentCTC: candidate.currentCTC,
					expectedCTC: candidate.expectedCTC,
					gender: candidate.gender,
					status: candidate.status,
					totalExperience: candidate.totalExperience,
					skills: candidate.skills.map(e => findItem(e.value)).join(', '),
					currentLocation: findItem(candidate.currentLocation),
					// preferredLocations: candidate.preferredLocations.map(e => findItem(e.value)).join(', '),
					preferredLocations: candidate.preferredLocations,
					currentCompanyName: candidate.currentCompanyName,
					noticePeriod: candidate.noticePeriod,
					data: candidate
				}
			)
		})
	}, [candidates, findItem, findJob])

	const formattedData = formatData()

	const filteredRows = formattedData && formattedData.filter((row) => {
		return ((row && row.recruiterName && row.recruiterName.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.noticePeriod && row.noticePeriod.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.phoneNumber && row.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})

	const handleSelectAll = () => {
		setSelectAll(!selectAll)
		if (!selectAll) {
			let allIds = skillsDropdownData && skillsDropdownData.map(d => ({ selected: true, value: d.value }))
			setSkills(allIds)
		}
		if (!!selectAll) {
			setSkills([])
		}
	}

	const handleSelectOption = useCallback((value) => {
		const index = selectedSkills && selectedSkills.findIndex((s) => s.value === value)
		let skills = selectedSkills && selectedSkills.map(p => p)
		if (index > -1 || !index) {
			skills && skills.splice(index, 1)
		} else {
			skills.push({ value: value, selected: true })
		}
		if (selectedSkills && skillsDropdownData && (skills.length === skillsDropdownData.length)) {
			setSelectAll(true)
		} else {
			setSelectAll(false)
		}
		setSkills(skills)
	}, [setSelectAll, selectedSkills, setSkills, skillsDropdownData])

	const handleSelectAllLocations = () => {
		setSelectAllLocations(!selectAllLocations)
		if (!selectAllLocations) {
			let allIds = locationsDropdownData && locationsDropdownData.map(d => ({ selected: true, value: d.value }))
			setLocations(allIds)
		}
		if (!!selectAllLocations) {
			setLocations([])
		}
	}

	const handleSelectLocationOption = useCallback((value) => {
		const index = selectedLocations && selectedLocations.findIndex((s) => s.value === value)
		let locations = selectedLocations && selectedLocations.map(p => p)
		if (index > -1 || !index) {
			locations && locations.splice(index, 1)
		} else {
			locations.push({ value: value, selected: true })
		}
		if (selectedLocations && locationsDropdownData && (locations.length === locationsDropdownData.length)) {
			setSelectAllLocations(true)
		} else {
			setSelectAllLocations(false)
		}
		setLocations(locations)
	}, [setSelectAllLocations, selectedLocations, setLocations, locationsDropdownData])

	const actionMarkup = ({ data }) => (
		<React.Fragment>
			<Button
				color="link"
				title="View Candidate Details"
				onClick={() => window.open(
					`${HOSTNAME}/candidate/${data._id}`,
					'_blank'
				)}
			>
				<FontAwesomeIcon icon={faEye} />
			</Button>
			{(isAdmin || isManager || isRecruiter) && <>
				<Button
					color="link"
					title="Edit Candidate"
					onClick={() => toggleAddEditCandidatesModal(data)}
				>
					<FontAwesomeIcon icon={faPencilAlt} />
				</Button>
				<Button
					color="link"
					className="ml-2 text-danger"
					title="Delete Candidate"
					onClick={() => toggleConfirmationModal(data)}
				>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			</>}
		</React.Fragment>
	)

	const toggleFilterPopup = useCallback(() => {
		setOpenFilterPopup(!openFilterPopup)
	}, [openFilterPopup])


	const makeUrlString = useCallback((values) => {
		var urlString = ""
		if (values.status)
			urlString += `status=${values.status}&`
		if (values.currentCTC)
			urlString += `currentCTC=${values.currentCTC}&`
		if (values.expectedCTC)
			urlString += `expectedCTC=${values.expectedCTC}&`
		if (values.noticePeriod)
			urlString += `noticePeriod=${values.noticePeriod}&`
		if (values.skill)
			urlString += `skill=${values.skill}`
		return urlString
	}, [])

	const onApplyFilter = useCallback((values) => {
		if (!values) {
			if (isRecruiter) {
				fetchRecruiterCandidates(userId)
					.then(async (res) => {
						const result = await res.json()
						setCandidates(result)
					})
			} else {
				fetchCandidates()
					.then(async (res) => {
						const result = await res.json()
						setCandidates(result)
					})
			}
			setFilterQuery({
				currentCTC: null,
				expectedCTC: null,
				noticePeriod: null,
				status: null,
				skill: null
			})
			return false
		} else {
			let urlString = makeUrlString(values)
			if (isRecruiter) {
				fetchRecruiterCandidates(userId, urlString)
					.then(async (res) => {
						const result = await res.json()
						setCandidates(result)
					})
			} else {
				fetchCandidates(urlString)
					.then(async (res) => {
						const result = await res.json()
						setCandidates(result)
					})
			}
			toggleFilterPopup()
			setFilterQuery(values)
		}
	}, [setFilterQuery, makeUrlString, toggleFilterPopup])

	return (
		<>
			<Container fluid>
				<Row className="mt-5">
					<Col>
						{formattedData && <TableComponent
							showCheckbox={false}
							columns={(isAdmin || isManager || isRecruiter) ? candidateColumns : candidateColumns.filter(c => c.id !== 'currentCTC' && c.id !== 'expectedCTC')}
							data={filteredRows ? filteredRows : []}
							perPage={10}
							requestSearch={requestSearch}
							cancelSearch={cancelSearch}
							defaultSort="recruiterName"
							defaultSortOrder="asc"
							showSerialNo={true}
							showFilterButton={true}
							action={actionMarkup}
							onApplyFilter={onApplyFilter}
							handleFilterPopup={toggleFilterPopup}
							searchTerm={searchTerm}
						/>}
					</Col>
				</Row>
			</Container>

			<AddEditCandidateModal
				isOpen={openAddEditModal}
				toggle={toggleAddEditCandidatesModal}
				data={selectedItem}
				onSubmit={onSubmit}
				handleSelectAll={handleSelectAll}
				handleSelectOption={handleSelectOption}
				selectedSkills={selectedSkills && selectedSkills}
				skillsDropdownData={skillsDropdownData}
				selectAll={selectAll}
				formattedJobs={formattedJobs}
				locationsDropdownData={locationsDropdownData}
				handleSelectAllLocations={handleSelectAllLocations}
				handleSelectLocationOption={handleSelectLocationOption}
				selectedLocations={selectedLocations && selectedLocations}
				selectAllLocations={selectAllLocations}
			/>

			<ConfirmationModal
				isOpen={openConfirmationPopup}
				toggle={toggleConfirmationModal}
				onConfirm={onDelete}
				message={`Are you sure you want to remove this candidate?`}
			/>

			<CandidateFilterModal
				isOpen={openFilterPopup}
				toggle={toggleFilterPopup}
				filterQuery={filterQuery}
				skillsDropdownData={skillsDropdownData}
				onSubmit={onApplyFilter}
				isAdmin={isAdmin}
				isRecruiter={isRecruiter}
				isManager={isManager}
			/>
		</>
	)
}

CandidateManagement.propTypes = {
	openAddEditModal: bool,
	toggleAddEditCandidatesModal: func.isRequired,
	selectedItem: any,
	openConfirmationPopup: bool,
	toggleConfirmationModal: func.isRequired
}

CandidateManagement.defaultProps = {
	openAddEditModal: false,
	selectedItem: null,
	openConfirmationPopup: false
}

export default CandidateManagement