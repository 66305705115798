import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
    Button,
    Form,
    FormGroup,
    Table,
    Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    DatePickerAdapter,
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AddHolidayForm = (
    {
        formID,
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
    }
) => (
    <Form onSubmit={handleSubmit} style={{ overflowX: 'auto' }}>

        <Table className="f-15 table table-striped" id="timesheet-modal">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Occassion</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <FieldArray name="holidays">
                    {({ fields }) => <Fragment>
                        {fields.map((name, index) => (
                            <tr className="w-100" key={`${name}_${index}`}>
                                <td key={`${name}_${index}_date`}>
                                    <Field
                                        name={`${name}.date`}
                                        type="date"
                                        bsSize="lg"
                                        validate={
                                            composeValidators(
                                                validateRequired(),
                                            )
                                        }
                                        component={DatePickerAdapter}
                                    />
                                    <FormFeedbackAdapter name={`${name}.date`} />
                                </td>
                                <td key={`${name}_${index}_occassion`}>
                                    <Field
                                        name={`${name}.occassion`}
                                        type="text"
                                        bsSize="lg"
                                        validate={
                                            composeValidators(
                                                validateRequired(),
                                            )
                                        }
                                        component={InputAdapter}
                                    />
                                    <FormFeedbackAdapter name={`${name}.occassion`} />
                                </td>
                                <td key={`delete_${name}_${index}.`}>
                                    <Button
                                        className="delete-button"
                                        onClick={() => fields.remove(index)}
                                        color="link">
                                        <FontAwesomeIcon icon={faMinus} className="mr-2" />
                                    </Button>
                                    <Button
                                        className="add-button"
                                        style={{ display: "none" }}
                                        color="link"
                                        onClick={() => fields.push()}
                                        disabled={submitting} size="lg">
                                        <FontAwesomeIcon icon={faPlus} className="mr-2" /></Button>
                                </td>
                            </tr>
                        ))}
                    </Fragment>}
                </FieldArray>
            </tbody>
        </Table>
        <SubmitErrorAlerts submitError={submitError} />
        <Row className="justify-content-end pr-4">
            <FormGroup>
                <Button
                    color="primary"
                    className="p-3 mr-2"
                    disabled={submitting || submitSucceeded}
                    type="submit">
                    Add</Button>
            </FormGroup>

            <FormGroup>
                <Button
                    color="primary"
                    className="p-3 mr-2"
                    disabled={submitting || submitSucceeded}
                    type="button"
                    onClick={toggle}
                >Cancel</Button>
            </FormGroup>
        </Row>
    </Form>
)

AddHolidayForm.propTypes = propTypes

export default AddHolidayForm
