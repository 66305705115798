import React from 'react'
import { userDetailtabsArray } from '../../data/profileData'
import { NavLink, NavItem, Nav } from 'reactstrap'

const UserDetailsTab = ({ selectedTab, toggleTabs }) => {
    return (
        <Nav tabs>
            {userDetailtabsArray.map(tab => (
                <NavItem key={tab.value}>
                    <NavLink
                        onClick={() => toggleTabs(tab.value)}
                        className={selectedTab === tab.value ? "active" : ''}>
                        {tab.label}
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};

export default UserDetailsTab