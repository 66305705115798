import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav, Container, Row, Col, Button } from 'reactstrap'
import CandidateManagement from '../components/recruitment/CandidateManagement'
import {
	loggingOut,
} from '../middleware/api'
import JobsManagement from '../components/recruitment/JobsManagement'
import { cryptInfo } from '../config'

const isAdmin = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'
const isManager = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'

class RecruitmentContainer extends Component {
	state = {
		openAddEditCandidateModal: false,
		openAddEditJobModal:false,
		showCandidates: true,
		isOpen: true,
		openConfirmationPopup: null,
		showJobs: false,
		selectedJobForEditOrDelete:null,
		selectedItem:null
	}

	componentDidMount() {
		loggingOut()
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	setTimings(time) {
		this.setState({
			timers: time
		})
	}

	toggleCandidatesManagement = () => {
		this.setState({
			showCandidates: true,
			showJobs: false
		})
	}

	toggleJobsManagement = () => {
		this.setState({
			showCandidates: false,
			showJobs: true
		})
	}

	toggleAddEditCandidatesModal = (candidate) => {
		this.setState(state => ({
			openAddEditCandidateModal: !state.openAddEditCandidateModal,
			selectedItem: candidate,
		}))
	}

	toggleAddEditJobModal = (job) => {
		this.setState(state => ({
			openAddEditJobModal: !state.openAddEditJobModal,
			selectedItem: job,
		}))
	}

	toggleConfirmationModal = (candidate) => {
		this.setState(state => ({
			openConfirmationPopup: !state.openConfirmationPopup,
			selectedItem: candidate,
		}))
	}

	render() {
		const {
			openAddEditCandidateModal,
			openAddEditJobModal,
			selectedItem,
			showCandidates,
			isOpen,
			openConfirmationPopup,
			showJobs } = this.state

		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						<Container fluid>
							<Row className="page-heading">
								<Col className="text-left">
									<span>{showCandidates ? 'Candidates' : 'Recruitment Management'}</span>
								</Col>
								<Col className="text-right">
									{showCandidates && <Button color="warning" size="lg" onClick={() => this.toggleAddEditCandidatesModal(null)}>
										Add New Candidate
									</Button>}
									{(showJobs && (isAdmin || isManager)) && <Button color="warning" size="lg" onClick={() => this.toggleAddEditJobModal(null)}>
										Add New Job
									</Button>}
								</Col>
							</Row>
						</Container>
						<div className="container-fluid pt-5">
							<Nav tabs className="menu-items">
								<NavItem>
									<NavLink
										onClick={this.toggleCandidatesManagement}
										className={showCandidates ? "active" : ''}>
										Candidates Management
									</NavLink>
								</NavItem>
							<NavItem>
									<NavLink
										onClick={this.toggleJobsManagement}
										className={showJobs ? "active" : ''}>
										Jobs Management
									</NavLink>
								</NavItem>
							</Nav>
							{
								showCandidates &&
								<CandidateManagement
								  openAddEditModal={openAddEditCandidateModal}
									toggleAddEditCandidatesModal={this.toggleAddEditCandidatesModal}
									toggleConfirmationModal={this.toggleConfirmationModal}
									selectedItem={selectedItem}
									openConfirmationPopup={openConfirmationPopup}
								/>
							}
							{
								showJobs &&
								<JobsManagement
									openAddEditModal={openAddEditJobModal}
									toggleAddEditJobModal={this.toggleAddEditJobModal}
									toggleConfirmationModal={this.toggleConfirmationModal}
									selectedItem={selectedItem}
									openConfirmationPopup={openConfirmationPopup}
								/>
							}
						</div>
					</Content>
				</div>
			</>
		)
	}
}

export default RecruitmentContainer