import React, { Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'


const propTypes = {
  containerClassName: PropTypes.string,
  id                : PropTypes.string.isRequired,
  type              : PropTypes.oneOf(['checkbox']).isRequired,
  label             : PropTypes.node,
  labelHidden       : PropTypes.bool.isRequired,
  invalid           : PropTypes.func,
  valid             : PropTypes.func,
}

const defaultProps = {
  labelHidden: false,
  invalid    : meta => meta.touched && meta.invalid,
  valid      : () => false,
}

const CustomCheckboxAdapter = (
  {
    containerClassName,
    id,
    disabled,
    input,
    label,
    labelHidden,
    meta,
    // validation
    invalid,
    valid,
  },
) => {

  const classes = classNames(
    containerClassName,
    'gten-checkbox',
    {
      'is-invalid': invalid(meta),
      'is-valid'  : valid(meta),
    },
  )

  return (
    <Fragment>
      <div className={ classes } title={ disabled ? 'Disabled' : '' }>
        <input id={ id } type="checkbox" { ...input } value={ meta.value } disabled={ disabled }/>
        <label htmlFor={ id }>
          <span className="faux-checkbox">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15">
              <path fill="currentColor"
                    d="M5.532 15a1 1 0 0 1-.75-.338L.25 9.533a1 1 0 0 1 1.5-1.324l3.662 4.145L13.163.455a1 1 0 1 1 1.676 1.092l-8.468 13a.995.995 0 0 1-.765.451L5.532 15z"/>
            </svg>
          </span>
          { labelHidden ? <span className="sr-only">{ label }</span> :
            <span className="gten-checkbox-label">{ label }</span> }
        </label>
      </div>
      { /*language=SCSS*/ }
      <style jsx>{ `

        // Bootstrap framework
        //////////////////////
        @import "../../styles/bootstrap-custom/jsx-import";

        .gten-checkbox {

          // hide input in a way that doesn't prevent submission
          input {
            position : absolute;
            z-index  : -1;
            opacity  : 0;
          }

          label {
            cursor        : pointer;
            padding-left  : 23px;
            position      : relative;
            margin-bottom : 0;
            min-height    : 23px;
          }

          .faux-checkbox {
            width           : 23px;
            height          : 23px;
            display         : flex;
            justify-content : center;
            align-items     : center;
            position        : absolute;
            left            : 0;
            top             : 1px;
            border          : 2px solid $gray-600;
            border-radius   : $input-border-radius;
            color           : $blue-purple;
            @include box-shadow($input-box-shadow);
            @include transition($input-transition);
          }

          svg {
            display : none;
          }

          input:disabled + label {

            cursor : default;
            color  : $light-periwinkle;

            .faux-checkbox {
              border-color : $light-periwinkle;
              color        : $light-periwinkle;
            }
          }

          input:checked + label svg {
            display : block;
          }

          input:focus + label .faux-checkbox {
            @if $enable-shadows {
              box-shadow : $input-box-shadow, $input-focus-box-shadow;
            } @else {
              box-shadow : $input-focus-box-shadow;
            }
          }

          input:invalid,
          &.is-invalid input {

            + label {
              color : $form-feedback-invalid-color;

              .faux-checkbox {
                border-color : $form-feedback-invalid-color;
              }
            }

            &:disabled + label {
              cursor : default;
              color  : $light-periwinkle;

              .faux-checkbox {
                border-color : $light-periwinkle;
                color        : $light-periwinkle;
              }
            }

            &:focus + label .faux-checkbox {
              box-shadow : 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, .25);
            }
          }
        }

        .gten-checkbox-label {
          display      : block;
          padding-left : 10px;
        }

      ` }</style>
    </Fragment>
  )
}

CustomCheckboxAdapter.defaultProps = defaultProps

CustomCheckboxAdapter.propTypes = propTypes

export default CustomCheckboxAdapter