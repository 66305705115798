import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { Container, Row, Col, Button } from 'reactstrap'
import { cryptInfo } from '../config'
import PageHeading from '../components/content/PageHeading'
import PoliciesListComponent from '../components/policies/PoliciesListComponent'

class PoliciesContainer extends Component {
	state = {
		isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
		isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
		teamMember: null,
		isOpen: true,
		openAddEditPolicyModal: false,
		openConfirmationModal: false,
		selectedType: null,
		modalType: ""
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	toggleNavbar = (selectedTab) => {
		this.setState({ selectedTab })
	}
	toggleConfirmationModal = (selectedType) => {
		this.setState((prevState) => ({
			openConfirmationModal: !prevState.openConfirmationModal,
			selectedType
		}))
	}

	toggleAddEditPolicyModal = (selectedType, modalType) => {
		this.setState((prevState => ({
			openAddEditPolicyModal: !prevState.openAddEditPolicyModal,
			selectedType,
			modalType
		})))
	}

	render() {
		const {
			isAdmin,
			isOpen,
			modalType,
			openConfirmationModal, selectedType, openAddEditPolicyModal } = this.state
		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						{isAdmin ? <Container fluid>
							<Row className="page-heading">
								<Col className="text-left">
									<span>Policies</span>
								</Col>
								<Col className="text-right">
									<Button color="warning" size="lg" onClick={() => this.toggleAddEditPolicyModal(null, 'add')}>
										Add New Policy
									</Button>
								</Col>
							</Row>
						</Container> : <PageHeading title="Policies" />}
						<div className="container-fluid pt-5">
							<div style={{ overflowX: 'auto' }}>
								<PoliciesListComponent
									openConfirmationModal={openConfirmationModal}
									selectedType={selectedType}
									modalType={modalType}
									isAdmin={isAdmin}
									toggleConfirmationModal={this.toggleConfirmationModal}
									openAddEditPolicyModal={openAddEditPolicyModal}
									toggleAddEditPolicyModal={this.toggleAddEditPolicyModal}
								/>
							</div>
						</div>
					</Content>
				</div>
			</>
		);
	}
}

export default PoliciesContainer;