import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

// Reactstrap
import {
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
	DatePickerAdapter,
	FormFeedbackAdapter,
	InputAdapter,
} from '../ffadapters'
// Final-Form Helpers
import {
	SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
	validateRequired,
} from '../../lib/validators'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}
const InterviewForm = (
	// form states that trigger render need to be in container subscription
	{
		handleSubmit,
		submitError,
		submitSucceeded,
		submitting,
		toggle,
		values
	}
) => {
	return (
		<Form onSubmit={handleSubmit} className="p-4" id="kraform">
			<Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`date`}>Date of Interview</Label>
						<Field
							name="date"
							type="date"
							bsSize="lg"
							component={DatePickerAdapter}
							validate={validateRequired()} />
						<FormFeedbackAdapter name="date" />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`time`}>Time of Interview</Label>
						<Field
							name="time"
							type="time"
							bsSize="lg"
							component={DatePickerAdapter}
							validate={validateRequired()} />
						<FormFeedbackAdapter name="time" />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`url`}>Google Meet Link</Label>
						<Field
							name="url"
							type="text"
							bsSize="lg"
							component={InputAdapter}
							validate={validateRequired()} />
						<FormFeedbackAdapter name="url" />
					</FormGroup>
				</Col>
			</Row>

			<SubmitErrorAlerts submitError={submitError} />
			<Row>
				<Col md>
					<FormGroup>
						<Button
							block
							color="primary"
							className="p-3 mr-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							type="submit">
							Notify Interview Schedule</Button>
					</FormGroup>
				</Col>

				<Col md>
					<FormGroup>

						<Button
							block
							color="outline-primary"
							className="p-3 ml-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							onClick={toggle}>Cancel</Button>
					</FormGroup>
				</Col>
			</Row>
		</Form>
	)
}

InterviewForm.propTypes = propTypes

export default InterviewForm
