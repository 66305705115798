import React, { Component } from 'react'
import {loggingOut } from '../middleware/api'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { TwitterTimelineEmbed, TwitterFollowButton} from 'react-twitter-embed';
import PageHeading from '../components/content/PageHeading';
class OurFeedContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timers: "00:00:00",
            isOpen: true
        }

        this.setTimings = this.setTimings.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }

    componentDidMount() {
        loggingOut()
    }
    render() {
        const { isOpen } = this.state
        return (
            <>
                <div className="App wrapper hide-overflow">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <PageHeading title="Our Feeds" />
                        <div className="container-fluid pt-2">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="centerContent">
                                        <div className="selfCenter" style={{ width: "95%", height: "80vh", margin: 20 }}>
                                            <div >
                                                <TwitterFollowButton screenName="gtentechnology" />
                                            </div>

                                            <TwitterTimelineEmbed
                                                sourceType="profile"
                                                screenName="gtentechnology"
                                                theme="light"
                                                autoHeight
                                                noScrollbar
                                                noFooter
                                                noBorders
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </Content>
                </div>
            </>
        );
    }
}

export default OurFeedContainer;