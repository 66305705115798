import React from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import AddKRAPage from '../components/AddKRAPage'
import { fetchUser, fetchFeedbackKRAs, fetchSubmittedKRAs } from '../middleware/api'
import PageHeading from '../components/content/PageHeading'

class UserFeedbackContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            kra: null,
            team: null,
            userKRAs: null,
            user: null,
            project: this.props.match.params.project,
            appraisal_rating: 0,
            assigned: null,
            status: null,
            isOpen:true
        }
        this.toggle = this.toggle.bind(this)
        this.setTimings = this.setTimings.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    getUserKRAs(userId) {
        var count = 0
        var savedCount = 0
        var commentCount = 0
        var countCreated = 0

        fetchFeedbackKRAs(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    userKRAs: result
                })
                result.forEach(kra => {
                    if (!kra.status)
                        countCreated++
                    if (kra.status === 'Accomplished')
                        count++
                    if (kra.status === 'Saved')
                        savedCount++
                    if (kra.status === 'Accepted' || kra.status === 'Rejected')
                        commentCount++
                })
                if (countCreated > 0) {
                    this.setState({
                        assigned: 'User',
                        status: 'Waiting for Accomplishment'
                    })
                } else {
                    if (count > 0) {
                        this.setState({
                            assigned: 'Manager',
                            status: 'Waiting for Feedback'
                        })
                    } else {
                        if (savedCount > 0) {
                            this.setState({
                                assigned: 'User',
                                status: 'Waiting for Employee Input'
                            })
                        } else {
                            if (commentCount > 0) {
                                this.setState({
                                    assigned: 'Manager',
                                    status: 'Wating for Submission'
                                })
                            } else {
                                this.setState({
                                    assigned: 'Manager',
                                    status: 'Submitted'
                                })
                            }
                        }

                    }
                }
            })
    }
    getUser(userId) {
        fetchUser(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    user: result
                })
            })
    }
    getPreviousRating(userId) {
        var count = 0
        fetchSubmittedKRAs(userId)
            .then(async (res) => {
                const result = await res.json()
                result.forEach(kra => {
                    count += ((kra.allocation * kra.rating) / 100)
                })
                this.setState({
                    appraisal_rating: count
                })
            })
    }
    componentDidMount() {
        const userId = this.props.match.params.id
        this.getUserKRAs(userId)
        this.getUser(userId)
        this.getPreviousRating(userId)

    }
    render() {
        const { userKRAs, user, project, appraisal_rating, assigned, status, isOpen } = this.state
        return (
            <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                    <PageHeading title="KRA Feedback" />
                <AddKRAPage
                    userKRAs={userKRAs && userKRAs}
                    user={user && user}
                    project={project && project}
                    getUserKRAs={this.getUserKRAs}
                    appraisal_rating={appraisal_rating && appraisal_rating}
                    assigned={assigned && assigned}
                    status={status && status}
                />
                </Content>
            </div>
        )
    }
}

export default UserFeedbackContainer