import React, { Component } from 'react'
import { Row, Col, Container, Button } from 'reactstrap'
import { fetchRequestedReimbursementsAll, updateReimbursement } from '../../middleware/reimbursement'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import TableComponent from '../elements/TableComponent'

class RequestedList extends Component {

    state = {
        reimbursements: [],
        searchTerm: ""
    }

    componentDidMount = () => {
        this.refreshList()
    }

    refreshList = async () => {
        await fetchRequestedReimbursementsAll()
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    reimbursements: result
                })
            })
    }

    approveRejectItem = async (values, type) => {
        values.status = type
        await updateReimbursement(values._id, values)
            .then(() => {
                this.refreshList()
            })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { reimbursements } = this.state
        if (!reimbursements || (reimbursements && reimbursements.length === 0))
            return []

        return reimbursements && reimbursements.map((reimbursement, index) => {
            return (
                {
                    id: index + 1,
                    employeeName: reimbursement.empName,
                    reimbursementType: reimbursement.reimbursementTypeName,
                    billNo: reimbursement.billNo,
                    billDate: reimbursement.billDate,
                    amount: reimbursement.amount,
                    userId: reimbursement.empId,
                    data: reimbursement
                }
            )
        })
    }

    render() {
        const { searchTerm, reimbursements } = this.state
        const { toggleEmployeeDetail } = this.props
        const formattedData = reimbursements && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.employeeName && row.employeeName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.billNo && row.billNo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.billDate && row.billDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.reimbursementType && row.reimbursementType.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })
        const columns = [
            {
                id: 'employeeName',
                label: 'Employee Name',
                paramToPass: 'userId',
                onClick: toggleEmployeeDetail
            },
            {
                id: 'reimbursementType',
                label: 'Reimbursement Type',
            },
            {
                id: 'billNo',
                label: 'Bill No',
            },
            {
                id: 'billDate',
                label: 'Bill Date',
            },
            {
                id: 'amount',
                label: 'Amount',
            },
        ]

        var timeStamp = + new Date()
        const actionMarkup = ({ data }) => (
            <React.Fragment>
                {data.fileUploadKeys && data.fileUploadKeys.map(file => (
                    <a
                        key={file}
                        title="View File"
                        rel="noopener noreferrer"
                        href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
                        className="text-primary"
                        target="_blank" >
                        <FontAwesomeIcon icon={faEye} />
                    </a>
                ))}
                <Button
                    color="link"
                    title="Approve"
                    className="text-primary"
                    onClick={() => this.approveRejectItem(data, 'Approved')}>
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
                <Button
                    color="link"
                    title="Reject"
                    className="text-danger"
                    onClick={() => this.approveRejectItem(data, 'Rejected')}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </React.Fragment>
        )

        return (
            <Container fluid>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            defaultSort="userName"
                            action={actionMarkup}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default RequestedList