import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

// Employee Investments Requests
export const fetchEmployeeInvestments = (employeeId) => {
	const url = `${apiRoot}/investments/${employeeId}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

//Employees by Admin
export const fetchRequestedInvestments = (employeeId) => {
	const url = `${apiRoot}/investments/requested/${employeeId}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchApprovedInvestments = (employeeId) => {
	const url = `${apiRoot}/investments/approved/${employeeId}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchRequestedInvestmentsAll = () => {
	const url = `${apiRoot}/investments/requested`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchApprovedInvestmentsAll = () => {
	const url = `${apiRoot}/investments/approved`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchInvestments = () => {
	const url = `${apiRoot}/investments`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createInvestment = (values) => {
	const url = `${apiRoot}/investments`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateInvestment = (InvestmentId, Investment) => {
	const url = `${apiRoot}/investments/${InvestmentId}`
	let method = HTTP_METHODS.PUT
	let body = Investment
	return callApi(url, method, body)
}
export const deleteInvestment = (InvestmentId) => {
	const url = `${apiRoot}/investments/${InvestmentId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}