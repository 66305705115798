import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}
const AddKRAForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        values,
        reset,
        onSave
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4" id="kraform">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`empname`}>Employee Name</Label>
                        <Field
                            name="empname"
                            type="text"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="empname" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`project`}>Project</Label>
                        <Field
                            name="project"
                            type="text"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="project" />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`doj`}>Date of Joining</Label>
                        <Field
                            name="doj"
                            type="text"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="doj" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`appraisal_rating`}>Last Appraisal Rating</Label>
                        <Field
                            name="appraisal_rating"
                            type="text"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="appraisal_rating" />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`summary`}>KRA Summary</Label>

                        <Field
                            name="summary"
                            type="textarea"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="summary" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`description`}>Description</Label>
                        <Field
                            name="description"
                            type="textarea"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="description" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`allocation`}>% Allocation</Label>

                        <Field
                            name="allocation"
                            type="number"
                            bsSize="lg"
                            min={0}
                            max={100}
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="allocation" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`measurement_criteria`}>Measurement Criteria</Label>
                        <Field
                            name="measurement_criteria"
                            type="textarea"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="measurement_criteria" />
                    </FormGroup>
                </Col>
            </Row> 
            <Row>
                <Col md className="col-md-6">
                    <FormGroup>
                        <Label htmlFor={`gaptofill`}>Gap To Fill</Label>

                        <Field
                            name="gaptofill"
                            type="textarea"
                            bsSize="lg"
                            validate={
                                composeValidators(validateRequired())
                            }
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="gaptofill" />
                    </FormGroup>
                </Col>
            </Row>
            <SubmitErrorAlerts submitError={submitError} />
            <Row>
                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="primary"
                            className="p-3 mr-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            type="submit">
                            {"Save"}</Button>
                    </FormGroup>
                </Col>

                <Col md>
                    <FormGroup>

                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={toggle}>Cancel</Button>

                    </FormGroup>
                </Col>
            </Row>


        </Form>
    )
}

AddKRAForm.propTypes = propTypes

export default AddKRAForm
