export const taskStatus = {
  active: 'Active',
  obsolete: 'Obsolete',
  suggestion: 'Suggestion',
  rejected: 'Rejected',
  deactive:'Deactive'
}

export const taskStatusArray = Object.keys(taskStatus).map(value => ({
  value,
  label: taskStatus[value]
}))