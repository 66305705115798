import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
const ConfirmationModal = ({ toggle, isOpen, onConfirm, message }) => {
    return (
        <>
            <Modal isOpen={isOpen}  size="sm">
                <ModalHeader className="font-weight-bold" tag="h2" toggle={() => toggle(null)}>Confirmation</ModalHeader>
                <ModalBody>
                    <p>{message}</p>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={onConfirm}>
                        Confirm
                    </Button>
                    <Button
                        color="primary"
                        outline
                        onClick={() => toggle(null)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ConfirmationModal