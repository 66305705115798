import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditPolicyForm from './AddEditPolicyForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

const AddEditPolicyModal = ({ isOpen, toggle, onSubmit, selectedType }) => {
	return (
		<>
			<Modal size="lg" isOpen={isOpen} toggle={() => toggle(null,null)}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Policy</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditPolicyForm}
						toggle={() => toggle(null,null)}
						onSubmit={onSubmit}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							values: true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={selectedType && { ...selectedType }}
					/>
				</ModalBody>
			</Modal>
		</>
	);
};

export default AddEditPolicyModal;