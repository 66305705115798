import React from 'react'
import {
	Modal,
	ModalHeader,
	ModalBody,
} from 'reactstrap'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import EmployeeStatusForm from './EmployeeStatusForm'

const InactiveEmployeeModal = ({ isOpen, toggle, userDetails,onSubmit }) => {
	return (
		<div>
			<Modal isOpen={isOpen} toggle={() => toggle(null)}>
				<ModalHeader toggle={() => toggle(null)} className="text-primary">
					{userDetails && userDetails.fname + " " + userDetails.lname}
				</ModalHeader>
				<ModalBody>
					<h2>Are you sure, you want to mark {userDetails && userDetails.fname} {userDetails && userDetails.lname} inactive? </h2>
					<Form
						component={EmployeeStatusForm }
						formId="employeestatus"
						toggle={toggle}
						onSubmit={onSubmit}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							values: true
						}}
						mutators={{
							...arrayMutators,
						}}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default InactiveEmployeeModal