import SimpleCrypto from "simple-crypto-js"
const _secretKey = "@d3$hkum@rpr@j@p@t1"
export const cryptInfo = new SimpleCrypto(_secretKey)
export const AWS_ACCESS_KEY_ID = 'AKIARV7DGVLMSEI5AKMB'
export const AWS_SECRET_ACCESS_KEY = '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'
export const AWS_END_POINT = 's3.amazonaws.com'
export const AWS_BUCKET_NAME = 'gtentech'
var foldername = 'localhost'
var investmentFolderName = 'localhost'
var candidateFolderName = 'localhost'
var referralFolderName = 'localhost'
var policyFolderName = 'localhost'
var hostName = window.location.hostname
var appUrl = hostName
if (hostName !== 'localhost') {
    hostName = "https://hrmsapi.gtentechnologies.com"
    appUrl = hostName
    foldername = "reimbursements"
    investmentFolderName = "investments"
    candidateFolderName = "candidates"
    policyFolderName = "policies"
    referralFolderName = "referrals"
} else {
    hostName = "http://localhost:3002"
    appUrl = "http://localhost:3000"
}
export const DOC_FOLDER = foldername
export const INVESTMENT_DOC_FOLDER = investmentFolderName
export const POLICY = policyFolderName
export const CANDIDATE_FOLDER = candidateFolderName
export const REFERRAL_FOLDER = referralFolderName
export const HOSTNAME = appUrl
export const API_CALL = hostName