import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditFamilyForm from './AddEditFamilyForm'
import { Form } from 'react-final-form'

const FamilyDetailModal = ({ onSubmit, isOpen, toggle, selectedMember }) => {
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader
                    tag="h2"
                    toggle={toggle}>Add/Edit Family Member</ModalHeader>
                <ModalBody>
                    <Form
                        component={AddEditFamilyForm}
                        id="addfamilymember"
                        toggle={toggle}
                        onSubmit={onSubmit}
                        subscription={{
                            pristine: true,
                            submitError: true,
                            submitting: true,
                            hasValidationErrors: true,
                            values: true
                        }}
                        initialValues={selectedMember}
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default FamilyDetailModal;