import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import Tasks from '../components/Tasks'
import AddTaskModal from '../components/AddTaskModal'
import { fetchTasks, loggingOut } from '../middleware/api'
import { cryptInfo } from '../config'
import { Container, Row, Col, Button } from 'reactstrap'

class TaskContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddProjectModal: false,
            tasks: null,
            task: null,
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')),
            isOpen: true
        }
        this.getTasks = this.getTasks.bind(this)
        this.toggle = this.toggle.bind(this)
        this.setTimings = this.setTimings.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    toggleModal = () => {
        this.setState(state => ({
            openAddTaskModal: !state.openAddTaskModal,
            task: null
        }))
    }

    toggleEditModal = (task) => {
        this.toggleModal()
        this.setState({
            task: task
        })
    }
    getTasks() {
        fetchTasks()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    tasks: result
                })
            })
    }
    componentDidMount() {
        loggingOut()
        this.getTasks()
    }
    render() {
        const { openAddTaskModal, task, tasks, isOpen } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Tasks</span>
                                </Col>
                                <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleModal}>
                                        Add Task
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <div className="container-fluid">
                            <Tasks
                                tasks={tasks}
                                fetchTasks={this.getTasks}
                                toggleEditModal={this.toggleEditModal}
                            />
                        </div>
                        <AddTaskModal
                            isOpen={openAddTaskModal}
                            toggle={this.toggleModal}
                            fetchTasks={this.getTasks}
                            task={task}
                        />
                    </Content>
                </div>
            </>
        );
    }
}

export default TaskContainer;