import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import EditTimesheetForm from './EditTimesheetForm'
import { Form } from 'react-final-form'
import Moment from 'react-moment'
import arrayMutators from 'final-form-arrays'
import { updateCompleteTimesheet } from '../../middleware/api'

class EditTimesheetModal extends React.Component {

    state = {
        timesheet: null,
        projects: [],
    }

    onSave = (values) => {
        const { toggleModal, activeTimesheet, fetchTimelogs } = this.props
        values.user_id = activeTimesheet.user_id
        values.identifier = activeTimesheet.identifier
        values.status = "Saved"
        values.remark = ""
        values.username = localStorage.getItem('fname') + " " + localStorage.getItem('lname')
        values.status = "Saved"
        if (activeTimesheet && activeTimesheet._id) {
            updateCompleteTimesheet(activeTimesheet._id, values)
                .then(async (response) => {
                    await toggleModal()
                    await fetchTimelogs()
                })
        }
    }
    countTotalHours = (timesheet) => {
        let count = 0
        timesheet && timesheet.timesheet.forEach(timesheet => {
            count += !isNaN(timesheet.mon) ? parseFloat(timesheet.mon) : 0
            count += !isNaN(timesheet.tue) ? parseFloat(timesheet.tue) : 0
            count += !isNaN(timesheet.wed) ? parseFloat(timesheet.wed) : 0
            count += !isNaN(timesheet.thu) ? parseFloat(timesheet.thu) : 0
            count += !isNaN(timesheet.fri) ? parseFloat(timesheet.fri) : 0
        })
        return count
    }
    onSubmit = () => {
        const { toggleModal, activeTimesheet, fetchTimelogs } = this.props
        activeTimesheet.status = "Submitted"
        if (activeTimesheet && activeTimesheet._id) {
            updateCompleteTimesheet(activeTimesheet._id, activeTimesheet)
                .then(async (response) => {
                    await toggleModal()
                    await fetchTimelogs()
                })
        }
    }

    render() {
        const { isOpen, toggleModal, activeTimesheet, projects, tasks } = this.props
        const totalHours = activeTimesheet && this.countTotalHours(activeTimesheet)
        console.log(activeTimesheet)
        const defaultValues = [{
            projects: "",
            tasks: "",
            description: "",
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0
        }]
        return (
            <div>
                <Modal size="lg" id="project-modal" isOpen={isOpen} toggle={() => toggleModal(null)}>
                    <ModalHeader tag="h3" toggle={() => toggleModal()}>Timesheet Details ({activeTimesheet && activeTimesheet.username})
                        Week: <Moment format="MM/DD">{activeTimesheet && activeTimesheet.startOfWeek}</Moment>
                        -
                        <Moment format="MM/DD" add={{ days: 4 }}>{activeTimesheet && activeTimesheet.startOfWeek}</Moment>
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            component={EditTimesheetForm}
                            formId="edittimesheet"
                            onSubmit={this.onSave}
                            onSubmitForApproval={this.onSubmit}
                            userProjectsArray={projects}
                            taskArray={tasks}
                            toggle={() => toggleModal(null)}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                            }}
                            hoursCompleted={totalHours >= 10 ? true : false}
                            submitted={(activeTimesheet &&
                                (activeTimesheet.status === 'Approved' ||
                                    activeTimesheet.status === 'Pending')) ? true : false}
                            saved={totalHours < 10 ? false : true}
                            startOfWeek={activeTimesheet && activeTimesheet.startOfWeek}
                            mutators={{
                                ...arrayMutators
                            }}
                            initialValues={
                                {
                                    timesheet: activeTimesheet && activeTimesheet.length !== 0 ? activeTimesheet.timesheet : defaultValues
                                }
                            }

                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default EditTimesheetModal;