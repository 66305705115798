import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { Row, Col } from 'reactstrap'
import TimeSheetComponent from '../components/welcome-page/TimesheetComponent'
import AttendanceComponent from '../components/welcome-page/AttendanceComponent'
import LeavesComponent from '../components/welcome-page/LeavesComponent'
//import PerformanceComponent from '../components/welcome-page/PerformanceComponent'
import SpecialDaysComponent from '../components/welcome-page/SpecialDaysComponent'
import UpcommingHolidays from '../components/welcome-page/UpcommingHolidays'
import { cryptInfo } from '../config'
import moment from 'moment'
import {
    fetchUserTimelogs,
    loggingOut,
    fetchUserLeavesLimit,
    fetchTodayHolidays,
    fetchTodayBirthdays,
    fetchTodayAnniversary,
    fetchUserKRAs,
    fetchManagerTimesheet,
    fetchManagerLeave,
    fetchUserReport,
    fetchTodayJoiningAnniversary,
    fetchAllPendingLeaves,
    fetchSubmittedTimelogs,
    fetchAllUserReportWithManager,
    fetchHolidays,
    fetchUser
} from '../middleware/api'
import PageHeading from '../components/content/PageHeading'
import EmployeeDetailModal from '../components/EmployeeDetailModal'
import { fetchRolesByName } from '../middleware/roles'
import PoliciesComponent from '../components/welcome-page/PoliciesComponent'

const role = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role'))

class WelcomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            timesheets: [],
            leaves: [],
            holidays: [],
            birthdays: [],
            anniversaries: [],
            today: new Date(),
            timers: "00:00:00",
            kras: [],
            userReports: [],
            isOpen: true,
            joininganniversaries: [],
            upcommingholidays: [],
            openDetailModal: false,
            selectedEmployee: null,
            userPermission: localStorage.getItem('permission') ? JSON.parse(localStorage.getItem('permission')) : []
        }
        this.setTimings = this.setTimings.bind(this)
        this.toggle = this.toggle.bind(this)
        this.fetchLeaves = this.fetchLeaves.bind(this)
        this.fetchTimesheet = this.fetchTimesheet.bind(this)
    }

    setTimings(time) {
        this.setState({
            timers: time
        })
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    fetchLeaves() {
        const { isAdmin, isManager } = this.state
        const userId = localStorage.getItem('user_id')
        isManager && fetchManagerLeave(userId, null)
            .then(async (res) => {
                const result = await res.json()
                this.setState({
                    leaves: result
                })
            })
        isAdmin && fetchAllPendingLeaves()
            .then(async (res) => {
                const result = await res.json()
                this.setState({
                    leaves: result
                })
            })
    }

    fetchTimesheet() {
        const { isAdmin, isManager } = this.state
        const userId = localStorage.getItem('user_id')
        isManager && fetchManagerTimesheet(userId)
            .then(async (res) => {
                const result = await res.json()
                this.setState({
                    timesheets: result
                })
            })
        isAdmin && fetchSubmittedTimelogs()
            .then(async (res) => {
                const result = await res.json()
                this.setState({
                    timesheets: result
                })
            })
    }


    componentDidMount() {
        loggingOut()
        const { isAdmin, isManager, userPermission } = this.state

        !userPermission && fetchRolesByName(role)
            .then(async (res) => {
                const roleData = await res.json()
                roleData && roleData[0] && roleData[0].permission && localStorage.setItem('permission', JSON.stringify(roleData[0].permission))
                window.location.reload()
            })
        const userId = localStorage.getItem('user_id')
        this.fetchTimesheet()
        this.fetchLeaves()
        if (isManager || isAdmin) {
            fetchAllUserReportWithManager(userId)
                .then(async (data) => {
                    const user = await data.json()
                    this.setState({
                        userReports: user
                    })
                })
        }
        else {
            fetchUserReport(userId)
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        userReports: result
                    })
                })
        }
        fetchUserKRAs(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    kras: result
                })
            })
        !isAdmin && !isManager && fetchUserTimelogs(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    timesheets: result
                })
            })
        !isAdmin && !isManager && fetchUserLeavesLimit(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    leaves: result
                })
            })
        fetchTodayHolidays(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    holidays: result
                })
            })
        fetchTodayBirthdays(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    birthdays: result
                })
            })
        fetchTodayAnniversary(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    anniversaries: result
                })
            })
        fetchTodayJoiningAnniversary(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    joininganniversaries: result
                })
            })

        fetchHolidays()
            .then(async (response) => {
                const result = await response.json()
                let upcomingHolidays = 0
                let filteredUpcomingHolidays = result.filter(item => {
                    if (moment().isBefore(item.date) && upcomingHolidays < 5) {
                        upcomingHolidays++
                        return true
                    } else {
                        return false
                    }
                })
                this.setState({
                    upcommingholidays: filteredUpcomingHolidays
                })
            })

    }

    refreshUserLogs = () => {
        const userId = localStorage.getItem('user_id')
        const { isManager, isAdmin } = this.state
        if (isManager || isAdmin) {
            fetchAllUserReportWithManager(userId)
                .then(async (data) => {
                    const user = await data.json()
                    this.setState({
                        userReports: user
                    })
                })

        } else {
            fetchUserReport(userId)
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        userReports: result
                    })
                })
        }
    }

    toggleEmployeeDetail = (selectedEmployee) => {
        if (selectedEmployee) {
            fetchUser(selectedEmployee)
                .then(async (response) => {
                    var result = await response.json()
                    this.setState({ selectedEmployee: result })
                })
        }
        this.setState((prevStat) => ({
            openDetailModal: !prevStat.openDetailModal
        }))
    }

    render() {
        const { timesheets, leaves, holidays, birthdays, selectedEmployee, openDetailModal, anniversaries, today, timers, userReports, isOpen, joininganniversaries, upcommingholidays, userPermission, isAdmin } = this.state
        return (
            <div className="App wrapper">
                <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                <Content toggle={this.toggle} isOpen={isOpen} refreshUserLogs={this.refreshUserLogs} setTimings={this.setTimings}>
                    <PageHeading title="Dashboard" />
                    <div className="container-fluid">
                        <Row className="mx-0 mt-5">
                            {userPermission.indexOf('timesheet') >= 0 && <Col className="dashboard-tiles mr-md-3">
                                <TimeSheetComponent
                                    timesheets={timesheets}
                                    fetchTimesheet={this.fetchTimesheet}
                                    toggleEmployeeDetail={this.toggleEmployeeDetail}
                                />
                            </Col>}
                            <Col className="dashboard-tiles mt-3 mt-md-0">
                                <AttendanceComponent
                                    timer={timers && timers}
                                    today={today && today}
                                    userReports={userReports && userReports}
                                    toggleEmployeeDetail={this.toggleEmployeeDetail}
                                />
                            </Col>

                        </Row>
                        <Row className="mx-0 mt-md-3">
                            {userPermission.indexOf('leave') >= 0 && <Col className="dashboard-tiles mr-md-3 mt-3 mt-md-0">
                                <LeavesComponent
                                    leaves={leaves}
                                    fetchLeaves={this.fetchLeaves}
                                    toggleEmployeeDetail={this.toggleEmployeeDetail}
                                />
                            </Col>}
                            <Col className="dashboard-tiles mt-3 mt-md-0">
                                <SpecialDaysComponent
                                    days={holidays}
                                    birthdays={birthdays && birthdays}
                                    anniversaries={anniversaries && anniversaries}
                                    joininganniversaries={joininganniversaries && joininganniversaries}
                                />
                            </Col>
                        </Row>
                        <Row className="mx-0 mt-md-3">
                            {userPermission.indexOf('holiday') >= 0 && <Col className="dashboard-tiles mr-md-3">
                                <UpcommingHolidays
                                    holidays={upcommingholidays && upcommingholidays}
                                />
                            </Col>}
                            {userPermission.indexOf('policy') >= 0 && <Col className="dashboard-tiles mt-3 mt-md-0">
                                <PoliciesComponent
                                    isAdmin={isAdmin}
                                />
                            </Col>}
                        </Row>
                    </div>
                    <EmployeeDetailModal
                        isOpen={openDetailModal}
                        toggle={this.toggleEmployeeDetail}
                        userDetails={selectedEmployee}
                    />
                </Content>
            </div>
        );
    }
}

export default WelcomeContainer;