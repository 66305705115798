import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditDataForm from './AddEditDataForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { any, bool, func } from 'prop-types'

const AddEditDataModal = ({ isOpen, formattedTypes, toggle, data, onSubmit }) => {
	return (
		<>
			<Modal id="project-modal" isOpen={isOpen} toggle={toggle}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Data</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditDataForm}
						formId="adddata"
						toggle={toggle}
						onSubmit={onSubmit}
						formattedTypes={formattedTypes}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							reset: true,
							values:true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={data}
					/>
				</ModalBody>
			</Modal>
		</>
	)
}
AddEditDataModal.propTypes = {
	isOpen: bool,
	toggle: func.isRequired,
	data: any,
}

AddEditDataModal.defaultProps = {
	isOpen: false,
	data: null,
}
export default AddEditDataModal