import React, { Component } from 'react'
import AWS from 'aws-sdk'
import { Row, Col, Container, Spinner, Button } from 'reactstrap'
import { createReimbursement, fetchReimbursementsTypes, fetchRequestedReimbursements, updateReimbursement, deleteReimbursement } from '../../middleware/reimbursement'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConfirmationModal from '../content/ConfirmationModal'
import AddReimbusrementModal from './AddReimbusrementModal'
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_END_POINT, AWS_BUCKET_NAME, DOC_FOLDER } from '../../config'
import moment from "moment"
import { getCurrentFinancialYear } from '../../data/billMonth'
import TableComponent from '../elements/TableComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'

const userId = localStorage.getItem('user_id')
const userName = localStorage.getItem('fname') + " " + localStorage.getItem('lname')

AWS.config.update({
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    region: 'ap-south-1',
  })
  
class RequestsListEmployee extends Component {

    state = {
        reimbursements: [],
        reimbursementTypes: [],
        loading: false,
        searchTerm: ""
    }

    componentDidMount = async () => {
        await fetchReimbursementsTypes()
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    reimbursementTypes: result
                })
            })
        this.refreshList()
    }

    formatReimbursementTypes = () => {
        const { reimbursementTypes } = this.state
        return reimbursementTypes && reimbursementTypes.filter(reimbursement => reimbursement.status === 'active').map((type => ({
            value: type._id,
            label: type.name,
            billRequired: type.billRequired
        })))
    }

    onSubmit = async (values) => {
        this.setState({ loading: true })
        const { reimbursementTypes } = this.state
        const { toggleAddReimbursementModal } = this.props
        values && values.reimbursements && values.reimbursements.map(async (type) => {
            type.month = type.billDate ? moment(type.billDate).format('MMM').toLowerCase() : moment().format('MMM').toLowerCase()
            type.financialYear = getCurrentFinancialYear()
            var reimbursementName = reimbursementTypes && reimbursementTypes.filter(reimbursement => {
                return reimbursement._id === type.reimbursementType
            })
            type.empId = userId
            type.status = "Requested"
            type.empName = userName
            type.reimbursementTypeName = reimbursementName && reimbursementName[0] && reimbursementName[0].name
            await this.sendingCertificateFiles(type, DOC_FOLDER, this.uploadFinalDocument)

        })
        toggleAddReimbursementModal(null)
    }

    uploadFinalDocument = async (values) => {
        try {
            if (values._id) {
                await updateReimbursement(values._id, values)
                    .then(() => {
                        toast.success("Reimbursement type successfully updated.")
                        this.refreshList()
                    })
            } else {
                await createReimbursement(values)
                    .then(() => {
                        toast.success("Reimbursement type successfully added.")
                        this.refreshList()
                    })
            }
        }
        catch (error) {
            values.fileUploadKeys && values.fileUploadKeys.map(file => {
                this.deleteS3Object(file)
                return false
            })
            toast.error("Error: Something went wrong.")
            return
        }
    }

    sendingCertificateFiles = async (values, fileType, callback) => {
        var payloadArray = []
        var files = values.fileUploadKeys && values.fileUploadKeys
        if (values.fileUploadKeys && values.fileUploadKeys.length > 0 && values.file && values.fileUploadKeys.length > values.file.length) {
            files.slice(0, values.file.length)
            var extraFiles = files.slice(values.file.length, values.fileUploadKeys.length)
            extraFiles.map(file => {
                this.deleteS3Object(file)
                return false
            })
        } else {
            files = values.fileUploadKeys
        }
        if (values.file) {
            Array.from(values.file).forEach(async (file, index) => {
                let payload
                if (files && files.length > 0 && index < files.length) {
                    payload = await this.s3Signature(files[index], fileType, userId)
                } else {
                    payload = await this.s3Signature(null, fileType, userId)
                }
                if (values.file) {
                    let uploadSuccess = await this.sendFile(file, payload)
                    if (uploadSuccess.ok === true) {
                        payloadArray.push(payload['fields'].key)
                        values.fileUploadKeys = payloadArray
                    } else {
                        console.log(uploadSuccess)
                    }
                }
                index === values.file.length - 1 && setTimeout(async () => {
                    await callback(values)
                }, 2000)
            })
        } else {
            // values.fileUploadKeys = values.fileUploadKeys
            await callback(values)
        }
    }

    deleteS3Object = async (fileKey) => {
        return new Promise((resolve, reject) => {
            try {
                let s3bucket = new AWS.S3({
                    accessKeyId: AWS_ACCESS_KEY_ID,
                    secretAccessKey: AWS_SECRET_ACCESS_KEY,
                })
                var params = { Bucket: AWS_BUCKET_NAME, Key: fileKey }
                s3bucket.deleteObject(params, function (err, data) {
                    if (err) reject(err)
                    // an error occurred
                    else resolve(data) // successful response
                })
            } catch (e) {
                reject(e)
            }
        })
    }

    s3Signature = async (fileKey, type, userId) => {
        const timeStamp = + new Date() + + new Date()
        return new Promise((resolve, reject) => {
            const s3 = new AWS.S3({
                accessKeyId: AWS_ACCESS_KEY_ID,
                secretAccessKey: AWS_SECRET_ACCESS_KEY,
            })
            const myBucket = AWS_BUCKET_NAME
            const params = {
                Bucket: myBucket,
                Fields: {
                    key: fileKey ? `${fileKey}` : `${type}/${userId}/${timeStamp.toString()}` // Any Unique Identifier
                },
                Conditions: [
                    ['content-length-range', 0, 10000000], // 10 Mb
                    { 'acl': 'public-read' },
                    ["starts-with", "$Content-Type", ""]
                ]
            }
            s3.createPresignedPost(params, function (err, data) {
                if (err) {
                    console.error('Presigning post data encountered an error', err)
                } else {
                    resolve(data)
                }
            })
        })
    }

    sendFile = async (file, payload) => {
        var form = new FormData()
        form.append('acl', 'public-read')
        for (const field in payload.fields) {
            form.append('Content-Type', file.type)
            form.append(field, payload.fields[field])
        }
        form.append('file', file)
        const response = await fetch(payload.url, {
            method: 'POST',
            body: form,
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        })
        return response
    }

    refreshList = async () => {
        await fetchRequestedReimbursements(userId)
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    reimbursements: result,
                    loading: false
                })
            })
    }

    removeReimbursementType = async () => {
        const { toggleConfirmationModal, selectedType } = this.props
        await deleteReimbursement(selectedType._id)
            .then(() => {
                toast.success("Reimbursement successfully removed.")
                selectedType && selectedType.fileUploadKeys && selectedType.fileUploadKeys.map(file => {
                    this.deleteS3Object(file)
                    return false
                })
                toggleConfirmationModal()

                this.refreshList()
            })
            .catch((err) => {
                console.log(err)
                toast.error("Error: Something went wrong.")
            })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { reimbursements } = this.state
        if (!reimbursements || (reimbursements && reimbursements.length === 0))
            return []

        return reimbursements && reimbursements.map((reimbursement, index) => {
            return (
                {
                    id: index + 1,
                    employeeName: reimbursement.empName,
                    reimbursementType: reimbursement.reimbursementTypeName,
                    billNo: reimbursement.billNo,
                    billDate: reimbursement.billDate,
                    amount: reimbursement.amount,
                    userId: reimbursement.empId,
                    data: reimbursement
                }
            )
        })
    }

    render() {
        const { reimbursements, loading, searchTerm } = this.state
        const { openConfirmationModal, selectedType, openAddReimbursementModal, toggleConfirmationModal, toggleAddReimbursementModal } = this.props
        const reimbursementType = this.formatReimbursementTypes()
        const formattedData = reimbursements && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.employeeName && row.employeeName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.billNo && row.billNo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.billDate && row.billDate.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.reimbursementType && row.reimbursementType.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })
        const columns = [
            {
                id: 'reimbursementType',
                label: 'Reimbursement Type',
            },
            {
                id: 'billNo',
                label: 'Bill No',
            },
            {
                id: 'billDate',
                label: 'Bill Date',
            },
            {
                id: 'amount',
                label: 'Amount',
            },
        ]

        var timeStamp = + new Date()
        const actionMarkup = ({ data }) => (
            <React.Fragment>
                {data.fileUploadKeys && data.fileUploadKeys.map(file => (
                    <a
                        key={file}
                        rel="noopener noreferrer"
                        href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
                        className="text-primary"
                        target="_blank" >
                        <FontAwesomeIcon icon={faEye} />
                    </a>

                ))}
                <Button
                    color="link"
                    className="text-primary"
                    onClick={() => toggleAddReimbursementModal(data)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button
                    color="link"
                    className="text-danger"
                    onClick={() => toggleConfirmationModal(data)}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </React.Fragment>
        )

        return (
            <>
                <Container fluid>
                    {loading ? <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /> :
                        <Row className="mt-5">
                            <Col>
                                {formattedData && <TableComponent
                                    showCheckbox={false}
                                    columns={columns}
                                    data={filteredRows ? filteredRows : []}
                                    perPage={10}
                                    requestSearch={this.requestSearch}
                                    cancelSearch={this.cancelSearch}
                                    showSerialNo={true}
                                    defaultSort="userName"
                                    action={actionMarkup}
                                    searchTerm={searchTerm}
                                />}
                            </Col>
                        </Row>}
                </Container>
                <ToastContainer />
                <AddReimbusrementModal
                    isOpen={openAddReimbursementModal}
                    toggle={toggleAddReimbursementModal}
                    onSubmit={this.onSubmit}
                    selectedType={selectedType}
                    reimbursementType={reimbursementType}
                />
                <ConfirmationModal
                    isOpen={openConfirmationModal}
                    toggle={toggleConfirmationModal}
                    onConfirm={this.removeReimbursementType}
                    message="Are you sure you want to remove this entry?"
                />
            </>
        )
    }
}

export default RequestsListEmployee