import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col,
    Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
    DatePickerAdapter
} from '../ffadapters'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'
import moment from 'moment'
import { familyRelationArray } from '../../data/profileData'
const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AddEditFamilyForm = (
    {
        handleSubmit,
        submitSucceeded,
        submitting,
        toggle,
        values
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`name`}>Name*</Label>
                        <Field
                            name="name"
                            type="text"
                            bsSize="lg"
                            component={InputAdapter}
                            validate={
                                composeValidators(
                                    validateRequired(),
                                )
                            }
                        />

                        <FormFeedbackAdapter name="name" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`relation`}>Relation*</Label>
                        <Field
                            name="relation"
                            type="select"
                            bsSize="lg"
                            component={SelectAdapter}
                            validate={
                                composeValidators(
                                    validateRequired(),
                                )
                            }
                            options={familyRelationArray}
                        />

                        <FormFeedbackAdapter name="relation" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label htmlFor={`dob`}>Date of Birth*</Label>
                        <Field
                            name="dob"
                            type="date"
                            bsSize="lg"
                            required={true}
                            max={moment().add(1, 'day').format('YYYY-MM-DD')}
                            component={DatePickerAdapter} />

                        <FormFeedbackAdapter name="dob" />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting || submitSucceeded}
                        size="lg"
                        type="submit"
                    >Submit</Button>
                </Col>
                <Col md>
                    <Button
                        block
                        color="outline-primary"
                        className="p-3 mt-3"
                        disabled={submitting || submitSucceeded}
                        size="lg"
                        onClick={toggle}
                    >Cancel</Button>
                </Col>

            </FormGroup>

        </Form>
    )
}

AddEditFamilyForm.propTypes = propTypes

export default AddEditFamilyForm
