import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddKRAForm from './performance/AddKRAForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR } from 'final-form'
import { createKRA, updateKRA } from '../middleware/api'
import { cryptInfo } from '../config'

class AddKRAModal extends React.Component {
    state = {
        kra: null,
        minDate: "2019-06-01"
    }

    onSubmit = (values) => {
        const { kra, fetchKRAs, toggle, fetchUserKRA, userKRACount } = this.props
        const totalAllocation = parseInt(userKRACount) + parseInt(values.allocation)
        if (totalAllocation > 100) {
            return { [FORM_ERROR]: "Error: Total percentage allocation for a user cant't be greater than 100." }
        }
        values.user = kra.userdata._id
        values.useremail = kra.userdata.email
        values.feedbackdue = true
        values.feedback = ""
        values.manager = kra.manager
        values.submitted = "not"
        values.manageremail = cryptInfo.decrypt(localStorage.getItem('email'))

        if (kra && kra._id) {
            updateKRA(kra._id, values)
                .then(async (response) => {
                    await fetchKRAs()
                    fetchUserKRA()
                    toggle()
                })
        } else {
            createKRA(values)
                .then(async (response) => {
                    await fetchKRAs()
                    fetchUserKRA(values.user)
                    toggle()
                })
        }
    }

    render() {

        const { isOpen, toggle, kra, className } = this.props
        const { minDate } = this.state
        const modalTitle = "Add KRA"
        return (
            <div>
                <Modal id="project-modal" isOpen={isOpen} toggle={toggle} className={className}>
                    <ModalHeader 
                        tag="h2"
                        toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                       <Form
                            component={AddKRAForm}
                            formId="addkra"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                                reset: true
                            }}
                            minDate={minDate}
                            mutators={{
                                ...arrayMutators,
                            }}
                            update={kra}
                            initialValues={
                                {
                                    empname: kra && kra.userdata.fname + " " + kra.userdata.lname,
                                    project: kra && kra.project,
                                    doj: kra && kra.userdata.doj ? kra.userdata.doj : "",
                                    appraisal_rating: kra && kra.userdata.appraisal_rating ? kra.userdata.appraisal_rating : ""
                                }
                            }
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddKRAModal;