import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { leavesArray } from '../../data/leaveTypeData'

// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
    DatePickerAdapter
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}
const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
)

const LeaveForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        values,
        isManager,
        isAdmin,
        reactSelectAdapter,
        formError
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4">
            {(isManager || isAdmin) &&
                <Row>
                    <Col className="col-md-6">
                        <FormGroup>
                            <Label htmlFor={`teamMember`}>Team Member</Label>
                            <Field
                                name="teamMember"
                                type="select"
                                bsSize="lg"
                                component={reactSelectAdapter}
                            />
                            <FormFeedbackAdapter name="teamMember" />
                        </FormGroup>
                    </Col>
                </Row>
            }

            <Row>
                <Col className="col-md-6">
                    <FormGroup>
                        <Label htmlFor={`type`}>Type</Label>
                        <Field
                            name="type"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={leavesArray} />

                        <FormFeedbackAdapter name="type" />
                    </FormGroup>
                </Col>

                <Col className="col-md-6">
                    <Condition when={`type`} is={`casual`}>
                        <FormGroup>
                            <Label htmlFor={`pendingCL`}>Available Casual Leave Balance</Label>
                            <Field
                                name="pendingCL"
                                type="text"
                                bsSize="lg"
                                readOnly={true}
                                component={InputAdapter} />
                            <FormFeedbackAdapter name="to" />
                        </FormGroup>
                    </Condition>
                    <Condition when={`type`} is={`earned`}>
                        <FormGroup>
                            <Label htmlFor={`pendingEL`}>Available Earned Leave Balance</Label>
                            <Field
                                name="pendingEL"
                                type="text"
                                bsSize="lg"
                                readOnly={true}
                                component={InputAdapter} />
                            <FormFeedbackAdapter name="to" />
                        </FormGroup>
                    </Condition>
                </Col>

            </Row>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`from`}>From</Label>
                        <Field
                            name="from"
                            type="date"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={DatePickerAdapter} />

                        <FormFeedbackAdapter name="from" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`to`}>To</Label>
                        <Field
                            name="to"
                            type="date"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={DatePickerAdapter} />

                        <FormFeedbackAdapter name="to" />
                    </FormGroup>
                </Col>
            </Row>
            <Condition when={`from`} is={values.to}>
                <Row>
                    <Col className="col-md-6">
                        <FormGroup>
                            <label htmlFor={`halfday`}>Halfday</label>
                            <Field
                                id="leave-checkbox"
                                name="halfday"
                                type="checkbox"
                                bsSize="lg"
                                component={InputAdapter} />
                            <FormFeedbackAdapter name="halfday" />
                        </FormGroup>
                    </Col>
                </Row>
            </Condition>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`reason`}>Reason</Label>
                        <Field
                            name="reason"
                            type="textarea"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="reason" />
                    </FormGroup>
                </Col>
            </Row>

            <SubmitErrorAlerts submitError={submitError || formError} />
            <Row>
                <Col md>
                    <FormGroup row>
                        <Button
                            block
                            color="primary"
                            className="p-3 mr-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            type="submit">
                            {"Submit"}</Button>
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup row>

                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={toggle}>Cancel</Button>

                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

LeaveForm.propTypes = propTypes

export default LeaveForm
