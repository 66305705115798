export const leaveData = { wfh: 'WFH', lwp: 'LWP', onduty: 'On Duty', earned: 'Earned Leave', casual: 'Casual Leave' }

export const leavesArray = Object.keys(leaveData).map(value => ({
    value,
    label: leaveData[value]
}))

export const leaveStatus = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Cancelled', value: 'Cancelled' },
    { label: 'Pending', value: 'Pending' }
]
