import React, { Component } from 'react'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { deleteClient, fetchProjects } from '../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cryptInfo } from '../config'
import ConfirmationModal from './content/ConfirmationModal'
import TableComponent from './elements/TableComponent'
class Clients extends Component {
    constructor() {
        super()
        this.state = {
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            projects: '',
            selectedClient: null,
            openConfirmDeleteClient: false,
            searchTerm: ""
        }
    }
    componentDidMount() {
        this.getProjects()
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { clients } = this.props
        if (!clients || (clients && clients.length === 0))
            return []

        return clients && clients.map((client, index) => {
            return (
                {
                    id: index + 1,
                    clientName: client.clientname,
                    projectStatus: this.findProjectStatus(client._id),
                    projectName: this.findProjectName(client._id),
                    data: { ...client, projectStatus: this.findProjectStatus(client._id), projectName: this.findProjectName(client._id) }
                }
            )
        })
    }

    delete = () => {
        const { fetchClients } = this.props
        const { selectedClient } = this.state
        deleteClient(selectedClient._id)
            .then(async () => {
                await fetchClients()
                toast.success("Client has been successfully removed.")
                this.toggleConfirmDeleteModal()
            })
    }

    getProjects = () => {
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    projects: result
                })

            })
    }

    findProjectName = (clientId) => {
        const { projects } = this.state
        var projName = []
        projects && projects.map((proj) => {
            if (proj.client === clientId) {
                projName.push(proj.projectname)
            }
            return false
        })
        return projName
    }

    findProjectStatus = (clientId) => {
        const { projects } = this.state
        var projectIds = []
        var selectedProjects = []
        projects && projects.map((project) => {
            if (project.client === clientId && project.status === 'active' && projectIds.indexOf(project._id) === -1) {
                selectedProjects.push(project.status)
            }
            return false
        })
        return selectedProjects.length > 0 ? 'active' : ''
    }

    filterClient = () => {
        const { clients } = this.props
        var formattedClients = []
        clients && clients.map(client => {
            client.projectStatus = this.findProjectStatus(client._id)
            client.projectName = this.findProjectName(client._id)
            formattedClients.push(client)
            return false
        })
        return formattedClients
    }

    toggleConfirmDeleteModal = (selectedClient) => {
        this.setState((prevState) => ({
            openConfirmDeleteClient: !prevState.openConfirmDeleteClient,
            selectedClient
        }))
    }
    
    render() {
        const { clients, toggleEditModal } = this.props
        const { openConfirmDeleteClient, selectedClient, searchTerm } = this.state
        const formattedData = clients && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return row && row.clientName && row.clientName.toLowerCase().includes(searchTerm.toLowerCase())
        })

        const columns = [
            {
                id: 'clientName',
                label: 'Client Name',
            }
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                <Button
                    color="link"
                    className="text-primary"
                    onClick={() => toggleEditModal(data)}
                >
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="mr-2" />

                </Button>
                <Button
                    title={data.projectStatus === "active" ? `Cannot delete as client is associated with ${data.projectName} which is an active project ` : ""}
                    color="link"
                    className="text-danger"
                    onClick={() => this.toggleConfirmDeleteModal({ ...data, projectName: data.projectName })}
                ><FontAwesomeIcon
                        icon={faTrash}
                        className="mr-2" /></Button>
            </React.Fragment>
        )
        return (
            <>
                <Container fluid >
                    <Row className="mt-5">
                        <Col>
                            {formattedData && <TableComponent
                                showCheckbox={false}
                                columns={columns}
                                data={filteredRows ? filteredRows : []}
                                perPage={10}
                                defaultSort="clientName"
                                requestSearch={this.requestSearch}
                                cancelSearch={this.cancelSearch}
                                showSerialNo={true}
                                action={actionMarkup}
                                searchTerm={searchTerm}
                            />}
                        </Col>
                    </Row>

                </Container>
                <ConfirmationModal
                    isOpen={openConfirmDeleteClient}
                    toggle={this.toggleConfirmDeleteModal}
                    onConfirm={this.delete}
                    message={`Are you sure you want to remove this client? ${selectedClient && selectedClient.projectName.length > 0 ? `This client is associated with ${selectedClient.projectName && selectedClient.projectName.join(',')}.` : ""}`}
                />
                <ToastContainer />
            </>
        )
    }
}

export default Clients