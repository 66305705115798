import React, { Component } from 'react'
import Users from '../components/Users'
import AddUserModal from '../components/AddUserModal'
import { fetchUsers, loggingOut } from '../middleware/api'
import { cryptInfo } from '../config'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { Container, Row, Col, Button } from 'reactstrap'
import { fetchRoles } from '../middleware/roles'

class UserContainer extends Component {

    state = {
        openAddUserModal: false,
        users: null,
        user: null,
        timers: "00:00:00",
        isOpen: true,
        roles: []
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    toggleModal = () => {
        this.setState(state => ({
            openAddUserModal: !state.openAddUserModal,
            user: null
        }))
    }

    setTimings = (time) => {
        this.setState({
            timers: time
        })
    }

    toggleEditModal = (user) => {
        this.toggleModal()
        this.setState({
            user: user
        })
    }

    getUser = () => {
        fetchUsers()
            .then(async (response) => {
                const result = await response.json()
                console.log(result)
                this.setState({
                    users: result
                })
            })
    }

    getRoles = () => {
        fetchRoles()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    roles: result
                })
            })
    }

    componentDidMount() {
        if (cryptInfo.decrypt(localStorage.getItem('role')) !== 'admin' && cryptInfo.decrypt(localStorage.getItem('role')) !== 'manager') {
            localStorage.clear()
            window.location.reload()
        }
        loggingOut()
        this.getUser()
        this.getRoles()
    }

    render() {
        const { openAddUserModal, user, users, isOpen, roles } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Our Employees</span>
                                </Col>
                                <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleModal}>
                                        Add Employee(s)
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <div className="container-fluid">
                            <Users
                                users={users}
                                fetchUsers={this.getUser}
                                toggleEditModal={this.toggleEditModal}
                            />
                        </div>

                        <AddUserModal
                            isOpen={openAddUserModal}
                            toggle={this.toggleModal}
                            fetchUsers={this.getUser}
                            user={user}
                            roles={roles && roles.map(role => ({ value: role.role, label: role.role.toUpperCase() }))}
                        />
                    </Content>
                </div>
            </>
        );
    }
}

export default UserContainer;