import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav, Container, Row, Col, Button } from 'reactstrap'
import {
	fetchUser,
	fetchUsers,
} from '../middleware/api'
import { cryptInfo } from '../config'
import PageHeading from '../components/content/PageHeading'
import EmployeeDetailModal from '../components/EmployeeDetailModal'
import RequestsListAdmin from '../components/investments/RequestsListAdmin'
import ApprovedInvestments from '../components/investments/ApprovedInvestments'
import Settings from '../components/reimbursement/Settings'
import InvestmentsListComp from '../components/investments/InvestmentsListComp'
import ApprovedInvestmentsEmployees from '../components/investments/ApprovedInvestmentsEmployees'
import BalanceReport from '../components/reimbursement/BalanceReport'

class InvestmentsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
			isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
			teamMember: null,
			isOpen: true,
			selectedEmployee: null,
			openDetailModal: false,
			selectedTab: 'requests',
			openAddInvestmentModal: false,
			openConfirmationModal: false,
			selectedType: null,
			modalType: ""
		}
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	getAllUsers() {
		fetchUsers()
			.then(async (response) => {
				const result = await response.json()
				this.setState({
					teamMember: result
				})
			})
	}

	toggleEmployeeDetail = (selectedEmployee) => {
		if (selectedEmployee) {
			fetchUser(selectedEmployee)
				.then(async (response) => {
					var result = await response.json()
					this.setState({ selectedEmployee: result })
				})
		}
		this.setState((prevStat) => ({
			openDetailModal: !prevStat.openDetailModal
		}))
	}

	toggleNavbar = (selectedTab) => {
		this.setState({ selectedTab })
	}
	toggleConfirmationModal = (selectedType) => {
		this.setState((prevState) => ({
			openConfirmationModal: !prevState.openConfirmationModal,
			selectedType
		}))
	}
	toggleAddInvestmentModal = (selectedType, modalType) => {
		this.setState((prevState => ({
			openAddInvestmentModal: !prevState.openAddInvestmentModal,
			selectedType,
			modalType
		})))
	}
	render() {
		const {
			isAdmin,
			isOpen,
			isManager,
			selectedTab,
			selectedEmployee,
			modalType,
			openDetailModal, openConfirmationModal, selectedType, openAddInvestmentModal } = this.state
		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						{selectedTab === 'requests' && (!isAdmin && !isManager) ? <Container fluid>
							<Row className="page-heading">
								<Col className="text-left">
									<span>Investments Details</span>
								</Col>
								<Col className="text-right">
									<Button color="warning" size="lg" onClick={() => this.toggleAddInvestmentModal(null, 'add')}>
										Add Investment
									</Button>
								</Col>
							</Row>
						</Container> : <PageHeading title="Investments Details" />}
						<div className="container-fluid pt-5">
							<Nav tabs>
								<NavItem>
									<NavLink
										onClick={() => this.toggleNavbar('requests')}
										className={selectedTab === 'requests' ? "active" : ''}>
										Requests
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										onClick={() => this.toggleNavbar('approved')}
										className={selectedTab === 'approved' ? "active" : ''}>
										Approved
									</NavLink>
								</NavItem>
							</Nav>
							<div style={{ overflowX: 'auto' }}>
								{
									selectedTab === 'requests' && (isAdmin || isManager) &&
									<RequestsListAdmin
										toggleEmployeeDetail={this.toggleEmployeeDetail}
									/>
								}
								{
									selectedTab === 'requests' && (!isAdmin && !isManager) &&
									<InvestmentsListComp
										openConfirmationModal={openConfirmationModal}
										selectedType={selectedType}
										modalType={modalType}
										toggleConfirmationModal={this.toggleConfirmationModal}
										openAddInvestmentModal={openAddInvestmentModal}
										toggleAddInvestmentModal={this.toggleAddInvestmentModal}
									/>}

								{
									selectedTab === 'approved' && (isAdmin || isManager) &&
									<ApprovedInvestments
										toggleEmployeeDetail={this.toggleEmployeeDetail}
									/>
								}
								{
									selectedTab === 'approved' && (!isAdmin && !isManager) &&
									<ApprovedInvestmentsEmployees />
								}
								{
									selectedTab === 'settings' && (isAdmin || isManager) &&
									<Settings
									/>
								}
								{
									selectedTab === 'settings' && (!isAdmin && !isManager) &&
									<BalanceReport
									/>
								}
							</div>
						</div>
						<EmployeeDetailModal
							isOpen={openDetailModal}
							toggle={this.toggleEmployeeDetail}
							userDetails={selectedEmployee}
						/>
					</Content>
				</div>
			</>
		);
	}
}

export default InvestmentsContainer;