import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

// Reactstrap
import {
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
	FormFeedbackAdapter,
	SelectAdapter,
	InputAdapter
} from '../ffadapters'
// Final-Form Helpers
import {
	SubmitErrorAlerts
} from '../ffhelpers'
import { candidateStatuses, noticePeriodData } from '../../data/recruitmentData'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}

const CandidateFilterForm = (
	// form states that trigger render need to be in container subscription
	{
		handleSubmit,
		submitError,
		submitSucceeded,
		submitting,
		toggle,
		formError,
		skillsDropdownData,
		isAdmin,
		isRecruiter,
		isManager
	}
) => {
	return (
		<Form onSubmit={handleSubmit} className="p-4">
			{(isAdmin ||
				isRecruiter ||
				isManager) && <Row>
					<Col className="col-md-6">
						<FormGroup>
							<Label htmlFor={`currentCTC`}>Current CTC</Label>
							<Field
								name="currentCTC"
								type="number"
								bsSize="lg"
								component={InputAdapter}
							/>
							<FormFeedbackAdapter name="currentCTC" />
						</FormGroup>
					</Col>
					<Col className="col-md-6">
						<FormGroup>
							<Label htmlFor={`expectedCTC`}>Expected CTC</Label>
							<Field
								name="expectedCTC"
								type="number"
								bsSize="lg"
								component={InputAdapter}
							/>
							<FormFeedbackAdapter name="expectedCTC" />
						</FormGroup>
					</Col>
				</Row>}

			<Row>
				<Col className="col-md-6">
					<FormGroup>
						<Label htmlFor={`noticePeriod`}>Notice Period</Label>
						<Field
							name="noticePeriod"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							options={noticePeriodData && noticePeriodData}
						/>
						<FormFeedbackAdapter name="noticePeriod" />
					</FormGroup>
				</Col>
				<Col className="col-md-6">
					<FormGroup>
						<Label htmlFor={`status`}>Status</Label>
						<Field
							name="status"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							options={candidateStatuses && candidateStatuses}
						/>
						<FormFeedbackAdapter name="status" />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col className="col-md-6">
					<FormGroup>
						<Label htmlFor={`skill`}>Skill</Label>
						<Field
							name="skill"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							options={skillsDropdownData}
						/>
						<FormFeedbackAdapter name="skill" />
					</FormGroup>
				</Col>
			</Row>

			<SubmitErrorAlerts submitError={submitError || formError} />
			<Row>
				<Col md>
					<FormGroup row>
						<Button
							block
							color="primary"
							className="p-3 mr-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							type="submit">
							{"Submit"}</Button>
					</FormGroup>
				</Col>
				<Col md>
					<FormGroup row>
						<Button
							block
							color="outline-primary"
							className="p-3 ml-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							onClick={toggle}>Cancel</Button>
					</FormGroup>
				</Col>
			</Row>

		</Form>
	)
}

CandidateFilterForm.propTypes = propTypes

export default CandidateFilterForm
