import React, { Component } from 'react'
import { Table } from 'reactstrap'
import Moment from 'react-moment'
import { cryptInfo } from '../../config'

class AttendanceComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'

        }
    }

    isToday(logDate) {
        const today = new Date()
        const logsDate = new Date(logDate)
        return logsDate.getDate() === today.getDate() &&
            logsDate.getMonth() === today.getMonth() &&
            logsDate.getFullYear() === today.getFullYear()
    }

    render() {
        const { timer, userReports, toggleEmployeeDetail } = this.props
        const { isManager, isAdmin } = this.state
        return (
            <>
                {(isManager || isAdmin) &&
                    <h4 >
                        Team Attendance</h4>
                }
                {!isManager && !isAdmin &&
                    <h4 title="Check in to fill your attendance for today.">
                        Attendance {timer !== '00:00:00' && `( Today :${timer} )`}</h4>
                }

                {
                    <>
                        <Table id="welcome-page-table">
                            <thead>
                                <tr>
                                    {(isManager || isAdmin) &&
                                        <th>Name</th>}
                                    <th>Date</th>
                                    <th>Check In</th>
                                    <th>Check Out</th>
                                    <th>Overall Time</th>
                                </tr>
                            </thead>
                            <tbody>{
                                userReports && userReports.length > 0 ? userReports.map((report, index) => (
                                    <tr key={report._id}>
                                        {(isManager || isAdmin) && <td style={{ cursor: 'pointer' }} onClick={() => toggleEmployeeDetail(report.user_id)}>{report.empname}</td>}
                                        <td>{this.isToday(report.date) ? <b>Today</b> : <Moment format="DD/MM/YYYY">{report.date}</Moment>}</td>
                                        <td><Moment format="HH:mm:ss">{report.signin_time}</Moment></td>
                                        <td>{report.signout_time ? <Moment format="HH:mm:ss">{report.signout_time}</Moment> : "Didn't Sign Out"}</td>
                                        <td>{report.overall_time}</td>
                                    </tr>
                                )) :
                                    <tr><td colSpan={(isAdmin || isManager) ? "5" : "4"}>Nothing to show</td></tr>
                            }
                            </tbody>
                        </Table>

                    </>
                }
            </>
        );
    }
}

export default AttendanceComponent;