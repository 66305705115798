import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const ClientForm = (
    {
        formID,
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        update,
        isAdmin,
        projects
    }
) => (
    
        <Form onSubmit={handleSubmit} className="p-4">

           {/* <FormGroup row>
                <Label htmlFor={`${formID}-client`}>Client Id</Label>

                <Field
                    name="id"
                    type="text"
                    bsSize="lg"
                    component={InputAdapter}
                />

                <FormFeedbackAdapter name="id" />
           </FormGroup> */}
            <FormGroup row>
                <Label htmlFor={`${formID}-clientname`}>Client Name</Label>

                <Field
                    name="clientname"
                    type="text"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={InputAdapter}
                />

                <FormFeedbackAdapter name="clientname" />
            </FormGroup>
            
            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                <Button
                    block
                    color="primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    type="submit"
                >{ update ? "Update Client" : " Add Client" }</Button>
                 </Col>
                 <Col md>
                <Button
                    block
                    color="outline-primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    onClick={toggle}
                >Cancel</Button>
                </Col>

            </FormGroup>

        </Form>
      
    )

    ClientForm.propTypes = propTypes

export default ClientForm
