import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchReferrals = () => {
	const url = `${apiRoot}/referrals`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchReferralForUser = (userId) => {
	const url = `${apiRoot}/referrals/${userId}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createReferral = (values) => {
	const url = `${apiRoot}/referrals`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateReferral = (referralId, referral) => {
	const url = `${apiRoot}/referrals/${referralId}`
	let method = HTTP_METHODS.PUT
	let body = referral
	return callApi(url, method, body)
}

export const deleteReferral = (referralId) => {
	const url = `${apiRoot}/referrals/${referralId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}

export const sendMailToAdmin = (data) => {
  const url = `${apiRoot}/mail/referral`
  let method = HTTP_METHODS.POST
  let body = data
  return callApi(url, method, body)
}