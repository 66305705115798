import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col, Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    DatePickerAdapter,
    SelectAdapter,
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateEmail,
    validateRequired,
    validateGtenEmail,
    validatePhoneNumber
} from '../../lib/validators'
import { bloodGroups } from '../../data/bloodGroups'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const UserProfileForm = (
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        isAdmin,
        maxDate,
        roles
    }
) => {
    console.log(roles)
    return (
        <Form onSubmit={handleSubmit} className="p-4">
            <Row>
                <Col md className="col-md-6">
                    <FormGroup>
                        <Label htmlFor={`empid`}>Employee ID</Label>
                        <Field
                            name="empid"
                            type="empid"
                            bsSize="lg"
                            readOnly={!isAdmin}
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="empid" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>

                <Col md>
                    <FormGroup>
                        <Label htmlFor={`role`}>Role</Label>
                        <Field
                            name="role"
                            type="select"
                            bsSize="lg"
                            readOnly={!isAdmin}
                            disabled={!isAdmin}
                            component={SelectAdapter}
                            options={roles}
                        />
                        <FormFeedbackAdapter name="role" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor={`email`}>Email</Label>
                        <Field
                            name="email"
                            type="email"
                            bsSize="lg"
                            readOnly={!isAdmin}
                            validate={
                                composeValidators(
                                    validateRequired(),
                                    validateEmail(),
                                    validateGtenEmail()
                                )
                            }
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="email" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`firstname`}> First Name*</Label>

                        <Field
                            id={`firstname`}
                            name="fname"
                            type="text"
                            bsSize="lg"
                            pattern="[A-Za-z]+"
                            title="Only alphabets are allowed."
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="fname" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`lastname`}>Last Name</Label>
                        <Field
                            name="lname"
                            type="text"
                            bsSize="lg"
                            pattern="[A-Za-z]+"
                            title="Only alphabets are allowed."
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="lname" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`phone`}>Phone no.</Label>
                        <Field
                            name="phone"
                            type="number"
                            title="Please don not prefix country code."
                            validate={
                                composeValidators(
                                    validatePhoneNumber()
                                )
                            }
                            bsSize="lg"
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="phone" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`bloodgroup`}>Blood Group</Label>
                        <Field
                            name="bloodgroup"
                            type="select"
                            bsSize="lg"
                            component={SelectAdapter}
                            options={bloodGroups}
                        />
                        <FormFeedbackAdapter name="bloodgroup" />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`birthday`}>Birthday</Label>
                        <Field
                            name="birthday"
                            type="date"
                            bsSize="lg"
                            max={maxDate}
                            component={DatePickerAdapter}
                        />
                        <FormFeedbackAdapter name="birthday" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor={`marriage`}>Marriage Anniversary</Label>
                        <Field
                            name="marriage"
                            type="date"
                            bsSize="lg"
                            max={maxDate}
                            component={DatePickerAdapter}
                        />
                        <FormFeedbackAdapter name="marriage" />
                    </FormGroup>
                </Col>
            </Row>
            {isAdmin &&
                <>
                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`doj`}>Date Of Joining</Label>
                                <Field
                                    name="doj"
                                    type="date"
                                    bsSize="lg"
                                    required={true}
                                    component={DatePickerAdapter}
                                />
                                <FormFeedbackAdapter name="doj" />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`olb`}>Opening Leave balance</Label>
                                <Field
                                    name="olb"
                                    type="number"
                                    bsSize="lg"
                                    step={0.5}
                                    component={InputAdapter}
                                    required={true}
                                />
                                <FormFeedbackAdapter name="olb" />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`dol`}>Date Of Leaving</Label>
                                <Field
                                    name="dol"
                                    type="date"
                                    bsSize="lg"
                                    component={DatePickerAdapter}
                                />
                                <FormFeedbackAdapter name="dol" />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`leavingreason`}>Reason for Leaving</Label>
                                <Field
                                    name="leavingreason"
                                    type="textarea"
                                    bsSize="lg"
                                    component={InputAdapter}
                                />
                                <FormFeedbackAdapter name="leavingreason" />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                </>
            }
            <Row>
          {isAdmin &&  <Col md="6">
					<FormGroup>
						<Label htmlFor={`status`}>Status</Label>
						<Field
							name="status"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							validate={validateRequired()}
							options={[{ value: 'Active', label: 'Active' }, { value: 'Inactive', label: 'Inactive' }, { value: 'Resigned', label: 'Resigned' }]} />
						<FormFeedbackAdapter name="status" />
					</FormGroup>
				</Col>}
                <Col className="text-left" md="6">
                    <FormGroup tag="fieldset">
                        <legend>Do you want to claim tax free reimbursements?</legend>
                        <FormGroup check>
                            <Label check>
                                <Field
                                    name="reimbursementClaim"
                                    type="radio"
                                    value="true"
                                    component={InputAdapter}
                                />
                                <FormFeedbackAdapter name="leavingreason" />
           Yes
          </Label>
                        </FormGroup><br />
                        <FormGroup check>
                            <Label check>
                                <Field
                                    name="reimbursementClaim"
                                    type="radio"
                                    value="false"
                                    component={InputAdapter}
                                />
                                <FormFeedbackAdapter name="leavingreason" />
            No
          </Label>
                        </FormGroup>
                    </FormGroup>
                </Col>
            </Row>
            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting || submitSucceeded}
                        size="lg"
                        type="submit"
                    >{"Save"}</Button>
                </Col>
            </FormGroup>

        </Form>
    )
}

UserProfileForm.propTypes = propTypes

export default UserProfileForm
