import React, { Component } from 'react'
import { Table } from 'reactstrap'
import Moment from 'react-moment'
import Loading from '../Loading'
class SpecialDaysComponent extends Component {
    render() {
        const { days, birthdays, anniversaries, joininganniversaries } = this.props
        return (
            <>
                <h4>Today</h4>
                {
                    days || birthdays || anniversaries || joininganniversaries ?
                        <>
                            <Table id="welcome-page-table">
                                <thead>
                                    <tr>
                                        <th><Moment format="dddd Do MMM YYYY" ></Moment></th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    days && days.map((day, index) => (
                                        <tr key={day._id}>
                                            <td>Occassion : {day.occassion}</td>
                                        </tr>
                                    ))
                                }
                                    {
                                        birthdays && birthdays.map((user, index) => (
                                            <tr key={user._id}>
                                                <td>{`Today is ${user.fname}'s birthday.`}</td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        anniversaries && anniversaries.map((user, index) => (
                                            <tr key={user._id}>
                                                <td>{`Today is ${user.fname}'s anniversary.`}</td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        joininganniversaries && joininganniversaries.map((user, index) => {
                                            var joiningYear = user.doj ? new Date(user.doj).getFullYear() : null
                                            var currentYear = new Date().getFullYear()
                                            return (
                                                <tr key={user._id}>
                                                    <td>{`Today is ${user.fname} ${user.lname}'s joining ${joiningYear && (joiningYear === currentYear) ? 'date' : 'anniversary'}.`}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {
                                        (days && days.length === 0) && (birthdays && birthdays.length === 0)
                                        && (anniversaries && anniversaries.length === 0) && (joininganniversaries && joininganniversaries.length === 0) &&

                                        <tr key={"anniversaries"}>
                                            <td>{`No event today`}</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>

                        </>
                        : <Loading />
                }
            </>
        );
    }
}

export default SpecialDaysComponent;