import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../elements/TableComponent'
import { fetchJobs, updateJob, createJob, deleteJob, fetchManagerJobs } from '../../middleware/jobs'
import { fetchUsers } from '../../middleware/api'
import { fetchAllData, fetchTypes } from '../../middleware/metadata'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddEditJobModal from './AddEditJobModal'
import { any, bool, func } from 'prop-types'
import ConfirmationModal from '../content/ConfirmationModal'
import { cryptInfo } from '../../config'
import { FORM_ERROR } from 'final-form'
import { jobsColumns } from '../../data/columnsData'

const JobsManagement = ({
	openAddEditModal,
	toggleAddEditJobModal,
	selectedItem,
	openConfirmationPopup,
	toggleConfirmationModal
}) => {
	const [searchTerm, setSearchTerm] = useState("")
	const [jobs, setJobs] = useState([])
	const [employees, setEmployees] = useState([])
	const [selectAll, setSelectAll] = useState(false)
	const [selectedPanelist, setPenalist] = useState([])
	const [allDataForForm, setAllDataForForm] = useState([])
	const [allMetadataTypes, setAllMetadataTypes] = useState([])
	const [selectAllSkills, setSelectAllSkills] = useState(false)
	const [selectedSkills, setSkills] = useState([])
	const [selectAllLocations, setSelectAllLocations] = useState(false)
	const [selectedLocations, setLocations] = useState([])

	const isAdmin = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'
	const isManager = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'
	const userId = localStorage.getItem('user_id')

	const refreshContent = useCallback(() => {
		if (isManager) {
			fetchManagerJobs(userId)
				.then(async (res) => {
					const result = await res.json()
					setJobs(result)
				})
		} else {
			fetchJobs()
				.then(async (res) => {
					const result = await res.json()
					setJobs(result)
				})
		}

		if (selectedItem) {
			setPenalist(selectedItem.interviewPanel)
			setSkills(selectedItem.mandatorySkills)
			setLocations(selectedItem.location)
		} else {
			setPenalist([])
			setSkills([])
			setLocations([])
		}
	}, [selectedItem, isManager, userId, setSkills, setLocations, setPenalist])

	const getAllEmployees = useCallback(() => {
		fetchUsers()
			.then(async (res) => {
				const result = await res.json()
				setEmployees(result)
			})
	}, [])

	const getData = useCallback(() => {
		fetchAllData()
			.then(async (res) => {
				const result = await res.json()
				setAllDataForForm(result)
			})
	}, [])

	const getAllTypes = useCallback(() => {
		fetchTypes()
			.then(async (res) => {
				const result = await res.json()
				setAllMetadataTypes(result)
			})
	}, [])

	useEffect(() => {
		refreshContent()
		getAllEmployees()
		getData()
		getAllTypes()
	}, [refreshContent, getAllEmployees, getData, getAllTypes])

	const onDelete = useCallback(() => {
		deleteJob(selectedItem._id)
			.then(async (response) => {
				refreshContent()
				toggleConfirmationModal(null)
			})
	}, [selectedItem, toggleConfirmationModal, refreshContent])

	const actionMarkup = ({ data }) => (
		<React.Fragment>
			<Button
				color="link"
				title="Edit Job"
				onClick={() => toggleAddEditJobModal(data)}
			>
				<FontAwesomeIcon icon={faPencilAlt} />
			</Button>
			<Button
				color="link"
				className="ml-2 text-danger"
				title="Delete Job"
				onClick={() => toggleConfirmationModal(data)}
			>
				<FontAwesomeIcon icon={faTrash} />
			</Button>
		</React.Fragment>
	)

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}

	const findUser = useCallback((id) => {
		if (!employees || (employees && employees.length === 0))
			return null
		return employees && employees.filter(emp => emp._id === id).map((employee) => {
			return employee.fname + " " + employee.lname
		}).join(', ')
	}, [employees])

	const formatEmployees = useCallback((type) => {
		if (!employees || (employees && employees.length === 0))
			return []
		return type ? employees && employees.filter(emp => emp.role === type).map((employee) => {
			return (
				{
					value: employee._id,
					label: employee.fname + " " + employee.lname
				}
			)
		}) : employees && employees.map((employee) => {
			return (
				{
					value: employee._id,
					label: employee.fname + " " + employee.lname
				}
			)
		})

	}, [employees])

	const managers = formatEmployees('manager')
	const interviewPenalist = formatEmployees()

	const handleSelectAll = () => {
		setSelectAll(!selectAll)
		if (!selectAll) {
			let allIds = interviewPenalist && interviewPenalist.map(d => ({ selected: true, value: d.value }))
			setPenalist(allIds)
		}
		if (!!selectAll) {
			setPenalist([])
		}
	}

	const handleSelectOption = useCallback((value) => {
		const index = selectedPanelist && selectedPanelist.findIndex((s) => s.value === value)
		let penalist = selectedPanelist && selectedPanelist.map(p => p)
		if (index > -1 || !index) {
			penalist && penalist.splice(index, 1)
		} else {
			penalist.push({ value: value, selected: true })
		}
		if (selectedPanelist && interviewPenalist && (penalist.length === interviewPenalist.length)) {
			setSelectAll(true)
		} else {
			setSelectAll(false)
		}
		setPenalist(penalist)
	}, [setSelectAll, selectedPanelist, setPenalist, interviewPenalist])

	const fetchDataByType = useCallback((type) => {
		const typeId = allMetadataTypes && allMetadataTypes.filter(e => e.name === type)
		const selectedType = typeId[0] || []
		if (!allDataForForm || (allDataForForm && allDataForForm.length === 0 && typeId.length === 0))
			return []
		return selectedType && allDataForForm && allDataForForm.filter(e => e.type === selectedType._id).map((d) => {
			return (
				{
					value: d._id,
					label: d.name
				}
			)
		})
	}, [allDataForForm, allMetadataTypes])


	const skillsDropdownData = fetchDataByType('skill')
	const locationsDropdownData = fetchDataByType('location')

	const findItem = useCallback((id) => {
		if (!allDataForForm || (allDataForForm && allDataForForm.length === 0))
			return null
		return allDataForForm && allDataForForm.filter(item => item._id === id).map((e) => {
			return e.name + " "
		}).join(', ')
	}, [allDataForForm])

	const formatData = useCallback(() => {
		if (!jobs || (jobs && jobs.length === 0))
			return []

		return jobs && jobs.map((job, index) => {
			return (
				{
					id: index + 1,
					otherSkills: job.otherSkills,
					twitter: job.twitter,
					linkedIn: job.linkedIn,
					title: job.title,
					summary: job.summary,
					description: job.description,
					experience: job.experience,
					status: job.status,
					mandatorySkills: job.mandatorySkills.map(e => findItem(e.value)).join(', '),
					location: job.location.map(e => findItem(e.value)).join(', '),
					hiringManager: findUser(job.hiringManager),
					interviewPanel: job.interviewPanel.map(e => findUser(e.value)).join(', '),
					data: job
				}
			)
		})
	}, [jobs, findUser, findItem])

	const formattedData = formatData()
	const filteredRows = formattedData && formattedData.filter((row) => {
		return ((row && row.title && row.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.experience && row.experience.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.location && row.location.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.mandatorySkills && row.mandatorySkills.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.hiringManager && row.hiringManager.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.interviewPanel && row.interviewPanel.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})

	const onSubmit = useCallback((values) => {
		if (selectedSkills.length === 0) {
			return { [FORM_ERROR]: "Please select some skills." }
		} else {
			values.mandatorySkills = selectedSkills
		}
		if (selectedLocations.length === 0) {
			return { [FORM_ERROR]: "Please select some locations." }
		} else {
			values.location = selectedLocations
		}
		values.interviewPanel = selectedPanelist
		if (values && values._id) {
			updateJob(values._id, values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditJobModal(null)
				})
		} else {
			createJob(values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditJobModal(null)
				})
		}
	}, [toggleAddEditJobModal, refreshContent, selectedPanelist, selectedSkills, selectedLocations])

	const handleSelectAllSkills = () => {
		setSelectAllSkills(!selectAllSkills)
		if (!selectAllSkills) {
			let allIds = skillsDropdownData && skillsDropdownData.map(d => ({ selected: true, value: d.value }))
			setSkills(allIds)
		}
		if (!!selectAllSkills) {
			setSkills([])
		}
	}

	const handleSelectSkills = useCallback((value) => {
		const index = selectedSkills && selectedSkills.findIndex((s) => s.value === value)
		let skills = selectedSkills && selectedSkills.map(p => p)
		if (index > -1 || !index) {
			skills && skills.splice(index, 1)
		} else {
			skills.push({ value: value, selected: true })
		}
		if (selectedSkills && skillsDropdownData && (skills.length === skillsDropdownData.length)) {
			setSelectAllSkills(true)
		} else {
			setSelectAllSkills(false)
		}
		setSkills(skills)
	}, [setSelectAllSkills, selectedSkills, setSkills, skillsDropdownData])

	const handleSelectAllLocations = () => {
		setSelectAllLocations(!selectAllLocations)
		if (!selectAllLocations) {
			let allIds = locationsDropdownData && locationsDropdownData.map(d => ({ selected: true, value: d.value }))
			setLocations(allIds)
		}
		if (!!selectAllLocations) {
			setLocations([])
		}
	}

	const handleSelectLocationOption = useCallback((value) => {
		const index = selectedLocations && selectedLocations.findIndex((s) => s.value === value)
		let locations = selectedLocations && selectedLocations.map(p => p)
		if (index > -1 || !index) {
			locations && locations.splice(index, 1)
		} else {
			locations.push({ value: value, selected: true })
		}
		if (selectedLocations && locationsDropdownData && (locations.length === locationsDropdownData.length)) {
			setSelectAllLocations(true)
		} else {
			setSelectAllLocations(false)
		}
		setLocations(locations)
	}, [setSelectAllLocations, selectedLocations, setLocations, locationsDropdownData])

	return (
		<>
			<Container fluid>
				<Row className="mt-5">
					<Col>
						{formattedData && <TableComponent
							showCheckbox={false}
							columns={jobsColumns}
							data={filteredRows ? filteredRows : []}
							perPage={10}
							requestSearch={requestSearch}
							cancelSearch={cancelSearch}
							defaultSort="title"
							defaultSortOrder="asc"
							showSerialNo={true}                                                                                                                         
							action={(isAdmin || isManager) ? actionMarkup : null}
							searchTerm={searchTerm}
						/>}
					</Col>
				</Row>
			</Container>

			<AddEditJobModal
				isOpen={openAddEditModal}
				toggle={toggleAddEditJobModal}
				data={selectedItem}
				onSubmit={onSubmit}
				handleSelectAll={handleSelectAll}
				handleSelectOption={handleSelectOption}
				selectedPanelist={selectedPanelist && selectedPanelist}
				interviewPenalist={interviewPenalist}
				selectAll={selectAll}
				managers={managers}

				handleSelectAllSkills={handleSelectAllSkills}
				handleSelectSkills={handleSelectSkills}
				selectedSkills={selectedSkills && selectedSkills}
				skillsDropdownData={skillsDropdownData}
				selectAllSkills={selectAllSkills}
				locationsDropdownData={locationsDropdownData}
				handleSelectAllLocations={handleSelectAllLocations}
				handleSelectLocationOption={handleSelectLocationOption}
				selectedLocations={selectedLocations && selectedLocations}
				selectAllLocations={selectAllLocations}

			/>

			<ConfirmationModal
				isOpen={openConfirmationPopup}
				toggle={toggleConfirmationModal}
				onConfirm={onDelete}
				message={`Are you sure you want to remove this job?`}
			/>
		</>
	)
}

JobsManagement.propTypes = {
	openAddEditModal: bool,
	toggleAddEditJobModal: func.isRequired,
	selectedItem: any,
	openConfirmationPopup: bool,
	toggleConfirmationModal: func.isRequired
}

JobsManagement.defaultProps = {
	openAddEditModal: false,
	selectedItem: null,
	openConfirmationPopup: false
}

export default JobsManagement