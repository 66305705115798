import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchTypes = () => {
	const url = `${apiRoot}/types`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchType = (id) => {
	const url = `${apiRoot}/types/${id}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createType = (values) => {
	const url = `${apiRoot}/types`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateType = (candidateId, candidate) => {
	const url = `${apiRoot}/types/${candidateId}`
	let method = HTTP_METHODS.PUT
	let body = candidate
	return callApi(url, method, body)
}

export const deleteType = (candidateId) => {
	const url = `${apiRoot}/types/${candidateId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}

export const fetchAllData = () => {
	const url = `${apiRoot}/data`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchData = (id) => {
	const url = `${apiRoot}/data/${id}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createData = (values) => {
	const url = `${apiRoot}/data`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateData = (jobId, job) => {
	const url = `${apiRoot}/data/${jobId}`
	let method = HTTP_METHODS.PUT
	let body = job
	return callApi(url, method, body)
}

export const deleteData = (jobId) => {
	const url = `${apiRoot}/data/${jobId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}