import React, { Component } from 'react'
import { Table } from 'reactstrap'
import Loading from '../../components/Loading'
import PaginationComponent from '../ShortPagination'
import { calculatePageNumber } from '../../data/dataFromApis'

class TeamMemberLeaveBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            leave: null,
            currentPage: 1,
            itemsPerPage: 5,
        }
        this.setPageNumber = this.setPageNumber.bind(this)
    }
    setPageNumber = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        })
    }

    render() {
        const { teamMember,toggleEmployeeDetail } = this.props
        const { currentPage, itemsPerPage } = this.state
        const indexOfLastItem = currentPage * itemsPerPage
        const indexOfFirstItem = indexOfLastItem - itemsPerPage
        const currentItems = teamMember && teamMember.slice(indexOfFirstItem, indexOfLastItem)
        const pageNumbers = teamMember && calculatePageNumber(teamMember)
        return (
            <>
                {
                    currentItems && currentItems.length > 0 ?
                        <div className="container scroll-table">
                            <Table bordered hover>
                                <thead style={{ backgroundColor: "#a9b5c3", color: "#FFFFFF",fontWeight:'500 !important' }}>
                                    <tr >
                                        <th>Sr. No.</th>
                                        <th>Employee Name</th>
                                        <th>Leave Balance</th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    currentItems.map((employee, index) => (
                                        <tr key={employee._id}>
                                            <td>{10 * (currentPage - 1) + index + 1}</td>
                                            <td style={{ cursor: 'pointer' }} onClick={() => toggleEmployeeDetail(employee._id)}>{employee.fname}</td>
                                            <td>{employee.leavebalanceEL ? employee.leavebalanceEL : 0}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                        : <Loading />
                }
                {
                    teamMember && teamMember.length > itemsPerPage &&
                    <PaginationComponent
                        setPageNumber={this.setPageNumber}
                        pageNumbers={pageNumbers}
                        currentPage={currentPage}
                    />
                }
            </>
        );
    }
}

export default TeamMemberLeaveBalance;