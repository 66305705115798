import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { fetchUserName, fetchClientName } from '../data/dataFromApis'
import { fetchProjects, fetchUsers, fetchClients } from '../middleware/api'
import TableComponent from '../components/elements/TableComponent'

class ActiveProjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: null,
            users: [],
            clients: [],
            searchTerm: ""
        }
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    componentDidMount() {
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    projects: result
                })
            })
        fetchUsers()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    users: result
                })
            })
        fetchClients()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    clients: result
                })
            })
    }

    formatData = () => {
        const { clients, users, projects } = this.state
        if (!projects || (projects && projects.length === 0))
            return []

        return projects && projects.map((project, index) => (
            {
                id: index + 1,
                projectName: project.projectname,
                client: fetchClientName(clients, project.client),
                projectManager: fetchUserName(users, project.projectmanager),
            }
        ))

    }

    render() {
        const { projects, users, clients, searchTerm } = this.state
        const formattedData = projects && clients && users && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return row && row.projectName && row.projectName.toLowerCase().includes(searchTerm.toLowerCase())
        })

        const columns = [
            {
                id: 'projectName',
                label: 'Project Name',
            },
            {
                id: 'client',
                label: 'Client',
            },
            {
                id: 'projectManager',
                label: 'Project Manager',
            },
        ]
        return (
            <>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            defaultSort="projectName"
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
            </>
        )
    }
}

export default ActiveProjects