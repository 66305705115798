import React, { Component } from 'react'
import { Button, Container, Row, Col } from 'reactstrap'
import { leaveData } from '../../data/leaveTypeData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateLeave, fetchUser, updateUser, sendApprovalMail } from '../../middleware/api'
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { cryptInfo } from '../../config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment';
import 'react-table-filter/lib/styles.css';
import ConfirmationModal from '../../components/content/ConfirmationModal'
import TableComponent from '../elements/TableComponent'
import ApplyFIlterModal from '../ApplyFIlterModal'

class TeammemberView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leave: null,
            selectedLeave: null,
            openConfirmCancelModal: null,
            searchTerm: ""
        }
    }

    approveLeave(leave, type) {
        const { fetchLeaves } = this.props
        leave.status = type
        if (type === 'Approved' && (leave.type === 'earned' || leave.type === 'lwp')) {
            fetchUser(leave.user)
                .then(async (response) => {
                    const user = await response.json()
                    user.leavebalanceEL = user.leavebalanceEL - leave.count
                    updateUser(user._id, user)
                        .then(async (response) => {

                        })
                })
        }
        else if (type === 'Approved' && leave.type === 'casual') {
            fetchUser(leave.user)
                .then(async (response) => {
                    const user = await response.json()
                    user.leavebalanceCL = user.leavebalanceCL - leave.count
                    updateUser(user._id, user)
                        .then(async (response) => {

                        })
                })
        }

        updateLeave(leave._id, leave)
            .then(async (response) => {
                await fetchLeaves()
                toast.success("Leave has been successfully updated.")
                sendApprovalMail({
                    subject: "Leave Approval/Rejection",
                    receiver: leave.email,
                    sender: cryptInfo.decrypt(localStorage.getItem('email')),
                    type: leaveData[leave.type],
                    reason: leave.reason,
                    from: leave.from,
                    to: leave.to,
                    status: leave.status,
                    message: `Hello ${leave.empname}, your leave(s) for ${leave.count} day(s) has been 
                    ${type}.`
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { leaves } = this.props
        if (!leaves || (leaves && leaves.length === 0))
            return []

        return leaves && leaves.map((leave, index) => {
            return (
                {
                    id: index + 1,
                    date: `${moment(leave.from).format('YYYY/MM/DD')} - ${moment(leave.to).format('YYYY/MM/DD')}`,
                    leaveType: leaveData[leave.type],
                    employeeName: leave.empname,
                    count: leave.count,
                    reason: leave.reason,
                    status: leave.status,
                    userId: leave.user,
                    appliedOn: moment(leave.created_at).format('YYYY/MM/DD'),
                    data: leave
                }
            )
        })
    }

    confirmCancelLeave = () => {
        const { selectedLeave } = this.state
        var leave = { ...selectedLeave }
        const { fetchLeaves } = this.props
        leave.status = 'Cancelled'
        if (leave.type === 'earned' || leave.type === 'lwp') {
            fetchUser(leave.user)
                .then(async (response) => {
                    const user = await response.json()
                    user.leavebalanceEL = user.leavebalanceEL + parseFloat(leave.count)
                    updateUser(user._id, user)
                        .then(async (response) => {

                        })
                })
        }
        else if (leave.type === 'casual') {
            fetchUser(leave.user)
                .then(async (response) => {
                    const user = await response.json()
                    user.leavebalanceCL = user.leavebalanceCL + parseFloat(leave.count)
                    updateUser(user._id, user)
                        .then(async (response) => {

                        })
                })
        }

        updateLeave(leave._id, leave)
            .then(async (response) => {
                await fetchLeaves()
                toast.success("Leave has been successfully cancelled.")
                sendApprovalMail({
                    subject: "Leave Cancellation",
                    receiver: leave.email,
                    sender: cryptInfo.decrypt(localStorage.getItem('email')),
                    type: leave.type,
                    reason: leave.reason,
                    from: leave.from,
                    to: leave.to,
                    status: leave.status,
                    message: `Hello ${leave.empname}, your leave(s) for ${leave.count} day(s) has been 
                    Cancelled by admin.`
                })
            })
            .catch((err) => {
                console.log(err)
            })
        this.toggleConfirmCancelModal()
    }

    toggleConfirmCancelModal = (selectedLeave) => {
        this.setState((prevState) => ({
            openConfirmCancelModal: !prevState.openConfirmCancelModal,
            selectedLeave
        }))
    }
    render() {
        const { toggleEmployeeDetail,filterQuery, leaves, openFilterModal, handleFilterPopup, onApplyFilter, users } = this.props
        const { openConfirmCancelModal, searchTerm } = this.state
        const formattedData = leaves && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.leaveType && row.leaveType.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.status && row.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.count && row.count.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.date && row.date.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.employeeName && row.employeeName.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'employeeName',
                label: 'Employee Name',
                paramToPass: 'userId',
                onClick: toggleEmployeeDetail
            },
            {
                id: 'date',
                label: 'Date',
            },
            {
                id: 'leaveType',
                label: 'Type',
            },
            {
                id: 'count',
                label: 'Days',
            },
            {
                id: 'reason',
                label: 'Reason',
            },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'appliedOn',
                label: 'Applied On',
            },
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                {data.status === "Pending" ?
                    <>
                        <Button
                            color="link"
                            onClick={() => this.approveLeave(data, 'Approved')}
                        >
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        </Button>

                        <Button
                            color="link"
                            onClick={() => this.approveLeave(data, 'Rejected')}
                        >
                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                        </Button>
                    </> :
                    data.status === 'Approved' ?
                        <Button
                            color="link"
                            onClick={() => this.toggleConfirmCancelModal(data, 'Cancel')}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />Cancel
                        </Button>
                        :
                        <></>
                }
            </React.Fragment>
        )

        return (

            <>
                <Container fluid>
                    <Row className="mt-5">
                        <Col>
                            {formattedData && <TableComponent
                                showCheckbox={false}
                                columns={columns}
                                data={filteredRows ? filteredRows : []}
                                perPage={10}
                                onApplyFilter={onApplyFilter}
                                handleFilterPopup={handleFilterPopup}
                                showFilterButton={true}
                                requestSearch={this.requestSearch}
                                cancelSearch={this.cancelSearch}
                                defaultSort="appliedOn"
                                defaultSortOrder="desc"
                                showSerialNo={true}
                                action={actionMarkup}
                                searchTerm={searchTerm}
                            />}
                        </Col>
                    </Row>
                </Container>

                <ApplyFIlterModal
                    isOpen={openFilterModal}
                    toggle={handleFilterPopup}
                    users={users}
                    filterQuery={filterQuery}
                    onSubmit={onApplyFilter}
                />
                <ConfirmationModal
                    isOpen={openConfirmCancelModal}
                    toggle={this.toggleConfirmCancelModal}
                    onConfirm={this.confirmCancelLeave}
                    message="Are you sure you want to cancel this leave?"
                />
                <ToastContainer />
            </>
        );
    }
}

export default TeammemberView;