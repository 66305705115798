import React, { useCallback } from 'react'
import {
	Modal,
	ModalHeader,
	ModalBody,
} from 'reactstrap'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import FeedbackForm from './FeedbackForm'

const FeedbackModal = ({ isOpen, toggle, onSubmit, candidate, userId }) => {

	const defFeedback = [{
		skill: "",
		feedback: "",
		other: "",
		questionAsked: "",
		rating: null
	}]

	const getFeedback = useCallback(() => {
		var allFeedbacks = (candidate && candidate.feedback) ? candidate.feedback : []
		var currentUserFeedback = allFeedbacks.filter(f => f.userId === userId)
		if (currentUserFeedback.length > 0) {
			return currentUserFeedback[0].feedback
		} else {
			return []
		}
	}, [candidate, userId])

	const initialFeedbacks = getFeedback()

	return (
		<div>
			<Modal isOpen={isOpen} size="lg" toggle={() => toggle(null)}>
				<ModalHeader toggle={() => toggle(null)} className="text-primary">
					Add/Edit Feedback
				</ModalHeader>
				<ModalBody>
					<Form
						component={FeedbackForm}
						formId="feedback"
						toggle={toggle}
						onSubmit={onSubmit}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							values: true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={{ feedback: initialFeedbacks.length > 0 ? initialFeedbacks : defFeedback }}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default FeedbackModal