import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav, Container, Row, Col, Button } from 'reactstrap'
import TypesManagement from '../components/metadata/TypesManagement'
import {
	loggingOut,
} from '../middleware/api'
import DataManagement from '../components/metadata/DataManagement'

class MetadataContainer extends Component {
	state = {
		openAddEditTypeModal: false,
		openAddEditDataModal:false,
		showTypes: true,
		isOpen: true,
		openConfirmationPopup: null,
		showData: false,
		selectedJobForEditOrDelete:null,
		selectedItem:null
	}

	componentDidMount() {
		loggingOut()
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	setTimings(time) {
		this.setState({
			timers: time
		})
	}

	toggleCandidatesManagement = () => {
		this.setState({
			showTypes: true,
			showData: false
		})
	}

	toggleDataManagement = () => {
		this.setState({
			showTypes: false,
			showData: true
		})
	}

	toggleAddEditTypeModal = (candidate) => {
		this.setState(state => ({
			openAddEditTypeModal: !state.openAddEditTypeModal,
			selectedItem: candidate,
		}))
	}

	toggleAddEditDataModal = (job) => {
		this.setState(state => ({
			openAddEditDataModal: !state.openAddEditDataModal,
			selectedItem: job,
		}))
	}

	toggleConfirmationModal = (candidate) => {
		this.setState(state => ({
			openConfirmationPopup: !state.openConfirmationPopup,
			selectedItem: candidate,
		}))
	}

	render() {
		const {
			openAddEditTypeModal,
			openAddEditDataModal,
			selectedItem,
			showTypes,
			isOpen,
			openConfirmationPopup,
			showData } = this.state

		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						<Container fluid>
							<Row className="page-heading">
								<Col className="text-left">
									<span>{showTypes ? 'Metadata Types' : 'Metadata'}</span>
								</Col>
								<Col className="text-right">
									{showTypes && <Button color="warning" size="lg" onClick={() => this.toggleAddEditTypeModal(null)}>
										Add New Type
									</Button>}
									{showData && <Button color="warning" size="lg" onClick={() => this.toggleAddEditDataModal(null)}>
										Add New Data
									</Button>}
								</Col>
							</Row>
						</Container>
						<div className="container pt-5">
							<Nav tabs className="menu-items">
								<NavItem>
									<NavLink
										onClick={this.toggleCandidatesManagement}
										className={showTypes ? "active" : ''}>
										Data Types Management
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										onClick={this.toggleDataManagement}
										className={showData ? "active" : ''}>
										Metadata Management
									</NavLink>
								</NavItem>
							</Nav>
							{
								showTypes &&
								<TypesManagement
								  openAddEditModal={openAddEditTypeModal}
									toggleAddEditTypeModal={this.toggleAddEditTypeModal}
									toggleConfirmationModal={this.toggleConfirmationModal}
									selectedItem={selectedItem}
									openConfirmationPopup={openConfirmationPopup}
								/>
							}
							{
								showData &&
								<DataManagement
									openAddEditModal={openAddEditDataModal}
									toggleAddEditDataModal={this.toggleAddEditDataModal}
									toggleConfirmationModal={this.toggleConfirmationModal}
									selectedItem={selectedItem}
									openConfirmationPopup={openConfirmationPopup}
								/>
							}
						</div>
					</Content>
				</div>
			</>
		)
	}
}

export default MetadataContainer