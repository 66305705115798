import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditJobForm from './AddEditJobForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { any, bool, func, arrayOf, object } from 'prop-types'

const AddEditJobModal = ({ isOpen,
	 toggle,
	 data,
	 onSubmit,
	 handleSelectAll,
	 handleSelectOption,
	 selectedPanelist,
	 selectAll,
	managers,
	interviewPenalist,
	handleSelectAllSkills,
	handleSelectSkills,
	selectedSkills,
	skillsDropdownData,
	selectAllSkills,
	locationsDropdownData,
	handleSelectAllLocations,
	handleSelectLocationOption,
	selectedLocations,
	selectAllLocations
 }) => {
	return (
		<>
			<Modal id="project-modal" size="lg" isOpen={isOpen} toggle={toggle}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Job</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditJobForm}
						formId="addjob"
						toggle={toggle}
						onSubmit={onSubmit}
						handleSelectAll={handleSelectAll}
						handleSelectOption={handleSelectOption}
						selectedPanelist={selectedPanelist}
						allOptions={interviewPenalist}
						selectAll={selectAll}
						managers={managers}
						interviewPenalist={interviewPenalist}

						handleSelectAllSkills={handleSelectAllSkills}
				handleSelectSkills={handleSelectSkills}
				selectedSkills={selectedSkills && selectedSkills}
				skillsDropdownData={skillsDropdownData}
				selectAllSkills={selectAllSkills}
				locationsDropdownData={locationsDropdownData}
				handleSelectAllLocations={handleSelectAllLocations}
				handleSelectLocationOption={handleSelectLocationOption}
				selectedLocations={selectedLocations && selectedLocations}
				selectAllLocations={selectAllLocations}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							reset: true,
							values:true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={data}
					/>
				</ModalBody>
			</Modal>
		</>
	)
}
AddEditJobModal.propTypes = {
	isOpen: bool,
	toggle: func.isRequired,
	data: any,
	selectedPanelist: any,
	managers: arrayOf(object).isRequired,
	interviewPenalist:arrayOf(object).isRequired
}

AddEditJobModal.defaultProps = {
	isOpen: false,
	data: null,
	selectedPanelist: []
}
export default AddEditJobModal