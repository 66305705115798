import React, { Component } from 'react'
import { GoogleLogin } from 'react-google-login'
import { Container, UncontrolledAlert, Row, Col, FormGroup, Button, Label, Input } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { loginUser } from '../middleware/api'
import { loginUserByPassword } from '../middleware/users'
import { fetchRolesByName } from '../middleware/roles'
import { cryptInfo } from '../../src/config'
import image from '../static/images/gten.png'

class LandingPageContainer extends Component {

    state = {
        isSignedIn: localStorage.getItem('isSignedin') || false,
        error: null,
        password: "",
        email: ""
    }
    setPassword = (password) => {
        this.setState({ password })
    }

    setEmail = (email) => {
        this.setState({ email })
    }

    onSubmit = async () => {
        const { password, email } = this.state
        if (!password || !email) {
            this.setState({ error: 'Please fill email id or  password.' })
            return
        }
        let values = {
            email,
            password
        }
        await loginUserByPassword(values)
            .then(async (response) => {
                const data = await response.json()
                if (!data.error) {
                    const user = data && data.user && data.user
                    const token = data && data.token && data.token
                    if (user && token) {
                        localStorage.setItem('email', cryptInfo.encrypt(user.email))
                        localStorage.setItem('fname', user.fname)
                        localStorage.setItem('lname', user.lname)
                        localStorage.setItem('image', user.image)
                        localStorage.setItem('user_id', user._id)
                        localStorage.setItem('role', cryptInfo.encrypt(user.role))
                        localStorage.setItem('isSignedin', true)
                        localStorage.setItem('reimbursementClaim', user.reimbursementClaim)
                        localStorage.setItem('token', token)

                        await fetchRolesByName(user.role)
                            .then(async (res) => {
                                const roleData = await res.json()
                                roleData && roleData[0] && roleData[0].permission && localStorage.setItem('permission', JSON.stringify(roleData[0].permission))
                            })
                        window.location.reload()
                    }
                } else {
                    this.setState({
                        error: data.message
                    })
                }
            })
            .catch(async (err) => {
                this.setState({ error: 'Something went wrong!!!' })
            })
    }

    success = async (response) => {
        let userData = {
            email: response.profileObj.email,
            image: response.profileObj.imageUrl
        }
        await loginUser(userData)
            .then(async (response) => {
                const data = await response.json()
                if (!data.error) {
                    const user = data && data.user && data.user
                    const token = data && data.token && data.token
                    if (user && token) {

                        localStorage.setItem('email', cryptInfo.encrypt(user.email))
                        localStorage.setItem('fname', user.fname)
                        localStorage.setItem('lname', user.lname)
                        localStorage.setItem('image', user.image)
                        localStorage.setItem('user_id', user._id)
                        localStorage.setItem('role', cryptInfo.encrypt(user.role))
                        localStorage.setItem('isSignedin', true)
                        localStorage.setItem('reimbursementClaim', user.reimbursementClaim)
                        localStorage.setItem('token', token)

                        await fetchRolesByName(user.role)
                            .then(async (res) => {
                                const roleData = await res.json()
                                roleData && roleData[0] && roleData[0].permission && localStorage.setItem('permission', JSON.stringify(roleData[0].permission))
                            })
                        window.location.reload()
                    }
                } else {
                    this.setState({
                        error: data.message
                    })
                }
            })
            .catch(async (err) => {
                this.setState({ error: 'Something went wrong!!!' })
            })
    }

    failure = (response) => {
        this.setState({ error: 'Something went wrong!!!' })
    }

    render() {
        const { isSignedIn, password, email } = this.state;
        if (isSignedIn) {
            return <Redirect to="/welcome" />
        }
        return (
            <Container >
                <div className="form-center">

                    <Container fluid>
                        <Row>
                            <Col className="text-center">
                                <img src={image} alt="Not Found" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="headline center">
                        {/* <h1>GTEN Time Sheet</h1> */}
                        <p className="tagline">Sign in to <strong>GTEN</strong> HRMS portal.</p>
                    </div>

                    <Container>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input
                                        type="email"
                                        value={email}
                                        onChange={(event) => this.setEmail(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Password</Label>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={(event) => this.setPassword(event.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.error && <UncontrolledAlert color="danger">
                            {this.state.error}
                        </UncontrolledAlert>}
                        <Row className="mb-2">
                            <Col className="text-center">
                                <Button color="primary" onClick={this.onSubmit}>
                                    Sign In
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <a href="/forgot-password">Forgot password?</a>.
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                If you haven't set your password yet <a href="/register">click here</a> to set password.
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-center text-lg">
                                Or
                            </Col>
                        </Row>
                    </Container>

                    <GoogleLogin className="text-uppercase btn-primary btn btn-lg btn-block text-center f-16 p-2 google-button"
                        clientId="238806895726-0g4cmj2hrk4v66iia4km6dssv6ad292m.apps.googleusercontent.com"
                        buttonText="Sign In With Google"
                        onSuccess={this.success}
                        onFailure={this.failure}
                        cookiePolicy={'single_host_origin'}
                    />
                </div>
            </Container>

        );
    }
}

export default LandingPageContainer;
