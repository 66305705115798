export const experienceData = [
	{ label: '0-1 year', value: '0-1' },
	{ label: '1-3 years', value: '1-3' },
	{ label: '2-5 years', value: '2-5' },
	{ label: '5-7 years', value: '5-7' },
	{ label: '7-9 years', value: '7-9' },
	{ label: '9+ years', value: '9+' },
]

export const noticePeriodData = [
	{ label: 'Less than 7 days', value: 'Less than 7 days' },
	{ label: '7 days', value: '7 days' },
	{ label: '15 days', value: '15 days' },
	{ label: '1 month', value: '1 month' },
	{ label: '1.5 months', value: '1.5 months' },
	{ label: '2 months', value: '2 months' },
	{ label: '2.5 months', value: '2.5 months' },
	{ label: '3 months', value: '3 months' },
	{ label: 'More than 3 months', value: 'More than 3 months' },
	{ label: 'No notice period', value: 'No notice period' },
]

export const candidateStatuses = [
	{ label: 'Interview Scheduled', value: 'Interview Scheduled' },
	{ label: 'Schedule Interview', value: 'Schedule Interview' },
	{ label: 'Interview Confirmed', value: 'Interview Confirmed' },
	{ label: '1st Tech Clear', value: '1st Tech Clear' },
	{ label: '2nd Tech Clear', value: '2nd Tech Clear' },
	{ label: 'Offered', value: 'Offered' },
	{ label: 'Not Shortlisted', value: 'Not Shortlisted' },
	{ label: 'Backout', value: 'Backout' },
	{ label: 'Not Selected', value: 'Not Selected' },
]

