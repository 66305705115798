import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchPolicies = () => {
	const url = `${apiRoot}/policies`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchActivePolicies = () => {
	const url = `${apiRoot}/policies/active`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchPoliciesHomePage = () => {
	const url = `${apiRoot}/policies/home-page`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchPoliciesHomePageAdmin = () => {
	const url = `${apiRoot}/policies/home-page/admin`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchPolicy = (id) => {
	const url = `${apiRoot}/policies/${id}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createPolicy = (values) => {
	const url = `${apiRoot}/policies`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updatePolicy = (jobId, job) => {
	const url = `${apiRoot}/policies/${jobId}`
	let method = HTTP_METHODS.PUT
	let body = job
	return callApi(url, method, body)
}

export const deletePolicy = (jobId) => {
	const url = `${apiRoot}/policies/${jobId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}