import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { fetchEmployeeFamilyMembers, createFamilyMember, updateFamilyMember, deleteFamilyMember } from '../../middleware/familymember'
import Moment from 'react-moment'
import FamilyDetailModal from './FamilyDetailModal'
import { mapFinalFormErrors } from '../../lib/utils'
import ConfirmationModal from '../content/ConfirmationModal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const mapErrors = mapFinalFormErrors('Something went wrong.')

const columns = [
    { key: 'srNo', label: 'Sr. No.' },
    { key: 'name', label: 'Name' },
    { key: 'relation', label: 'Relation' },
    { key: 'dob', label: 'Date of Birth' },
    { key: 'action', label: 'Action' }
]
class Assets extends Component {
    state = {
      familiyMembers:[]
    }
    componentDidMount = () => {
        this.refreshContent()
    }
   
    onSubmit = (values) => {
        const { userId,toggleModal } = this.props
        values.empId = userId
        if (values && values._id) {
            updateFamilyMember(values._id, values)
                .then(() => {
                    this.refreshContent()
                    toggleModal()
                    toast.success("Member successfully updated.")
                })
                .catch(() => {
                    toast.error("Error: Something went wrong.")
                    return mapErrors
                })
        } else {
            createFamilyMember(values)
                .then(() => {
                    this.refreshContent()
                    toggleModal()
                    toast.success("Member successfully added.")
                })
                .catch(() => {
                    toast.error("Error: Something went wrong.")
                    return mapErrors
                })
        }
    }

    refreshContent = () => {
        const { userId } = this.props
        fetchEmployeeFamilyMembers(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({ familiyMembers: result })
            })
    }
    removeFamilyMember = () => {
        const { selectedMember,toggleConfirmDeleteModal } = this.props
        deleteFamilyMember(selectedMember._id)
            .then(() => {
                this.refreshContent()
                toggleConfirmDeleteModal()
                toast.success("Member successfully removed.")
            })
            .catch(() => {
                toast.error("Error: Unable to remove member. Please try after sometime.")
            })
    }
    
    render() {
        const { openFamilyModal, selectedMember, openConfirmDeleteModal,toggleConfirmDeleteModal,toggleModal } = this.props
        const {familiyMembers} = this.state
        return (
            <>
                <div className="row mt-4" style={{overflowX:'auto'}}>
                    <div className="col">
                        <Table striped bordered hover>
                            <thead>
                                <tr style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                                    {columns.map(column => (
                                        <th key={column.key}>{column.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    familiyMembers && familiyMembers.length > 0 ? familiyMembers.map((member, index) => (
                                        <tr key={'row' + index}>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">{member.name}</td>
                                            <td className="text-capitalize">{member.relation}</td>
                                            <td><Moment format="DD/MM/YYYY">{member.dob}</Moment></td>
                                            <td>
                                                <Button
                                                    color="link"
                                                    title="Edit Asset"
                                                    onClick={() => toggleModal(member)}>
                                                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                                                </Button>
                                                <Button
                                                    color="link"
                                                    title="Remove Asset"
                                                    className="text-danger"
                                                    onClick={() => toggleConfirmDeleteModal(member)}>
                                                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                                </Button></td>
                                        </tr>
                                    )) :
                                        <tr><td colSpan={columns.length} className="text-center">Nothing to show</td></tr>}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <FamilyDetailModal
                    isOpen={openFamilyModal}
                    toggle={toggleModal}
                    onSubmit={this.onSubmit}
                    selectedMember={selectedMember}
                />
                <ConfirmationModal
                    isOpen={openConfirmDeleteModal}
                    toggle={toggleConfirmDeleteModal}
                    onConfirm={this.removeFamilyMember}
                    message="Are you sure you want to remove this member?"
                />
                <ToastContainer />
            </>
        )
    }
}
export default Assets;