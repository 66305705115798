import React, { Component } from 'react'
import { Button, Row, Col, Container } from 'reactstrap'
import AddCommentModal from '../AddCommentModal'
import TableComponent from '../elements/TableComponent'

class Performance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddCommentModal: false,
            kra: null,
            type: null,
            searchTerm: ""
        }
    }
    toggleCommentModal = (kra, type) => {
        this.setState(state => ({
            openAddCommentModal: !state.openAddCommentModal,
            kra: kra,
            type: type
        }))
    }
    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { kras } = this.props
        if (!kras || (kras && kras.length === 0))
            return []

        return kras && kras.map((kra, index) => {
            return (
                {
                    id: index + 1,
                    summary: kra.summary,
                    description: kra.description,
                    allocation: kra.status,
                    data: kra
                }
            )
        })
    }
    render() {
        const { kras, getUserKRAs } = this.props
        const { openAddCommentModal, kra, type, searchTerm } = this.state
        const formattedData = kras && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.summary && row.summary.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.description && row.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.allocation && row.allocation.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'summary',
                label: 'KRA Summary'
            },
            {
                id: 'description',
                label: 'Description',
            },
            {
                id: 'allocation',
                label: 'Allocation',
            }
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                {
                    data.accomplishment ?
                        <Button
                            color="primary"
                            className="mr-3 p-2"
                            disabled={(!data.feedback || data.comment) ? true : false}
                            title={!data.feedback ? "Wait for the manager to provide feedback." :
                                "KRA has been submitted."
                            }
                            onClick={() => this.toggleCommentModal(data, 'comment')}
                        >Add Comment
                        </Button> :
                        <Button
                            color="primary"
                            className="mr-3 p-2"
                            onClick={() => this.toggleCommentModal(data, 'accomplishment')}
                        >Add Accomplishment
                        </Button>
                }

                <Button
                    color="primary"
                    className="mr-3 p-2"
                    title="Click here to view the KRA."
                    disabled={data.status !== 'Submitted'}
                    onClick={() => this.toggleCommentModal(data, 'view')}
                >View KRA
                </Button>
            </React.Fragment>
        )

        return (

            <>
                <Container fluid>
                    <Row className="mt-5">
                        <Col>
                            {formattedData && <TableComponent
                                showCheckbox={false}
                                columns={columns}
                                data={filteredRows ? filteredRows : []}
                                perPage={10}
                                requestSearch={this.requestSearch}
                                cancelSearch={this.cancelSearch}
                                defaultSort="appliedOn"
                                defaultSortOrder="desc"
                                showSerialNo={true}
                                action={actionMarkup}
                                searchTerm={searchTerm}
                            />}
                        </Col>
                    </Row>
                </Container>
                <AddCommentModal
                    isOpen={openAddCommentModal}
                    toggle={this.toggleCommentModal}
                    kra={kra}
                    getUserKRAs={getUserKRAs}
                    type={type}
                />
            </>
        );
    }
}

export default Performance;