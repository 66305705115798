export const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
  }

export const callApi = (url, method, body) => {
    var requestOptions = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : "",
      }
    }
    if (body !== null) {
      requestOptions.body = JSON.stringify(body)
    }
  
    return fetch(url, requestOptions)
  }