import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav, Container, Row, Col, Button } from 'reactstrap'
import {
    fetchUser,
    fetchUsers,
} from '../middleware/api'
import { cryptInfo } from '../config'
import PageHeading from '../components/content/PageHeading'
import EmployeeDetailModal from '../components/EmployeeDetailModal'
import RequestedList from '../components/reimbursement/RequestedList'
import ApprovedList from '../components/reimbursement/ApprovedList'
import Settings from '../components/reimbursement/Settings'
import RequestsListEmployee from '../components/reimbursement/RequestsListEmployee'
import ApprovedListEmployee from '../components/reimbursement/ApprovedListEmployee'
import BalanceReport from '../components/reimbursement/BalanceReport'

class ReimbursementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            teamMember: null,
            isOpen: true,
            selectedEmployee: null,
            openDetailModal: false,
            selectedTab: 'requests',
            openAddReimbursementModal: false,
            openConfirmationModal: false,
            selectedType: null
        }
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    getAllUsers() {
        fetchUsers()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    teamMember: result
                })
            })
    }

    toggleEmployeeDetail = (selectedEmployee) => {
        if (selectedEmployee) {
            fetchUser(selectedEmployee)
                .then(async (response) => {
                    var result = await response.json()
                    this.setState({ selectedEmployee: result })
                })
        }
        this.setState((prevStat) => ({
            openDetailModal: !prevStat.openDetailModal
        }))
    }
    toggleNavbar = (selectedTab) => {
        this.setState({ selectedTab })
    }
    toggleConfirmationModal = (selectedType) => {
        this.setState((prevState) => ({
            openConfirmationModal: !prevState.openConfirmationModal,
            selectedType
        }))
    }
    toggleAddReimbursementModal = (selectedType) => {
        this.setState((prevState => ({
            openAddReimbursementModal: !prevState.openAddReimbursementModal,
            selectedType
        })))
    }
    render() {
        const {
            isAdmin,
            isOpen,
            isManager,
            selectedTab,
            selectedEmployee, openDetailModal, openConfirmationModal, selectedType, openAddReimbursementModal } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        {selectedTab === 'requests' && (!isAdmin && !isManager) ? <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Reimbursement Details</span>
                                </Col>
                                <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleAddReimbursementModal}>
                                        Add Request
                                    </Button>
                                </Col>
                            </Row>
                        </Container> : <PageHeading title="Reimbursement Details" />}
                        <div className="container-fluid pt-5">
                            <Nav tabs className="menu-items">
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggleNavbar('requests')}
                                        className={selectedTab === 'requests' ? "active" : ''}>
                                        Requests
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggleNavbar('approved')}
                                        className={selectedTab === 'approved' ? "active" : ''}>
                                        Approved
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggleNavbar('settings')}
                                        className={selectedTab === 'settings' ? "active" : ''}>
                                        {((isAdmin || isManager) && 'Settings') || ((!isAdmin && !isManager) && 'Balance Report')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div>
                                {
                                    selectedTab === 'requests' && (isAdmin || isManager) &&
                                    <RequestedList
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                                {
                                    selectedTab === 'requests' && (!isAdmin && !isManager) &&
                                    <RequestsListEmployee
                                        openConfirmationModal={openConfirmationModal}
                                        selectedType={selectedType}
                                        toggleConfirmationModal={this.toggleConfirmationModal}
                                        openAddReimbursementModal={openAddReimbursementModal}
                                        toggleAddReimbursementModal={this.toggleAddReimbursementModal}
                                    />}

                                {
                                    selectedTab === 'approved' && (isAdmin || isManager) &&
                                    <ApprovedList
                                        toggleEmployeeDetail={this.toggleEmployeeDetail}
                                    />
                                }
                                {
                                    selectedTab === 'approved' && (!isAdmin && !isManager) &&
                                    <ApprovedListEmployee />
                                }
                                {
                                    selectedTab === 'settings' && (isAdmin || isManager) &&
                                    <Settings
                                    />
                                }
                                {
                                    selectedTab === 'settings' && (!isAdmin && !isManager) &&
                                    <BalanceReport
                                    />
                                }
                            </div>
                        </div>
                        <EmployeeDetailModal
                            isOpen={openDetailModal}
                            toggle={this.toggleEmployeeDetail}
                            userDetails={selectedEmployee}
                        />
                    </Content>
                </div>
            </>
        );
    }
}

export default ReimbursementContainer;