import React from 'react'
import AddKRAFeedback from './performance/AddKRAFeedback'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { updateKRA, kraMail } from '../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cryptInfo } from '../config'

class AddKRAModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kra: null,
        }
        this.submit = this.submit.bind(this)
    }

    onSave = (values) => {
        values.kras.forEach(kra => {
            if (kra.status !== 'Submitted') {
                kra.feedbackdue = kra.feedback ? false : true
                kra.status = 'Saved'
                kra && updateKRA(kra._id, kra)
                    .then(async (response) => {
                        await toast.success("KRA(s) feedback has been successfully drafted.")
                        if (kra.feedback) {
                            kraMail({
                                subject: "KRA feedback mail",
                                receiver: kra.useremail,
                                sender: cryptInfo.decrypt(localStorage.getItem('email')),
                                message: `<p>Hello ,<br/>
                            <i>${localStorage.getItem('fname')} has just updated the feedback to the KRA. Please review/submit the KRA.</i>
                            <br/>
                            <a href="http://hrms.gtentechnologies.com/login"> LOGIN TO GTEN PORTAL</a>
                            </p>
                            `
                            })
                        }
                    })
            }
        });
    }

    submit = (values) => {
        values.kras.forEach(kra => {
            if (kra.status !== 'Submitted') {
            kra.feedbackdue = kra.feedback ? false : true
            kra.status = 'Submitted'
            kra && updateKRA(kra._id, kra)
                .then(async (response) => {
                    await toast.success("KRA(s) has been successfully submitted.")
                    kraMail({
                        subject: "KRA feedback mail",
                        receiver: kra.useremail,
                        sender: cryptInfo.decrypt(localStorage.getItem('email')),
                        message: `<p>Hello ,<br/>
                        <i>${localStorage.getItem('fname')} has just submitted the KRA. You can view this on GTEN portal..</i>
                        <br/>
                        <a href="http://hrms.gtentechnologies.com/login"> LOGIN TO GTEN PORTAL</a>
                        </p>
                        `
                    })
                })
            }
        });
    }

    onCancel = () => {
        var confirm = window.confirm("Are you sure you want to exit?")
        if (confirm === true)
            window.location.href = '/performance-home'
    }

    render() {

        const { kras, user, userKRAs, project, appraisal_rating, assigned, status } = this.props
        const defKra = {
            summary: "",
            description: "",
            allocation: "",
            measurement_criteria: "",
            gaptofill: ""
        }
        const defaultValues = kras ? {
            summary: kras && kras.summary ? kras.summary : "",
            description: kras && kras.description ? kras.description : "",
            allocation: kras && kras.allocation ? kras.allocation : "",
            measurement_criteria: kras && kras.measurement_criteria ? kras.measurement_criteria : "",
            gaptofill: kras && kras.gaptofill ? kras.gaptofill : "",
        } : { kras: [defKra] }
        return (
            <div>
                <Form
                    component={AddKRAFeedback}
                    formId="addkra"
                    onSubmit={this.submit}
                    onSubmitFeedback={this.onSave}
                    onCancel={this.onCancel}
                    subscription={{
                        pristine: true,
                        submitError: true,
                        submitting: true,
                        hasValidationErrors: true,
                        values: true
                    }}
                    user={user && user} 
                    status={status && status}
                    assigned={assigned && assigned}
                    project={project && project}
                    appraisal_rating={appraisal_rating  && appraisal_rating}
                    mutators={{
                        ...arrayMutators,
                    }}
                    initialValues={{
                        kras: userKRAs ? userKRAs : defaultValues
                    }
                    }
                />
                <ToastContainer />
            </div>
        );
    }
}

export default AddKRAModal;