import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { userRoleArray } from '../../data/userRolesData'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateEmail,
    validateRequired,
    validateGtenEmail
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const EditUserForm = (
    {
        formID,
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        update
    }
) => (
        <Form onSubmit={handleSubmit} className="p-4">
            <FormGroup row>
                <Label htmlFor={`${formID}-firstname`}> First Name</Label>

                <Field
                    id={`${formID}-firstname`}
                    name="fname"
                    type="text"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={InputAdapter}
                />

                <FormFeedbackAdapter name="fname" />
            </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-lastname`}>Last Name</Label>
                <Field
                    id={`${formID}-lastname`}
                    name="lname"
                    type="text"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={InputAdapter}
                />
                <FormFeedbackAdapter name="lname" />
            </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-email`}>Email</Label>
                <Field
                    id={`${formID}-email`}
                    name="email"
                    type="email"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                            validateEmail(),
                            validateGtenEmail()
                        )
                    }
                    component={InputAdapter}
                />
                <FormFeedbackAdapter name="email" />
            </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-role`}>Role</Label>
                <Field
                    id={`${formID}-role`}
                    name="role"
                    type="select"
                    bsSize="lg"
                    validate={validateRequired()}
                    component={SelectAdapter}
                    options={userRoleArray}
                />
                <FormFeedbackAdapter name="role" />
            </FormGroup>
            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                <Button
                    block
                    color="primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    type="submit"
                >{update ? "Update User" : " Add User"}</Button>
                </Col>
                <Col md>
                <Button
                    block
                    color="outline-primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    onClick={toggle}
                >Cancel</Button>
                </Col>
            </FormGroup>

        </Form>
    )

EditUserForm.propTypes = propTypes

export default EditUserForm
