export const groups = {
    "A+": 'A+',
    "A-": 'A-',
    "B+": 'B+',
    "B-": 'B-',
    "AB+": 'AB+',
    "AB-": 'AB-',
    "O+": 'O+',
    "O-": 'O-'
}

export const bloodGroups = Object.keys(groups).map(value => ({
    value,
    label: groups[value]
}))