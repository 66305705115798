import React, { Component } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import ContactInfoForm from './ContactInfoForm'
import { updateUser } from '../../middleware/api'
import { mapFinalFormErrors } from '../../lib/utils'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cryptInfo } from '../../config'

const mapErrors = mapFinalFormErrors('Unable to save Contact Details')

class ContactDetails extends Component {
    state = {
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
        isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
    }
    onSubmit = (values) => {
        const { isManager, isAdmin } = this.state
        const { user, toggleConfirmationPopup, fetchUser, userId } = this.props
        if (user && user._id) {
            updateUser(user._id, values)
                .then(async () => {
                    await fetchUser()
                    toast.success("Contact details have been successfully saved.")
                    if ((isAdmin || isManager) && userId !== localStorage.getItem('user_id'))
                        toggleConfirmationPopup()
                })
                .catch(mapErrors)
        }
    }
    render() {
        const { managers, projects, user } = this.props
        const defaultValues = user && user
        return (
            <div>
                <Form
                    component={ContactInfoForm}
                    formId="user-profile"
                    name="user"
                    onSubmit={this.onSubmit}
                    managerUsers={managers}
                    projectArray={projects}
                    isAdmin={this.state.isAdmin}
                    subscription={{
                        pristine: true,
                        submitError: true,
                        submitting: true,
                        hasValidationErrors: true,
                    }}
                    mutators={{
                        ...arrayMutators
                    }}
                    initialValues={defaultValues}
                />
                <ToastContainer />
            </div>
        );
    }
}

export default ContactDetails;