import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col, Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const BankDetailForm = (
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`account`}> Account Number</Label>

                        <Field
                            id={`account`}
                            name="account"
                            type="text"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="account" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`holdername`}>Name in Bank Account</Label>
                        <Field
                            id={`holdername`}
                            name="holdername"
                            type="text"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="holdername" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`bank`}> Bank Name</Label>

                        <Field
                            id={`bank`}
                            name="bank"
                            type="text"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="bank" />
                    </FormGroup>
                </Col>

                <Col md>
                    <FormGroup>
                        <Label htmlFor={`ifsc`}> Bank IFSC Code</Label>

                        <Field
                            id={`ifsc`}
                            name="ifsc"
                            type="text"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="ifsc" />
                    </FormGroup>

                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`panNumber`}> PAN Number</Label>

                        <Field
                            id={`panNumber`}
                            name="panNumber"
                            type="text"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="panNumber" />
                    </FormGroup>

                </Col>
                <Col className="col-md-6">
                    <FormGroup>
                        <Label htmlFor={`bankaddress`}>Bank Address</Label>
                        <Field
                            id={`bankaddress`}
                            name="bankaddress"
                            type="textarea"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="bankaddress" />
                    </FormGroup>
                </Col>
            </Row>

            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting || submitSucceeded}
                        size="lg"
                        type="submit"
                    >{"Save"}</Button>
                </Col>
            </FormGroup>

        </Form>
    )
}

BankDetailForm.propTypes = propTypes

export default BankDetailForm
