import React from 'react'
import { months, years } from './monthData'

const PaySlipFilters = ({ currentMonth, currentYear, setMonth, setYear, isAdmin }) => {
  return (
    <div className='d-flex'>
      {isAdmin && <select className="form-control" style={{ width: '100%' }} value={currentMonth} onChange={(event) => setMonth(event.target.value)}>
        <option value={''}>Select Month</option>
        {months && months.map((month, index) => (
          <option key={month} value={index}>{month}</option>
        ))}
      </select>}
      <select className="form-control ml-3" style={{ width: '100%' }} value={currentYear} onChange={(event) => setYear(event.target.value)}>
        <option value={''}>Select Year</option>
        {years && years.map((year, index) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>

    </div>
  )
}

export default PaySlipFilters
