import React, { Component } from 'react'
import { Button, Row, Col, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateLeave } from '../../middleware/api'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import TableComponent from '../elements/TableComponent'
import { leaveData } from '../../data/leaveTypeData'

class Leaves extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            leave: null,
            currentPage: 1,
            leavesPerPage: 10,
            searchValue: '',
            searchTerm: ""
        }
    }

    componentDidMount() {
        this.props.getUser()
    }

    cancelLeave(leave) {
        const { fetchLeaves } = this.props
        leave.status = "Cancelled"
        updateLeave(leave._id, leave)
            .then(async (response) => {
                await fetchLeaves()
                toast.info("Leave application is cancelled.")
            })
            .catch((err) => {
                console.log(err)
            })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { leaves } = this.props
        if (!leaves || (leaves && leaves.length === 0))
            return []

        return leaves && leaves.map((leave, index) => {
            return (
                {
                    id: index + 1,
                    date: `${moment(leave.from).format('YYYY/MM/DD')} - ${moment(leave.to).format('YYYY/MM/DD')}`,
                    leaveType: leaveData[leave.type],
                    count: leave.count,
                    reason: leave.reason,
                    status: leave.status,
                    appliedOn:moment(leave.created_at).format('YYYY/MM/DD'),
                    data: leave
                }
            )
        })
    }

    render() {
        const { leaves } = this.props
        const { searchTerm } = this.state
        const formattedData = leaves && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.leaveType && row.leaveType.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.status && row.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.count && row.count.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.date && row.date.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'date',
                label: 'Date',
                sort:'disabled'
            },
            {
                id: 'leaveType',
                label: 'Leave Type',
            },
            {
                id: 'count',
                label: 'Count',
            },
            {
                id: 'reason',
                label: 'Reason',
            },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'appliedOn',
                label: 'Applied On',
            },
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                <Button
                    color="link"
                    title="Cancel Leave Application"
                    disabled={data.status !== "Pending"}
                    onClick={() => this.cancelLeave(data)}
                >
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                </Button>
            </React.Fragment>
        )
        return (

            <>
                <Container fluid>
                    <Row className="mt-5">
                        <Col>
                            {formattedData && <TableComponent
                                showCheckbox={false}
                                columns={columns}
                                data={filteredRows ? filteredRows : []}
                                perPage={10}
                                requestSearch={this.requestSearch}
                                cancelSearch={this.cancelSearch}
                                defaultSort="appliedOn"
                                defaultSortOrder="desc"
                                showSerialNo={true}
                                action={actionMarkup}
                                searchTerm={searchTerm}
                            />}
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
            </>
        )
    }
}

export default Leaves