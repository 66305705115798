import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Table } from 'reactstrap'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'

const RequestsComponent = ({
    toggleAddInvestmentModal,
    toggleConfirmationModal,
    investments
}) => {
    var timeStamp = + new Date()
    return (
        <>
            <Table striped hover bordered className="f-15">
                <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                    <tr>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>File</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        investments && investments.length > 0 ? investments.map(investment => (
                            <tr key={investment._id}>
                                <td>{investment.investmentTitle}</td>
                                <td>{investment.investmentDate}</td>
                                <td>{investment.investmentAmount}</td>
                                <td>{investment.fileUploadKeys && investment.fileUploadKeys.map(file => (
                                    <a
                                        key={file}
                                        rel="noopener noreferrer"
                                        href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
                                        className="text-primary"
                                        target="_blank" >
                                        <FontAwesomeIcon icon={faEye} />
                                    </a>

                                ))}</td>
                                <td>
                                    <Button
                                        color="link"
                                        className="text-primary"
                                        onClick={() => toggleAddInvestmentModal(investment, 'edit')}>
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Button>
                                    <Button
                                        color="link"
                                        className="text-danger"
                                        onClick={() => toggleConfirmationModal(investment)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </td>
                            </tr>
                        )) :
                            <tr><td colSpan="8">Nothing to show</td></tr>
                    }
                </tbody>
            </Table>
        </>
    );
};

export default RequestsComponent;