import React from 'react'
import {
	Modal,
	ModalHeader,
	ModalBody,
} from 'reactstrap'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import InterviewForm from './InterviewForm'

const InterviewModal = ({ isOpen, toggle, userDetails, onSubmit }) => {
	return (
		<div>
			<Modal isOpen={isOpen} toggle={() => toggle(null)}>
				<ModalHeader toggle={() => toggle(null)} className="text-primary">
					Schedule Interview
				</ModalHeader>
				<ModalBody>
					<Form
						component={InterviewForm}
						formId="interview"
						toggle={toggle}
						onSubmit={onSubmit}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							values: true
						}}
						mutators={{
							...arrayMutators,
						}}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
}

export default InterviewModal