import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import Moment from 'react-moment'
import { cryptInfo } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import TimesheetModal from '../timesheet/TimesheetModal'
import { fetchProjects, fetchTasks } from '../../middleware/api'
class TimesheetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin' ||
                cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            openViewModal: false,
            currentTimesheet: null,
            projects: null,
            tasks: []
        }
        this.toggleTimesheetModal = this.toggleTimesheetModal.bind(this);
    }

    toggleTimesheetModal(currentTimesheet) {
        const { fetchTimesheet } = this.props
        this.setState(state => ({
            openViewModal: !state.openViewModal,
            currentTimesheet: currentTimesheet,
        }))
        fetchTimesheet()
    }
    componentDidMount() {
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    projects: result
                })
            })
        fetchTasks()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    tasks: result
                })
            })
    }
    render() {
        const { timesheets, toggleEmployeeDetail } = this.props
        const { isAdmin, openViewModal, projects, tasks, currentTimesheet } = this.state
        return (
            <>
                <h4 className="text-center font-weight-bold">
                    <a href="timesheet-home" title="Click here to go to timesheet management.">Timesheets</a>
                </h4>
                {

                    <>
                        <Table id="welcome-page-table">
                            <thead>
                                {isAdmin && <tr>
                                    <th>Name</th>
                                    <th>Week</th>
                                    <th>Action</th>
                                </tr>
                                }
                                {!isAdmin && <tr>
                                    <th>Week</th>
                                    <th>Status</th>
                                </tr>
                                }

                            </thead>
                            <tbody>{!isAdmin ?
                                timesheets.map((timesheet, index) => (
                                    <tr key={timesheet._id}>
                                        <td><Moment format="DD/MM">{timesheet.startOfWeek}</Moment> -
                                            <Moment format="DD/MM" add={{ days: 4 }}>{timesheet.startOfWeek}</Moment>
                                        </td>
                                        <td>{timesheet.status}</td>
                                    </tr>

                                )) :
                                timesheets.map((timesheet, index) => (
                                    <tr key={timesheet._id}>
                                        <td style={{ cursor: 'pointer' }} onClick={() => toggleEmployeeDetail(timesheet.user_id)}>{timesheet.username}</td>
                                        <td><Moment format="DD/MM">{timesheet.startOfWeek}</Moment> -
                                            <Moment format="DD/MM" add={{ days: 4 }}>{timesheet.startOfWeek}</Moment>
                                        </td>
                                        <td><Button color="link" title="View" onClick={() => this.toggleTimesheetModal(timesheet)}>
                                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                                        </Button></td>

                                    </tr>

                                ))
                            }
                            </tbody>
                        </Table>

                    </>
                }
                <TimesheetModal
                    isOpen={openViewModal}
                    toggleModal={this.toggleTimesheetModal}
                    currentTimesheet={currentTimesheet}
                    projects={projects && projects}
                    tasks={tasks && tasks}
                    fetchProjectName={this.fetchProjectName}
                />
            </>
        );
    }
}

export default TimesheetComponent;