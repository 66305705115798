import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchCandidates = (param) => {
	const url = `${apiRoot}/candidates${param ? `?${param}` : ""}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchCandidate = (id) => {
	const url = `${apiRoot}/candidates/${id}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const fetchRecruiterCandidates = (id,param) => {
	const url = `${apiRoot}/candidates/recruitercandidates/${id}${param ? `?${param}` : ""}`
	let method = HTTP_METHODS.GET
	let body = null
	return callApi(url, method, body)
}

export const createCandidate = (values) => {
	const url = `${apiRoot}/candidates`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updateCandidate = (candidateId, candidate) => {
	const url = `${apiRoot}/candidates/${candidateId}`
	let method = HTTP_METHODS.PUT
	let body = candidate
	return callApi(url, method, body)
}

export const updateStatus = (candidateId, candidate) => {
	const url = `${apiRoot}/candidates/updatestatus/${candidateId}`
	let method = HTTP_METHODS.PUT
	let body = candidate
	return callApi(url, method, body)
}

export const addEditFeedback = (candidateId, candidate) => {
	const url = `${apiRoot}/candidates/addfeedback/${candidateId}`
	let method = HTTP_METHODS.PUT
	let body = candidate
	return callApi(url, method, body)
}

export const confirm = (candidateId, candidate) => {
	const url = `${apiRoot}/candidates/confirminterview/${candidateId}`
	let method = HTTP_METHODS.PUT
	let body = candidate
	return callApi(url, method, body)
}

export const createInterview = (candidateId, candidate) => {
	const url = `${apiRoot}/candidates/scheduleinterview/${candidateId}`
	let method = HTTP_METHODS.PUT
	let body = candidate
	return callApi(url, method, body)
}

export const deleteCandidate = (candidateId) => {
	const url = `${apiRoot}/candidates/${candidateId}`
	let method = HTTP_METHODS.DELETE
	let body = null
	return callApi(url, method, body)
}