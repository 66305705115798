import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ClientForm from './clients/ClientForm'
import { Form } from 'react-final-form'
import { mapFinalFormErrors } from '../lib/utils'
import { createClient, updateClient } from '../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cryptInfo } from '../config'

const mapErrors = mapFinalFormErrors('Client creation failed.')


class AddClientModal extends React.Component {
    state = {
        client: null,
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
        projects: null
    }

    onSubmit = (values) => {
        const { fetchClients, client, toggle } = this.props
        if (client && client._id) {
            updateClient(client._id, values)
                .then(async (response) => {
                    const result = await response.json()
                    if (result.error === true) {
                        toast.error("Error: Client already exist.")
                    } else {
                        await fetchClients()
                        toggle()
                    }
                })
        } else {
            createClient(values)
                .then(async (response) => {
                    const result = await response.json()
                    if (result.error === true) {
                        toast.error("Error: Client already exist.")
                    } else {
                        await fetchClients()
                        toggle()
                    }
                })
                .catch(mapErrors)
        }
    }
    render() {

        const { isOpen, toggle, client } = this.props
        const { isAdmin, projects } = this.state
        const modalTitle = client ? "Edit Client" : "Add Client"

        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className={this.props.className}>
                    <ModalHeader
                        tag="h2"
                        toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form
                            component={ClientForm}
                            id="addclient"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                            }}
                            projects={projects && projects}
                            update={client}
                            isAdmin={isAdmin}
                            initialValues={
                                {
                                    clientname: client && client.clientname ? client.clientname : "",
                                    id: client && client.id ? client.id : ""
                                }
                            }
                        />
                    </ModalBody>
                </Modal>
                <ToastContainer />
            </div>
        );
    }
}

export default AddClientModal;