import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../elements/TableComponent'
import { fetchRoles, updateRole, createRole, deleteRole } from '../../middleware/roles'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddEditRoleModal from './AddEditRoleModal'
import { any, bool, func } from 'prop-types'
import ConfirmationModal from '../content/ConfirmationModal'
import { permissionArray } from '../../data/permissionsData'
import { FORM_ERROR } from 'final-form'

const RoleManagement = ({
	openAddEditRoleModal,
	toggleAddEditRoleModal,
	selectedRoleForEditOrDelete,
	openConfirmationPopup,
	toggleConfirmationModal
}) => {
	const [searchTerm, setSearchTerm] = useState("")
	const [roles, setRoles] = useState([])
	const [selectAll, setSelectAll] = useState(false)
	const [selectedPermissions, setPermissions] = useState([])

	const refreshContent = useCallback(() => {
		fetchRoles()
			.then(async (res) => {
				const result = await res.json()
				setRoles(result)
			})
		if (selectedRoleForEditOrDelete) {
			setPermissions(selectedRoleForEditOrDelete.permission && selectedRoleForEditOrDelete.permission.map(p => ({ value: p, selected: true })))
		} else {
			setPermissions([])
		}
	},[selectedRoleForEditOrDelete])

	useEffect(() => {
		refreshContent()
	}, [selectedRoleForEditOrDelete,refreshContent])

	const columns = [
		{
			id: 'role',
			label: 'Role'
		},
		{
			id: 'permission',
			label: 'Permission',
		},
	]

	const handleSelectAll = () => {
		setSelectAll(!selectAll)
		if (!selectAll) {
			let allIds = permissionArray && permissionArray.map(d => ({ selected: true, value: d.value }))
			setPermissions(allIds)
		}
		if (!!selectAll) {
			setPermissions([])
		}
	}

	const handleSelectOption = useCallback((value) => {
		const index = selectedPermissions && selectedPermissions.findIndex((s) => s.value === value)
		let permissions = selectedPermissions && selectedPermissions.map(p => p)
		if (index > -1 || !index) {
			permissions && permissions.splice(index, 1)
		} else {
			permissions.push({ value: value, selected: true })
		}
		if (selectedPermissions && permissionArray && (permissions.length === permissionArray.length)) {
			setSelectAll(true)
		} else {
			setSelectAll(false)
		}
		setPermissions(permissions)
	}, [setSelectAll, selectedPermissions, setPermissions])

	const onSubmit = useCallback((values) => {
		//checking if no permission is selected
		if (selectedPermissions.length === 0) {
			return { [FORM_ERROR]: 'Please select some permission for the role.' }
		}

		values.permission = selectedPermissions.map(p => p.value)
		values.role = values.role.toLowerCase()
		if (values && values._id) {
			updateRole(values._id, values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditRoleModal(null)
				})
		} else {
			let index = roles && roles.findIndex(role => role.role.toLowerCase() === values.role.toLowerCase())
			if (index >= 0) {
				return { [FORM_ERROR]: 'Duplicate entry not allowed.' }
			}
			createRole(values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditRoleModal(null)
				})
		}
	}, [toggleAddEditRoleModal, selectedPermissions,refreshContent,roles])

	const onDelete = useCallback(() => {
		deleteRole(selectedRoleForEditOrDelete._id)
			.then(async (response) => {
				refreshContent()
				toggleConfirmationModal(null)
			})
	}, [selectedRoleForEditOrDelete, toggleConfirmationModal,refreshContent])

	const actionMarkup = ({ data }) => (
		<React.Fragment>
			<Button
				color="link"
				title="Edit Role"
				onClick={() => toggleAddEditRoleModal(data)}
			>
				<FontAwesomeIcon icon={faPencilAlt} />
			</Button>
			<Button
				color="link"
				className="ml-2 text-danger"
				title="Delete Role"
				onClick={() => toggleConfirmationModal(data)}
			>
				<FontAwesomeIcon icon={faTrash} />
			</Button>
		</React.Fragment>
	)

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}

	const formatData = useCallback(() => {
		if (!roles || (roles && roles.length === 0))
			return []

		return roles && roles.map((role, index) => {
			return (
				{
					id: index + 1,
					role: role.role.toUpperCase(),
					permission: role.permission.join(', '),
					data: role
				}
			)
		})
	}, [roles])

	const formattedData = formatData()
	const filteredRows = formattedData && formattedData.filter((row) => {
		return ((row && row.role && row.role.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.permission && row.permission.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})
	
	return (
		<>
			<Container fluid>
				<Row className="mt-5">
					<Col>
						{formattedData && <TableComponent
							showCheckbox={false}
							columns={columns}
							data={filteredRows ? filteredRows : []}
							perPage={10}
							requestSearch={requestSearch}
							cancelSearch={cancelSearch}
							defaultSort="role"
							defaultSortOrder="asc"
							showSerialNo={true}
							action={actionMarkup}
							searchTerm={searchTerm}
						/>}
					</Col>
				</Row>
			</Container>

			<AddEditRoleModal
				isOpen={openAddEditRoleModal}
				toggle={toggleAddEditRoleModal}
				kra={selectedRoleForEditOrDelete}
				onSubmit={onSubmit}
				handleSelectAll={handleSelectAll}
				handleSelectOption={handleSelectOption}
				selectedPermissions={selectedPermissions && selectedPermissions}
				permissionArray={permissionArray}
				selectAll={selectAll}

			/>

			<ConfirmationModal
				isOpen={openConfirmationPopup}
				toggle={toggleConfirmationModal}
				onConfirm={onDelete}
				message={`Are you sure you want to remove this role?`}
			/>
		</>
	)
}

RoleManagement.propTypes = {
	openAddEditRoleModal: bool,
	toggleAddEditRoleModal: func.isRequired,
	selectedRoleForEditOrDelete: any,
	openConfirmationPopup: bool,
	toggleConfirmationModal: func.isRequired
}

RoleManagement.defaultProps = {
	openAddEditRoleModal: false,
	selectedRoleForEditOrDelete: null,
	openConfirmationPopup: false
}

export default RoleManagement