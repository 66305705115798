import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import TimesheetApproved from '../components/TimesheetApproved'
import UserTimesheet from '../components/UserTimesheet'
import ActiveProjects from '../components/ActiveProjects'
import { Nav, NavItem, NavLink, Row, Col, Container } from 'reactstrap'
import { fetchManagerAllTimesheet, loggingOut, fetchTimelogs, fetchUser } from '../middleware/api'
import { cryptInfo } from '../config';
import PageHeading from '../components/content/PageHeading'
import EmployeeDetailModal from '../components/EmployeeDetailModal'

class HomeContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
      isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
      showApprovalPage: (cryptInfo.decrypt(localStorage.getItem('role')) === 'admin' || cryptInfo.decrypt(localStorage.getItem('role')) === 'manager')
        ? true : false,
      showUserTimesheet: (cryptInfo.decrypt(localStorage.getItem('role')) === 'dev' || cryptInfo.decrypt(localStorage.getItem('role')) === 'qa')
        ? true : false,
      showActiveProjects: false,
      timesheets: [],
      openDetailModal: false,
      selectedEmployee: null
    }
    this.setTimings = this.setTimings.bind(this)
    this.toggle = this.toggle.bind(this)
    this.filterUpdated = this.filterUpdated.bind(this)
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  setTimings(time) {
    this.setState({
      timers: time
    })
  }
  toggleApprovalPage = () => {
    const { isAdmin, isManager } = this.state
    isAdmin && this.getAllTImesheet()
    isManager && this.fetchTimesheets()
    this.setState({
      showApprovalPage: true,
      showActiveProjects: false,
      showUserTimesheet: false,

    })
  }
  toggleActiveProjects = () => {
    this.fetchTimesheets()
    this.setState({
      showApprovalPage: false,
      showActiveProjects: true,
      showUserTimesheet: false
    })
  }
  toggleUserTimesheet = () => {
    this.setState({
      showApprovalPage: false,
      showActiveProjects: false,
      showUserTimesheet: true
    })
  }
  fetchTimesheets() {
    const userId = localStorage.getItem('user_id')
    fetchManagerAllTimesheet(userId)
      .then(async (res) => {
        const result = await res.json()
        this.setState({
          timesheets: result
        })
      })
  }
  getAllTImesheet() {
    fetchTimelogs()
      .then(async (res) => {
        const result = await res.json()
        this.setState({
          timesheets: result
        })
      })
  }
  filterUpdated(newData) {
    this.setState({
      timesheets: newData
    })
  }
  componentDidMount() {
    const { isAdmin, isManager } = this.state
    loggingOut()
    isAdmin && this.getAllTImesheet()
    isManager && this.fetchTimesheets()
  }
  toggleEmployeeDetail = (selectedEmployee) => {
    if (selectedEmployee) {
      fetchUser(selectedEmployee)
        .then(async (response) => {
          var result = await response.json()
          this.setState({ selectedEmployee: result })
        })
    }
    this.setState((prevStat) => ({
      openDetailModal: !prevStat.openDetailModal
    }))
  }
  render() {

    const { showApprovalPage, isAdmin, openDetailModal, selectedEmployee, showUserTimesheet, showActiveProjects, timesheets, isOpen, isManager } = this.state
    return (
      <div className="App wrapper">
        <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
        <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
          {!showUserTimesheet && <PageHeading title={showApprovalPage ? 'Timesheet Approval' : showUserTimesheet ? "My Timesheet" : "Active Projects"} />}

          {showUserTimesheet && <Container fluid>
            <Row className="page-heading">
              <Col className="text-left">
                <span>My Timesheet</span>
              </Col>
              <Col className="text-right">
                <a href="timesheet" className="btn btn-warning btn-lg">
                  Add Timesheet
                </a>
              </Col>
            </Row>
          </Container>}
          <div className="container-fluid pt-5">
            <Nav tabs className="menu-items">
              {
                (isAdmin || isManager) &&
                <NavItem>
                  <NavLink
                    onClick={this.toggleApprovalPage}
                    title="Click here to see the timesheet!"
                    className={showApprovalPage ? "active" : ''}>
                    Timesheet Approval
                  </NavLink>
                </NavItem>
              }

              <NavItem>
                <NavLink
                  onClick={this.toggleUserTimesheet}
                  title="Click here to see your timesheets!"
                  className={showUserTimesheet ? "active" : ''}>
                  My Timesheet
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={this.toggleActiveProjects}
                  title="Click here to see your active projects!"
                  className={showActiveProjects ? "active" : ''}>
                  Active Projects
                </NavLink>
              </NavItem>
            </Nav>
            <div >
              {
                showApprovalPage && (isAdmin || isManager) && <TimesheetApproved
                  timesheets={timesheets && timesheets}
                  filterUpdated={this.filterUpdated}
                  toggleEmployeeDetail={this.toggleEmployeeDetail}
                />
              }
              {
                showUserTimesheet && <UserTimesheet />
              }
              {
                showActiveProjects && <ActiveProjects />
              }
            </div>

          </div>
          <EmployeeDetailModal
            isOpen={openDetailModal}
            toggle={this.toggleEmployeeDetail}
            userDetails={selectedEmployee}
          />
        </Content>
      </div>
    );
  }
}

export default HomeContainer;