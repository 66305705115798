import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FormFeedback } from 'reactstrap'

/**
 * Display is toggled by invalid prop on the Reactstrap form element placed above in the DOM.
 * If you need to place it elsewhere you need to instead create a custom component with its own display logic.
 *
 * @see https://github.com/final-form/react-final-form#independent-error-component
 * @see example of custom component version for alternative placement https://github.com/Soundvessel/final-form-reactstrap
 *
 * @todo param documentation
 * @todo prop-types
 */

const FormFeedbackAdapter = ({ name }) => (
  <Field
    name={ name }
    subscription={ { error: true, submitError: true } }
    render={ ({ meta: { error, submitError } }) =>
      (error || submitError) ? <FormFeedback>{ error || submitError }</FormFeedback> : null
    }
  />
)

FormFeedbackAdapter.propTypes = {
  name: PropTypes.string,
}

export default FormFeedbackAdapter