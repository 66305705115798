import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import UploadSlipForm from "./UploadSlipForm";

export function Upload({ toggle, isOpen, modalTitle, className }) {

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle} className={className}>
      <ModalHeader
        tag="h2"
        toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>
        <UploadSlipForm toggle={toggle} />
      </ModalBody>
    </Modal>
  );
}
