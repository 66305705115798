import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'

import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}
const AddCommentForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        type,
        acceptOrReject,
        values
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4" id="kraform">

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`summary`}>KRA Summary</Label>

                        <Field
                            name="summary"
                            type="textarea"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="summary" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`description`}>Description</Label>
                        <Field
                            name="description"
                            type="textarea"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="description" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`allocation`}>% Allocation</Label>

                        <Field
                            name="allocation"
                            type="number"
                            bsSize="lg"
                            min={0}
                            readOnly={true}
                            max={100}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="allocation" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`measurement_criteria`}>Measurement Criteria</Label>
                        <Field
                            name="measurement_criteria"
                            type="textarea"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="measurement_criteria" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`gaptofill`}>Gap To Fill</Label>

                        <Field
                            name="gaptofill"
                            type="textarea"
                            bsSize="lg"
                            readOnly={true}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="gaptofill" />
                    </FormGroup>
                </Col>
                {
                    (type === 'accomplishment' || type === 'view') &&
                    <Col md>
                        <FormGroup>
                            <Label htmlFor={`accomplishment`}>{type !== 'view' ? "Add" : ""} Accomplishment</Label>

                            <Field
                                name="accomplishment"
                                type="textarea"
                                bsSize="lg"
                                readOnly={type === 'view' ? true : false}
                                validate={type === 'view' ? "" : composeValidators(validateRequired())}
                                component={InputAdapter} />

                            <FormFeedbackAdapter name="accomplishment" />
                        </FormGroup>
                    </Col>
                }
                {
                    type === 'comment' &&
                    <Col md>
                        <FormGroup>
                            <Label htmlFor={`comment`}>Add Comment</Label>

                            <Field
                                name="comment"
                                type="textarea"
                                bsSize="lg"
                                validate={composeValidators(validateRequired())}
                                component={InputAdapter} />

                            <FormFeedbackAdapter name="comment" />
                        </FormGroup>
                    </Col>
                }

            </Row>
            {type === 'view' &&
                <>
                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`feedback`}>Feedback</Label>

                                <Field
                                    name="feedback"
                                    type="textarea"
                                    bsSize="lg"
                                    readOnly={true}
                                    component={InputAdapter} />

                                <FormFeedbackAdapter name="feedback" />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`improvement`}>Suggested Improvement </Label>
                                <Field
                                    name="improvement"
                                    type="textarea"
                                    bsSize="lg"
                                    readOnly={true}
                                    component={InputAdapter} />

                                <FormFeedbackAdapter name="improvement" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`comment`}>Comment</Label>

                                <Field
                                    name="comment"
                                    type="textarea"
                                    bsSize="lg"
                                    readOnly={true}
                                    component={InputAdapter} />

                                <FormFeedbackAdapter name="comment" />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`rating`}>Rating</Label>
                                <Field
                                    name="rating"
                                    type="number"
                                    bsSize="lg"
                                    readOnly={true}
                                    component={InputAdapter} />

                                <FormFeedbackAdapter name="rating" />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            }
            <SubmitErrorAlerts submitError={submitError} />
            <Row>
                {
                    type === 'comment' &&
                    <>
                        <Col md>
                            <FormGroup>
                                <Button
                                    block
                                    color="primary"
                                    className="p-3 mr-2"
                                    disabled={submitting || submitSucceeded}
                                    size="lg"
                                    onClick={() => acceptOrReject(values, 'Accepted')}
                                    type="button">
                                    {"Accept"}</Button>
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Button
                                    block
                                    color="primary"
                                    className="p-3 mr-2"
                                    disabled={submitting || submitSucceeded}
                                    size="lg"
                                    onClick={() => acceptOrReject(values, 'Rejected')}
                                    type="button">
                                    {"Reject"}</Button>
                            </FormGroup>
                        </Col>
                    </>
                }
                {
                    type === 'accomplishment' &&
                    <Col md>
                        <FormGroup>
                            <Button
                                block
                                color="primary"
                                className="p-3 mr-2"
                                disabled={submitting || submitSucceeded}
                                size="lg"
                                type="submit">
                                {"Submit"}</Button>
                        </FormGroup>
                    </Col>
                }


                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={toggle}>{type === 'view' ? 'OK' : "Cancel"}</Button>

                    </FormGroup>
                </Col>
            </Row>


        </Form>
    )
}

AddCommentForm.propTypes = propTypes

export default AddCommentForm
