import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {taskStatusArray} from '../../data/taskStatusData'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const TaskForm = (
    {
        formID,
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        update,
        isAdmin,
        projects
    }
) => (
        <Form onSubmit={handleSubmit} className="p-4">
            <FormGroup row>
                <Label htmlFor={`${formID}-project`}>Project</Label>

                <Field
                    id={`${formID}-project`}
                    name="project"
                    type="select"
                    bsSize="lg"
                    component={SelectAdapter}
                    options={projects}
                />

                <FormFeedbackAdapter name="status" />
           </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-taskname`}>Task name</Label>

                <Field
                    id={`${formID}-taskname`}
                    name="taskname"
                    type="text"
                    bsSize="lg"
                    maxLength="100"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={InputAdapter}
                />

                <FormFeedbackAdapter name="taskname" />
            </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-description`}>Description</Label>

                <Field
                    id={`${formID}-description`}
                    name="description"
                    type="textarea"
                    maxLength="500"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={InputAdapter}
                />

                <FormFeedbackAdapter name="description" />
            </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-status`}>Status</Label>

                <Field
                    id={`${formID}-status`}
                    name="status"
                    type="select"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={SelectAdapter}
                    options={isAdmin ? taskStatusArray : [{value:'suggestion',label:"Suggestion"}]}
                />

                <FormFeedbackAdapter name="status" />
           </FormGroup>
            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                <Button
                    block
                    color="primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    type="submit"
                >{ update ? "Update Task" : " Add Task" }</Button>
                 </Col>
                 <Col md>
                <Button
                    block
                    color="outline-primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    onClick={toggle}
                >Cancel</Button>
                </Col>

            </FormGroup>

        </Form>
    )

    TaskForm.propTypes = propTypes

export default TaskForm
