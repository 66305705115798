import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav } from 'reactstrap'
import Performance from '../components/performance/Performance'
import ManagerView from '../components/performance/ManagerView'
import AddKRAModal from '../components/AddKRAModal'
import {
    fetchKRAs,
    fetchUserKRAs,
    loggingOut,
    fetchUser,
    fetchManagerProject,
    fetchManagerKRA
} from '../middleware/api'
import { cryptInfo } from '../config'
import PageHeading from '../components/content/PageHeading'

class PerformanceContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAddKRAModal: false,
            kras: null,
            kra: null,
            showEmployeeView: true,
            showManagerView: false,
            userKRACount: null,
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin' || cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            userKRAs: null,
            user: null,
            team: [],
            managerKRAs: null,
            isOpen: true
        }
        this.getKRAs = this.getKRAs.bind(this)
        this.getUserKRAs = this.getUserKRAs.bind(this)
        this.getManagerKRAs = this.getManagerKRAs.bind(this)
        this.toggle = this.toggle.bind(this)
        this.setTimings = this.setTimings.bind(this)
    }
    componentDidMount() {
        loggingOut()
        this.getKRAs()
        this.getUserKRAs()
        this.getManagerKRAs()
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    toggleEmployeeView = () => {
        this.getUserKRAs()
        this.setState({
            showEmployeeView: true,
            showManagerView: false,

        })
    }
    toggleManagerView = () => {
        this.setState({
            showEmployeeView: false,
            showManagerView: true,
        })
    }

    toggleModal = () => {
        this.setState(state => ({
            openAddKRAModal: !state.openAddKRAModal,
            kra: null
        }))
    }

    toggleEditModal = (kra) => {
        this.toggleModal()
        kra && kra.userdata._id !== undefined && fetchUserKRAs(kra.userdata._id)
            .then(async (res) => {
                var count = 0
                const userKRAs = await res.json()
                userKRAs && userKRAs.forEach(kra => {
                    if (kra.allocation)
                        count += parseInt(kra.allocation)
                })
                this.setState({
                    userKRACount: count
                })
            })
        this.setState({
            kra: kra
        })
    }

    getKRAs() {
        var userId = localStorage.getItem('user_id')
        var teamMember = []
        var dummyData = []
        fetchManagerProject(userId)
            .then(async (res) => {
                const projects = await res.json()
                projects.forEach(project => {
                    project.teammember.forEach(member => {
                        if (dummyData.indexOf(member.user) === -1) {
                            dummyData.push(member.user)
                            fetchUser(member.user)
                                .then(async (res) => {
                                    const user = await res.json()
                                    var data = {
                                        project: project.projectname,
                                        manager: project.projectmanager,
                                        userdata: user
                                    }
                                    if (user)
                                        teamMember.push(data)
                                })
                        }
                    })
                })
                this.setState({
                    team: teamMember
                })
            })
        fetchKRAs()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    kras: result
                })
            })

    }

    getUserKRAs() {
        const userId = localStorage.getItem('user_id')
        fetchUserKRAs(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    userKRAs: result
                })
            })
    }

    getManagerKRAs() {
        const id = localStorage.getItem('user_id')
        fetchManagerKRA(id)
            .then(async (res) => {
                const result = await res.json()
                this.setState({
                    managerKRAs: result
                })
            })
    }

    render() {
        const { openAddKRAModal,
            kra,
            isAdmin,
            showEmployeeView,
            showManagerView,
            userKRAs,
            team,
            userKRACount,
            isOpen } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <PageHeading title="KRA Details" />
                        <div className="container-fluid pt-5">
                            <Nav tabs className="menu-items">
                                <NavItem>
                                    <NavLink
                                        onClick={this.toggleEmployeeView}
                                        className={showEmployeeView ? "active" : ''}>
                                        My KRAs
                                    </NavLink>
                                </NavItem>

                                {isAdmin &&
                                    <NavItem>
                                        <NavLink
                                            onClick={this.toggleManagerView}
                                            className={showManagerView ? "active" : ''}>
                                            Manager View
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>

                            <div>
                                {
                                    showManagerView && isAdmin &&
                                    <ManagerView
                                        kras={team}
                                        toggleModal={this.toggleModal}
                                        fetchKRAs={this.getKRAs}
                                        toggleEditModal={this.toggleEditModal}
                                    />
                                }
                                {
                                    showEmployeeView &&
                                    <Performance
                                        kras={userKRAs && userKRAs}
                                        toggleCommentModal={this.toggleCommentModal}
                                        getUserKRAs={this.getUserKRAs}
                                    />
                                }
                            </div>
                        </div>
                        <AddKRAModal
                            isOpen={openAddKRAModal}
                            toggle={this.toggleModal}
                            fetchKRAs={this.getUserKRAs}
                            fetchManagerKRA={this.getManagerKRAs}
                            fetchUserKRA={this.getUserKRAs}
                            userKRACount={userKRACount && userKRACount}
                            kra={kra}
                        />
                    </Content>
                </div>
            </>
        )
    }
}

export default PerformanceContainer