import React from 'react'
import { sidebarArray } from '../../data/sideBarData'
import { NavItem, NavLink, Nav, Button } from 'reactstrap'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSignOutAlt, faTools, faFileAlt, faBriefcase, faDatabase, faPencilAlt, faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { cryptInfo } from '../../config'
import { fetchRolesByName } from '../../middleware/roles'

const userImage = localStorage.getItem('image')
const userId = localStorage.getItem('user_id') && localStorage.getItem('user_id')
const isAdmin = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'
const isManager = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'
// const isRecruiter = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'recruiter'

class SideBar extends React.Component {

  state = {
    userPermission: localStorage.getItem('permission') ? JSON.parse(localStorage.getItem('permission')) : []
  }

  componentDidMount = async () => {
    const role = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role'))
    window.setInterval(async () => {
      await fetchRolesByName(role)
        .then(async (res) => {
          const roleData = await res.json()
          roleData && roleData[0] && roleData[0].permission && localStorage.setItem('permission', JSON.stringify(roleData[0].permission))
        })
    }, 30000)

  }

  signOut() {
    localStorage.clear()
    window.location.reload()
  }

  render() {
    const { userPermission } = this.state
    // console.log(userPermission, userPermission.indexOf('payslips'))
    var reimbursementClaim = localStorage.getItem('reimbursementClaim') && localStorage.getItem('reimbursementClaim') === 'true'
    var sidebarItems = (!isAdmin && !isManager && !reimbursementClaim) ? sidebarArray.filter(menuItem => menuItem.link !== 'reimbursement') : sidebarArray
    return (
      <div className={classNames('sidebar shadow-sm', { 'is-open': this.props.isOpen })}>
        <div className="sidebar-header pb-0 pt-1 pl-3 pr-0 ">
          <Button onClick={this.props.toggle} className="rfloat" color="link">
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
          </Button><br />
          <div className="profile-outer">
            <div className="">
              {userImage ? <img className="img-circle" src={userImage} alt="user" /> : <span>{localStorage.getItem('fname')[0]}</span>}
            </div>
          </div>
          <div className="p-3">
            <div className="text-center">
              <h2 className="username">{localStorage.getItem('fname') + " " + localStorage.getItem('lname')}</h2>
            </div>
          </div>
        </div>

        <Nav vertical className="list-unstyled pb-3">
          {
            sidebarItems.map((items) => {
              if (userPermission.indexOf(items.value) >= 0)
                return (
                  <NavItem key={items.label}>
                    <NavLink href={`/${items.link}`}><FontAwesomeIcon icon={items.icon} className="mr-2" />{items.label}</NavLink>
                  </NavItem>
                )
              return false
            })
          }
          {(isAdmin && userPermission.indexOf('setting') >= 0) &&
            <NavItem key="setting" >
              <NavLink href={`/platform-settings`}><FontAwesomeIcon icon={faTools} className="mr-2" />Platform Settings</NavLink>
            </NavItem>
          }
          {userPermission.indexOf('policy') >= 0 &&
            <NavItem key="policies" >
              <NavLink href={`/policies`}><FontAwesomeIcon icon={faFileAlt} className="mr-2" />Policies</NavLink>
            </NavItem>
          }
          {userPermission.indexOf('payslips') >= 0 &&
            <NavItem key="payslips" >
              <NavLink href={`/payslips`}><FontAwesomeIcon icon={faRupeeSign} className="mr-2" />Pay Slips</NavLink>
            </NavItem>
          }
          {userPermission.indexOf('recruitment') >= 0 &&
            <NavItem key="recruitment" >
              <NavLink href={`/recruitment`}><FontAwesomeIcon icon={faBriefcase} className="mr-2" />Recruitment</NavLink>
            </NavItem>
          }
          {isAdmin &&
            <NavItem key="metadata" >
              <NavLink href={`/metadata`}><FontAwesomeIcon icon={faDatabase} className="mr-2" />Metadata</NavLink>
            </NavItem>
          }
          <NavItem key="info" >
            <NavLink href={`/user-info/${userId}`}><FontAwesomeIcon icon={faPencilAlt} className="mr-2" />Edit Personal Info</NavLink>
          </NavItem>

          <NavItem key="logout">
            <Button className="logout" onClick={this.signOut} color="link"> <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />Log Out </Button>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default SideBar;
