import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { Container, Row, Col, Button } from 'reactstrap'
import { cryptInfo } from '../config'
import ReferralsListComponent from '../components/referrals/ReferralsListComponent'

class ReferralContainer extends Component {
	state = {
		isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
		isOpen: true,
		openAddEditReferralModal: false,
		openConfirmationModal: false,
		selectedItem: null,
		modalType: ""
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	toggleNavbar = (selectedTab) => {
		this.setState({ selectedTab })
	}
	toggleConfirmationModal = (selectedItem) => {
		this.setState((prevState) => ({
			openConfirmationModal: !prevState.openConfirmationModal,
			selectedItem
		}))
	}

	toggleAddEditReferralModal = (selectedItem, modalType) => {
		this.setState((prevState => ({
			openAddEditReferralModal: !prevState.openAddEditReferralModal,
			selectedItem,
			modalType
		})))
	}

	render() {
		const {
			isAdmin,
			isOpen,
			modalType,
			openConfirmationModal,
			selectedItem,
			openAddEditReferralModal } = this.state
			
		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						<Container fluid>
							<Row className="page-heading">
								<Col className="text-left">
									<span>Referrals</span>
								</Col>
								<Col className="text-right">
									<Button color="warning" size="lg" onClick={() => this.toggleAddEditReferralModal(null, 'add')}>
										Add New Referral
									</Button>
								</Col>
							</Row>
						</Container>
						<div className="container-fluid pt-5">
							<div style={{ overflowX: 'auto' }}>
								<ReferralsListComponent
									openConfirmationModal={openConfirmationModal}
									selectedItem={selectedItem}
									modalType={modalType}
									isAdmin={isAdmin}
									toggleConfirmationModal={this.toggleConfirmationModal}
									openAddEditReferralModal={openAddEditReferralModal}
									toggleAddEditReferralModal={this.toggleAddEditReferralModal}
								/>
							</div>
						</div>
					</Content>
				</div>
			</>
		);
	}
}

export default ReferralContainer;