import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
	Button,
	Form,
	FormGroup,
	Row,
	Col,
	Label
} from 'reactstrap'
import {
	FormFeedbackAdapter,
	InputAdapter,
	SelectAdapter
} from '../ffadapters'
import {
	SubmitErrorAlerts
} from '../ffhelpers'
import {
	validateRequired
} from '../../lib/validators'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}

const AddEditPolicyForm = (
	{
		handleSubmit,
		submitError,
		submitting,
		toggle,
		values
	}
) => {

	return (
		<Form onSubmit={handleSubmit} className="pt-4">
			<Row>
				<Col>
					<FormGroup>
						<Label>Title</Label>
						<Field
							name={`title`}
							type="text"
							bsSize="lg"
							validate={validateRequired()}
							component={InputAdapter} />
						<FormFeedbackAdapter name={`title`} />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormGroup>
						<Label>Description</Label>
						<Field
							name={`description`}
							type="textarea"
							bsSize="lg"
							validate={validateRequired()}
							component={InputAdapter} />
						<FormFeedbackAdapter name={`description`} />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col>
					<FormGroup>
						<Label>Status</Label>
						<Field
							name={`status`}
							type="select"
							bsSize="lg"
							validate={validateRequired()}
							component={SelectAdapter}
							options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }, { label: 'On Hold', value: 'on hold' }]} />
						<FormFeedbackAdapter name={`status`} />
					</FormGroup>
				</Col>
				<Col>
					<FormGroup>
						<Label>Attachment</Label>
						<Field name={`file`}>
							{({ input: { value, onChange, ...input } }) => (
								<input
									{...input}
									type="file"
									accept="application/pdf"
									style={{ padding: '3px' }}
									className="border-info form-control"
									name={`file`}
									onChange={({ target }) => onChange(target.files)}
								/>
							)}
						</Field>

						<FormFeedbackAdapter name={`file`} />
					</FormGroup>
				</Col>
			</Row>


			<SubmitErrorAlerts submitError={submitError} />
			<FormGroup row>
				<Col md>
					<Button
						block
						color="primary"
						className="p-3 mt-3"
						disabled={submitting}
						size="lg"
						type="submit"
					>Submit</Button>
				</Col>
				<Col md>
					<Button
						block
						type="button"
						color="outline-primary"
						className="p-3 mt-3"
						disabled={submitting}
						size="lg"
						onClick={() => toggle()}
					>Cancel</Button>
				</Col>

			</FormGroup>

		</Form>

	)
}

AddEditPolicyForm.propTypes = propTypes

export default AddEditPolicyForm
