import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { projectTypeArray } from '../../data/projectTypesData'
import { projectStatusArray } from '../../data/projectStatusData'

// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
    DatePickerAdapter
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}
const ProjectForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        update,
        adminAndManagers,
        values,
        clientArray
    }
) => {
    console.log(values)
    return (
        <Form onSubmit={handleSubmit} className="p-4">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`projectname`}>Project Name</Label>
                        <Field
                            id={`projectname`}
                            name="projectname"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="projectname" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`client`}>Client</Label>
                        <Field
                            id={`client`}
                            name="client"
                            type="select"
                            bsSize="lg"
                            placeholder="Select Client"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={clientArray} />

                        <FormFeedbackAdapter name="client" />
                    </FormGroup>
                </Col>
               {values && values.client === 'add_new' && <Col md>
                    <FormGroup>
                        <Label htmlFor={`newClient`}>New Client</Label>
                        <Field
                            id={`newClient`}
                            name="newClient"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />

                        <FormFeedbackAdapter name="newClient" />
                    </FormGroup>
                </Col>}
            </Row>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`status`}>Status</Label>

                        <Field
                            id={`status`}
                            name="status"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={projectStatusArray} />

                        <FormFeedbackAdapter name="status" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`type`}>Type</Label>

                        <Field
                            id={`type`}
                            name="type"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={projectTypeArray} />

                        <FormFeedbackAdapter name="type" />
                    </FormGroup>

                </Col>
            </Row>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`startdate`}>Start Date</Label>

                        <Field
                            id={`startdate`}
                            name="startdate"
                            type="date"
                            bsSize="lg"
                            validate={validateRequired()}
                            component={DatePickerAdapter}
                            min="2000-01-01" />

                        <FormFeedbackAdapter name="startdate" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`enddate`}>End Date</Label>

                        <Field
                            id={`enddate`}
                            name="enddate"
                            type="date"
                            bsSize="lg"
                            validate={validateRequired()}
                            component={DatePickerAdapter}
                             min={values.startdate} />

                        <FormFeedbackAdapter name="enddate" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-6">
                    <FormGroup>
                        <Label htmlFor={`projectmanager`}>Project Manager</Label>

                        <Field
                            name={`projectmanager`}
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={adminAndManagers} />

                        <FormFeedbackAdapter name="projectmanager" />
                    </FormGroup>
                </Col>
            </Row>
            <SubmitErrorAlerts submitError={submitError} />
            <Row>
                <Col md>
                    <FormGroup row>
                        <Button
                            block
                            color="primary"
                            className="p-3 mr-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            type="submit">
                            {update ? "Update Project" : " Add Project"}</Button>
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup row>

                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={toggle}>Cancel</Button>


                    </FormGroup>
                </Col>
            </Row>


        </Form>
    )
}

ProjectForm.propTypes = propTypes

export default ProjectForm
