export const userDetailtabs = {
    personalInfo: 'Personal Info',
    bankingInfo: 'Banking Info',
    contactInfo: 'Contact Info',
    familyInfo: 'Family Info',
  }
  
  export const userDetailtabsArray = Object.keys(userDetailtabs).map(value => ({
    value,
    label: userDetailtabs[value]
  }))

export const familyRelation = {
    spouse: 'Spouse',
    son: 'Son',
    daughter: 'Daughter',
  }
  
  export const familyRelationArray = Object.keys(familyRelation).map(value => ({
    value,
    label: familyRelation[value]
  }))