import React, { Component } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import UserProfileForm from './UserProfileForm'
import { updateUser } from '../../middleware/api'
import { fetchRoles } from '../../middleware/roles'
import { mapFinalFormErrors } from '../../lib/utils'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cryptInfo } from '../../config'
const mapErrors = mapFinalFormErrors('Unable to save user Details')
class PersonalInfo extends Component {
    state = {
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
        isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
        maxDate: "",
        roles:[]
    }
    componentDidMount() {
        this.maxDate()
        fetchRoles()
        .then(async (response) => {
            const result = await response.json()
            this.setState({roles:result})
        })
    }
    maxDate = () => {
        let today = new Date(),
            day = today.getDate(),
            month = today.getMonth() + 1, //January is 0
            year = today.getFullYear();
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        today = year + '-' + month + '-' + day
        this.setState({
            maxDate: today
        })
    }
    onSubmit = (values) => {
        const { fetchUser, user, userId,toggleConfirmationPopup } = this.props
        const { isAdmin, isManager } = this.state
        if (userId === localStorage.getItem('user_id')) {
            localStorage.setItem('fname', values.fname)
            localStorage.setItem('lname', values.lname)
            localStorage.setItem('role', cryptInfo.encrypt(values.role))
            localStorage.setItem('reimbursementClaim', values.reimbursementClaim)
        }
        if (isAdmin || isManager) {
            // if (user.leavebalance === null && user.leaveCredited === false && values.olb !== 0 && values.olb !== null && values.doj === "") {
            //     values.leavebalance = values.olb
            //     values.leaveCredited = true
            // }
            if (user.leavebalanceCL === null && user.leaveCredited === false && values.olb !== 0 && values.olb !== null && values.doj !== "") {
                values.leavebalanceCL = values.olb
                values.leaveCredited = true
            }

            if (user.leavebalanceEL === null && !user.leaveCredited && values.doj !== "") {
                var newDate = new Date(values.doj)
                var day = newDate.getDate()
                if (day >= 1 && day <= 7 && user.leaveCredited === false) {
                    values.leavebalanceEL = 1
                    values.leaveCredited = true
                }
                if (day > 7 && day <= 15 && user.leaveCredited === false) {
                    values.leavebalanceEL = 0.5
                    values.leaveCredited = true
                }
                if (day > 15) {
                    values.leavebalanceEL = null
                    values.leaveCredited = true
                }
            }
        }
        if (user && user._id) {
            updateUser(user._id, values)
                .then(async (response) => {
                    await fetchUser()
                    toast.success("Profile details have been successfully saved.")
                    if((isAdmin || isManager) && userId !== localStorage.getItem('user_id'))
                    toggleConfirmationPopup()
                })
                .catch(mapErrors)
        }

    }
    render() {
        const { managers, projects, user } = this.props
        const { maxDate, isAdmin,roles } = this.state
        const defaultValues = user && user

        return (
            <div>
                <Form
                    component={UserProfileForm}
                    formId="user-profile"
                    name="user"
                    onSubmit={this.onSubmit}
                    managerUsers={managers}
                    projectArray={projects}
                    isAdmin={isAdmin}
                    roles={roles && roles.map(role => ({value:role.role,label:role.role.toUpperCase()}))}
                    subscription={{
                        pristine: true,
                        submitError: true,
                        submitting: true,
                        hasValidationErrors: true,
                    }}
                    maxDate={maxDate}
                    mutators={{
                        ...arrayMutators
                    }}
                    initialValues={defaultValues}
                />
                <ToastContainer />
            </div>

        );
    }
}

export default PersonalInfo;