export const sidebarData = {
  home: {
    label: 'Home',
  },
  timesheet: {
    label: 'Timesheet',
  },
  leave: {
    label: 'Leaves',
  },
  projects: {
    label: 'Projects',
  },
  employees: {
    label: 'Employees',
  },
  clients: {
    label: 'Clients',
  },
  assetsallocated: {
    label: 'Assets Allocated',
  },
  task: {
    label: 'Tasks',
  },
  performance: {
    label: 'Performance',
  },
  reimbursement: {
    label: 'Reimbursement',
  },
  policy: {
    label: 'Policies',
  },
  payslips: {
    label: 'Pay Slips',
  },
  recruitment: {
    label: 'Recruitment',
  },
  referrals: {
    label: 'Referrals',
  },
  holiday: {
    label: 'Holidays',
  },
  setting: {
    label: 'Platform Setting',
  },
  feed: {
    label: 'Our Feeds',
  },
  logs: {
    label: 'User Logs',
  },
}

export const permissionArray = Object.keys(sidebarData).map(value => ({
  value,
  label: sidebarData[value].label
}))