import React, { Component } from 'react'
import { Table, Button } from 'reactstrap'
import Moment from 'react-moment'
import { leaveData } from '../../data/leaveTypeData'
import { cryptInfo } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { fetchUser, updateLeave, updateUser, sendApprovalMail } from '../../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
class LeavesComponent extends Component {
    state = {
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin' || cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
    }
    approveLeave(leave, type) {
        const { fetchLeaves } = this.props
        leave.status = type
        if (type === 'Approved' && leave.type === 'earned') {
            // if (type === 'Approved' && (leave.type === 'earned' || leave.type === 'lwp')) {
            fetchUser(leave.user)
                .then(async (response) => {
                    const user = await response.json()
                    user.leavebalanceEL = user.leavebalanceEL - leave.count
                    user.yearLeaveEL = user.yearLeaveEL - leave.count
                    updateUser(user._id, user)
                        .then(async (response) => {

                        })
                })
        }
        else if (type === 'Approved' && leave.type === 'casual') {
            fetchUser(leave.user)
                .then(async (response) => {
                    const user = await response.json()
                    user.leavebalanceCL = user.leavebalanceCL - leave.count
                    updateUser(user._id, user)
                        .then(async (response) => {
                        })
                })
        }
        updateLeave(leave._id, leave)
            .then(async (response) => {
                await fetchLeaves(null)
                toast.success("Leave has been successfully updated.")
                sendApprovalMail({
                    subject: "Leave Approval/Rejection",
                    receiver: leave.email,
                    sender: cryptInfo.decrypt(localStorage.getItem('email')),
                    type: leaveData[leave.type],
                    reason: leave.reason,
                    from: leave.from,
                    to: leave.to,
                    status: leave.status,
                    message: `Hello ${leave.empname}, your leave(s) for ${leave.count} day(s) has been 
                    ${type}.`
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    render() {
        const { leaves, toggleEmployeeDetail } = this.props
        const { isAdmin } = this.state
        return (
            <>
                <h4>
                    <a href="leaves-home" title="Click here to go to leave management.">Leaves</a>
                </h4>
                {
                    <>
                        <Table id="welcome-page-table">
                            <thead>
                                {isAdmin && <tr>
                                    <th>Name</th>
                                    <th>From-To</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                                }
                                {!isAdmin && <tr>
                                    <th>From-To</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                </tr>
                                }
                            </thead>
                            <tbody>{
                                !isAdmin ?
                                    leaves.map((leave, index) => (

                                        <tr key={leave._id}>
                                            <td key={leave.from + index}><Moment format="DD/MM">{leave.from}</Moment> -
                                                <Moment format="DD/MM" >{leave.to}</Moment>
                                            </td>
                                            <td key={leave.type + index}>{leaveData[leave.type]}</td>
                                            <td key={leave.status + index}>{leave.status}</td>
                                        </tr>
                                    )) :
                                    leaves.map((leave, index) => (

                                        <tr key={leave._id}>
                                            <td key={leave.type + index} style={{ cursor: 'pointer' }} onClick={() => toggleEmployeeDetail(leave.user)}>{leave.empname}</td>
                                            <td key={leave.from + index}><Moment format="DD/MM">{leave.from}</Moment> -
                                                <Moment format="DD/MM">{leave.to}</Moment>
                                            </td>
                                            <td key={leave._id + index}>{leaveData[leave.type]}</td>

                                            <td key={leave.status + index}>
                                                <Button color="link" title="Approve" onClick={() => this.approveLeave(leave, 'Approved')}>
                                                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                                </Button>
                                            </td>
                                            <td key={"reject" + index}>
                                                <Button color="link" title="Reject" onClick={() => this.approveLeave(leave, 'Rejected')}>
                                                    <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))

                            }

                            </tbody>
                        </Table>

                    </>

                }
                <ToastContainer />
            </>
        );
    }
}

export default LeavesComponent;