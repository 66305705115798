import React, { Fragment } from 'react'

/**
 * Loading screen to use in place of <Layout>
 */

const Loading = () => (
  <Fragment>
    <h4 className="text-center loading">
      Nothing to show
   </h4>
  </Fragment>
)

export default Loading
