export const assetValue = {
    laptop: 'Laptop',
    headphone: 'Headphone',
    mouse: 'Mouse',
    pendrive: 'Pendrive',
    externalharddisk:'External Hard Disk'
  }
  
  export const assetsArray = Object.keys(assetValue).map(value => ({
    value,
    label: assetValue[value]
  }))