import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import AddCommentForm from './performance/AddCommentForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { updateKRA, kraMail } from '../middleware/api'
import { cryptInfo } from '../config'

class AddCommentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kra: null,
        }

        this.acceptOrReject = this.acceptOrReject.bind(this)
    }

    onSubmit = (values) => {
        const { kra, toggle, getUserKRAs } = this.props
        kra.accomplishment = values.accomplishment
        kra.status = "Accomplished"

        kra && updateKRA(kra._id, kra)
            .then(async (response) => {
                await kraMail({
                    subject: "KRA update mail",
                    receiver: kra.useremail,
                    sender: cryptInfo.decrypt(localStorage.getItem('email')),
                    message: `<p>Hello ,<br/>
                    <i>${localStorage.getItem('fname')} has just updated the accomplishment to the KRA. Please review/submit the KRA.</i>
                    <br/>
                    <a href="http://hrms.gtentechnologies.com/login"> LOGIN TO GTEN PORTAL</a>
                    </p>
                    `
                })
                getUserKRAs()
                toggle()
            })
    }

    acceptOrReject(values, type) {
        const { kra, toggle, getUserKRAs } = this.props
        if (!values.comment)
            return
        kra.comment = values.comment
        kra.status = type

        kra && updateKRA(kra._id, kra)
            .then(async (response) => {
                await kraMail({
                    subject: "KRA update mail",
                    receiver: kra.manageremail,
                    sender: cryptInfo.decrypt(localStorage.getItem('email')),
                    message: `<p>Hello ,<br/>
                    <i>${localStorage.getItem('fname')} has just updated the comment to the KRA. 
                    <b>STATUS : </b>${type}
                    Please review/provide feedback/submit the KRA.</i>
                    <br/>
                    <a href="http://hrms.gtentechnologies.com/login"> LOGIN TO GTEN PORTAL</a>
                    </p>
                    `
                })
                getUserKRAs()
                toggle()
            })
        return
    }

    render() {

        const { isOpen, toggle, kra, className, type } = this.props
        const modalTitle = type === "comment" ? "Add Comment" : (type === "view" ? "KRA" : "Add Accomplishment")
        return (
            <div>
                <Modal id="project-modal" isOpen={isOpen} toggle={toggle} className={className}>
                    <ModalBody>
                        <h2 className="text-primary text-center">
                            {modalTitle}
                        </h2>
                        <hr />
                        <Form
                            component={AddCommentForm}
                            formId="addcomment"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            acceptOrReject={this.acceptOrReject}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                                reset: true,
                                values: true
                            }}
                            mutators={{
                                ...arrayMutators,
                            }}
                            type={type}
                            update={kra}
                            initialValues={
                                {
                                    summary: kra && kra.summary,
                                    description: kra && kra.description,
                                    allocation: kra && kra.allocation,
                                    gaptofill: kra && kra.gaptofill,
                                    measurement_criteria: kra && kra.measurement_criteria,
                                    accomplishment: kra && kra.accomplishment ? kra.accomplishment : "",
                                    comment: kra && kra.comment ? kra.comment : "",
                                    feedback: kra && kra.feedback ? kra.feedback : "",
                                    improvement: kra && kra.improvement ? kra.improvement : "",
                                    rating: kra && kra.rating ? kra.rating : ""
                                }
                            }
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddCommentModal;