import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const setPassword = (data) => {
	const url = `${apiRoot}/users/set-password`
	let method = HTTP_METHODS.POST
	let body = data
	return callApi(url, method, body)
}

export const loginUserByPassword = (userData) => {
  const url = `${apiRoot}/users/login-password`
  let method = HTTP_METHODS.POST
  let body = userData
  return callApi(url, method, body)
}