import React, { Fragment } from 'react';
import RegistrationPageContainer from '../containers/RegistrationPageContainer'
const RegistrationPage = (props) => {
    return (
        <Fragment>
            <RegistrationPageContainer email={props.match.params.email} />
        </Fragment>
    );
}

export default RegistrationPage;