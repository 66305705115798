import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col,
    Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter
} from '../ffadapters'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'
import { assetsArray } from '../../data/assetsData'
const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AssetForm = (
    {
        handleSubmit,
        submitSucceeded,
        submitting,
        toggle,
        update,
        employees
    }
) => (
    <Form onSubmit={handleSubmit} className="p-4">
        <Row>
            <Col md>
                <FormGroup>
                    <Label htmlFor={`empid`}>Employee Name</Label>
                    <Field
                        name="empid"
                        type="select"
                        bsSize="lg"
                        component={SelectAdapter}
                        validate={
                            composeValidators(
                                validateRequired(),
                            )
                        }
                        options={employees}
                    />

                    <FormFeedbackAdapter name="empid" />
                </FormGroup>
            </Col>
            <Col md>
                <FormGroup>
                    <Label htmlFor={`assesttype`}>Assets Type</Label>
                    <Field
                        name="assesttype"
                        type="select"
                        bsSize="lg"
                        component={SelectAdapter}
                        validate={
                            composeValidators(
                                validateRequired(),
                            )
                        }
                        options={assetsArray}

                    />

                    <FormFeedbackAdapter name="assesttype" />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md>
                <FormGroup>
                    <Label htmlFor={`serial`}>Serial</Label>
                    <Field
                        name="serial"
                        type="text"
                        bsSize="lg"
                        validate={
                            composeValidators(
                                validateRequired(),
                            )
                        }
                        component={InputAdapter} />

                    <FormFeedbackAdapter name="serial" />
                </FormGroup>
            </Col>
            <Col md>
                <FormGroup>
                    <Label htmlFor={`specification`}>Specification</Label>
                    <Field
                        name="specification"
                        type="text"
                        bsSize="lg"
                        validate={
                            composeValidators(
                                validateRequired(),
                            )
                        }
                        component={InputAdapter} />

                    <FormFeedbackAdapter name="specification" />
                </FormGroup>
            </Col>
        </Row>
        <Row>

            <Col className="col-md-6">
                <FormGroup>
                    <Label htmlFor={`remarks`}>Remarks</Label>
                    <Field
                        name="remark"
                        type="textarea"
                        bsSize="lg"
                        validate={
                            composeValidators(
                                validateRequired(),
                            )
                        }
                        component={InputAdapter} />

                    <FormFeedbackAdapter name="remarks" />
                </FormGroup>
            </Col>
        </Row>

        <FormGroup row>
            <Col md>
                <Button
                    block
                    color="primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    type="submit"
                >{update ? "Update" : "Add"}</Button>
            </Col>
            <Col md>
                <Button
                    block
                    color="outline-primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    onClick={toggle}
                >Cancel</Button>
            </Col>

        </FormGroup>

    </Form>
)

AssetForm.propTypes = propTypes

export default AssetForm
