import { HTTP_METHODS, callApi } from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const createToken = (values) => {
	const url = `${apiRoot}/token/reset-password-link`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}

export const updatePassword = (userId, token, values) => {
	const url = `${apiRoot}/token/reset-password/${userId}/${token}`
	let method = HTTP_METHODS.POST
	let body = values
	return callApi(url, method, body)
}
