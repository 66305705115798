import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    DatePickerAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const EditHolidayForm = (
    {
        formID,
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        update
    }
) => (
        <Form onSubmit={handleSubmit} className="p-4">
            <FormGroup row>
                <Label htmlFor={`${formID}-date`}>Date</Label>

                <Field
                    name="date"
                    type="date"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={DatePickerAdapter}
                />

                <FormFeedbackAdapter name="date" />
            </FormGroup>
            <FormGroup row>
                <Label htmlFor={`${formID}-occassion`}>Occassion</Label>
                <Field
                    name="occassion"
                    type="text"
                    bsSize="lg"
                    validate={
                        composeValidators(
                            validateRequired(),
                        )
                    }
                    component={InputAdapter}
                />
                <FormFeedbackAdapter name="occassion" />
            </FormGroup>
            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                <Button
                    block
                    color="primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    type="submit"
                >{"Update"}</Button>
                </Col>
                <Col md>
                <Button
                    block
                    color="outline-primary"
                    className="p-3 mt-3"
                    disabled={submitting || submitSucceeded}
                    size="lg"
                    onClick={toggle}
                >Cancel</Button>
                </Col>
            </FormGroup>

        </Form>
    )

EditHolidayForm.propTypes = propTypes

export default EditHolidayForm
