import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { Container, Row, Col, Spinner } from 'reactstrap'
import { withRouter } from 'react-router-dom';
import PageHeading from '../components/content/PageHeading'
import { fetchPolicy } from '../middleware/policies'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

class PolicyInfoContainer extends Component {

	state = {
		policyId: this.props.match.params.id,
		policy: null,
		isOpen: true
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	componentDidMount = async () => {
		const { policyId } = this.state
		await fetchPolicy(policyId)
			.then(async (response) => {
				const result = await response.json()
				console.log(result)
				this.setState({
					policy: result
				})
			})
	}

	render() {
		const {
			isOpen,
			policy
		} = this.state

		var timeStamp = + new Date()

		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						<PageHeading title="Policy" />
						{
							policy ? <Container fluid className="pt-5">
								<Row>
									<Col className="text-left">
										<h3>{policy.title}</h3>
									</Col>
								</Row>

								<Row>
									<Col className="text-left">
										<p>{policy.description}</p>
									</Col>
								</Row>

								<Row>
									<Col className="text-left">
										{policy.fileUploadKeys && policy.fileUploadKeys.map(file => (
											<a
												key={file}
												rel="noopener noreferrer"
												href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
												className="text-primary"
												target="_blank" >
												<FontAwesomeIcon icon={faEye} /> Click To View Policy Attachment
											</a>
										))}
									</Col>
								</Row>

							</Container> :

								<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
						}

					</Content>
				</div>
			</>
		);
	}
}

export default withRouter(PolicyInfoContainer)