import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../elements/TableComponent'
import { fetchTypes, updateType, createType, deleteType } from '../../middleware/metadata'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddEditTypeModal from './AddEditTypeModal'
import { any, bool, func } from 'prop-types'
import ConfirmationModal from '../content/ConfirmationModal'

const TypesManagement = ({
	openAddEditModal,
	toggleAddEditTypeModal,
	selectedItem,
	openConfirmationPopup,
	toggleConfirmationModal
}) => {

	const [searchTerm, setSearchTerm] = useState("")
	const [types, setTypes] = useState([])

	const refreshContent = useCallback(() => {
		fetchTypes()
			.then(async (res) => {
				const result = await res.json()
				setTypes(result)
			})
	}, [])

	useEffect(() => {
		refreshContent()
	}, [refreshContent])

	const columns = [
		{
			id: 'type',
			label: 'Type'
		},
	]

	const onSubmit = useCallback((values) => {
		values.name = values.name.toLowerCase()
		if (values && values._id) {
			updateType(values._id, values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditTypeModal(null)
				})
		} else {
			createType(values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditTypeModal(null)
				})
		}
	}, [toggleAddEditTypeModal, refreshContent])

	const onDelete = useCallback(() => {
		deleteType(selectedItem._id)
			.then(async (response) => {
				refreshContent()
				toggleConfirmationModal(null)
			})
	}, [selectedItem, toggleConfirmationModal, refreshContent])

	const actionMarkup = ({ data }) => (
		<React.Fragment>
			<Button
				color="link"
				title="Edit Type"
				onClick={() => toggleAddEditTypeModal(data)}
			>
				<FontAwesomeIcon icon={faPencilAlt} />
			</Button>
			<Button
				color="link"
				className="ml-2 text-danger"
				title="Delete Type"
				onClick={() => toggleConfirmationModal(data)}
			>
				<FontAwesomeIcon icon={faTrash} />
			</Button>
		</React.Fragment>
	)

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}

	const formatData = useCallback(() => {
		if (!types || (types && types.length === 0))
			return []

		return types && types.map((type, index) => {
			return (
				{
					id: index + 1,
					type: type.name.toUpperCase(),
					data: type
				}
			)
		})
	}, [types])

	const formattedData = formatData()

	const filteredRows = formattedData && formattedData.filter((row) => {
		return ((row && row.type && row.type.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})

	return (
		<>
			<Container fluid>
				<Row className="mt-5">
					<Col>
						{formattedData && <TableComponent
							showCheckbox={false}
							columns={columns}
							data={filteredRows ? filteredRows : []}
							perPage={10}
							requestSearch={requestSearch}
							cancelSearch={cancelSearch}
							defaultSort="type"
							defaultSortOrder="asc"
							showSerialNo={true}
							action={actionMarkup}
							searchTerm={searchTerm}
						/>}
					</Col>
				</Row>
			</Container>

			<AddEditTypeModal
				isOpen={openAddEditModal}
				toggle={toggleAddEditTypeModal}
				data={selectedItem}
				onSubmit={onSubmit}
			/>

			<ConfirmationModal
				isOpen={openConfirmationPopup}
				toggle={toggleConfirmationModal}
				onConfirm={onDelete}
				message={`Are you sure you want to remove this item?`}
			/>
		</>
	)
}

TypesManagement.propTypes = {
	openAddEditModal: bool,
	toggleAddEditTypeModal: func.isRequired,
	selectedItem: any,
	openConfirmationPopup: bool,
	toggleConfirmationModal: func.isRequired
}

TypesManagement.defaultProps = {
	openAddEditModal: false,
	selectedItem: null,
	openConfirmationPopup: false
}

export default TypesManagement