import React from 'react'
import classy from 'classnames'
import { Component } from 'react'

class Dropdown extends Component {
  state = {
    open: false
  }

  componentDidMount = () => {
    const { checked } = this.props
    this.setState({ checked })
  }

  closeDropdown = () => {
    this.setState({ open: false })
  }

  openDropdown = () => {
    this.setState({ open: true })
  }

  render() {
    const { className, title, children, error, disabled, id } = this.props
    const { open } = this.state
    return (
      <div id={id && id} className={classy(className, 'gten-dropdown', { 'open': open },{'disabled' : disabled})}>
        <div className={classy('form-control overflow-hidden', { 'is-invalid': error })} onClick={disabled ? () => false : this.openDropdown}>
          {title && <span>{title}</span>}
        </div>
        <div className="backdrop" onClick={this.closeDropdown} ></div>
        <div className="gten-dropdown-options">
          {children}
        </div>
      </div>)
  }
}

export default Dropdown