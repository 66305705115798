import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../elements/TableComponent'
import { fetchAllData, updateData, createData, deleteData, fetchTypes } from '../../middleware/metadata'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddEditDataModal from './AddEditDataModal'
import { any, bool, func } from 'prop-types'
import ConfirmationModal from '../content/ConfirmationModal'

const DataManagement = ({
	openAddEditModal,
	toggleAddEditDataModal,
	selectedItem,
	openConfirmationPopup,
	toggleConfirmationModal
}) => {
	const [searchTerm, setSearchTerm] = useState("")
	const [data, setDatas] = useState([])
	const [types, setTypes] = useState([])

	const columns = [
		{
			id: 'name',
			label: 'Name'
		},
		{
			id: 'type',
			label: 'Type',
		},
	]

	const refreshContent = useCallback(() => {
		fetchAllData()
			.then(async (res) => {
				const result = await res.json()
				setDatas(result)
			})
	}, [])

	const getAllTypes = useCallback(() => {
		fetchTypes()
			.then(async (res) => {
				const result = await res.json()
				setTypes(result)
			})
	}, [])

	useEffect(() => {
		getAllTypes()
		refreshContent()
	}, [refreshContent, getAllTypes])

	const onDelete = useCallback(() => {
		deleteData(selectedItem._id)
			.then(async (response) => {
				refreshContent()
				toggleConfirmationModal(null)
			})
	}, [selectedItem, toggleConfirmationModal, refreshContent])

	const actionMarkup = ({ data }) => (
		<React.Fragment>
			<Button
				color="link"
				title="Edit Data"
				onClick={() => toggleAddEditDataModal(data)}
			>
				<FontAwesomeIcon icon={faPencilAlt} />
			</Button>
			<Button
				color="link"
				className="ml-2 text-danger"
				title="Delete Data"
				onClick={() => toggleConfirmationModal(data)}
			>
				<FontAwesomeIcon icon={faTrash} />
			</Button>
		</React.Fragment>
	)

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}
	const findType = useCallback((id) => {
		if (!types || (types && types.length === 0))
			return null
		return types && types.filter(type => type._id === id).map((el) => {
			return el.name
		}).join(', ')
	}, [types])

	const formatData = useCallback(() => {
		if (!data || (data && data.length === 0))
			return []

		return data && data.map((el, index) => {
			return (
				{
					id: index + 1,
					name: el.name,
					type: el.type ? findType(el.type).toUpperCase() : 'N/A',
					data: el
				}
			)
		})
	}, [data,findType])

	const formatTypes = useCallback(() => {
		if (!types || (types && types.length === 0))
			return []

		return types && types.map((el, index) => {
			return (
				{
					value: el._id,
					label: el.name.toUpperCase(),
				}
			)
		})
	}, [types])


	const formattedData = formatData()
	const filteredRows = formattedData && formattedData.filter((row) => {
		return ((row && row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})
	const formattedTypes = formatTypes()
	const onSubmit = useCallback((values) => {
		if (values && values._id) {
			updateData(values._id, values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditDataModal(null)
				})
		} else {
			createData(values)
				.then(async (response) => {
					refreshContent()
					toggleAddEditDataModal(null)
				})
		}
	}, [toggleAddEditDataModal, refreshContent])

	return (
		<>
			<Container fluid>
				<Row className="mt-5">
					<Col>
						{formattedData && <TableComponent
							showCheckbox={false}
							columns={columns}
							data={filteredRows ? filteredRows : []}
							perPage={10}
							requestSearch={requestSearch}
							cancelSearch={cancelSearch}
							defaultSort="name"
							defaultSortOrder="asc"
							showSerialNo={true}
							action={actionMarkup}
							searchTerm={searchTerm}
						/>}
					</Col>
				</Row>
			</Container>

			<AddEditDataModal
				isOpen={openAddEditModal}
				toggle={toggleAddEditDataModal}
				data={selectedItem}
				onSubmit={onSubmit}
				formattedTypes={formattedTypes}
			/>

			<ConfirmationModal
				isOpen={openConfirmationPopup}
				toggle={toggleConfirmationModal}
				onConfirm={onDelete}
				message={`Are you sure you want to remove this item?`}
			/>
		</>
	)
}

DataManagement.propTypes = {
	openAddEditModal: bool,
	toggleAddEditDataModal: func.isRequired,
	selectedItem: any,
	openConfirmationPopup: bool,
	toggleConfirmationModal: func.isRequired
}

DataManagement.defaultProps = {
	openAddEditModal: false,
	selectedItem: null,
	openConfirmationPopup: false
}

export default DataManagement