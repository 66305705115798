import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
    Button,
    Form,
    FormGroup,
    Table,
    Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
    DatePickerAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateEmail,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AddUserForm = (
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        roles
    }
) => (
    <Form onSubmit={handleSubmit} style={{ overflowX: 'auto' }}>
        <div className="container-fluid p-0">
            <Table id="timesheet-table" className="add-user f-15 table table-striped">
                <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                    <tr>
                        <th>Emp. Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>DOJ</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="users">
                        {({ fields }) => <Fragment>
                            {fields.map((name, index) => (

                                <tr className="w-100" key={`${name}_${index}`}>
                                    <td key={`${name}_${index}empid`}>
                                        <Field
                                            name={`${name}.empid`}
                                            type="text"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.empid`} />
                                    </td>
                                    <td key={`${name}_${index}_fname`}>
                                        <Field
                                            name={`${name}.fname`}
                                            type="text"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.fname`} />
                                    </td>
                                    <td key={`${name}_${index}_lname`}>
                                        <Field
                                            name={`${name}.lname`}
                                            type="text"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.lname`} />
                                    </td>

                                    <td key={`${name}_${index}_email}`}>
                                        <Field
                                            name={`${name}.email`}
                                            type="email"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                    validateEmail()
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.email`} />
                                    </td>
                                    <td key={`${name}_${index}_role`} >
                                        <Field
                                            name={`${name}.role`}
                                            type="select"
                                            bsSize="lg"
                                            validate={validateRequired()}
                                            component={SelectAdapter}
                                            options={roles}
                                        />
                                        <FormFeedbackAdapter name={`${name}.role`} />
                                    </td>
                                    <td key={`${name}_${index}_doj`} >
                                        <Field
                                            name={`${name}.doj`}
                                            type="date"
                                            bsSize="lg"
                                            required={true}
                                            component={DatePickerAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.doj`} />
                                    </td>

                                    <td key={`delete_${name}_${index}.`}>
                                        <Button
                                            className="delete-button"
                                            onClick={() => fields.remove(index)}
                                            color="link">
                                            <FontAwesomeIcon icon={faMinus} className="mr-2" />
                                        </Button>
                                        <Button
                                            className="add-button"
                                            style={{ display: "none" }}
                                            color="link"
                                            onClick={() => fields.push()}
                                            disabled={submitting} size="lg">
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" /></Button>
                                    </td>
                                </tr>

                            ))}

                        </Fragment>}
                    </FieldArray>
                </tbody>
            </Table>
        </div>
        <SubmitErrorAlerts submitError={submitError} />
        <Row className="justify-content-end pr-4">

            <FormGroup>
                <Button
                    color="primary"
                    className="p-3 mr-2"
                    disabled={submitting || submitSucceeded}
                    type="submit">
                    {" Add Employee(s)"}</Button>
            </FormGroup>

            <FormGroup>
                <Button
                    color="outline-primary"
                    className="p-3 mr-2"
                    disabled={submitting || submitSucceeded}
                    type="button"
                    onClick={toggle}
                >Cancel</Button>
            </FormGroup>

        </Row>


    </Form>
)

AddUserForm.propTypes = propTypes

export default AddUserForm
