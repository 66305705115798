import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import Holidays from '../components/Holidays'
import AddHolidayModal from '../components/AddHolidayModal'
import { fetchHolidays, loggingOut } from '../middleware/api'
import { Container, Row, Col, Button } from 'reactstrap'
import { cryptInfo } from '../config'

class HolidayContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddHolidayModal: false,
            holidays: null,
            holiday: null,
            isOpen: true,
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'
        }

        this.getHoliday = this.getHoliday.bind(this)
        this.toggle = this.toggle.bind(this)
        this.setTimings = this.setTimings.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }

    toggleModal = () => {
        this.setState(state => ({
            openAddHolidayModal: !state.openAddHolidayModal,
            holiday: null
        }))
    }

    toggleEditModal = (holiday) => {
        this.toggleModal()
        this.setState({
            holiday: holiday
        })
    }
    getHoliday() {
        fetchHolidays()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    holidays: result
                })
            })
    }
    componentDidMount() {
        loggingOut()
        this.getHoliday()
    }
    render() {
        const { openAddHolidayModal, holiday, holidays, isOpen, isAdmin } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Holidays</span>
                                </Col>
                                {isAdmin && <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleModal}>
                                        Add Holiday
                                    </Button>
                                </Col>}
                            </Row>
                        </Container>
                        <Container fluid>
                            <Holidays
                                holidays={holidays}
                                fetchHolidays={this.getHoliday}
                                toggleEditModal={this.toggleEditModal}
                            />
                        </Container>
                        <AddHolidayModal
                            isOpen={openAddHolidayModal}
                            toggle={this.toggleModal}
                            fetchHolidays={this.getHoliday}
                            holiday={holiday}
                        />

                    </Content>
                </div>
            </>
        );
    }
}

export default HolidayContainer;