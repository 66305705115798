import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { updateAsset, deleteAsset } from '../middleware/api'
import { cryptInfo } from '../config'
import TableComponent from './elements/TableComponent'
import moment from 'moment'

class Assets extends Component {
    constructor() {
        super()
        this.state = {
            searchTerm: "",
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager'
        }
    }

    acceptAsset(asset) {
        const { fetchAssets } = this.props
        asset.status = "ACCEPTED"
        updateAsset(asset._id, asset)
            .then(async () => {
                await fetchAssets()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    releaseAsset(asset) {
        const { fetchAssets } = this.props
        asset.status = "RELEASE-REQUEST"
        updateAsset(asset._id, asset)
            .then(async () => {
                await fetchAssets()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    rejectAsset(asset) {
        const { fetchAssets } = this.props
        asset.status = "REJECTED"
        updateAsset(asset._id, asset)
            .then(async () => {
                await fetchAssets()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    acceptAssetUpdated(asset) {
        const { fetchAllAssets } = this.props
        asset.status = "RELEASED"
        updateAsset(asset._id, asset)
            .then(async () => {
                await fetchAllAssets()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    deleteUserAsset = (asset) => {
        const { fetchAllAssets } = this.props
        deleteAsset(asset._id)
            .then(async () => {
                await fetchAllAssets()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { assets } = this.props
        if (!assets || (assets && assets.length === 0))
            return []

        return assets && assets.map((asset, index) => {
            return (
                {
                    id: index + 1,
                    userId: asset.empid,
                    employeeName: asset.empname,
                    assetType: asset.assesttype,
                    serial: asset.serial,
                    status: asset.status,
                    remark: asset.remark,
                    specification: asset.specification,
                    allocatedOn: moment(asset.allocatedon).format('YYYY-MM-DD'),
                    data: asset
                }
            )
        })
    }

    render() {
        const { assets, toggleEditModal, toggleEmployeeDetail } = this.props
        const { isAdmin, isManager, searchTerm } = this.state

        const formattedData = assets && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.employeeName && row.employeeName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.status && row.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.assetType && row.assetType.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.serial && row.serial.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'employeeName',
                label: 'Employee',
                paramToPass: 'userId',
                onClick: (isAdmin || isManager) ? toggleEmployeeDetail : null
            },
            {
                id: 'assetType',
                label: 'Asset Type',
            },
            {
                id: 'serial',
                label: 'Serial',
            },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'allocatedOn',
                label: 'Allocated On',
            },
            {
                id: 'specification',
                label: 'Specification',
            }
        ]


        const actionMarkup = ({ data }) => (
            <React.Fragment>
                {
                    (isAdmin || isManager) &&
                    <>
                        {
                            <>
                                {data.status === 'RELEASE-REQUEST' && <Button
                                    title="Accept Release Request"
                                    color="link"
                                    onClick={() => this.acceptAssetUpdated(data)}>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="mr-2" />
                                </Button>}
                                <Button
                                    color="link"
                                    title="Edit Asset"
                                    onClick={() => toggleEditModal(data)}>
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        className="mr-2" />
                                </Button>
                                <Button
                                    color="link"
                                    title="Remove Asset"
                                    className="text-danger"
                                    onClick={() => this.deleteUserAsset(data)}>
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className="mr-2" />
                                </Button>
                            </>
                        }
                    </>
                }
                {
                    (!isAdmin && !isManager) &&
                    <>
                        {
                            data && data.status === "CREATED" ?

                                <>
                                    <Button
                                        title="Accept Asset"
                                        color="link"
                                        onClick={() => this.acceptAsset(data)}>

                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="mr-2" />
                                    </Button>
                                    <Button
                                        title="Reject Asset"
                                        color="link"
                                        onClick={() => this.rejectAsset(data)}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className="mr-2" />Reject
                                    </Button>
                                </>
                                :
                                <Button
                                    className="no-wrap"
                                    title="Put Asset Release Request"
                                    color="link"
                                    disabled={data.status === "RELEASED" || data.status === "REJECTED" || data.status === "RELEASE-REQUEST"}
                                    onClick={() => this.releaseAsset(data)}>

                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="mr-2" />Release
                                </Button>
                        }
                    </>
                }
            </React.Fragment>
        )

        return (

            <Row className="mt-5">
                <Col>
                    {formattedData && <TableComponent
                        showCheckbox={false}
                        columns={columns}
                        data={filteredRows ? filteredRows : []}
                        perPage={10}
                        requestSearch={this.requestSearch}
                        cancelSearch={this.cancelSearch}
                        showSerialNo={true}
                        defaultSort="employeeName"
                        action={actionMarkup}
                        searchTerm={searchTerm}
                    />}
                </Col>
            </Row>
        )
    }
}

export default Assets