import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DayPicker, { DateUtils } from 'react-day-picker'


const propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.array,
  }).isRequired,
}

class MultiDayCalAdapter extends Component {

  // @see http://react-day-picker.js.org/examples/selected-multiple
  handleDayClick = (day, { selected }) => {

    const { input: { onChange, value } } = this.props

    // break array reference so Final-Form re-renders new value
    const selectedDays = value.slice()

    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day),
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    onChange(selectedDays)
  }

  render() {

    const { disabledDays, input: { value } } = this.props

    return (
      <DayPicker
        disabledDays={ disabledDays }
        onDayClick={ this.handleDayClick }
        selectedDays={ value }
      />
    )
  }
}

MultiDayCalAdapter.propTypes = propTypes

export default MultiDayCalAdapter