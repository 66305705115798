import React from 'react'
import { Input } from 'reactstrap'

const DatePickerAdapter = (
    {
      input,
      meta,
      min,
      max,
      invalid = meta => meta.touched && meta.invalid,
      valid = () => false,
      disabledDays,
      ...rest
    }
  ) => {
    return(
      <Input
        { ...input } { ...rest } invalid={ invalid(meta) } valid={ valid(meta) }
        min={min} max={max} />
    )}

export default DatePickerAdapter