import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import EditUserForm from './users/EditUserForm'
import AddUserForm from './users/AddUserForm'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { mapFinalFormErrors } from '../lib/utils'
import arrayMutators from 'final-form-arrays'
import { createUser, updateUser, addUserMail } from '../middleware/api'
import { cryptInfo } from '../config'

const mapErrors = mapFinalFormErrors('User addition failed')

class AddUserModal extends React.Component {
    state = {
        user: null
    }

    onSubmit = async (values) => {
        const { fetchUsers, user, toggle } = this.props
        if (user && user._id) {
            updateUser(user._id, values)
                .then(async (response) => {
                    await fetchUsers()
                    toggle()
                })
        } else {
            values.users.forEach(async (user) => {
                user.leavebalanceEL = null
                user.leavebalanceCL = null
                user.leaveCredited = false
                user.status = "Active"
                await createUser(user)
                    .then(async (response) => {
                        const user = await response.json()
                        if (user.error) {
                            alert("Some of the Users with the same email already exists")
                        } else {
                            await addUserMail({
                                subject: "Registration mail",
                                receiver: user.email,
                                sender: cryptInfo.decrypt(localStorage.getItem('email')),
                                message: `<p>Hello ${user.fname + " " + user.lname},<br/>
                                    <i>You have been added to GTEN Portal.Please follow the link to login/signup to the portal.</i>
                                    <br/>
                                    <a href="http://localhost:3000/register/${user.email}"> LOGIN TO GTEN PORTAL</a>
                                    </p>
                                `
                            })
                            fetchUsers()
                            toggle()
                        }

                    })

                    .catch((error) => {
                        console.log(error)
                    })
            });

        }
    }

    render() {

        const { isOpen, toggle, user, roles } = this.props
        const modalTitle = user ? "Edit Employee" : "Add Employee"
        const defUser = {
            fname: "",
            lname: "",
            email: "",
            role: ""
        }
        const defaultValues = user ? {
            fname: user && user.fname ? user.fname : "",
            lname: user && user.lname ? user.lname : "",
            email: user && user.email ? user.email : "",
            role: user && user.role ? user.role : "",
        } : { users: [defUser] }
        return (
            <div>
                <Modal size="lg" isOpen={isOpen} toggle={toggle} className={this.props.className}>
                    <ModalHeader
                        tag="h2"
                        toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form
                            component={user ? EditUserForm : AddUserForm}
                            id="adduser"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            roles={roles}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                            }}
                            mutators={{
                                ...arrayMutators,
                            }}
                            update={user}
                            initialValues={
                                defaultValues
                            }
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddUserModal;