import React from 'react'
import Dropdown from './Dropdown'
import { Input, FormGroup, Label } from 'reactstrap'
import { func, instanceOf } from 'prop-types'
const CheckboxDropdown = (
	{
		onChangeSelectAll,
		onChangeOption,
		selectAllChecked,
		allOptions,
		arrayToUpdate,
		title,
		showSelectAll,
		disabled
	}
) => {
	var selectedOptionsLabel
	var selectedOptions = []
	if (arrayToUpdate && arrayToUpdate.length > 0) {
		allOptions && allOptions.map(option => {
			const index = arrayToUpdate && arrayToUpdate.findIndex((s) => s.value === option.value)
			if (index > -1) {
				selectedOptions.push(option)
			}
			return false
		})
		selectedOptionsLabel = selectedOptions && selectedOptions.map(doc => doc.label).join(", ")
	
	}
	return (
		<>
			<Dropdown
				title={selectedOptionsLabel || `${title}`}
				disabled={disabled}
			>
				{showSelectAll && <FormGroup check>
					<Label check>
						<Input type="checkbox"
							className=""
							value="*"
							checked={selectAllChecked}
							onChange={(event) => onChangeSelectAll(event.target.value)
							} /> {' '}
						Select All
					</Label>
				</FormGroup>}
				<br />
				<>
					{allOptions && allOptions.map((option, i) => {
						let optionOne = arrayToUpdate && arrayToUpdate.filter(e => option.value === e.value)
						return (<React.Fragment key={"option" + i}>
							<FormGroup check>
								<Label check>
									<Input type="checkbox"
										className=""
										checked={optionOne && optionOne.length > 0 && optionOne[0].selected}
										value={option.value || ''}
										onChange={(event) => onChangeOption(event.target.value)
										} /> {' '}
									{option.label}
								</Label>
							</FormGroup>
							<br /></React.Fragment>)
					})}
				</>
			</Dropdown>

		</>
	)
}

CheckboxDropdown.propTypes = {
	onChangeSelectAll: func,
	onChangeOption: func.isRequired,
	arrayToUpdate: instanceOf(Array)
}

export default CheckboxDropdown