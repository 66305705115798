import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import EditHolidayForm from './holidays/EditHolidayForm'
import AddHolidayForm from './holidays/AddHolidayForm'
import { Form } from 'react-final-form'
import { mapFinalFormErrors } from '../lib/utils'
import arrayMutators from 'final-form-arrays'
import { createHoliday, updateHoliday } from '../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const mapErrors = mapFinalFormErrors('Holiday creation failed')

class AddHolidayModal extends React.Component {
    state = {
        holiday: null
    }

    onSubmit = (values) => {
        const { fetchHolidays, holiday, toggle } = this.props
        if (holiday && holiday._id) {
            updateHoliday(holiday._id, values)
                .then(async (response) => {
                    const data = await response.json()
                        if(data.error){
                            toast.error("Holiday already exist for the given date.")
                        }else{
                            toast.success("Holiday added successfully.")
                            fetchHolidays()
                            toggle()
                        }
                })
        } else {
            values.holidays.forEach(holiday => {
                createHoliday(holiday)
                    .then(async (response) => {
                        const data = await response.json()
                        if(data.error){
                            toast.error("Holiday already exist for the given date.")
                        }else{
                            toast.success("Holiday added successfully.")
                            fetchHolidays()
                            toggle()
                        }
                    })
                    .catch(mapErrors)
            });
            
        }
    }

    render() {

        const { isOpen, toggle, holiday } = this.props
        const modalTitle = holiday ? "Edit Holiday" : "Add Holiday"
        const defHoliday = {
            date: "",
            occassion: ""
        }
        const defaultValues = holiday ? {
            date: holiday && holiday.date ? holiday.date : "",
            occassion: holiday && holiday.occassion ? holiday.occassion : ""
        } : { holidays: [defHoliday] }
        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className={this.props.className}>
                    <ModalHeader 
                        tag="h2"
                        toggle={toggle} >{modalTitle}</ModalHeader>
                        <ModalBody>
                            <Form
                                component={holiday ? EditHolidayForm : AddHolidayForm}
                                id="addholiday"
                                toggle={toggle}
                                onSubmit={this.onSubmit}
                                subscription={{
                                    pristine: true,
                                    submitError: true,
                                    submitting: true,
                                    hasValidationErrors: true,
                                }}
                                mutators={{
                                    ...arrayMutators,
                                }}
                                update={holiday}
                                initialValues={
                                    defaultValues
                                }
                            />
                        </ModalBody>
                </Modal>
                <ToastContainer/>
            </div>
        );
    }
}

export default AddHolidayModal;