import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditCandidateForm from './AddEditCandidateForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { any, bool, func } from 'prop-types'

const AddEditCandidateModal = ({ isOpen,
	toggle,
	data,
	onSubmit,
	handleSelectAll,
	handleSelectOption,
	selectedSkills,
	selectAll,
	skillsDropdownData,
	locationsDropdownData,
	handleSelectAllLocations,
	handleSelectLocationOption,
	selectedLocations,
	selectAllLocations,
	formattedJobs
}) => {
	return (
		<>
			<Modal id="project-modal" size="lg" isOpen={isOpen} toggle={toggle}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Candidate</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditCandidateForm}
						formId="addcandidate"
						toggle={toggle}
						onSubmit={onSubmit}
						handleSelectAll={handleSelectAll}
						handleSelectOption={handleSelectOption}
						selectedSkills={selectedSkills}
						allOptions={skillsDropdownData}
						selectAll={selectAll}
						skillsDropdownData={skillsDropdownData}
						locationsDropdownData={locationsDropdownData}
						handleSelectAllLocations={handleSelectAllLocations}
						handleSelectLocationOption={handleSelectLocationOption}
						selectedLocations={selectedLocations}
						selectAllLocations={selectAllLocations}
						formattedJobs={formattedJobs}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							reset: true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={{ recruiterName: localStorage.getItem('fname') + " " + localStorage.getItem('lname'),recruiterId: localStorage.getItem('user_id'), ...data }}
					/>
				</ModalBody>
			</Modal>
		</>
	)
}

AddEditCandidateModal.propTypes = {
	isOpen: bool,
	toggle: func.isRequired,
	kra: any,
	selectedSkills: any,
	formattedJobs:any.isRequired,
	skillsDropdownData:any.isRequired,
	locationsDropdownData:any.isRequired
}

AddEditCandidateModal.defaultProps = {
	isOpen: false,
	kra: null,
	selectedSkills: [],
	formattedJobs:[],
	skillsDropdownData:[],
	locationsDropdownData:[]
}
export default AddEditCandidateModal