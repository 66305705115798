import {
  faHome,
  faClock,
  faRupeeSign,
  faCalendar,
  faChartBar,
  faBirthdayCake,
  faListOl,
  faBell,
  faDesktop,
  faHandshake
} from '@fortawesome/free-solid-svg-icons';

export const sidebarData = {
  home: {
    label: 'Home',
    icon: faHome,
    link: 'welcome'
  },
  timesheet: {
    label: 'Timesheet',
    icon: faClock,
    link: 'timesheet-home'
  },
  leave: {
    label: 'Leaves',
    icon: faCalendar,
    link: 'leaves-home'
  },
  assetsallocated: {
    label: 'Assets Allocated',
    icon: faDesktop,
    link: 'assets'
  },
  task: {
    label: 'Tasks',
    icon: faListOl,
    link: 'tasks'
  },
  performance: {
    label: 'Performance',
    icon: faChartBar,
    link: 'performance-home'
  },
  reimbursement: {
    label: 'Reimbursement',
    icon: faRupeeSign,
    link: 'reimbursement'
  },
  referrals: {
    label: 'Referrals',
    icon: faHandshake,
    link: 'referrals'
  },
  // investments: {
  //   label: 'Investments',
  //   icon: faWallet,
  //   link: 'investments'
  // },
  holiday: {
    label: 'Holidays',
    icon: faBirthdayCake,
    link: 'holidays'
  },
  feed: {
    label: 'Our Feeds',
    icon: faBell,
    link: 'ourfeeds'
  }

}

export const sidebarArray = Object.keys(sidebarData).map(value => ({
  value,
  label: sidebarData[value].label,
  icon: sidebarData[value].icon,
  link: sidebarData[value].link
}))