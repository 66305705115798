import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import TimesheetModal from '../components/timesheet/TimesheetModal'
import { fetchMultipleProjectName } from '../data/dataFromApis'
import { fetchProjects, fetchTasks } from '../middleware/api'
import { cryptInfo } from '../config'
import moment from 'moment'
import 'react-table-filter/lib/styles.css'
import TableComponent from './elements/TableComponent'

class TimesheetApproved extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timesheets: [],
            projects: null,
            currentTimesheet: null,
            isOpen: false,
            tasks: [],
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            searchTimesheet: '',
            stateDown: true,
            users: [],
            searchTerm: ""
        }
        this.toggleTimesheetModal = this.toggleTimesheetModal.bind(this);
    }

    componentDidMount() {
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()

                this.setState({
                    projects: result
                })
            })
        fetchTasks()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    tasks: result
                })

            })
    }

    toggleTimesheetModal(currentTimesheet) {
        this.setState(state => ({
            isOpen: !state.isOpen,
            currentTimesheet: currentTimesheet,
        }))
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { projects } = this.state
        const { timesheets } = this.props
        if (!timesheets || (timesheets && timesheets.length === 0))
            return []

        return timesheets && timesheets.map((timesheet, index) => {
            var projectArray = projects && timesheet.timesheet && fetchMultipleProjectName(projects, timesheet.timesheet)
            return (
                {
                    id: index + 1,
                    projects: (projectArray.length === 0 || !projectArray) ? "N/A" : projectArray.join(', '),
                    startOfWeek: `${moment(timesheet.startOfWeek).format('MM/DD')} - ${moment(timesheet.startOfWeek).format('MM/DD')}`,
                    userName: timesheet.username,
                    status: timesheet.status,
                    remark: timesheet.remark && timesheet.remark !== "" ? timesheet.remark : "Nil",
                    userId: timesheet.user_id,
                    data: timesheet
                }
            )
        })
    }

    render() {
        const { timesheets, toggleEmployeeDetail } = this.props
        const { projects, isOpen, currentTimesheet, tasks, isAdmin, searchTerm } = this.state
        const formattedData = timesheets && projects && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.userName && row.userName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.status && row.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.projects && row.projects.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.startOfWeek && row.startOfWeek.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'userName',
                label: 'Team Member',
                paramToPass: 'userId',
                onClick: isAdmin ? toggleEmployeeDetail : null
            },
            {
                id: 'projects',
                label: 'Projects',
            },
            {
                id: 'startOfWeek',
                label: 'Week',
            },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'remark',
                label: 'Remark',
            },
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                <Button
                    color="link"
                    onClick={() => this.toggleTimesheetModal(data)}
                >
                    <FontAwesomeIcon
                        icon={faEye}
                        className="mr-2" />View
                </Button>
            </React.Fragment>
        )

        return (

            <>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            defaultSort="userName"
                            action={actionMarkup}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
                <TimesheetModal
                    isOpen={isOpen}
                    toggleModal={this.toggleTimesheetModal}
                    currentTimesheet={currentTimesheet}
                    projects={projects && projects}
                    tasks={tasks && tasks}
                    fetchProjectName={this.fetchProjectName}
                />
            </>
        );
    }
}

export default TimesheetApproved;