import React, { Component } from 'react';
import { Table, Row, Col, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons'
import { fetchApprovedInvestments } from '../../middleware/investments'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'

const userId = localStorage.getItem('user_id')

class ApprovedInvestmentsEmployees extends Component {
    state = {
        reimbursements: []
    }
    componentDidMount = () => {
        this.refreshList()
    }
    refreshList = async () => {
        await fetchApprovedInvestments(userId)
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    reimbursements: result
                })
            })
    }
    render() {
        const { reimbursements } = this.state
        var timeStamp = + new Date()
        return (
            <Container fluid className="py-4">
                <Row>
                    <Col>
                        <Table striped bordered hover>
                            <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                                <tr>
                                    <th>Title</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>File</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reimbursements && reimbursements.length > 0 ? reimbursements.map((row, index) => (
                                        <tr key={"row_" + index}>
                                            <td className="text-success">{row.investmentTitle}</td>
                                            <td>{row.investmentDate}</td>
                                            <td>{row.investmentAmount}</td>
                                            <td>{row.fileUploadKeys && row.fileUploadKeys.map(file => (
                                                <a
                                                    key={file}
                                                    rel="noopener noreferrer"
                                                    href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
                                                    className="text-primary"
                                                    target="_blank" >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </a>

                                            ))}</td>
                                            <td><FontAwesomeIcon icon={faCheckCircle} color="green" /></td>
                                        </tr>
                                    )) :
                                        <tr title="Your investment has been approved."><td colSpan="7">Nothing to show</td></tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ApprovedInvestmentsEmployees;