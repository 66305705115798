import React, { Component } from 'react';
import './App.css';
import './styles/globals.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
class App extends Component {
  state = {
    isLogin: false
  }
  render() {
    return (
      <>
        <Router >
          <Routes />
        </Router>
      </>
    );
  }
}

export default App;