export const candidateColumns = [
	{
		id: 'recruiterName',
		label: 'Recruiter'
	},
	{
		id: 'job',
		label: 'Job Title'
	},
	{
		id: 'name',
		label: 'Candidate Name'
	},
	// {
	// 	id: 'phoneNumber',
	// 	label: 'Contact No',
	// },
	// {
	// 	id: 'email',
	// 	label: 'Email',
	// },
	{
		id: 'skills',
		label: 'Skills',
	},
	// {
	// 	id: 'totalExperience',
	// 	label: 'Experience',
	// },
	// {
	// 	id: 'currentLocation',
	// 	label: 'Current Location',
	// },
	// {
	// 	id: 'preferredLocations',
	// 	label: 'Preferred Locations',
	// },
	{
		id: 'currentCTC',
		label: 'Current CTC',
	},
	{
		id: 'expectedCTC',
		label: 'Expected CTC',
	},
	// {
	// 	id: 'currentCompanyName',
	// 	label: 'Company Name',
	// },
	{
		id: 'noticePeriod',
		label: 'Notice Period',
	},
	// {
	// 	id: 'gender',
	// 	label: 'Gender',
	// },
	{
		id: 'status',
		label: 'Status',
	},
]

export const jobsColumns = [
	{
		id: 'title',
		label: 'Title'
	},
	{
		id: 'summary',
		label: 'Summary',
	},
	{
		id: 'description',
		label: 'Description',
	},
	{
		id: 'experience',
		label: 'Experience',
	},
	{
		id: 'mandatorySkills',
		label: 'Mandatory Skills',
	},
	{
		id: 'otherSkills',
		label: 'Good To Have Skills',
	},
	{
		id: 'location',
		label: 'Location',
	},
	{
		id: 'hiringManager',
		label: 'Hiring Manager',
	},
	{
		id: 'interviewPanel',
		label: 'Interview Panel',
	},
	{
		id: 'status',
		label: 'Status',
	},
]

export const referralColumns = [
	{
		id: 'name',
		label: 'Name'
	},
	{
		id: 'email',
		label: 'Email',
	},
	{
		id: 'phone',
		label: 'Phone',
	},
	{
		id: 'skills',
		label: 'Skills',
	},
	{
		id: 'experience',
		label: 'Total Experience',
	},
	{
		id: 'status',
		label: 'Status',
	},
]


