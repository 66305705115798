import React, { Component } from 'react'
import { Input, Label, Row, Col } from 'reactstrap'
import { cryptInfo } from '../config'
import 'react-table-filter/lib/styles.css'
import TableComponent from './elements/TableComponent'
import moment from 'moment'

class UserLogs extends Component {
    constructor() {
        super()
        this.state = {
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            stateDown: true,
            users: [],
            searchTerm: ""
        }
    }

    handleDays(event) {
        const { handleDays } = this.props
        handleDays(event.target.value)
        this.setState({
            searchTerm: ""
        })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { logs } = this.props
        if (!logs || (logs && logs.length === 0))
            return []

        return logs && logs.map((log, index) => {
            return (
                {
                    id: index + 1,
                    employeeName: log.empname,
                    date: moment(log.date).format('YYYY-MM-DD'),
                    checkInTime: moment(log.signin_time).format('HH:mm:ss'),
                    checkOutTime: log.signout_time ? moment(log.signout_time).format('HH:mm:ss') : "Didn't Sign Out.",
                    checkInLocation: `Lattitude:${log.sign_in_lat}\nLongitude:${log.sign_in_long}`,
                    checkOutLocation: log.signout_time ? `Lattitude:${log.sign_out_lat}\nLongitude:${log.sign_out_long}` : "Didn't Sign Out.",
                    totalTime: log.overall_time,
                    userId: log._id,
                    data: log
                }
            )
        })
    }

    render() {
        const { logs } = this.props
        const { searchTerm } = this.state
        const formattedData = logs && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.employeeName && row.employeeName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.totalTime && row.totalTime.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.date && row.date.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'employeeName',
                label: 'Employee Name'
            },
            {
                id: 'date',
                label: 'Date',
            },
            {
                id: 'checkInTime',
                label: 'Check In Time',
            },
            {
                id: 'checkOutTime',
                label: 'Check Out Time',
            },
            {
                id: 'checkInLocation',
                label: 'Check In Location',
            },
            {
                id: 'checkOutLocation',
                label: 'Check Out Location',
            },
            {
                id: 'totalTime',
                label: 'Total Time',
            },
        ]
        return (
            <>
                <Row>
                    <Col>
                        <div className="form-group form-inline">
                            <Label htmlFor={`noofdays`} className="ml-2">Select Days : &nbsp;</Label>
                            <Input type="select" name="noofdays" onChange={this.handleDays.bind(this)}>
                                <option value="7">7 Days</option>
                                <option value="30">1 Month</option>
                                <option value="90">3 Months</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            defaultSort="firstName"
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            action={null}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
            </>
        )
    }
}

export default UserLogs