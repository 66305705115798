import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { InputAdornment, TextField } from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array && array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{props.showCheckbox && <TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
					/>
				</TableCell>}
				{props.showSerialNo &&
					<TableCell
						key={`SrNo`}
						align='left'
						padding={'normal'}
					>
						<span>Sr. No.</span>
					</TableCell>
				}
				{props.columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				{props.action &&
					<TableCell
						key={`action`}
						align='left'
						padding={'normal'}
					>

					</TableCell>
				}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: '1 1 50%',
	},
	menuItem: {
		'.MuiMenu-paper': {
			minWidth: '180px'
		}
	}
}))

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles()
	const { numSelected, filterPaySlip } = props
	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
				<TextField
					style={{ width: '50%', maxWidth: '475px', float: 'left' }}
					variant="outlined"
					value={props.searchTerm}
					className="search-bar"
					label=""
					placeholder={"Search"}
					onChange={searchVal => props.requestSearch(searchVal.target.value)}
					InputLabelProps={{ shrink: false }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
						endAdornment: (
							props.searchTerm ? <InputAdornment position="end" style={{ pointer: 'cursor' }}>
								<IconButton onClick={props.cancelSearch}>
									<Close />
								</IconButton>
							</InputAdornment> : null
						)
					}}
				/>
			</Typography>

			{props.showFilterButton && <Tooltip title="Click to Apply Filter">
				<IconButton aria-label="filter list" onClick={props.handleFilterPopup}>
					<FilterListIcon />
				</IconButton>
			</Tooltip>}
			{props.showFilterButton && <Tooltip title="Click to Clear Filter">
				<IconButton aria-label="filter list" onClick={() => props.onApplyFilter(null)}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>}
			{props.selectedStatusFilter && <>
				<select style={{
					padding: '16px',
					borderRadius: '8px',
					border: '1px solid #b9aed2',
					background: 'transparent',
					outline: 'none'
				}} onChange={(e) => props.selectedStatusFilter(e.target.value)}>
					<option value="Active">Active</option>
					<option value="Inactive">Inactive</option>
					<option value="Resigned">Resigned</option>
				</select>
			</>}
			{
				filterPaySlip && <>
					{filterPaySlip}
				</>
			}
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		borderTop: '1px solid #bdb1d6'
	},
	paper: {
		width: '100%',
		paddingTop: '10px',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}))

export default function TableComponent({
	showCheckbox,
	columns,
	data,
	requestSearch,
	cancelSearch,
	searchTerm,
	perPage,
	showSerialNo,
	action,
	defaultSort,
	defaultSortOrder,
	showFilterButton,
	handleFilterPopup,
	onApplyFilter,
	selectedStatusFilter,
	filterPaySlip
}) {
	const classes = useStyles()
	const [order, setOrder] = React.useState(defaultSortOrder || 'asc')
	const [orderBy, setOrderBy] = React.useState(defaultSort)
	const [selected, setSelected] = React.useState([])
	const [page, setPage] = React.useState(0)
	const [dense, setDense] = React.useState(true)
	const [rowsPerPage, setRowsPerPage] = React.useState(perPage)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = data.map((n) => n.name)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	}

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected = []
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}
		setSelected(newSelected)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const isSelected = (name) => selected.indexOf(name) !== -1

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>

				<EnhancedTableToolbar
					searchTerm={searchTerm}
					requestSearch={requestSearch}
					cancelSearch={cancelSearch}
					numSelected={selected.length}
					showFilterButton={showFilterButton}
					handleFilterPopup={handleFilterPopup}
					onApplyFilter={onApplyFilter}
					selectedStatusFilter={selectedStatusFilter}
					filterPaySlip={filterPaySlip}
				/>
				<TableContainer className="mt-4">
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={data.length}
							showCheckbox={showCheckbox}
							columns={columns}
							showSerialNo={showSerialNo}
							action={action}
						/>

						<TableBody>
							{
								data && data.length == 0 ? <TableRow>
									<TableCell colSpan={columns.length + (action ? 1 : 0) + (showSerialNo ? 1 : 0)}>
										<p className="text-center">No data found</p>
									</TableCell>
								</TableRow> : <>
									{stableSort(data && data, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, rowIndex) => {
											const isItemSelected = isSelected(row.name)
											const labelId = `enhanced-table-checkbox-${rowIndex}`
											return (
												<TableRow
													hover
													onClick={(event) => handleClick(event, row.name)}
													role="checkbox"
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={'row' + (rowIndex + 1)}
													selected={isItemSelected}
													columns={columns}
												>
													{showCheckbox && <TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															inputProps={{ 'aria-labelledby': labelId }}
														/>
													</TableCell>}
													{
														showSerialNo &&
														<TableCell>
															<span>{rowsPerPage * (page) + rowIndex + 1}</span>
														</TableCell>
													}
													{columns && columns.map(({ id, onClick, paramToPass, format }, index) => {
														return <TableCell
															key={'col' + index + rowIndex}
															onClick={onClick ? () => onClick(row[paramToPass]) : () => false}
															style={onClick ? { cursor: 'pointer' } : { cursor: 'inherit' }}
															align="left"
														>{format ? format(row[id]) : row[id]}</TableCell>
													})}
													{action && <TableCell className="row-action" align="left">
														{action(row)}
													</TableCell>}
												</TableRow>
											)
										})}</>

							}
							{emptyRows > 0 && (
								<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
									<TableCell colSpan={columns.length} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				<FormControlLabel
					control={<Switch checked={dense} onChange={handleChangeDense} />}
					label="Dense padding"
				/>
			</Paper>
		</div >
	)
}
