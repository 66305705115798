import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ReimbursementRequestForm from './ReimbursementRequestForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
const defReimbursements = [{
    reimbursementType: "",
    status: "",
    amount: 1,
}]
const AddReimbusrementModal = ({ isOpen, toggle, onSubmit, reimbursementType,selectedType }) => {
    return (
        <>
            <Modal size="lg" isOpen={isOpen} toggle={toggle}>
                <ModalHeader
                    tag="h2"
                    toggle={toggle}>Add/Edit Reimbursement</ModalHeader>
                <ModalBody>
                    <Form
                        component={ReimbursementRequestForm}
                        toggle={toggle}
                        onSubmit={onSubmit}
                        subscription={{
                            pristine: true,
                            submitError: true,
                            submitting: true,
                            hasValidationErrors: true,
                            values: true
                        }}
                        reimbursementType={reimbursementType}
                        mutators={{
                            ...arrayMutators,
                        }}
                        initialValues={{ reimbursements: selectedType ? [selectedType] : defReimbursements }}
                    />
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddReimbusrementModal;