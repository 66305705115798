import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { deleteHoliday } from '../middleware/api'
import { cryptInfo } from '../config'
import TableComponent from './elements/TableComponent'
import moment from "moment"

class Holidays extends Component {
    constructor() {
        super()
        this.state = {
            searchTerm: "",
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'
        }
    }

    delete(id) {
        const { fetchHolidays } = this.props
        deleteHoliday(id)
            .then(() => {
                fetchHolidays()
            })
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { holidays } = this.props
        if (!holidays || (holidays && holidays.length === 0))
            return []

        return holidays && holidays.map((holiday, index) => {
            return (
                {
                    id: index + 1,
                    date: moment(holiday.date).format('YYYY/MM/DD'),
                    occassion: holiday.occassion,
                    day: moment(holiday.date).format('dddd'),
                    data: holiday
                }
            )
        })
    }
    render() {
        const { holidays, toggleEditModal } = this.props
        const { isAdmin, searchTerm } = this.state
        const formattedData = holidays && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.day && row.day.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.occassion && row.occassion.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.date && row.date.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'date',
                label: 'Date',
            },
            {
                id: 'occassion',
                label: 'Occassion',
            },
            {
                id: 'day',
                label: 'Day',
            },
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                <Button color="link" className="action no-wrap"
                    onClick={() => toggleEditModal(data)}
                >
                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
                </Button>
                <Button color="link" className="action text-danger no-wrap"
                    onClick={() => this.delete(data._id)}
                >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                </Button>
            </React.Fragment>
        )

        return (
            <>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            defaultSort="date"
                            action={isAdmin ? actionMarkup : null}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
            </>
        )
    }
}

export default Holidays