import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import CheckboxDropdown from '../content/CheckboxDropdown'
// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter,
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'
import { experienceData } from '../../data/recruitmentData'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const AddEditJobForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        managers,
        handleSelectAll,
        selectedPanelist,
        handleSelectOption,
        allOptions,
        selectAll,
        handleSelectAllSkills,
        handleSelectSkills,
        selectedSkills,
        skillsDropdownData,
        selectAllSkills,
        locationsDropdownData,
        handleSelectAllLocations,
        handleSelectLocationOption,
        selectedLocations,
        selectAllLocations
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4" id="jobform">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`title`}>Job Posting Title</Label>
                        <Field
                            name="title"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="title" />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`summary`}>Brief Summary</Label>
                        <Field
                            name="summary"
                            type="textarea"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="summary" />
                    </FormGroup>
                </Col>

                <Col md>
                    <FormGroup>
                        <Label htmlFor={`description`}>Description</Label>
                        <Field
                            name="description"
                            type="textarea"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="description" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`experience`}>Experience Required</Label>
                        <Field
                            name="experience"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={experienceData} />
                        <FormFeedbackAdapter name="experience" />
                    </FormGroup>
                </Col>
                <Col>
                    <Label htmlFor={`skills`}>Locations</Label>
                    <CheckboxDropdown
                        onChangeSelectAll={handleSelectAllLocations}
                        onChangeOption={handleSelectLocationOption}
                        selectAllChecked={selectAllLocations}
                        allOptions={locationsDropdownData.map(option => ({ value: option.value, label: option.label }))}
                        showSelectAll={true}
                        arrayToUpdate={selectedLocations}
                        title="Select Locations"
                    />
                </Col>
                {/* <Col md>
                    <FormGroup>
                        <Label htmlFor={`location`}>Location</Label>
                        <Field
                            name="location"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="location" />
                    </FormGroup>
                </Col> */}

            </Row>
            <Row>
                <Col>
                    <Label htmlFor={`skills`}>Mandatory Skills</Label>
                    <CheckboxDropdown
                        onChangeSelectAll={handleSelectAllSkills}
                        onChangeOption={handleSelectSkills}
                        selectAllChecked={selectAllSkills}
                        allOptions={skillsDropdownData.map(option => ({ value: option.value, label: option.label }))}
                        showSelectAll={true}
                        arrayToUpdate={selectedSkills}
                        title="Select Skills"
                    />
                </Col>
                {/* <Col md>
                    <FormGroup>
                        <Label htmlFor={`mandatorySkills`}>Mandatory Skills <span style={{ fontSize: '10px' }}>(Please enter comma seperated values like ReactJs, NodeJs, etc.)</span></Label>
                        <Field
                            name="mandatorySkills"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="mandatorySkills" />
                    </FormGroup>
                </Col> */}
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`otherSkills`}>Good to have skills <span style={{ fontSize: '10px' }}>(Please enter comma seperated values like ReactJs, NodeJs, etc.)</span></Label>
                        <Field
                            name="otherSkills"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="otherSkills" />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`hiringManager`}>Hiring Manager</Label>
                        <Field
                            name="hiringManager"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={managers} />
                        <FormFeedbackAdapter name="hiringManager" />
                    </FormGroup>
                </Col>
                <Col>
                    <Label htmlFor={`interviewPanel`}>Interview Penal</Label>
                    <CheckboxDropdown
                        onChangeSelectAll={handleSelectAll}
                        onChangeOption={handleSelectOption}
                        selectAllChecked={selectAll}
                        allOptions={allOptions.map(option => ({ value: option.value, label: option.label }))}
                        showSelectAll={true}
                        arrayToUpdate={selectedPanelist}
                        title="Select Interview Penal"
                    />
                </Col>
            </Row>
            <Row className="mb-5 mt-2">

                <Col md>
                    <FormGroup>
                        <Label htmlFor={`status`}>Status</Label>
                        <Field
                            name="status"
                            type="select"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={SelectAdapter}
                            options={[{ value: 'Open', label: 'Open' }, { value: 'Closed', label: 'Closed' }]} />
                        <FormFeedbackAdapter name="status" />
                    </FormGroup>
                </Col>
                <Col>
                    <Row>
                        <Col md="6">
                            <Label>Please select social media platforms to which you want to share this.</Label>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`linkedIn`}>LinkedIn</Label>
                                <Field
                                    name="linkedIn"
                                    type="checkbox"
                                    bsSize="lg"
                                    format={v => v === "true"}
                                    parse={v => (v ? "true" : false)}
                                    component={InputAdapter} />
                                <FormFeedbackAdapter name="linkedIn" />
                            </FormGroup>
                        </Col>
                        <Col md>
                            <FormGroup>
                                <Label htmlFor={`twitter`}>Twitter</Label>
                                <Field
                                    name="twitter"
                                    type="checkbox"
                                    bsSize="lg"
                                    format={v => v === "true"}
                                    parse={v => (v ? "true" : false)}
                                    component={InputAdapter} />
                                <FormFeedbackAdapter name="twitter" />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

            </Row>
            <SubmitErrorAlerts submitError={submitError} />
            <Row>
                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="primary"
                            className="p-3 mr-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            type="submit">
                            {"Save"}</Button>
                    </FormGroup>
                </Col>

                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={() => toggle(null)}>Cancel</Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

AddEditJobForm.propTypes = propTypes

export default AddEditJobForm
