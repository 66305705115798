import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditTypeForm from './AddEditTypeForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { any, bool, func } from 'prop-types'

const AddEditTypeModal = ({ isOpen, toggle, data, onSubmit}) => {
	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Metadata Type</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditTypeForm}
						formId="addtype"
						toggle={toggle}
						onSubmit={onSubmit}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							reset: true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={data}
					/>
				</ModalBody>
			</Modal>
		</>
	)
}
AddEditTypeModal.propTypes = {
	isOpen: bool,
	toggle: func.isRequired,
	data: any,
	selectedPermissions: any
}

AddEditTypeModal.defaultProps = {
	isOpen: false,
	data: null,
	selectedPermissions: []
}
export default AddEditTypeModal