import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import OffBoardForm from './OffBoardForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {filterUser} from '../../data/dataFromApis'
import { fetchUsers,updateProject,fetchUser, updateUser } from '../../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import { fetchRoles } from '../../middleware/roles'

class DeactivateModal extends React.Component {
    state = {
        users: null,
        deactivate:false,
        roles:[]
    }
    
    componentDidMount() {
        fetchUsers()
        .then(async (response) => {
            const result = await response.json()
            this.setState({
                users: result
            })
        })
        fetchRoles()
        .then(async (response) => {
            const result = await response.json()
            this.setState({
                roles: result
            })
        })
    }

    onSubmit = (values) => { 
        const { fetchProjects, toggleModal, project } = this.props
        project.teammember = values.teammember
        values.teammember && values.teammember.forEach(member => {
            fetchUser(member.user)
            .then(async (response)=>{
                var user = await response.json()
                if(user && user.manager.indexOf(project.projectmanager) === -1){
                    user.manager.push(project.projectmanager)
                    updateUser(user._id,user)
                .then(async (res)=>{
                    
                })
                }    
            })
        });
        if(project.teammember.length === 0){
            project.status = 'inactive'
        updateProject(project._id,project)
            .then(async (response) => {
                await fetchProjects()
                toggleModal(null)
            })
            toast.success('Project deactivated Succesfully')
        }
    }

    render() {
        const { toggleModal, deactivateOpen, project,handleCancel } = this.props
        const { users,roles } = this.state
        let adminUsers = users && filterUser(users, 'admin')
        let devUsers = users && filterUser(users, 'dev')
        let qaUsers = users && filterUser(users, 'qa')
        let managerUsers = users && filterUser(users, 'manager')
        const defMember = [{
            role:"",
            startdate:"",
            enddate:"",
            allocation:"",
            user:""
        }]
        return (
            <div>
                <Modal id="teammember-modal" isOpen={deactivateOpen} toggle={handleCancel}>
                    <ModalHeader
                    tag="h2"
                    toggle={handleCancel}>Off Board Team Members</ModalHeader>
                    <ModalBody>
                        <Form 
                            component= {OffBoardForm}
                            showOffBoardButton = {true}
                            formId="offboarding"
                            toggle={toggleModal}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                                values:true
                            }}
                            adminUsers={adminUsers && adminUsers}
                            roles={roles && roles.map(role => ({value:role.role,label:role.role.toUpperCase()}))}
                            users ={users && users}
                            devUsers={devUsers && devUsers}
                            qaUsers={qaUsers && qaUsers}
                            managerUsers={managerUsers && managerUsers}
                            mutators={{
                                ...arrayMutators,
                            }}
                            initialValues={{ teammember: project && project.teammember && project.teammember.length > 0 ? project.teammember : defMember }}
                        />

                    </ModalBody>
                </Modal>
                <ToastContainer />
            </div>
        );
    }
}

export default DeactivateModal;