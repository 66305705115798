import React from 'react'
import PropTypes from 'prop-types'
// Reactstrap
import { Alert } from 'reactstrap'

/**
 * Produces error alert when Final-form has validation errors on submission attempt.
 *
 * Useful with forms where user is scrolled away from highlighted fields.
 *
 * @param {boolean} hasValidationErrors - Final-form state of same name
 * @param {boolean} submitFailed - Final-form state of same name
 * @param {string} [msg = 'Please fix the highlighted errors above.'] - Optional error message to override default
 * @returns {*} bootstrap danger context alert with error message
 */

const ValidationErrorAlert = ({ hasValidationErrors, submitFailed, msg }) => (
  <>
    {
      // If validation errors when submitted, display an alert
      (hasValidationErrors && submitFailed) ? <Alert color="danger">{ msg }</Alert> : null
    }
  </>
)

ValidationErrorAlert.propTypes = {
  hasValidationErrors: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  msg: PropTypes.string
}

ValidationErrorAlert.defaultProps = {
  hasValidationErrors: false,
  submitFailed: false,
  msg: 'Please fix the highlighted errors above.',
}

export default ValidationErrorAlert