import React from 'react'
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Button

} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from '../../static/images/gten.png'
import { userDailyTiming, fetchUserDailyTiming, updateReport } from '../../middleware/api'
import { cryptInfo } from '../../config'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'
const userId = localStorage.getItem('user_id') && localStorage.getItem('user_id')
class NavBar extends React.Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleSignout = this.toggleSignout.bind(this);
    this.startTimerUser = this.startTimerUser.bind(this);
    this.stopTimerUser = this.stopTimerUser.bind(this);
    this.state = {
      isOpen: false,
      isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
      isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
      dropdownOpen: false,
      signedIn: false,
      currentReport: null,
      submitted: false,
      longitude: null,
      latitude: null,
      update_report: null
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen  // navbar collapse
    });
  }
  toggleSignout() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  signOut() {
    localStorage.clear()
    window.location.reload()
  }
  overAllTime(signin, signOut) {
    var delta = Math.abs(new Date(signOut) - new Date(signin)) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    var hoursCounted = hours;
    if (hours < 10) {
      hoursCounted = `0${hours}`
    }
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    var minutesCounted = minutes;
    if (minutes < 10) {
      minutesCounted = `0${minutes}`
    }
    delta -= minutes * 60;

    var seconds = delta % 60;
    var secondsCounted = seconds;
    if (seconds < 10) {
      secondsCounted = `0${Math.floor(seconds)}`
    } else {
      secondsCounted = Math.floor(seconds)
    }

    var totalTime = `${hoursCounted}:${minutesCounted}:${secondsCounted}`
    return totalTime
  }

  startTimerUser() {
    const { refreshUserLogs } = this.props
    const userName = localStorage.getItem('fname') + " " + localStorage.getItem('lname')
    const { setTimings } = this.props
    const { longitude, latitude } = this.state
    var newDate = new Date()
    const id = `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`
    const user_id = userId
    const signInTime = newDate
    const signOutTime = ""
    fetchUserDailyTiming({
      identifier: id,
      user_id: userId
    })
      .then(async (response) => {

        var result = await response.json()

        if (result && result.length === 0) {
          var report = {
            date: newDate,
            identifier: id,
            user_id: user_id,
            signin_time: signInTime,
            signout_time: signOutTime,
            empname: userName,
            overall_time: '00:00:00',
            sign_in_lat: latitude,
            sign_in_long: longitude,
            sign_in: true
          }
          userDailyTiming(report)
            .then(async (res) => {
              var result = await res.json()
              this.setState({
                signedIn: true,
                currentReport: result,
                submitted: false
              })
              refreshUserLogs && refreshUserLogs()
            })
        }
        if (result[0] && result[0]._id) {
          var confirm = window.confirm("You have sign out before. Would you like to sign in again. It will start from where you left")
          if (confirm) {
            const report = {
              date: new Date(),
              identifier: result[0].id,
              user_id: result[0].user_id,
              signin_time: new Date(result[0].signin_time),
              signout_time: "",
              empname: result[0].empname,
              sign_in_lat: result[0].latitude,
              sign_in_long: result[0].longitude,
              overall_time: result[0].overall_time,
              sign_in: true
            }

            updateReport(result[0]._id, report)
              .then(async (response) => {
                await fetchUserDailyTiming({
                  identifier: id,
                  user_id: userId
                })
                  .then(async (response) => {
                    var result = await response.json()
                    this.setState({
                      signedIn: true,
                      currentReport: result[0],
                      submitted: true
                    })
                    refreshUserLogs && refreshUserLogs()
                  })

              })
            setTimings(result[0].overall_time)
          }
        }
      })
  }

  stopTimerUser() {
    const { setTimings, refreshUserLogs } = this.props
    const { currentReport, longitude, latitude } = this.state
    var newDate = new Date()
    var id = `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`
    const report = {
      date: currentReport.date,
      identifier: currentReport.identifier,
      user_id: currentReport.user_id,
      signin_time: currentReport.signin_time,
      signout_time: newDate,
      empname: currentReport.empname,
      overall_time: this.overAllTime(currentReport.signin_time, newDate),
      sign_in_lat: latitude,
      sign_in_long: longitude,
      sign_out_lat: latitude,
      sign_out_long: longitude,
      sign_in: false
    }
    fetchUserDailyTiming({
      identifier: id,
      user_id: userId
    })
      .then(async (response) => {
        const responseReturned = await response.json()
        updateReport(responseReturned[0] && responseReturned[0]._id, report)
          .then(async (response) => {
            await fetchUserDailyTiming({
              identifier: id,
              user_id: userId
            }).then(async (response) => {
              var result = await response.json()
              this.setState({
                signedIn: false,
                currentReport: result[0],
                submitted: true
              })
              setTimings(result[0].overall_time)
              refreshUserLogs && refreshUserLogs()

            })

          })
      })

    toast.success('Signed Out Succesfully')
  }

  componentDidMount() {
    const location = window.navigator && window.navigator.geolocation
    if (location) {
      location.getCurrentPosition((position) => {
        this.setState({
          latitude: position.coords.latitude.toFixed(2),
          longitude: position.coords.longitude.toFixed(2),
        })
      }, (error) => {
        this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }
    const { setTimings } = this.props
    var newDate = new Date()
    var id = `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`
    fetchUserDailyTiming({
      identifier: id,
      user_id: userId
    })
      .then(async (response) => {
        var result = await response.json()
        if (result[0] && result[0]._id && result[0].signout_time === "" && result[0].sign_in === true) {
          this.setState({
            signedIn: true,
            currentReport: result[0],
            submitted: false
          })
        }
        if (result[0] && result[0]._id && result[0].sign_in === false) {
          this.setState({
            currentReport: result[0],
            signedIn: false,
            submitted: true
          })
          setTimings(result[0].overall_time)
        }
      })
  }

  render() {
    const { signedIn, submitted, isAdmin, isManager } = this.state
    const { isOpen } = this.props
    return (
      <>
        <Navbar color="light" light expand="md">
          {
            !isOpen &&
            <Button color="link" onClick={this.props.toggle}><FontAwesomeIcon size="2x" icon={faArrowLeft} className="mr-2" /></Button>
          }
          <NavbarBrand className="nav-brand" href="/welcome">
            <img src={image} className="brand-image" alt="LOGO" />
          </NavbarBrand>
          {(isAdmin || isManager) && <>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="/projects">Projects</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/users">Employees</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/clients">Clients</NavLink>
                </NavItem>
                <NavItem className="no-wrap">
                  {signedIn ?
                    <Button color="primary" onClick={this.stopTimerUser} title={submitted ? "You have already submitted today's time/Do you want to submit again" : "Click to submit your log"}>Check-Out</Button>
                    :
                    <Button color="primary" onClick={this.startTimerUser}>Check-in</Button>
                  }
                </NavItem>
              </Nav>
            </Collapse>
          </>}
          {
            !isAdmin && !isManager &&
            <Nav className="ml-auto" navbar>
              <NavItem className="no-wrap">
                {signedIn ?
                  <Button color="primary" onClick={this.stopTimerUser} title={submitted ? "You have already submitted today's time/Do you want to submit again" : "Click to submit your log"}>Check-Out</Button>
                  :
                  <Button color="primary" onClick={this.startTimerUser}>Check-in</Button>
                }
              </NavItem>
            </Nav>
          }
        </Navbar>
        <ToastContainer />
      </>
    );
  }
}

export default NavBar;
