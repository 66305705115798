import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from '../elements/TableComponent'
import { createReferral, fetchReferrals, updateReferral, deleteReferral,fetchReferralForUser } from '../../middleware/referrals'
import { fetchAllData, fetchTypes } from '../../middleware/metadata'
import { Button, Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import AddEditReferralModal from './AddEditReferralModal'
import { any, bool, func } from 'prop-types'
import ConfirmationModal from '../content/ConfirmationModal'
import AWS from 'aws-sdk'
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_END_POINT, AWS_BUCKET_NAME, REFERRAL_FOLDER, cryptInfo } from '../../config'
import { FORM_ERROR } from 'final-form'
import { referralColumns } from '../../data/columnsData'
import { sendMailToAdmin } from '../../middleware/referrals'

const isAdmin = localStorage.getItem('role') && cryptInfo.decrypt(localStorage.getItem('role')) === 'admin'

const userId = localStorage.getItem('user_id')

AWS.config.update({
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
  region: 'ap-south-1',
})

const CandidateManagement = ({
	openAddEditReferralModal,
	toggleAddEditReferralModal,
	selectedItem,
	openConfirmationModal,
	toggleConfirmationModal
}) => {

	const [searchTerm, setSearchTerm] = useState("")
	const [referrals, setreferrals] = useState([])
	const [allDataForForm, setAllDataForForm] = useState([])
	const [allMetadataTypes, setAllMetadataTypes] = useState([])
	const [selectAll, setSelectAll] = useState(false)
	const [selectedSkills, setSkills] = useState([])


	const refreshContent = useCallback(() => {
		if(isAdmin){
		fetchReferrals()
			.then(async (res) => {
				const result = await res.json()
				setreferrals(result)
			})
		}else{
			fetchReferralForUser(userId)
			.then(async (res) => {
				const result = await res.json()
				setreferrals(result)
			})
		}
		if (selectedItem) {
			setSkills(selectedItem.skills)
		} else {
			setSkills([])
		}
	}, [setSkills, selectedItem])


	const getData = useCallback(() => {
		fetchAllData()
			.then(async (res) => {
				const result = await res.json()
				setAllDataForForm(result)
			})
	}, [])

	const getAllTypes = useCallback(() => {
		fetchTypes()
			.then(async (res) => {
				const result = await res.json()
				setAllMetadataTypes(result)
			})
	}, [])

	useEffect(() => {
		refreshContent()
		getData()
		getAllTypes()
	}, [refreshContent, getData, getAllTypes])


	const onDelete = useCallback(() => {
		deleteReferral(selectedItem._id)
			.then(async (response) => {
				refreshContent()
				toggleConfirmationModal(null)
			})
	}, [selectedItem, toggleConfirmationModal, refreshContent])

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}

	const onSubmit = async (values) => {
		if (selectedSkills.length === 0) {
			return { [FORM_ERROR]: "Please select some skills." }
		} else {
			values.skills = selectedSkills
		}

		await sendingCertificateFiles(values, REFERRAL_FOLDER, uploadFinalDocument)
	}

	const uploadFinalDocument = useCallback((values) => {
		var timeStamp = + new Date()
		try {
			if (values && values._id) {
				updateReferral(values._id, values)
					.then(() => {
						toggleAddEditReferralModal(null)
						refreshContent()
					})
			} else {
				values.status = values.statue || 'Submitted'
				createReferral(values)
					.then(async () => {
						toggleAddEditReferralModal(null)
						refreshContent()
						await sendMailToAdmin({
							subject: "New Referral",
							sender: cryptInfo.decrypt(localStorage.getItem('email')),
							data: values,
							skills: values.skills.map(e => findItem(e.value)).join(', '),
							user: userId,
							resume: `https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${values.fileUploadKeys[0]}?v=${timeStamp.toString()}`
						})
					})
			}
		}
		catch (error) {
			values.fileUploadKeys && values.fileUploadKeys.map(file => {
				deleteS3Object(file)
				return { [FORM_ERROR]: "Error: Something went wrong. Please try after sometime." }

			})
		}
	}, [toggleAddEditReferralModal, refreshContent])

	const sendingCertificateFiles = async (values, fileType, callback) => {
		var payloadArray = []
		var files = values.fileUploadKeys && values.fileUploadKeys
		if (values.fileUploadKeys && values.fileUploadKeys.length > 0 && values.file && values.fileUploadKeys.length > values.file.length) {
			files.slice(0, values.file.length)
			var extraFiles = files.slice(values.file.length, values.fileUploadKeys.length)
			extraFiles.map(file => {
				deleteS3Object(file)
				return false
			})
		} else {
			files = values.fileUploadKeys
		}
		if (values.file) {
			Array.from(values.file).forEach(async (file, index) => {
				let payload
				if (files && files.length > 0 && index < files.length) {
					payload = await s3Signature(files[index], fileType)
				} else {
					payload = await s3Signature(null, fileType)
				}
				if (values.file) {
					let uploadSuccess = await sendFile(file, payload)
					if (uploadSuccess.ok === true) {
						payloadArray.push(payload['fields'].key)
						values.fileUploadKeys = payloadArray
					} else {
						console.log(uploadSuccess)
					}
				}
				index === values.file.length - 1 && setTimeout(async () => {
					await callback(values)
				}, 2000)
			})
		} else {
			// values.fileUploadKeys = values.fileUploadKeys
			await callback(values)
		}
	}

	const deleteS3Object = async (fileKey) => {
		return new Promise((resolve, reject) => {
			try {
				let s3bucket = new AWS.S3({
					accessKeyId: AWS_ACCESS_KEY_ID,
					secretAccessKey: AWS_SECRET_ACCESS_KEY,
				});
				var params = { Bucket: AWS_BUCKET_NAME, Key: fileKey };
				s3bucket.deleteObject(params, function (err, data) {
					if (err) reject(err);
					// an error occurred
					else resolve(data); // successful response
				});
			} catch (e) {
				reject(e);
			}
		});
	};

	const s3Signature = async (fileKey, type) => {
		const timeStamp = + new Date() + + new Date()
		return new Promise((resolve, reject) => {
			const s3 = new AWS.S3({
				accessKeyId: AWS_ACCESS_KEY_ID,
				secretAccessKey: AWS_SECRET_ACCESS_KEY,
			});
			const myBucket = AWS_BUCKET_NAME
			const params = {
				Bucket: myBucket,
				Fields: {
					key: fileKey ? `${fileKey}` : `${type}/${timeStamp.toString()}` // Any Unique Identifier
				},
				Conditions: [
					['content-length-range', 0, 10000000], // 10 Mb
					{ 'acl': 'public-read' },
					["starts-with", "$Content-Type", ""]
				]
			};
			s3.createPresignedPost(params, function (err, data) {
				if (err) {
					console.error('Presigning post data encountered an error', err);
				} else {
					resolve(data);
				}
			});
		});
	}

	const sendFile = async (file, payload) => {
		var form = new FormData();
		form.append('acl', 'public-read');
		for (const field in payload.fields) {
			form.append('Content-Type', file.type)
			form.append(field, payload.fields[field]);
		}
		form.append('file', file)
		const response = await fetch(payload.url, {
			method: 'POST',
			body: form,
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		})
		return response
	};

	const fetchDataByType = useCallback((type) => {
		const typeId = allMetadataTypes && allMetadataTypes.filter(e => e.name === type)
		const selectedType = typeId[0] || []
		if (!allDataForForm || (allDataForForm && allDataForForm.length === 0 && typeId.length === 0))
			return []
		return selectedType && allDataForForm && allDataForForm.filter(e => e.type === selectedType._id).map((d) => {
			return (
				{
					value: d._id,
					label: d.name
				}
			)
		})
	}, [allDataForForm, allMetadataTypes])

	const skillsDropdownData = fetchDataByType('skill')

	const findItem = useCallback((id) => {
		if (!allDataForForm || (allDataForForm && allDataForForm.length === 0))
			return null
		return allDataForForm && allDataForForm.filter(item => item._id === id).map((e) => {
			return e.name + " "
		}).join(', ')
	}, [allDataForForm])


	const formatData = useCallback(() => {
		if (!referrals || (referrals && referrals.length === 0))
			return []

		return referrals && referrals.map((item, index) => {
			return (
				{
					id: index + 1,
					name: item.name,
					phone: item.phone,
					experience: item.experience,
					status: item.status,
					referredBy: item.refferedBy,
					referredByName: item.referredByName,
					email: item.email,
					skills: item.skills.map(e => findItem(e.value)).join(', '),
					data: item
				}
			)
		})
	}, [referrals, findItem])

	const formattedData = formatData()

	const filteredRows = formattedData && formattedData.filter((row) => {
		return ((row && row.name && row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.phoneNumber && row.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})

	const handleSelectAll = () => {
		setSelectAll(!selectAll)
		if (!selectAll) {
			let allIds = skillsDropdownData && skillsDropdownData.map(d => ({ selected: true, value: d.value }))
			setSkills(allIds)
		}
		if (!!selectAll) {
			setSkills([])
		}
	}

	const handleSelectOption = useCallback((value) => {
		const index = selectedSkills && selectedSkills.findIndex((s) => s.value === value)
		let skills = selectedSkills && selectedSkills.map(p => p)
		if (index > -1 || !index) {
			skills && skills.splice(index, 1)
		} else {
			skills.push({ value: value, selected: true })
		}
		if (selectedSkills && skillsDropdownData && (skills.length === skillsDropdownData.length)) {
			setSelectAll(true)
		} else {
			setSelectAll(false)
		}
		setSkills(skills)
	}, [setSelectAll, selectedSkills, setSkills, skillsDropdownData])


	const actionMarkup = ({ data }) => (
		<React.Fragment>
			{isAdmin && <>
				<Button
					color="link"
					title="Edit Referral"
					onClick={() => toggleAddEditReferralModal(data)}
				>
					<FontAwesomeIcon icon={faPencilAlt} />
				</Button>
				<Button
					color="link"
					className="ml-2 text-danger"
					title="Delete Referral"
					onClick={() => toggleConfirmationModal(data)}
				>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			</>}
		</React.Fragment>
	)

	return (
		<>
			<Container fluid>
				<Row className="mt-5">
					<Col>
						{formattedData && <TableComponent
							showCheckbox={false}
							columns={isAdmin ? [{
								id: 'referredByName',
								label: 'Referred By'
							}, ...referralColumns] : referralColumns}
							data={filteredRows ? filteredRows : []}
							perPage={10}
							requestSearch={requestSearch}
							cancelSearch={cancelSearch}
							defaultSort="name"
							defaultSortOrder="asc"
							showSerialNo={true}
							action={isAdmin ? actionMarkup : null}
							searchTerm={searchTerm}
						/>}
					</Col>
				</Row>
			</Container>

			<AddEditReferralModal
				isOpen={openAddEditReferralModal}
				toggle={toggleAddEditReferralModal}
				data={selectedItem}
				onSubmit={onSubmit}
				handleSelectAll={handleSelectAll}
				handleSelectOption={handleSelectOption}
				selectedSkills={selectedSkills && selectedSkills}
				skillsDropdownData={skillsDropdownData}
				selectAll={selectAll}
				isAdmin={isAdmin}
			/>

			<ConfirmationModal
				isOpen={openConfirmationModal}
				toggle={toggleConfirmationModal}
				onConfirm={onDelete}
				message={`Are you sure you want to remove this item?`}
			/>

		</>
	)
}

CandidateManagement.propTypes = {
	openAddEditReferralModal: bool,
	toggleAddEditReferralModal: func.isRequired,
	selectedItem: any,
	openConfirmationModal: bool,
	toggleConfirmationModal: func.isRequired
}

CandidateManagement.toggleAddEditReferralModal = {
	openAddEditReferralModal: false,
	selectedItem: null,
	openConfirmationModal: false
}

export default CandidateManagement