import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import UserLogs from '../components/UserLogs'
import { fetchLogs, loggingOut, fetchUserlogs } from '../middleware/api'
import { cryptInfo } from '../config'
import PageHeading from '../components/content/PageHeading'
class UserLogsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: null,
            timers: "00:00:00",
            searchLogs: null,
            isOpen: true,
            userlogs: '',
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
        }
        this.getUserlogs = this.getUserlogs.bind(this)
        this.getLogs = this.getLogs.bind(this)
        this.setTimings = this.setTimings.bind(this)
        this.searchBar = this.searchBar.bind(this)
        this.handleDays = this.handleDays.bind(this)
        this.toggle = this.toggle.bind(this)
        this.filterUpdated = this.filterUpdated.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    getLogs() {
        fetchLogs(10)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    logs: result
                })
            })
    }
    getUserlogs() {
        const userId = localStorage.getItem('user_id')
        fetchUserlogs(userId, 7)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    logs: result
                })

            })
    }
    searchBar(searchTerm) {
        const { logs } = this.state
        var originalLogs = logs
        if (searchTerm === "") {
            this.setState({
                logs: originalLogs
            })
        }
        var searchLog = originalLogs && originalLogs.filter(item => {
            var search = searchTerm && searchTerm.toLowerCase()
            var name = item.empname ? item.empname.toLowerCase() : ""
            if (name.indexOf(search) !== -1 || item.identifier.indexOf(search) !== -1) {
                return item
            }
            return null
        })
        this.setState({
            searchLogs: searchLog
        })
    }
    handleDays(searchTerm) {
        const { isAdmin, isManager } = this.state
        if (isAdmin || isManager) {
            fetchLogs(searchTerm)
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        searchLogs: result
                    })
                })
        } else {
            const userId = localStorage.getItem('user_id')
            fetchUserlogs(userId, searchTerm)
                .then(async (response) => {
                    const result = await response.json()
                    this.setState({
                        logs: result
                    })

                })
        }
    }
    filterUpdated(newData) {
        this.setState({
            logs: newData
        })
    }
    componentDidMount() {
        const { isAdmin, isManager } = this.state
        loggingOut()
        if(!isAdmin && !isManager)
        this.getUserlogs()
        if (isAdmin || isManager) 
            this.getLogs()
    }
    render() {
        const { logs, searchLogs, isOpen } = this.state

        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <PageHeading title="User Logs" />
                        <div className="container pt-5" style={{ overflowX: 'auto' }}>
                            <UserLogs
                                logs={searchLogs ? searchLogs : logs}
                                handleChange={this.searchBar}
                                handleDays={this.handleDays}
                                filterUpdated={this.filterUpdated}
                            />
                        </div>
                    </Content>
                </div>
            </>
        );
    }
}

export default UserLogsContainer;