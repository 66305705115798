import React, { Component } from 'react';
import { Table, Row, Col, Container, Input } from 'reactstrap'
import { fetchApprovedInvestmentsAll } from '../../middleware/investments'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
class ApprovedInvestments extends Component {
    state = {
        reimbursements: [],
        searchTerm: ""
    }
    componentDidMount = () => {
        this.refreshList()
    }
    refreshList = async () => {
        await fetchApprovedInvestmentsAll()
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    reimbursements: result
                })
            })
    }
    handleSeacrh = (searchTerm) => {
        this.setState({
            searchTerm
        })
    }
    filterData = (value) => {
        const { reimbursements } = this.state
        return reimbursements && reimbursements.filter(reimbursement => (
            (reimbursement && reimbursement.empName.toLowerCase()).includes(value.toLowerCase())
        ))
    }
    render() {
        const { searchTerm } = this.state
        const { toggleEmployeeDetail } = this.props
        const filteredData = this.filterData(searchTerm)
        var timeStamp = + new Date()
        return (
            <Container fluid className="py-4">
                <Row>
                    <Col md="6">
                        <Input
                            onChange={(e) => this.handleSeacrh(e.target.value)}
                            type="search"
                            className="border-dark"
                            placeholder="Seach by employee name"
                        />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Table striped bordered hover>
                            <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Reimbursement Type</th>
                                    <th>Bill No</th>
                                    <th>Bill Date</th>
                                    <th>Amount</th>
                                    <th>File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredData && filteredData.length > 0 ? filteredData.map((row, index) => (
                                        <tr key={"row_" + index}>
                                            <td style={{ cursor: 'pointer' }} onClick={() => toggleEmployeeDetail(row.empId)} > {row.empName}</td>
                                            <td>{row.reimbursementTypeName || 'N/A'}</td>
                                            <td>{row.billNo || 'N/A'}</td>
                                            <td>{row.billDate}</td>
                                            <td>{row.amount}</td>
                                            <td>{row.fileUploadKeys && row.fileUploadKeys.map(file => (
                                                <a
                                                    key={file}
                                                    rel="noopener noreferrer"
                                                    href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
                                                    className="text-primary"
                                                    target="_blank" >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </a>
                                            ))}</td>
                                        </tr>
                                    )) :
                                        <tr><td colSpan="8">Nothing to show</td></tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ApprovedInvestments;