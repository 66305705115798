
import React, { Component } from 'react'
import Project from './containers/ProjectContainer'
import Task from './containers/TaskContainer'
import Client from './containers/ClientContainer'
import Home from './containers/HomeContainer'
import Users from './containers/UserContainer'
import Welcome from './containers/WelcomeContainer'
import UserProfile from './containers/UserProfileContainer'
import UserFeedback from './containers/UserFeedbackContainer'
import Timesheet from './containers/TimeSheetContainer'
import Feed from './containers/OurFeedContainer'
import LeaveHome from './containers/LeaveContainer'
import Holiday from './containers/HolidayContainer'
import UserLogs from './containers/UserLogsContainer'
import Performance from './containers/PerformanceContainer'
import PlatformSettings from './containers/PlatformSettingsContainer'
import Assets from './containers/UserAssetContainer'
import Reimbursement from './containers/ReimbursementContainer'
import Investments from './containers/InvestmentsContainer'
import Policies from './containers/PoliciesContainer'
import PolicyInfo from './containers/PolicyInfoContainer'
import { Route, Switch, Redirect } from 'react-router-dom'
import LandingPage from './components/LandingPage'
import RegistrationPage from './components/RegistrationPage'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import Recruitment from './containers/RecruitmentContainer'
import Metadata from './containers/MetadataContainer'
import CandidateDetail from './containers/CandidateDetailContainer'
import PayslipsContainer from './containers/PayslipsContainer'
import Referral from './containers/ReferralContainer'

class Routes extends Component {

    state = {
        isLogin: localStorage.getItem('isSignedin'),
        permission: localStorage.getItem('permission') && JSON.parse(localStorage.getItem('permission'))
    }

    render() {
        const { isLogin, permission } = this.state
        return (
            <Switch>
                <Route key="/" path="/" exact render={(routeProps) => isLogin ? (<Redirect to="/welcome" />) :
                    (<Redirect to="/login" />)} />
                <Route key="login" path="/login" component={LandingPage} />
                <Route key="projects" path="/projects" render={(routeProps) => (isLogin && permission.indexOf('projects') >= 0) ? (<Project {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="tasks" path="/tasks" render={(routeProps) => (isLogin && permission.indexOf('task') >= 0) ? (<Task {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="clients" path="/clients" render={(routeProps) => (isLogin && permission.indexOf('clients') >= 0) ? (<Client {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="welcome" path="/welcome" render={(routeProps) => isLogin ? (<Welcome {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="holidays" path="/holidays" render={(routeProps) => (isLogin && permission.indexOf('holiday') >= 0) ? (<Holiday {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="userlogs" path="/userlogs" render={(routeProps) => (isLogin && permission.indexOf('logs') >= 0) ? (<UserLogs {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="user-info" path={`/user-info/:id`} render={(routeProps) => isLogin ? (<UserProfile {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="feedback" path={`/feedback/:project/:id`} render={(routeProps) => isLogin ? (<UserFeedback {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="home" exact path="/timesheet-home" render={(routeProps) => (isLogin && permission.indexOf('timesheet') >= 0) ? (<Home {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="leave" exact path="/leaves-home" render={(routeProps) => (isLogin && permission.indexOf('leave') >= 0) ? (<LeaveHome {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="leave" exact path="/performance-home" render={(routeProps) => (isLogin && permission.indexOf('performance') >= 0) ? (<Performance {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="users" path="/users" render={(routeProps) => (isLogin && permission.indexOf('employees') >= 0) ? (<Users {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="timesheet" path="/timesheet" render={(routeProps) => (isLogin && permission.indexOf('timesheet') >= 0) ? (<Timesheet {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="feeds" path="/ourfeeds" render={(routeProps) => (isLogin && permission.indexOf('feed') >= 0) ? (<Feed {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="assets" path="/assets" render={(routeProps) => (isLogin && permission.indexOf('assetsallocated') >= 0) ? (<Assets {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="reimbursement" path="/reimbursement" render={(routeProps) => (isLogin && permission.indexOf('reimbursement') >= 0) ? (<Reimbursement {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="investments" path="/investments" render={(routeProps) => (isLogin && permission.indexOf('investment') >= 0) ? (<Investments {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="policies" path="/policies" render={(routeProps) => (isLogin && permission.indexOf('policy') >= 0) ? (<Policies {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="policy" path={`/policy/:id`} render={(routeProps) => isLogin ? (<PolicyInfo {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="setting" exact path="/platform-settings" render={(routeProps) => (isLogin && permission.indexOf('setting') >= 0) ? (<PlatformSettings {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="register" path="/register/:email" render={(routeProps) => {
                    return (<RegistrationPage {...routeProps} />)
                }} />
                <Route key="register1" path="/register" render={(routeProps) => {
                    return (<RegistrationPage {...routeProps} />)
                }} />
                <Route key="forgot-password" path="/forgot-password" render={(routeProps) => {
                    return (<ForgotPasswordPage {...routeProps} />)
                }} />
                <Route key="forgot-password" path="/password-reset/:userId/:token" render={(routeProps) => {
                    return (<ForgotPasswordPage {...routeProps} />)
                }} />

                <Route key="recruitment" exact path="/recruitment" render={(routeProps) => (isLogin && permission.indexOf('recruitment') >= 0) ? (<Recruitment {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="metadata" exact path="/metadata" render={(routeProps) => (isLogin) ? (<Metadata {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="condidate-info" path={`/candidate/:id`} render={(routeProps) => (isLogin && permission.indexOf('recruitment') >= 0) ? (<CandidateDetail {...routeProps} />) :
                    (<Redirect to="/login" />)} />

                <Route key="payslips" path={`/payslips`} render={(routeProps) => (isLogin) ? (<PayslipsContainer {...routeProps} />) :
                    (<Redirect to="/login" />)} />
                <Route key="referrals" path="/referrals" render={(routeProps) => (isLogin && permission.indexOf('referrals') >= 0) ? (<Referral {...routeProps} />) :
                    (<Redirect to="/login" />)} />
            </Switch>
        )
    }
}



export default Routes