import React from 'react'
import { Container, Row, Col } from 'reactstrap'
const PageHeading = ({title}) => {
    return (
        <>
            <Container fluid>
                <Row className="page-heading">
                    <Col className="text-left">
                        <span>{title}</span>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PageHeading