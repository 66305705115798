import React, { Component } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import BankDetailForm from './BankDetailForm'
import { updateUser } from '../../middleware/api'
import { mapFinalFormErrors } from '../../lib/utils'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { cryptInfo } from '../../config'

const mapErrors = mapFinalFormErrors('Unable to save banking Details')

class BankingDetails extends Component {
    state = {
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
        isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
    }
    onSubmit = (values) => {
        const { fetchUser, user, toggleConfirmationPopup, userId } = this.props
        const { isAdmin, isManager } = this.state
        if (user && user._id) {
            updateUser(user._id, values)
                .then(async (response) => {
                    await fetchUser()
                    toast.success("Banking details have been successfully saved.")
                    if ((isAdmin || isManager) && userId !== localStorage.getItem('user_id'))
                        toggleConfirmationPopup()
                })
                .catch(mapErrors)
        }
    }
    render() {
        const { managers, projects, user } = this.props
        const defaultValues = user && user

        return (
            <div>
                <Form
                    component={BankDetailForm}
                    formId="user-profile"
                    name="user"
                    onSubmit={this.onSubmit}
                    managerUsers={managers}
                    projectArray={projects}
                    isAdmin={this.state.isAdmin}
                    subscription={{
                        pristine: true,
                        submitError: true,
                        submitting: true,
                        hasValidationErrors: true,
                    }}
                    mutators={{
                        ...arrayMutators
                    }}
                    initialValues={defaultValues}
                />
                <ToastContainer />
            </div>

        );
    }
}

export default BankingDetails;