import React from 'react';
import { Spinner } from 'reactstrap'

const Loader = () => {
    return (
        <>
            <Spinner style={{ width: '50px', height: '50px' }} type="grow" />
        </>
    );
};

export default Loader;