import React, { Component } from 'react'
import { Button, Container, Row, Col } from 'reactstrap'
import TableComponent from '../elements/TableComponent'

class ManagerView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            kra: null,
            searchTerm: ""
        }
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { kras } = this.props
        if (!kras || (kras && kras.length === 0))
            return []

        return kras && kras.map((kra, index) => {
            return (
                {
                    id: index + 1,
                    teamMember: kra.userdata.fname + " " + kra.userdata.lname,
                    data: kra
                }
            )
        })
    }

    render() {
        const { toggleEditModal, kras } = this.props
        const { searchTerm } = this.state
        const formattedData = kras && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.teamMember && row.teamMember.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.description && row.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.allocation && row.allocation.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'teamMember',
                label: 'Team Member'
            },
        ]

        const actionMarkup = ({ data }) => (
            <React.Fragment>
                <Button
                    color="link"
                    title="Add KRA"
                    className="mr-3 text-primary"
                    onClick={() => toggleEditModal(data)}
                >Add KRA
                </Button>
                <a
                    className="btn text-warning"
                    color="link"
                    title="Provide Feedback"
                    href={`feedback/${data.project}/${data.userdata._id}`}
                >Provide Feedback
                </a>
            </React.Fragment>
        )
        return (
                <Container fluid>
                    <Row className="mt-5">
                        <Col>
                            {formattedData && <TableComponent
                                showCheckbox={false}
                                columns={columns}
                                data={filteredRows ? filteredRows : []}
                                perPage={10}
                                requestSearch={this.requestSearch}
                                cancelSearch={this.cancelSearch}
                                defaultSort="appliedOn"
                                defaultSortOrder="desc"
                                showSerialNo={true}
                                action={actionMarkup}
                                searchTerm={searchTerm}
                            />}
                        </Col>
                    </Row>
                </Container>
        )
    }
}

export default ManagerView