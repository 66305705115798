import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { NavItem, NavLink, Nav, Container, Row, Col, Button } from 'reactstrap'
import RoleManagement from '../components/settings/RoleManagement'
import {
	loggingOut,
} from '../middleware/api'

class PlatformSettingsContainer extends Component {
	state = {
		openAddEditRoleModal: false,
		showRoleManagement: true,
		isOpen: true,
		openConfirmationPopup: null
	}

	componentDidMount() {
		loggingOut()
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen })
	}

	setTimings(time) {
		this.setState({
			timers: time
		})
	}

	toggleRoleManagement = () => {
		this.getUserKRAs()
		this.setState({
			showRoleManagement: true

		})
	}

	toggleAddEditRoleModal = (role) => {
		this.setState(state => ({
			openAddEditRoleModal: !state.openAddEditRoleModal,
			selectedRoleForEditOrDelete: role,
		}))
	}

	toggleConfirmationModal = (role) => {
		this.setState(state => ({
			openConfirmationPopup: !state.openConfirmationPopup,
			selectedRoleForEditOrDelete: role,
		}))
	}

	render() {
		const {
			openAddEditRoleModal,
			selectedRoleForEditOrDelete,
			showRoleManagement,
			isOpen,
			openConfirmationPopup } = this.state

		return (
			<>
				<div className="App wrapper">
					<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
					<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
						<Container fluid>
							<Row className="page-heading">
								<Col className="text-left">
									<span>Platform Settings</span>
								</Col>
								<Col className="text-right">
									<Button color="warning" size="lg" onClick={() => this.toggleAddEditRoleModal(null)}>
										Add New Role
									</Button>
								</Col>
							</Row>
						</Container>
						<div className="container-fluid pt-5">
							<Nav tabs className="menu-items">
								<NavItem>
									<NavLink
										onClick={this.toggleRoleManagement}
										className={showRoleManagement ? "active" : ''}>
										Role Management
									</NavLink>
								</NavItem>
							</Nav>
							{
								showRoleManagement &&
								<RoleManagement
									openAddEditRoleModal={openAddEditRoleModal}
									toggleAddEditRoleModal={this.toggleAddEditRoleModal}
									toggleConfirmationModal={this.toggleConfirmationModal}
									selectedRoleForEditOrDelete={selectedRoleForEditOrDelete}
									openConfirmationPopup={openConfirmationPopup}
								/>
							}
						</div>
					</Content>
				</div>
			</>
		)
	}
}

export default PlatformSettingsContainer