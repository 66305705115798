import React, { Component } from 'react'
import { Spinner, Button, Table } from 'reactstrap'
import { fetchPoliciesHomePage, fetchPoliciesHomePageAdmin } from '../../middleware/policies'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

class PolicyComponent extends Component {

	state = {
		policies: null,
		isOpen: true
	}

	componentDidMount = async () => {
		const { isAdmin } = this.props
		!isAdmin && await fetchPoliciesHomePage()
			.then(async (response) => {
				const result = await response.json()
				this.setState({
					policies: result
				})
			})
		isAdmin && await fetchPoliciesHomePageAdmin()
			.then(async (response) => {
				const result = await response.json()
				this.setState({
					policies: result
				})
			})
	}

	render() {
		const {
			policies
		} = this.state

		const { isAdmin } = this.props

		var timeStamp = + new Date()

		return (
			<>
				<h4><a href="policies" title="Click here to go to policies page.">Policies</a></h4>
				{
					policies ?
						<Table id="welcome-page-table">
							<thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
								<tr>
									<th className={!isAdmin ? "text-left" : ""}>Title</th>
									{isAdmin && <th>Status</th>}
									{/* <th>Discription</th> */}
									{isAdmin && <th>Attachment</th>}
								</tr>
							</thead>
							<tbody>
								{
									policies && policies.length > 0 ? policies.map(policy => (
										<tr key={policy._id}>
											<td className={!isAdmin ? "text-left pl-0" : ""}>
												<Button color="link" onClick={() => window.location.href = `policy/${policy._id}`}>{policy.title}</Button>

											</td>
											{isAdmin && <td className="text-capitalize">{policy.status}</td>}
											{/* <td>{policy.description}</td> */}
											{isAdmin && <td>{policy.fileUploadKeys && policy.fileUploadKeys.map(file => (
												<a
													key={file}
													rel="noopener noreferrer"
													href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
													className="text-primary"
													target="_blank" >
													<FontAwesomeIcon icon={faEye} />
												</a>

											))}</td>}

										</tr>
									)) :
										<tr><td colSpan="8">Nothing to show</td></tr>
								}
							</tbody>
						</Table> :

						<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
				}
			</>
		);
	}
}

export default PolicyComponent