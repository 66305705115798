import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

// Reactstrap
import {
	Button,
	Form,
	FormGroup,
	Label,
	Row,
	Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
	DatePickerAdapter,
	FormFeedbackAdapter,
	SelectAdapter
} from './ffadapters'
// Final-Form Helpers
import {
	SubmitErrorAlerts
} from './ffhelpers'
// Validators
import {
	validateRequired,
} from '../lib/validators'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}
const EmployeeStatusForm = (
	// form states that trigger render need to be in container subscription
	{
		handleSubmit,
		submitError,
		submitSucceeded,
		submitting,
		toggle,
		values
	}
) => {
	return (
		<Form onSubmit={handleSubmit} className="p-4" id="kraform">
			<Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`status`}>Select Status</Label>
						<Field
							name="status"
							type="select"
							bsSize="lg"
							component={SelectAdapter}
							validate={validateRequired()}
							options={[{ value: 'Active', label: 'Active' }, { value: 'Inactive', label: 'Inactive' }, { value: 'Resigned', label: 'Resigned' }]} />
						<FormFeedbackAdapter name="status" />
					</FormGroup>
				</Col>
			</Row>
			{values.status === 'Resigned' && <Row>
				<Col md>
					<FormGroup>
						<Label htmlFor={`dol`}>Date of Leaving</Label>
						<Field
							name="dol"
							type="date"
							bsSize="lg"
							component={DatePickerAdapter}
							validate={validateRequired()} />
						<FormFeedbackAdapter name="dol" />
					</FormGroup>
				</Col>
			</Row>}

			<SubmitErrorAlerts submitError={submitError} />
			<Row>
				<Col md>
					<FormGroup>
						<Button
							block
							color="primary"
							className="p-3 mr-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							type="submit">
							{"Save"}</Button>
					</FormGroup>
				</Col>

				<Col md>
					<FormGroup>

						<Button
							block
							color="outline-primary"
							className="p-3 ml-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							onClick={toggle}>Cancel</Button>
					</FormGroup>
				</Col>
			</Row>
		</Form>
	)
}

EmployeeStatusForm.propTypes = propTypes

export default EmployeeStatusForm
