import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditRoleForm from './AddEditRoleForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { any, bool, func } from 'prop-types'

const AddEditRoleModal = ({ isOpen, permissionArray, toggle, kra, onSubmit, handleSelectAll, handleSelectOption, selectedPermissions, selectAll }) => {
	return (
		<>
			<Modal id="project-modal" isOpen={isOpen} toggle={toggle}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Role</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditRoleForm}
						formId="addrole"
						toggle={toggle}
						onSubmit={onSubmit}
						handleSelectAll={handleSelectAll}
						handleSelectOption={handleSelectOption}
						selectedPermissions={selectedPermissions}
						allOptions={permissionArray}
						selectAll={selectAll}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							reset: true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={kra}
					/>
				</ModalBody>
			</Modal>
		</>
	)
}
AddEditRoleModal.propTypes = {
	isOpen: bool,
	toggle: func.isRequired,
	kra: any,
	selectedPermissions:any
}

AddEditRoleModal.defaultProps = {
	isOpen: false,
	kra: null,
	selectedPermissions:[]
}
export default AddEditRoleModal