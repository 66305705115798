import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col, Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateEmail,
    validateRequired,
    validatePhoneNumber
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const ContactInfoForm = (
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`phone`}> Mobile Number</Label>
                        <Field
                            id={`phone`}
                            name="phone"
                            type="number"
                            title="Please don not prefix country code."
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired(),
                                    validatePhoneNumber()
                                )
                            }
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="phone" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`pemail`}>Personal Email</Label>
                        <Field
                            name="pemail"
                            type="email"
                            bsSize="lg"
                            validate={
                                composeValidators(
                                    validateRequired(),
                                    validateEmail()
                                )
                            }
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="pemail" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`emergencycontact`}> Emergency Contact Person</Label>

                        <Field
                            id={`emergencycontact`}
                            name="emergencycontact"
                            type="text"
                            bsSize="lg"
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="emergencycontact" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`emergencynumber`}> Emergency Phone Number</Label>
                        <Field
                            id={`emergencynumber`}
                            name="emergencynumber"
                            type="number"
                            title="Please do not prefix country code."
                            validate={
                                composeValidators(
                                    validatePhoneNumber()
                                )
                            }
                            bsSize="lg"
                            component={InputAdapter}
                        />
                        <FormFeedbackAdapter name="emergencynumber" />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`permanentAddress`}> Permanent Address*</Label>
                        <Field
                            id={`permanentAddress`}
                            name="permanentAddress"
                            type="textarea"
                            bsSize="lg"
                            placeholder="Fill Your Permanent Address."
                            component={InputAdapter}
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                        />
                        <FormFeedbackAdapter name="permanentAddress" />
                    </FormGroup>
                </Col>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`currentAddress`}> Current Address*</Label>
                        <Field
                            id={`currentAddress`}
                            name="currentAddress"
                            type="textarea"
                            placeholder="Fill Your Current Address."
                            validate={
                                composeValidators(
                                    validateRequired()
                                )
                            }
                            bsSize="lg"
                            component={InputAdapter}
                        />

                        <FormFeedbackAdapter name="currentAddress" />
                    </FormGroup>

                </Col>
            </Row>
            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting || submitSucceeded}
                        size="lg"
                        type="submit"
                    >{"Save"}</Button>
                </Col>
            </FormGroup>

        </Form>
    )
}

ContactInfoForm.propTypes = propTypes

export default ContactInfoForm
