import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import Projects from '../components/Projects'
import { Container, Row, Col, Button } from 'reactstrap'
import AddProjectModal from '../components/AddProjectModal'
import { fetchProjects, fetchProjectManagers, loggingOut, fetchClients } from '../middleware/api'
import { cryptInfo } from '../config'

class ProjectContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddProjectModal: false,
            projects: null,
            project: null,
            users: null,
            clients: null,
            timers: "00:00:00",
            isOpen: true
        }
        this.getProjects = this.getProjects.bind(this)
        this.getClients = this.getClients.bind(this)
        this.setTimings = this.setTimings.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    toggleModal = () => {
        this.setState(state => ({
            openAddProjectModal: !state.openAddProjectModal,
            project: null
        }))
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }
    toggleEditModal = (project) => {
        if (project && project.status === 'inactive') {
            this.setState(state => ({
                openAddProjectModal: state.openAddProjectModal
            }))
        } else {
            this.toggleModal()
            this.setState({
                project: project
            })
        }

    }
    getProjects() {
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    projects: result
                })
            })
    }
    getClients() {
        fetchClients()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    clients: result
                })
            })
    }
    componentDidMount() {
        if (cryptInfo.decrypt(localStorage.getItem('role')) !== 'admin' && cryptInfo.decrypt(localStorage.getItem('role')) !== 'manager') {
            localStorage.clear()
            window.location.reload()
        }
        this.getClients()
        this.getProjects()
        fetchProjectManagers()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    users: result
                })
            })
        loggingOut()
    }
    sortProjects = (projects) => {
        var activeProjects, inActiveProjects, finalData
        activeProjects = projects && projects.filter(project => project.status === 'active')
        inActiveProjects = projects && projects.filter(project => project.status === 'inactive')
        finalData = activeProjects.concat(inActiveProjects)
        return finalData
    }
    render() {
        const { openAddProjectModal, project, projects, users, clients, isOpen } = this.state
        const sortedProjects = projects && this.sortProjects(projects)
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                        <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Our Projects</span>
                                </Col>
                                <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleModal}>
                                        Add Project
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <div className="container-fluid">
                            <Projects
                                projects={projects && sortedProjects}
                                toggleModal={this.toggleModal}
                                fetchProjects={this.getProjects}
                                toggleEditModal={this.toggleEditModal}
                                clients={clients && clients}
                                users={users && users}
                            />
                        </div>
                        <AddProjectModal
                            isOpen={openAddProjectModal}
                            toggle={this.toggleModal}
                            fetchProjects={this.getProjects}
                            getClients={this.getClients}
                            project={project}
                            users={users && users}
                            clients={clients && clients}
                        />
                    </Content>
                </div>
            </>
        );
    }
}

export default ProjectContainer;