import React, { Component } from 'react'
import AWS from 'aws-sdk'
import { Row, Col, Container, Spinner } from 'reactstrap'
import { createPolicy, fetchPolicies, updatePolicy, deletePolicy, fetchActivePolicies } from '../../middleware/policies'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ConfirmationModal from '../content/ConfirmationModal'
import AddEditPolicyModal from './AddEditPolicyModal'
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_BUCKET_NAME, POLICY } from '../../config'
import PoliciesList from './PoliciesList'

AWS.config.update({
	accessKeyId: AWS_ACCESS_KEY_ID,
	secretAccessKey: AWS_SECRET_ACCESS_KEY,
	region: 'ap-south-1',
})

class PoliciesListComponent extends Component {

	state = {
		policies: [],
		loading: false
	}

	componentDidMount = async () => {
		this.refreshList()
	}

	onSubmit = async (values) => {
		this.setState({ loading: true })
		const { toggleAddEditPolicyModal } = this.props

		await this.sendingCertificateFiles(values, POLICY, this.uploadFinalDocument)

		toggleAddEditPolicyModal(null)
	}

	uploadFinalDocument = async (values) => {
		try {
			if (values._id) {
				await updatePolicy(values._id, values)
					.then(() => {
						toast.success("Policy successfully updated.")
						this.refreshList()
					})
			} else {
				await createPolicy(values)
					.then(() => {
						toast.success("Policy successfully added.")
						this.refreshList()
					})
			}
		}
		catch (error) {
			values.fileUploadKeys && values.fileUploadKeys.map(file => {
				this.deleteS3Object(file)
				return false
			})
			toast.error("Error: Something went wrong.")
			return
		}
	}

	sendingCertificateFiles = async (values, fileType, callback) => {
		var payloadArray = []
		var files = values.fileUploadKeys && values.fileUploadKeys
		if (values.fileUploadKeys && values.fileUploadKeys.length > 0 && values.file && values.fileUploadKeys.length > values.file.length) {
			files.slice(0, values.file.length)
			var extraFiles = files.slice(values.file.length, values.fileUploadKeys.length)
			extraFiles.map(file => {
				this.deleteS3Object(file)
				return false
			})
		} else {
			files = values.fileUploadKeys
		}
		if (values.file) {
			Array.from(values.file).forEach(async (file, index) => {
				let payload
				if (files && files.length > 0 && index < files.length) {
					payload = await this.s3Signature(files[index], fileType)
				} else {
					payload = await this.s3Signature(null, fileType)
				}
				if (values.file) {
					let uploadSuccess = await this.sendFile(file, payload)
					if (uploadSuccess.ok === true) {
						payloadArray.push(payload['fields'].key)
						values.fileUploadKeys = payloadArray
					} else {
						console.log(uploadSuccess)
					}
				}
				index === values.file.length - 1 && setTimeout(async () => {
					await callback(values)
				}, 2000)
			})
		} else {
			// values.fileUploadKeys = values.fileUploadKeys
			await callback(values)
		}
	}

	deleteS3Object = async (fileKey) => {
		return new Promise((resolve, reject) => {
			try {
				let s3bucket = new AWS.S3({
					accessKeyId: AWS_ACCESS_KEY_ID,
					secretAccessKey: AWS_SECRET_ACCESS_KEY,
				})
				var params = { Bucket: AWS_BUCKET_NAME, Key: fileKey }
				s3bucket.deleteObject(params, function (err, data) {
					if (err) reject(err)
					// an error occurred
					else resolve(data) // successful response
				})
			} catch (e) {
				reject(e)
			}
		})
	}

	s3Signature = async (fileKey, type) => {
		const timeStamp = + new Date() + + new Date()
		return new Promise((resolve, reject) => {
			const s3 = new AWS.S3({
				accessKeyId: AWS_ACCESS_KEY_ID,
				secretAccessKey: AWS_SECRET_ACCESS_KEY,
			})
			const myBucket = AWS_BUCKET_NAME
			const params = {
				Bucket: myBucket,
				Fields: {
					key: fileKey ? `${fileKey}` : `${type}/${timeStamp.toString()}` // Any Unique Identifier
				},
				Conditions: [
					['content-length-range', 0, 10000000], // 10 Mb
					{ 'acl': 'public-read' },
					["starts-with", "$Content-Type", ""]
				]
			}
			s3.createPresignedPost(params, function (err, data) {
				if (err) {
					console.error('Presigning post data encountered an error', err)
				} else {
					resolve(data)
				}
			})
		})
	}

	sendFile = async (file, payload) => {
		var form = new FormData()
		form.append('acl', 'public-read')
		for (const field in payload.fields) {
			form.append('Content-Type', file.type)
			form.append(field, payload.fields[field])
		}
		form.append('file', file)
		const response = await fetch(payload.url, {
			method: 'POST',
			body: form,
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		})
		return response
	}

	refreshList = async () => {
		const { isAdmin } = this.props
		isAdmin && await fetchPolicies()
			.then(async (response) => {
				var result = await response.json()
				this.setState({
					policies: result,
					loading: false
				})
			})
		!isAdmin && await fetchActivePolicies()
			.then(async (response) => {
				var result = await response.json()
				this.setState({
					policies: result,
					loading: false
				})
			})
	}

	removePolicyType = async () => {
		const { toggleConfirmationModal, selectedType } = this.props
		await deletePolicy(selectedType._id)
			.then(() => {
				toast.success("Policy successfully removed.")
				selectedType && selectedType.fileUploadKeys && selectedType.fileUploadKeys.map(file => {
					this.deleteS3Object(file)
					return false
				})
				toggleConfirmationModal()
				this.refreshList()
			})
			.catch((err) => {
				console.log(err)
				toast.error("Error: Something went wrong.")
			})
	}

	render() {
		const { policies, loading } = this.state
		const { openConfirmationModal, isAdmin, selectedType, openAddEditPolicyModal, toggleConfirmationModal, toggleAddEditPolicyModal } = this.props
		return (
			<>
				<Container fluid>
					<Row className="mt-4">
						<Col>
							{
								loading ?
									<Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /> :
									<PoliciesList
										toggleAddEditPolicyModal={toggleAddEditPolicyModal}
										toggleConfirmationModal={toggleConfirmationModal}
										policies={policies}
										isAdmin={isAdmin}
									/>
							}

						</Col>
					</Row>
				</Container>
				<ToastContainer />
				<AddEditPolicyModal
					isOpen={openAddEditPolicyModal}
					toggle={toggleAddEditPolicyModal}
					onSubmit={this.onSubmit}
					selectedType={selectedType}
				/>
				<ConfirmationModal
					isOpen={openConfirmationModal}
					toggle={toggleConfirmationModal}
					onConfirm={this.removePolicyType}
					message="Are you sure you want to remove this entry?"
				/>
			</>
		)
	}
}

export default PoliciesListComponent