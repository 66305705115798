import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import { fetchEmployeeBalanceReport } from '../../middleware/reimbursement'

const userId = localStorage.getItem('user_id')

class BalanceReport extends Component {
    state = {
        reimbursementTypes: [],
        reimbursements: null,
        balanceReport: null
    }
    componentDidMount = async () => {
        await fetchEmployeeBalanceReport(userId)
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    balanceReport: result
                })
            })
    }
    formatReimbursementTypes = () => {
        const { reimbursementTypes } = this.state
        return reimbursementTypes && reimbursementTypes.map((type => ({
            value: type._id,
            label: type.name,
            amount: type.amount
        })))
    }
    calculateClaim = (reimbursements) => {
        var total = 0
        reimbursements && reimbursements.length > 0 && reimbursements.map(item => {
            total += item.amount
            return false
        })
        return total
    }

    calculateClosingBalance = (balanceReport, months, type) => {

        var finalBalance = 0
        var closingBalance = 0
        var finalClosingBalance = 0
        balanceReport && balanceReport.map(month => {
            var totalClaim = 0
            if (month && month.reimbursements && month.reimbursements.reimbursementType === type) {
                month.reimbursements.details.map(item => {
                    totalClaim += item.amount
                    return false
                })
                closingBalance = month.reimbursements.maxAmount - totalClaim
                finalBalance += closingBalance
            }
            if (month.month === months && month.reimbursements.reimbursementType === type) {
                finalClosingBalance = finalBalance
            }
            return false
        })
        return finalClosingBalance
    }
    render() {
        const { balanceReport } = this.state
        return (
            <>
                <Container fluid>
                    <Row className="mt-4">
                        <Col>
                            <Table striped hover bordered>
                                <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                                    <tr>
                                        <th>Month</th>
                                        <th>Reimbursement Type</th>
                                        <th>Monthly Limit</th>
                                        <th>Claimed</th>
                                        <th>Closing Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        balanceReport && balanceReport.length > 0 ? balanceReport.map((type, index) => (
                                            <tr key={'row_' + index} >
                                                <td className="text-capitalize font-weight-bold" style={{backgroundColor:`${(index === 0 || balanceReport[index - 1].month !== balanceReport[index].month) && '#eebb6b'}`}}>
                                                    {(index === 0 || balanceReport[index - 1].month !== balanceReport[index].month) ? type.month : ""}</td>
                                                <td>{type.reimbursements && type.reimbursements.reimbursementType}</td>
                                                <td>{type.reimbursements && type.reimbursements.maxAmount}</td>
                                                <td>{
                                                    type.reimbursements && this.calculateClaim(type.reimbursements.details)
                                                }</td>
                                                {/* <td>{type.reimbursements && (type.reimbursements.maxAmount - this.calculateClaim(type.reimbursements.details))}</td> */}
                                                <td 
                                                style={{fontWeight:'bold',color:'white'}}
                                                className={
                                                    this.calculateClosingBalance(balanceReport, type.month, type.reimbursements.reimbursementType) < 0 ? 'bg-danger border-dark' :'bg-success'
                                                }>{this.calculateClosingBalance(balanceReport, type.month, type.reimbursements.reimbursementType)}</td>
                                            </tr>
                                        )):
                                        <tr><td colSpan="5">Nothing to Show</td></tr>
                                    }

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default BalanceReport;