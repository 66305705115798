import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import CandidateFilterForm from './CandidateFilterForm'

class CandidateFilterModal extends React.Component {

	render() {

		let { isOpen,
			toggle,
			onSubmit,
			filterQuery,
			skillsDropdownData,
			isAdmin,
			isRecruiter,
			isManager } = this.props

		return (
			<div>
				<Modal isOpen={isOpen} toggle={() => toggle()}>
					<ModalHeader
						tag="h2"
						toggle={() => toggle()}>Apply Filters</ModalHeader>
					<ModalBody>
						<Form
							component={CandidateFilterForm}
							formId="candidatefilter"
							toggle={toggle}
							onSubmit={onSubmit}
							skillsDropdownData={skillsDropdownData}
							subscription={{
								pristine: true,
								submitError: true,
								submitting: true,
								hasValidationErrors: true,
								values: true
							}}
							mutators={{
								...arrayMutators,
							}}

							isAdmin={isAdmin}
							isRecruiter={isRecruiter}
							isManager={isManager}
							initialValues={filterQuery}
						/>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default CandidateFilterModal