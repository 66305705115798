import React from 'react'
import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'

/**
 * Final-Form to Reactstrap <Input> component adapter ^
 *
 * ^ For select drop-downs and select[multiple], use <SelectAdapter>.
 *
 * ## Synchronous Field-Level Validation
 * Success validation highlighting is off by default.
 * Invalid validation behavior requires touch (gained and lost focus) by default.
 * Provide your own success/invalid functions into the prop to override.
 *
 * @see Implementation https://github.com/Soundvessel/final-form-reactstrap
 * @see https://github.com/final-form/react-final-form#third-party-components
 * @see https://reactstrap.github.io/components/form/
 *
 * @todo prop-types to exclude select!!!!
 */

const InputAdapter = (
  {
    input,
    meta,
    label,
    invalid = meta => meta.touched && meta.invalid,
    valid = () => false,
    ...rest
  }
) => <><Input {...input} {...rest} invalid={invalid(meta)} valid={valid(meta)} />
    {label && <Label style={{marginBottom:0}}>{label}</Label>}
  </>

InputAdapter.propTypes = {
  invalid: PropTypes.func,
  valid: PropTypes.func,
}

export default InputAdapter