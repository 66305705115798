import React from 'react'
import { map, isString } from 'lodash'
// Reactstrap
import { Alert } from 'reactstrap'

/**
 * Produces error alerts from Final-Form submitError form state
 *
 * @param {*} submitError - Final-Form form state of same name
 * @returns {*} stacked error alerts with bootstrap danger context or null if no errors
 *
 * @see https://github.com/final-form/final-form#submiterror-any-1
 * @see https://github.com/final-form/react-final-form#submission-errors
 */

const SubmitErrorAlerts = ({ submitError, className }) => (
  <>
    {
      // If submit errors, display them in alerts
      submitError ? (

        isString(submitError) ? (
          <Alert className={ className } color="danger">{ submitError }</Alert>
        ) : (
          map(submitError, (errorMsg, index) => (
            <Alert className={ className } key={ `errorAlert-${index}` } color="danger">
              { errorMsg }
            </Alert>
          ))
        )
      ) : null
    }
  </>
)

export default SubmitErrorAlerts