import {HTTP_METHODS, callApi} from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchEmployeeReimbursementsTypes = (employeeId) => {
    const url = `${apiRoot}/reimbursementtype/${employeeId}`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchReimbursementsTypes = () => {
    const url = `${apiRoot}/reimbursementtype`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const createReimbursementType = (values) => {
    const url = `${apiRoot}/reimbursementtype`
    let method = HTTP_METHODS.POST
    let body = values
    return callApi(url, method, body)
  }
  export const updateReimbursementType = (reimbursementTypeId, reimbursementType) => {
    const url = `${apiRoot}/reimbursementtype/${reimbursementTypeId}`
    let method = HTTP_METHODS.PUT
    let body = reimbursementType
    return callApi(url, method, body)
  }
  export const deleteReimbursementType = (reimbursementTypeId) => {
    const url = `${apiRoot}/reimbursementtype/${reimbursementTypeId}`
    let method = HTTP_METHODS.DELETE
    let body = null
    return callApi(url, method, body)
  }

  // Employee Reimbursements Requests
  export const fetchEmployeeReimbursements = (employeeId) => {
    const url = `${apiRoot}/reimbursement/${employeeId}`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchRequestedReimbursements = (employeeId) => {
    const url = `${apiRoot}/reimbursement/requested/${employeeId}`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchApprovedReimbursements = (employeeId) => {
    const url = `${apiRoot}/reimbursement/approved/${employeeId}`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchEmployeeBalanceReport = (employeeId) => {
    const url = `${apiRoot}/reimbursement/balance/${employeeId}`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchRequestedReimbursementsAll = () => {
    const url = `${apiRoot}/reimbursement/requested`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchApprovedReimbursementsAll = () => {
    const url = `${apiRoot}/reimbursement/approved`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const fetchReimbursements = () => {
    const url = `${apiRoot}/reimbursement`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const createReimbursement = (values) => {
    const url = `${apiRoot}/reimbursement`
    let method = HTTP_METHODS.POST
    let body = values
    return callApi(url, method, body)
  }
  export const updateReimbursement = (reimbursementId, reimbursement) => {
    const url = `${apiRoot}/reimbursement/${reimbursementId}`
    let method = HTTP_METHODS.PUT
    let body = reimbursement
    return callApi(url, method, body)
  }
  export const deleteReimbursement = (reimbursementId) => {
    const url = `${apiRoot}/reimbursement/${reimbursementId}`
    let method = HTTP_METHODS.DELETE
    let body = null
    return callApi(url, method, body)
  }