import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment'

// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Table,
    Row,
    Label,
    Input
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    SelectAdapter,
    InputAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const TimesheetForm = (
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        startOfWeek,
        onSubmitForApproval,
        saved,
        userProjectsArray,
        taskArray,
        submitted,
        hoursCompleted,
        onCancel,
        weeks,
        week,
        setStartOfWeek

    }
) => (
    <Form onSubmit={handleSubmit} className="p-2">
        <FormGroup style={{ maxWidth: '300px', padding: '0px 20px' }}>
            <Label className="font-weight-bold">Please select week</Label>
            <Input type="select" selected={startOfWeek} onChange={(e) => setStartOfWeek(e.target.value)}>
                <option disabled>Please select week</option>
                {
                    weeks && weeks.map(week => (
                        <option value={week.value}>{week.label}</option>
                    ))
                }
            </Input>
        </FormGroup>
        <div className="container-fluid scroll-table mt-3">
            <Table id="timesheet-table" className="f-15 table table-striped">
                <thead style={{ backgroundColor: '#a9b5c3' }}>
                    <tr>
                        <th>Project</th>
                        <th>Task</th>
                        <th>Description</th>
                        <th>
                            MON <br />
                            <Moment
                                format="MM/DD">
                                {startOfWeek
                                }</Moment>
                        </th>
                        <th>
                            TUE<br />
                            <Moment
                                format="MM/DD"
                                add={{ days: 1 }}>
                                {startOfWeek}
                            </Moment>
                        </th>
                        <th>
                            WED<br />
                            <Moment
                                format="MM/DD"
                                add={{ days: 2 }}>
                                {startOfWeek}
                            </Moment>
                        </th>
                        <th>
                            THU<br />
                            <Moment
                                format="MM/DD"
                                add={{ days: 3 }}>
                                {startOfWeek}
                            </Moment>
                        </th>
                        <th>
                            FRI<br />
                            <Moment
                                format="MM/DD"
                                add={{ days: 4 }}>
                                {startOfWeek}
                            </Moment>
                        </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="timesheet">
                        {({ fields }) => <Fragment>
                            {fields.map((name, index) => (

                                <tr className="w-100" key={`${name}_${index}`}>
                                    <td key={`projects_${name}_${index}`}>
                                        <Field name={`${name}.projects`}
                                            type="select"
                                            validate={composeValidators(validateRequired())}
                                            component={SelectAdapter}
                                            options={userProjectsArray} />
                                        <FormFeedbackAdapter name={`${name}.projects`} />
                                    </td>
                                    <td className="w-10 word-break" key={`tasks_${name}_${index}.`}>
                                        <Field
                                            name={`${name}.tasks`}
                                            type="select"
                                            validate={composeValidators(validateRequired())}
                                            component={SelectAdapter}
                                            options={taskArray} />
                                        <FormFeedbackAdapter name={`${name}.tasks`} />
                                    </td>
                                    <td key={`description_${name}_${index}.`}>
                                        <Field
                                            name={`${name}.description`}
                                            type="textarea"
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.description`} />
                                    </td>
                                    <td key={`mon_${name}_${index}`} className="w-10">
                                        <Field
                                            name={`${name}.mon`}
                                            type="number"
                                            component={InputAdapter}
                                            step={0.5}
                                            min={0}
                                            max={12} />
                                        <FormFeedbackAdapter name={`${name}.mon`} />
                                    </td>
                                    <td key={`tue_${name}_${index}`} className="w-10">
                                        <Field name={`${name}.tue`}
                                            type="number"
                                            component={InputAdapter}
                                            min={0}
                                            step={0.5}
                                            max={12} />
                                        <FormFeedbackAdapter name={`${name}.tue`} />
                                    </td>
                                    <td key={`wed_${name}_${index}`} className="w-10">
                                        <Field name={`${name}.wed`}
                                            type="number"
                                            component={InputAdapter}
                                            min={0}
                                            step={0.5}
                                            max={12} />
                                        <FormFeedbackAdapter name={`${name}.wed`} />
                                    </td>
                                    <td key={`thu_${name}_${index}`} className="w-10">
                                        <Field name={`${name}.thu`}
                                            type="number"
                                            component={InputAdapter}
                                            min={0}
                                            step={0.5}
                                            max={12} />
                                        <FormFeedbackAdapter name={`${name}.thu`} />
                                    </td>
                                    <td key={`fri_${name}_${index}`} className="w-10">
                                        <Field name={`${name}.fri`}
                                            type="number"
                                            component={InputAdapter}
                                            min={0}
                                            step={0.5}
                                            max={12} />
                                        <FormFeedbackAdapter name={`${name}.fri`} />
                                    </td>
                                    <td key={`delete_${name}_${index}.`}>
                                        <Button
                                            className="delete-button"
                                            onClick={() => fields.remove(index)}
                                            color="link"
                                            disabled={submitted || submitting}>
                                            <FontAwesomeIcon icon={faMinus} className="mr-2" />
                                        </Button>
                                        <Button
                                            className="add-button"
                                            style={{ display: "none" }}
                                            color="link"
                                            onClick={() => fields.push()}
                                            disabled={submitting || submitted} size="lg">
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" /></Button>
                                    </td>
                                </tr>

                            ))}

                        </Fragment>}
                    </FieldArray>
                </tbody>
            </Table>
        </div>
        <SubmitErrorAlerts submitError={submitError} />
        <Row className="to-end-start pr-4 mr-4">
            <FormGroup>
                <Button
                    color="primary"
                    className="p-2 mr-2"
                    disabled={submitting || submitSucceeded || submitted}
                    type="submit">
                    {"Save Timesheet"}</Button>
            </FormGroup>

            <FormGroup>
                <Button
                    color="primary"
                    className="p-2 mr-2"
                    title={submitted ? "Timesheet has been submitted" : (hoursCompleted ? "Click here to submit" : "Total working hours are less than 10")}
                    disabled={submitting || submitSucceeded || !saved || submitted}
                    type="button"
                    onClick={onSubmitForApproval}>
                    {submitted ? "Timesheet has been submitted" : "Submit For Approval"}</Button>
            </FormGroup>
            <FormGroup>
                <Button
                    color="primary"
                    className="p-2 mr-2"
                    title="Click here to go back"
                    type="button"
                    onClick={onCancel}><FontAwesomeIcon icon={faArrowLeft} className="mr-2" />Go Back</Button>
            </FormGroup>

        </Row>


    </Form>)

TimesheetForm.propTypes = propTypes

export default TimesheetForm
