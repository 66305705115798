import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import EditTimesheetModal from '../components/timesheet/EditTimesheetModal'
import { fetchUserTimelogs, fetchProjects, fetchActiveTasks } from '../middleware/api'
import TableComponent from './elements/TableComponent'
import moment from 'moment'

class UserTimesheet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timesheets: null,
            activeTimesheet: null,
            isOpenTimesheetModal: false,
            projects: null,
            tasks: null,
            searchTerm: ""
        }
        this.toggleEditTimesheetModal = this.toggleEditTimesheetModal.bind(this)
        this.fetchTimelogs = this.fetchTimelogs.bind(this)
    }

    componentDidMount() {
        const userId = localStorage.getItem('user_id')
        fetchUserTimelogs(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    timesheets: result
                })
            })
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                let projectsArray = []
                result && result.forEach(function (project) {
                    project.teammember[0] && project.teammember.forEach(function (teammembers) {
                        if (teammembers.user === userId) {
                            projectsArray.push({
                                value: project._id,
                                label: project.projectname
                            })
                        }
                    })
                })
                this.setState({
                    projects: projectsArray
                })
            })
        fetchActiveTasks()
            .then(async (response) => {
                const result = await response.json()
                let taskArray = []
                result && result.forEach(function (task) {
                    taskArray.push({
                        value: task._id,
                        label: task.taskname,
                        project: task.project
                    })
                })
                this.setState({
                    tasks: taskArray,
                })
            })
    }

    toggleEditTimesheetModal(activeTimesheet) {
        this.setState(state => ({
            isOpenTimesheetModal: !state.isOpenTimesheetModal,
            activeTimesheet: activeTimesheet,
        }))
    }
    fetchTimelogs() {
        const userId = localStorage.getItem('user_id')
        fetchUserTimelogs(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    timesheets: result
                })
            })
    }

    findTasks(projects, tasks) {
        const taskArray = []
        const projectTaskArray = []
        projects && projects.forEach(project => {
            tasks && tasks.forEach(task => {
                if (task.project === project.value) {
                    projectTaskArray.push(task)
                } else if (task.project === 'all') {
                    taskArray.push(task)
                }
            });
        });
        return projectTaskArray.length === 0 ? taskArray : projectTaskArray
    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { timesheets } = this.state
        if (!timesheets || (timesheets && timesheets.length === 0))
            return []

        return timesheets && timesheets.map((timesheet, index) => {
            return (
                {
                    id: index + 1,
                    startOfWeek: `${moment(timesheet.startOfWeek).format('MM/DD')} - ${moment(timesheet.startOfWeek).add(4, 'days').format('MM/DD')}`,
                    status: timesheet.status,
                    identifier:timesheet.identifier,
                    remark: timesheet.remark && timesheet.remark !== "" ? timesheet.remark : "Nil",
                    data: timesheet
                }
            )
        })
    }

    render() {
        const { timesheets, isOpenTimesheetModal, activeTimesheet, tasks, projects, searchTerm } = this.state
        const userTasks = projects && tasks && this.findTasks(projects, tasks)
        const formattedData = timesheets && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return row && row.startOfWeek && row.startOfWeek.toLowerCase().includes(searchTerm.toLowerCase())
        })
        const columns = [

            {
                id: 'startOfWeek',
                label: 'Week',
            },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'remark',
                label: 'Remark',
            },
        ]
        const actionMarkup = ({ data }) => (
            <React.Fragment>
                <Button
                    title={data.status === 'Submitted' ? "Timesheet has been submitted" : "Total working hours are less than 10"}
                    disabled={
                        data.status === 'Approved' ||
                        data.status === 'Submitted'
                    }
                    onClick={() => this.toggleEditTimesheetModal(data)}
                    color="link">Log Hours
                </Button>
            </React.Fragment>
        )
        return (
            <>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            defaultSort="startOfWeek"
                            defaultSortOrder="desc"
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            action={actionMarkup}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>
                <EditTimesheetModal
                    isOpen={isOpenTimesheetModal}
                    toggleModal={this.toggleEditTimesheetModal}
                    activeTimesheet={activeTimesheet}
                    projects={projects ? projects : []}
                    tasks={userTasks ? userTasks : []}
                    fetchTimelogs={this.fetchTimelogs}
                />
            </>
        );
    }
}

export default UserTimesheet;