import {HTTP_METHODS, callApi} from './callApi'
import { API_CALL } from '../../src/config'
const apiRoot = `${API_CALL}/api/v1`

export const fetchEmployeeFamilyMembers = (employeeId) => {
    const url = `${apiRoot}/familymember/${employeeId}`
    let method = HTTP_METHODS.GET
    let body = null
    return callApi(url, method, body)
  }
  export const createFamilyMember = (values) => {
    const url = `${apiRoot}/familymember`
    let method = HTTP_METHODS.POST
    let body = values
    return callApi(url, method, body)
  }
  export const updateFamilyMember = (familyMemberId, FamilyMember) => {
    const url = `${apiRoot}/familymember/${familyMemberId}`
    let method = HTTP_METHODS.PUT
    let body = FamilyMember
    return callApi(url, method, body)
  }
  export const deleteFamilyMember = (familyMemberId) => {
    const url = `${apiRoot}/familymember/${familyMemberId}`
    let method = HTTP_METHODS.DELETE
    let body = null
    return callApi(url, method, body)
  }