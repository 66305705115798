import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import PersonalInfo from '../components/users/PersonalInfo'
import BankingDetails from '../components/users/BankingDetails'
import ContactDetails from '../components/users/ContactDetails'
import { fetchProjects, fetchManagers, fetchUser, loggingOut } from '../middleware/api'
import UserDetailsTab from '../components/content/UserDetailsTab'
import FamilyDetails from '../components/users/FamilyDetails'
import { Container, Row, Col, Button } from 'reactstrap'
import ConfirmationModal from '../components/content/ConfirmationModal'
import { withRouter } from 'react-router-dom';
class UserProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managers: [],
      projects: [],
      user: null,
      showBankDetail: false,
      showPersonalInfo: true,
      showContactInfo: false,
      timers: "00:00:00",
      userId: this.props.match.params.id,
      isOpen: true,
      selectedTab: 'personalInfo',
      openFamilyModal: false,
      selectedMember: null,
      openConfirmDeleteModal: false,
      openConfirmationPopup: false
    }

    this.activeUser = this.activeUser.bind(this)
    this.setTimings = this.setTimings.bind(this)
    this.toggle = this.toggle.bind(this)
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  activeUser() {
    const userId = this.props.match.params.id
    fetchUser(userId)
      .then(async (response) => {
        const result = await response.json()
        this.setState({
          user: result
        })
      })
  }
  setTimings(time) {
    this.setState({
      timers: time
    })
  }
  toggleTabs = (tab) => {
    this.setState({ selectedTab: tab })
  }
  componentDidMount() {
    loggingOut()
    fetchProjects()
      .then(async (response) => {
        const result = await response.json()
        let projectsArray = []
        result && result.forEach(function (project) {
          projectsArray.push({
            value: project._id,
            label: project.projectname
          })
        })
        this.setState({
          projects: projectsArray
        })
      })
    this.activeUser()
    fetchManagers()
      .then(async (response) => {
        const result = await response.json()
        let managerArray = []
        result && result.forEach(function (user) {
          managerArray.push({
            value: user._id,
            label: user.fname + user.lname
          })
        })
        this.setState({
          managers: managerArray,
        })
      })
  }
  toggleModal = (selectedMember) => {
    this.setState((prevState) => ({
      openFamilyModal: !prevState.openFamilyModal,
      selectedMember
    }))
  }
  toggleConfirmDeleteModal = (selectedMember) => {
    this.setState((prevState) => ({
      openConfirmDeleteModal: !prevState.openConfirmDeleteModal,
      selectedMember
    }))
  }
  toggleConfirmationPopup = () => {
    this.setState((prevState) => ({
      openConfirmationPopup: !prevState.openConfirmationPopup
    }))
  }
  navigate = () => {
    this.props.history.push('/users');
  }
  render() {
    const { managers, projects, openConfirmationPopup, user, openConfirmDeleteModal, selectedTab, userId, isOpen, selectedMember, openFamilyModal } = this.state
    return (
      <div className="App wrapper">
        <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
        <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
          <Container fluid>
            <Row className="page-heading">
              <Col className="text-left">
                <span>Employee Details</span>
              </Col>
              {selectedTab === 'familyInfo' && <Col className="text-right">
                <Button color="warning" size="lg" onClick={this.toggleModal}>
                  Add Member
                        </Button>
              </Col>}
            </Row>
          </Container>
          <div className="container-fluid pt-5">
            <UserDetailsTab selectedTab={selectedTab} toggleTabs={this.toggleTabs} />
            {
              selectedTab === 'personalInfo' && <PersonalInfo
                managers={managers ? managers : []}
                projects={projects ? projects : []}
                user={user && user}
                toggleConfirmationPopup={this.toggleConfirmationPopup}
                fetchUser={this.activeUser}
                userId={userId && userId}
              />
            }
            {
              selectedTab === 'bankingInfo' && <BankingDetails
                managers={managers ? managers : []}
                projects={projects ? projects : []}
                user={user && user}
                userId={userId && userId}
                toggleConfirmationPopup={this.toggleConfirmationPopup}
                fetchUser={this.activeUser}
              />
            }
            {
              selectedTab === 'contactInfo' && <ContactDetails
                managers={managers ? managers : []}
                projects={projects ? projects : []}
                user={user && user}
                userId={userId && userId}
                toggleConfirmationPopup={this.toggleConfirmationPopup}
                fetchUser={this.activeUser}
              />
            }
            {
              selectedTab === 'familyInfo' && <FamilyDetails
                userId={userId && userId}
                openFamilyModal={openFamilyModal}
                selectedMember={selectedMember}
                toggleModal={this.toggleModal}
                openConfirmDeleteModal={openConfirmDeleteModal}
                toggleConfirmDeleteModal={this.toggleConfirmDeleteModal}
              />
            }
          </div>
          <ConfirmationModal
            onConfirm={this.toggleConfirmationPopup}
            isOpen={openConfirmationPopup}
            toggle={this.navigate}
            message="Do you want to edit more info? If yes click Confirm else click on Cancel to go to employees list page."
          />
        </Content>

      </div>
    );
  }
}
export default withRouter(UserProfileContainer);