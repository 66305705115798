import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import {
    Button,
    Form,
    FormGroup,
    Row,
    Col,
    Label
} from 'reactstrap'
import {
    DatePickerAdapter,
    FormFeedbackAdapter,
    InputAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    validateRequired
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const InvestmentsRequestForm = (
    {
        handleSubmit,
        submitError,
        submitting,
        toggle,
        values
    }
) => {
    console.log(values)
    return (
        <Form onSubmit={handleSubmit} className="pt-4">
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Investment Title</Label>
                        <Field
                            name={`investmentTitle`}
                            type="text"
                            bsSize="lg"
                            validate={validateRequired()}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name={`investmentTitle`} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Investment Date</Label>
                        <Field
                            name={`investmentDate`}
                            type="date"
                            bsSize="lg"
                            required={true}
                            component={DatePickerAdapter} />
                        <FormFeedbackAdapter name={`investmentDate`} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <Label>Amount</Label>
                        <Field
                            name={`investmentAmount`}
                            type="number"
                            bsSize="lg"
                            min={0}
                            required={true}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name={`investmentAmount`} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>File</Label>
                        <Field name={`file`}>
                            {({ input: { value, onChange, ...input } }) => (
                                <input
                                    {...input}
                                    type="file"
                                    style={{ padding: '3px' }}
                                    className="border-info form-control"
                                    name={`file`}
                                    onChange={({ target }) => onChange(target.files)}
                                />
                            )}
                        </Field>

                        <FormFeedbackAdapter name={`file`} />
                    </FormGroup>
                </Col>
            </Row>


            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting}
                        size="lg"
                        type="submit"
                    >Submit</Button>
                </Col>
                <Col md>
                    <Button
                        block
                        type="button"
                        color="outline-primary"
                        className="p-3 mt-3"
                        disabled={submitting}
                        size="lg"
                        onClick={() => toggle()}
                    >Cancel</Button>
                </Col>

            </FormGroup>

        </Form>

    )
}

InvestmentsRequestForm.propTypes = propTypes

export default InvestmentsRequestForm
