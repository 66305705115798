import React, { Component } from 'react'
import { Table } from 'reactstrap'
import Moment from 'react-moment'
export default class UpcommingHolidays extends Component {

    render() {
        const { holidays } = this.props
        return (
            <>
                <h4> Upcoming Holidays</h4>
                <Table id="welcome-page-table">
                    <thead>
                        <tr>
                            <th>Occassion</th>
                            <th>Day</th>
                            <th>Date</th>
                        </tr>

                    </thead>
                    <tbody>
                        {
                            holidays && holidays.length > 0 ? holidays.map((day, index) => (
                                <tr key={day._id}>
                                    <td>{day.occassion}</td>
                                    <td><Moment format="dddd">{day.date}</Moment></td>
                                    <td><Moment format="DD/MM/YYYY">{day.date}</Moment></td>
                                </tr>
                            )) :
                            <tr><td colSpan="3">Nothing to show</td></tr>
                        }
                    </tbody>
                </Table>
            </>
        )
    }
}