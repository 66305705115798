import React, { useEffect, useState } from 'react'
import { months, years } from './monthData'
import TableComponent from '../elements/TableComponent'
import { getAllPaySlips, getAllPaySlipsOfEmployee } from '../../middleware/payslips'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { AWS_BUCKET_NAME, AWS_END_POINT } from '../../config'
import PaySlipFilters from './PaySlipFilters'

const columns = [
	{
		id: 'empName',
		label: 'Name',
		format: (data) => (
			<span className='capitalize'>{data}</span>
		)
	},
	{
		id: 'empId',
		label: 'Employee Id',

	},
	{
		id: 'month',
		label: 'Month',
		format: (data) => (
			<span>{months[data]}</span>
		)
	},
	{
		id: 'year',
		label: 'Year',
	},
]

const GenerateSlip = ({ isAdmin }) => {
	const [searchTerm, setSearchTerm] = useState("")
	const currentMonth = new Date().getMonth()
	const currentYear = new Date().getFullYear()
	const [paySlips, setPaySlips] = useState([])
	var timeStamp = + new Date()
	const [month, setMonth] = useState(currentMonth)
	const [year, setYear] = useState(currentYear)

	const requestSearch = (searchTerm) => {
		setSearchTerm(searchTerm)
	}

	const cancelSearch = () => {
		setSearchTerm("")
	}

	const fetchPaySlips = async () => {
		isAdmin && await getAllPaySlips(month, year)
			.then(async (response) => {
				var result = await response.json()
				setPaySlips(result)
			})
		!isAdmin && await getAllPaySlipsOfEmployee(year)
			.then(async (response) => {
				var result = await response.json()
				console.log(result)
				setPaySlips(result)
			})
	}

	useEffect(() => {
		fetchPaySlips()
	}, [month, year])

	const filteredRows = paySlips && paySlips.filter((row) => {
		return ((row && row.empName && row.empName.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.empId && row.empId.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.month && row.month.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(row && row.year && row.year.toLowerCase().includes(searchTerm.toLowerCase()))
		)
	})

	const actionMarkup = (data) => (
		<React.Fragment>
			<a
				rel="noopener noreferrer"
				href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${data.key}?v=${timeStamp.toString()}`}
				className="text-primary"
				target="_blank"
				title="Download Payslip"
			>
				<FontAwesomeIcon icon={faEye} className="mr-2" />
			</a>
		</React.Fragment>
	)

	return (
		<>
			<TableComponent
				data={filteredRows || []}
				showCheckbox={false}
				columns={columns}
				perPage={10}
				defaultSort="name"
				requestSearch={requestSearch}
				cancelSearch={cancelSearch}
				searchTerm={searchTerm}
				showSerialNo={true}
				action={actionMarkup}
				filterPaySlip={<PaySlipFilters isAdmin={isAdmin} currentMonth={month} currentYear={year} setMonth={setMonth} setYear={setYear} />}
			/>
		</>
	)
}

export default GenerateSlip