
import isEmail from 'validator/lib/isEmail'
import moment from 'moment'
import { timeDiffFromNowHours } from './utils'

/**
 * Validators for Final-Form Synchronous Field-Level Validation
 *
 * @see https://github.com/final-form/react-final-form#synchronous-record-level-validation
 * @see Implementation https://github.com/Soundvessel/final-form-reactstrap
 *
 * @example
 * // field validation of required input with default error message
 * validate={validateRequired()}
 *
 * @example
 * // field validation of required input with custom required error message
 * validate={validateRequired('This is really required.')}
 *
 * @example
 * // field validation of min length with custom error message with HTML (wrap with fragment)
 * validate={
 *   8,
 *   <>Your password must be at least <strong>8</strong> characters long.></>
 * }
 *
 * @example
 * // field validation of required plus matching field named password with custom error message.
 * validate={composeValidators(
 *   validateRequired(),
 *   validateMatch('password', 'Passwords must match.')
 * )}
 *
 */


/**
 * Compose Validators (Function for combining validators)
 *
 * @param {*} validators - Validators as params will be composed together.
 * @returns {function(...[*]): (*)} - Returns first error message from validators.
 *
 * @example
 * // first validates with validateRequired() then validateMinLength(8)
 * composeValidators(
 *   validateRequired(),
 *   validateMinLength(8)
 * )
 *
 */
export const composeValidators = (...validators) => (...validatorParams) =>
  validators.reduce(
    (error, validator) => error || validator(...validatorParams),
    undefined,
  )

/**
 * Email
 *
 * @param {string} [msg='Please enter a valid email address.']
 *    - Override default error message with your own. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value is not an email address.
 */
export const validateEmail = (
  msg = 'Please enter a valid email address.',
) => value => isEmail(value) ? undefined : msg

export const validateGtenEmail = (
  msg = 'Please enter a valid GTEN email address',
) => value => value.indexOf('@globaltestingexperts.com') === -1 ? msg : undefined

export const validatePhoneNumber = (
  msg = 'Please enter a valid mobile number',
) => value => value && value.match( /^\d{10}$/) ? undefined : msg
/**
 * Is a Number
 *
 * @param {string} [msg='Must be a number.'] - Error message. Can include HTML.
 * @returns {function(string): string}       - Function that returns the error message if value is not a number.
 */
export const validateIsNumber = (
  msg = 'Must be a number.',
) => value => isNaN(value) && value ? msg : undefined

/**
 * Match
 *
 * @param {string} fieldName                   - The name of the field you want to validate is a match.
 * @param {string} [msg='Does not match.']     - Error message. Can include HTML.
 * @returns {function(string, object): string} - Function that returns an error message if values do not match.
 */
export const validateMatch = (
  fieldName,
  msg = 'Does not match.',
) => (value, allValues) => value !== allValues[fieldName] ? msg : undefined

/**
 * Max Length
 *
 * @param {number} maxLength - Length (characters or array items) value must be greater than or equal to.
 * @param {string} [msg=`Must be maximum of ${maxLength}.`] - Error message. Can include HTML.
 * @returns {function(string): string} - Function that returns the error message if value is greater than maxLength.
 */
export const validateMaxLength = (
  maxLength,
  msg = `Must be maximum of ${ maxLength }.`,
) => value => (value.length > maxLength) && value ? msg : undefined

/**
 * Min Length
 *
 * @param {number} minLength - Length (characters or array items) value must be less than or equal to.
 * @param {string} [msg=`Must be minimum of ${minLength}.`] - Error message. Can include HTML.
 * @returns {function(string): string} - Function that returns the error message if value is less than minLength.
 */
export const validateMinLength = (
  minLength,
  msg = `Must be minimum of ${ minLength }.`,
) => value => (value.length < minLength) && value ? msg : undefined

/**
 * Required
 *
 * @param {string} [msg='Required.']   - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value is empty.
 */
export const validateRequired = (
  msg = 'Required.',
  // check against length because unfilled checkboxes produces empty array
) => value => value && value.length !== 0 && value.replace(/\s/g, '').length >0 ? undefined : msg

/**
 * Min Number
 *
 * @param {number} min                 - Number which value can't be less than.
 * @param {string} [msg='...']         - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error message if value less than min.
 */
export const validateMinNumber = (
  min,
  msg = `Must be a minimum of ${ min }`,
) => value => (value < min) && value ? msg : undefined

/**
 * Date is in Array of Dates
 *
 * @param {Date}   value - The date object we wish to see if in array.
 * @param {Date[]} array - Array of date objects
 * @returns {boolean}    - True if value date exists in array otherwise false.
 */
export const validateDateIsInArray = (array, value) => !!array.find(item => item.getTime() === value.getTime())

/**
 * Today is in Array of Dates from react-day-picker
 *
 * @param {Date[]} dates  - Array of date objects from react-day-picker.
 * @returns {boolean}     - True if dates contains today.
 */
export const validateIsToday = (dates) => {

  let isToday = false

  // if values exist in array
  if (dates.length) {
    // get now in format of react-day-picker to prevent issues when close to midnight etc
    const todayDate = moment({ hour: 12, minute: 0, seconds: 0, milliseconds: 0 }).toDate()

    // is today?
    isToday = !!validateDateIsInArray(dates, todayDate)
  }

  return isToday
}

/**
 * Late Call: When today is selected and time is within two hours
 *
 * @param {String} startTime - Start time to check against current time. Must be in 24 hour format. (HTML5 time field).
 * @param {Date[]} dates     - Array of date objects from react-day-picker
 * @returns {boolean}        - True if dates contains today's date and startTime is within 2 hours of now
 */
export const validateIsLateCall = (startTime, dates) => {

  let isToday   = false,
      withinTwo = false

  // if we have values to check
  if (dates.length && startTime) {

    // is today?
    isToday = !!validateIsToday(dates)

    // calc time difference from now
    const timeDiff = timeDiffFromNowHours(startTime)

    // is within 2 hours of now?
    withinTwo = timeDiff <= 2
  }

  return (isToday && withinTwo)
}

/**
 * Is time before now when today's date is selected? (must be used with HTML5 time field)
 *
 * @param {string} rdpDatesArray       - The name of the react-day-picker field you want to check if contains today.
 * @param {string} [msg='...']         - Error Message. Can include HTML.
 * @returns {function(string): string} - Function that returns an error if dates contains today's date and time is before now
 */

export const validateIsBeforeNow = (
  rdpDatesArray,
  msg = 'Time/date can\'t be in past.',
) => (value, allValues) => {

  let isToday         = false,
      isBeforeTimeNow = false
  const dates = allValues[rdpDatesArray]

  // if values exist
  if (dates.length && value) {

    // is today?
    isToday = !!validateIsToday(dates)

    // calc time difference from now
    const timeDiff = timeDiffFromNowHours(value)

    // is time before now?
    isBeforeTimeNow = timeDiff < 0
  }

  return (isToday && isBeforeTimeNow) ? msg : undefined
}