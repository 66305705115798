import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'


const propTypes = {
  multiple   : PropTypes.bool,
  options    : PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  invalid    : PropTypes.func,
  valid      : PropTypes.func,
}

const defaultProps = {
  selectablePlaceholder: false,
  placeholder: 'Select...',
  invalid: meta => meta.touched && meta.invalid,
  valid: () => false,
}

const SelectAdapter = (
  {
    input,
    multiple,
    options,
    meta,
    placeholder,
    invalid,
    selectablePlaceholder,
    valid,
    ...rest
  },
) => {
  return (
    <Input
      { ...input }
      invalid={ invalid(meta) }
      valid={ valid(meta) }
      // className to style placeholder when not multiple
      className={ !input.value ? 'text-placeholder' : null }
      multiple={ multiple }
      // empty multiple must be empty array for final-form
      value={ !input.value && multiple ? [] : input.value }
      { ...rest }
    >
      { placeholder && (
        <option className="text-placeholder" disabled={ !selectablePlaceholder } value="">
          { placeholder }
        </option>
      ) }
      { /* Generate options once available */ }
      { options.length && options[0].hasOwnProperty('groupOptions')
        /* labeled groups of options */
        ? options.map((group, groupIndex) => (
          <optgroup label={ group.label } key={ groupIndex }>
            { group.groupOptions.map((option, optionIndex) => (
              <option key={ `${ groupIndex }-${ optionIndex }` } value={ option.value }>
                { option.label }
              </option>
            )) }
          </optgroup>
        ))
        /* options */
        : options.map((option, index) => (
          <option key={ index } value={ option.value }>
            { option.label }
          </option>
        )) }
    </Input>
  )
}

SelectAdapter.defaultProps = defaultProps

SelectAdapter.propTypes = propTypes

export default SelectAdapter