import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import TaskForm from './tasks/TaskForm'
import { Form } from 'react-final-form'
import { mapFinalFormErrors } from '../lib/utils'
import { createTask, updateTask, fetchProjects } from '../middleware/api'
import {cryptInfo} from '../config'

const mapErrors = mapFinalFormErrors('Task creation failed.')

class AddTaskModal extends React.Component {
    state = {
        task: null,
        isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
        projects:null
    }

    onSubmit = (values) => {

        const { fetchTasks, task, toggle } = this.props
        values.project = !values.project ? 'all' :values.project
        values.user =localStorage.getItem('user_id')
        values.username = localStorage.getItem('fname')
        if (!this.state.isAdmin) {
            values.status = 'suggestion'
        }
        if (task && task._id) {
            updateTask(task._id, values)
                .then(async (response) => {
                    await fetchTasks()
                    toggle()
                })
        } else {
            createTask(values)
                .then(async (response) => {
                    await fetchTasks()
                })
                .then(toggle())
                .catch(mapErrors)
        }
    }
    componentDidMount(){
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                let projectsArray = []
                result && result.forEach(function (project) {              
                            projectsArray.push({
                                value: project._id,
                                label: project.projectname
                            })
                })
                this.setState({
                    projects: projectsArray
                })
            })
}
    render() {

        const { isOpen, toggle, task } = this.props
        const { isAdmin, projects } = this.state
        const modalTitle = task ? "Edit Task" : "Add Task"

        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className={this.props.className}>
                    <ModalHeader 
                       tag="h2"
                       toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form
                            component={TaskForm}
                            id="addtask"
                            toggle={toggle}
                            onSubmit={this.onSubmit}
                            subscription={{
                                pristine: true,
                                submitError: true,
                                submitting: true,
                                hasValidationErrors: true,
                            }}
                            projects={projects && projects}
                            update={task}
                            isAdmin={isAdmin}
                            initialValues={
                                {
                                    taskname: task && task.taskname ? task.taskname : "",
                                    description: task && task.description ? task.description : "",
                                    status: task && task.status ? task.status : "",
                                }
                            }
                            handleStartDate={this.handleStartDate}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default AddTaskModal;