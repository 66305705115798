import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
    Button,
    Form,
    FormGroup,
    Table,
    Row
} from 'reactstrap'
import {
    FormFeedbackAdapter,
    InputAdapter,
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}

const FeedbackForm = (
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        values
    }
) => (
    <Form onSubmit={handleSubmit} style={{ overflowX: 'auto' }}>
        <div className="container-fluid p-0">
            <Table id="timesheet-table" className="add-user f-15 table table-striped">
                <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                    <tr>
                        <th>Skill Evaluated</th>
                        <th>Rating(1-5)</th>
                        <th>Question Asked</th>
                        <th>Feedback</th>
                        <th>Anything Worth Mentioning?</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="feedback">
                        {({ fields }) => <Fragment>
                            {fields.map((name, index) => (

                                <tr className="w-100" key={`${name}_${index}`}>
                                    <td key={`${name}_${index}skill`}>
                                        <Field
                                            name={`${name}.skill`}
                                            type="text"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.skill`} />
                                    </td>
                                    <td key={`${name}_${index}_rating`}>
                                        <Field
                                            name={`${name}.rating`}
                                            type="number"
                                            bsSize="lg"
                                            min={1}
                                            max={5}
                                            step=".5"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.rating`} />
                                    </td>
                                    <td key={`${name}_${index}_questionAsked`}>
                                        <Field
                                            name={`${name}.questionAsked`}
                                            type="textarea"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.questionAsked`} />
                                    </td>

                                    <td key={`${name}_${index}_feedback`}>
                                        <Field
                                            name={`${name}.feedback`}
                                            type="textarea"
                                            bsSize="lg"
                                            validate={
                                                composeValidators(
                                                    validateRequired(),
                                                )
                                            }
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.feedback`} />
                                    </td>
                                    <td key={`${name}_${index}_role`} >
                                        <Field
                                            name={`${name}.other`}
                                            type="textarea"
                                            bsSize="lg"
                                            validate={validateRequired()}
                                            component={InputAdapter}
                                        />
                                        <FormFeedbackAdapter name={`${name}.other`} />
                                    </td>

                                    <td key={`delete_${name}_${index}.`}>
                                        <Button
                                            className="delete-button"
                                            onClick={() => fields.remove(index)}
                                            color="link">
                                            <FontAwesomeIcon icon={faMinus} className="mr-2" />
                                        </Button>
                                        <Button
                                            className="add-button"
                                            style={{ display: "none" }}
                                            color="link"
                                            title="Add Skill"
                                            onClick={() => fields.push()}
                                            disabled={submitting} size="lg">
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" /></Button>
                                    </td>
                                </tr>
                            ))}
                        </Fragment>}
                    </FieldArray>
                </tbody>
            </Table>
        </div>
        <SubmitErrorAlerts submitError={submitError} />
        <Row className="justify-content-end pr-4">

            <FormGroup>
                <Button
                    color="primary"
                    className="p-3 mr-2"
                    disabled={submitting || submitSucceeded}
                    type="submit">
                    {" Add Feedback"}</Button>
            </FormGroup>

            <FormGroup>
                <Button
                    color="outline-primary"
                    className="p-3 mr-2"
                    disabled={submitting || submitSucceeded}
                    type="button"
                    onClick={toggle}
                >Cancel</Button>
            </FormGroup>
        </Row>
    </Form>
)

FeedbackForm.propTypes = propTypes

export default FeedbackForm
