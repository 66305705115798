import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import Timesheet from '../components/Timesheet'
import { fetchProjects, fetchActiveTasks, loggingOut } from '../middleware/api'
import PageHeading from '../components/content/PageHeading'

class TimeSheetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            tasks: [],
            isOpen: true,
            timers: "00:00:00"
        }
        this.setTimings = this.setTimings.bind(this)
        this.toggle = this.toggle.bind(this)
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    setTimings(time) {
        this.setState({
            timers: time
        })
    }


    componentDidMount() {
        loggingOut()
        const userId = localStorage.getItem('user_id')
        fetchProjects()
            .then(async (response) => {
                const result = await response.json()
                let projectsArray = []
                result && result.forEach(function (project) {
                    project.teammember[0] && project.teammember.forEach(function (teammembers) {
                        if (teammembers.user === userId) {
                            projectsArray.push({
                                value: project._id,
                                label: project.projectname
                            })
                        }
                    })
                })
                this.setState({
                    projects: projectsArray
                })
            })
        fetchActiveTasks()
            .then(async (response) => {
                const result = await response.json()
                let taskArray = []
                result && result.forEach(function (task) {
                    taskArray.push({
                        value: task._id,
                        label: task.taskname,
                        project: task.project
                    })
                })
                this.setState({
                    tasks: taskArray,
                })
            })
    }
    findTasks(projects, tasks) {
        const taskArray = []
        const projectTaskArray = []
        projects && projects.forEach(project => {
            tasks && tasks.forEach(task => {
                if (task.project === project.value) {
                    projectTaskArray.push(task)
                } else if (task.project === 'all') {
                    taskArray.push(task)
                }
            });
        });
        return projectTaskArray.length === 0 ? taskArray : projectTaskArray
    }
    findUnique(dummyTasks) {
        var tempArray = []
        var tempTasksArray = []
        dummyTasks && dummyTasks.forEach(task => {
            if (tempArray.indexOf(task.value) === -1) {
                tempArray.push(task.value)
                tempTasksArray.push(task)
            }
        });
        return tempTasksArray
    }
    render() {
        const { projects, tasks, isOpen } = this.state
        const dummyTasks = projects && tasks && this.findTasks(projects, tasks)
        const userTasks = dummyTasks && this.findUnique(dummyTasks)
        return (
            <div className="App wrapper hide-overflow">
                <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                <Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
                    <PageHeading title="Timesheet Details" />
                    <Timesheet
                        projects={projects ? projects : []}
                        tasks={userTasks ? userTasks : []}
                    />
                </Content>
            </div>
        );
    }
}

export default TimeSheetContainer;