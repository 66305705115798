import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import LeaveFilterForm from './leaves/LeaveFilterForm'

class ApplyFIlterModal extends React.Component {

	render() {

		let { isOpen, toggle, users, onSubmit,filterQuery } = this.props

		return (
			<div>
				<Modal isOpen={isOpen} toggle={() => toggle()}>
					<ModalHeader
						tag="h2"
						toggle={() => toggle()}>Apply Filters</ModalHeader>
					<ModalBody>
						<Form
							component={LeaveFilterForm}
							users={users}
							formId="filterleave"
							toggle={toggle}
							onSubmit={onSubmit}
							subscription={{
								pristine: true,
								submitError: true,
								submitting: true,
								hasValidationErrors: true,
								values: true
							}}
							mutators={{
								...arrayMutators,
							}}
							initialValues={filterQuery}
						/>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default ApplyFIlterModal