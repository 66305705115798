
export const filterUser = function (users, type) {
    var result = []
    for (var i = 0; i < users.length; i++) {
        if (users[i].role === type) {
            result.push({
                value: users[i]._id,
                label: users[i].fname + " " + users[i].lname
            })
        }
    }
    return result
}
export const filterManagerAndAdmin = function (users) {
    var result = []
    for (var i = 0; i < users.length; i++) {
        if (users[i].role === 'manager' || users[i].role === 'admin') {
            result.push({
                value: users[i]._id,
                label: users[i].fname + " " + users[i].lname
            })
        }
    }
    return result
}

export const filterProjects = function (projects) {
    var result = []
    for (var i = 0; i < projects.length; i++) {
        result.push({
            value: projects[i]._id,
            label: projects[i].projectname
        })
    }
    return result
}

export const fetchProjectName = (project, timesheet) => {
    let projectsArray = ""
    project.forEach(project => {
        if (timesheet.projects === project._id) {
            projectsArray = project.projectname
        }
    })
    return projectsArray
}
export const fetchTaskName = (tasks, timesheet) => {
    let tasksArray = ""
    tasks.forEach(task => {
        if (timesheet.tasks === task._id) {
            tasksArray = task.taskname
        }
    })
    return tasksArray
}
export const fetchUserName = (users, id) => {
    let userName = ""
    users && users.forEach(user => {
        if (user._id === id) {
            userName = user.fname + " " + user.lname
        }
    })
    return userName
}
export const fetchClientName = (clients, id) => {
    var selectedClient = clients && clients.filter(client => client._id === id)
    return selectedClient.length > 0 ? selectedClient[0].clientname : ""
}
export const fetchMultipleProjectName = (project, timesheet) => {
    let projectsArray = []
    timesheet.forEach(timesheet => {
        project.forEach(project => {
            if (timesheet.projects === project._id) {
                if (projectsArray.indexOf(project.projectname) === -1)
                    projectsArray.push(project.projectname)
            }
        })
    })
    return projectsArray
}
const itemsPerPage = 10
export const calculatePageNumber = (items) => {

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(items.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }
    return pageNumbers
}


export const getDates = function (startDate, endDate) {
    var dates = [],
        currentDate = startDate,
        addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
    while (currentDate <= endDate) {
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
    }
    return dates;
}
