import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { createInterview, fetchCandidate, confirm, updateStatus, addEditFeedback } from '../middleware/candidates'
import { fetchAllData } from '../middleware/metadata'
import { fetchJobs } from '../middleware/jobs'
import { Container, Row, Col, Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { cryptInfo } from '../config'
import { toast } from 'react-toastify'
import InterviewModal from '../components/recruitment/InterviewModal'
import CandidateDetails from '../components/recruitment/CandidateDetails'
import FeedbackModal from '../components/recruitment/FeedbackModal'
class CandidateDetailContainer extends Component {

	state = {
		candidate: null,
		isOpen: true,
		allMetadata: [],
		jobs: [],
		isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
		isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
		isRecruiter: cryptInfo.decrypt(localStorage.getItem('role')) === 'recruiter',
		userId: localStorage.getItem('user_id'),
		userName:localStorage.getItem('fname') + " " + localStorage.getItem('lname'),
		openInterviewModal: false,
	}

	componentDidMount = () => {
		const candidateId = this.props.match.params.id
		fetchCandidate(candidateId)
			.then(async (response) => {
				const result = await response.json()
				this.setState({
					candidate: result
				})
			})
		fetchAllData()
			.then(async (response) => {
				const result = await response.json()
				this.setState({
					allMetadata: result
				})
			})
		fetchJobs()
			.then(async (response) => {
				const result = await response.json()
				this.setState({
					jobs: result
				})
			})
	}

	findItem = (id) => {
		const { allMetadata } = this.state
		if (!allMetadata || (allMetadata && allMetadata.length === 0))
			return null
		return allMetadata && allMetadata.filter(item => item._id === id).map((e) => {
			return e.name + " "
		}).join(', ')
	}

	findJob = (id) => {
		const { jobs } = this.state
		if (!jobs || (jobs && jobs.length === 0))
			return null
		return jobs && jobs.filter(item => item._id === id).map((e) => {
			return e.title
		}).join(', ')
	}

	checkInterviewPanel = (id) => {
		const { jobs, userId } = this.state
		if (!jobs || (jobs && jobs.length === 0))
			return null
		var job = jobs && jobs.filter(item => item._id === id)
		var isPenalist = false
		if (job && job[0]) {
			job[0].interviewPanel.map(p => {
				if (p.value === userId) {
					isPenalist = true
				}
				return false
			})
		}
		return isPenalist
	}

	findHiringManager = (id) => {
		const { jobs, userId } = this.state
		if (!jobs || (jobs && jobs.length === 0))
			return null
		var job = jobs && jobs.filter(item => item._id === id)
		if (userId === job[0].hiringManager)
			return true
		else
			return false
	}

	changeStatus = (status) => {
		const { candidate } = this.state
		candidate && candidate[0] && updateStatus(candidate[0]._id, { status })
			.then((response) => {
				toast.success('Request to schedule an interview has been successfully sent to the recruiter.')
				fetchCandidate(candidate[0]._id)
					.then(async (response) => {
						const result = await response.json()
						this.setState({
							candidate: result
						})
					})
			})
	}

	toggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	}

	toggleInterviewModal = () => {
		this.setState(state => ({
			openInterviewModal: !state.openInterviewModal
		}))
	}

	toggleFeedbackModal = () => {
		this.setState(state => ({
			openFeedbackModal: !state.openFeedbackModal
		}))
	}

	scheduleInterview = (values) => {
		const { candidate } = this.state
		candidate && candidate[0] && createInterview(candidate[0]._id, values)
			.then((response) => {
				toast.success('Interview scheduled successfully. Please wait for hiring manager to confirm. You will be notified once confirmed')
				fetchCandidate(candidate[0]._id)
					.then(async (response) => {
						const result = await response.json()
						this.setState({
							candidate: result
						})
						this.toggleInterviewModal()
					})
			})
	}

	confirmInterview = () => {
		const { candidate } = this.state
		candidate && candidate[0] && confirm(candidate[0]._id, { status: 'Interview Scheduled' })
			.then((response) => {
				toast.success('Interview has been confirmed.')
				fetchCandidate(candidate[0]._id)
					.then(async (response) => {
						const result = await response.json()
						this.setState({
							candidate: result
						})
					})
			})
	}

	onFeedbackSubmit = (values) => {
		const { userId, candidate, userName } = this.state
		var feedback = []
		if(candidate && candidate[0] && candidate[0].feedback){
			candidate[0].feedback.map(f => {
				if(f.userId !== userId){
					feedback.push(f)
				}
				return false
			})
		}
			feedback.push({
				userId,
				userName,
				feedback: values.feedback
			})
		
		candidate && candidate[0] && addEditFeedback(candidate[0]._id, { feedback })
			.then((response) => {
				this.toggleFeedbackModal()
				toast.success('Your feedback is succesffully submitted.')
				fetchCandidate(candidate[0]._id)
					.then(async (response) => {
						const result = await response.json()
						this.setState({
							candidate: result
						})
					})
			})
	}

	render() {
		const { isOpen, candidate, openFeedbackModal, openInterviewModal, isAdmin, isManager, isRecruiter, userId } = this.state
		let isHiringManager = candidate && candidate[0] && this.findHiringManager(candidate[0].job)
		let isCandidateRecruiter = candidate && candidate[0].recruiterId === localStorage.getItem('user_id')
		const isInterviewPenalist = candidate && candidate[0] && this.checkInterviewPanel(candidate[0].job)

		return (
			<div className="App wrapper">
				<SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
				<Content toggle={this.toggle} isOpen={isOpen} setTimings={this.setTimings}>
					<Container fluid>
						<Row className="page-heading">
							<Col className="text-left">
								<span>Candidate Details</span>
							</Col>
						</Row>
					</Container>
					{candidate && candidate[0] && <Container fluid className="mt-3">
						<CandidateDetails
							candidate={candidate && candidate[0] && candidate[0]}
							isAdmin={isAdmin}
							isManager={isManager}
							isRecruiter={isRecruiter}
							findJob={this.findJob}
							findItem={this.findItem}
						/>
						{isHiringManager &&
							candidate && candidate[0].status !== 'Schedule Interview' &&
							candidate[0].status !== 'Interview Scheduled' &&
							candidate[0].status !== 'Interview Confirmed' &&
							<Row className="p-2">
								<Col className="text-left">
									<Button
										title="Click here to notify recruiter to schedule an interview."
										onClick={() => this.changeStatus('Schedule Interview')}>
										Notify Recruiter To	Schedule Interview
									</Button>
								</Col>
							</Row>}
						{isCandidateRecruiter && candidate && candidate[0].status === 'Schedule Interview' && !candidate[0].interview &&
							<Row className="p-2">
								<Col className="text-left">
									<Button
										title="Click here to an interview."
										onClick={() => this.toggleInterviewModal()}>
										Click To Schedule Interview
									</Button>
								</Col>
							</Row>}
						{isHiringManager && candidate && candidate[0].status === 'Interview Scheduled' &&
							<Row className="p-2">
								<Col className="text-left">
									<Button
										title="Click here will confirm the interview and notify the panelist and candidate."
										onClick={() => this.confirmInterview()}>
										Confirm Interview
									</Button>
								</Col>
							</Row>}
						{isInterviewPenalist &&
							<Row className="p-2">
								<Col className="text-left">
									<Button
										title="Click here to add feedback."
										onClick={() => this.toggleFeedbackModal()}>
										Add Feedback
									</Button>
								</Col>
							</Row>
						}
					</Container>}

					<InterviewModal
						isOpen={openInterviewModal}
						toggle={this.toggleInterviewModal}
						onSubmit={this.scheduleInterview}
					/>
					<FeedbackModal
						isOpen={openFeedbackModal}
						toggle={this.toggleFeedbackModal}
						onSubmit={this.onFeedbackSubmit}
						candidate={candidate && candidate[0]}
						userId={userId}
					/>
				</Content>
			</div>
		);
	}
}
export default withRouter(CandidateDetailContainer);