import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Table } from 'reactstrap'
import { AWS_END_POINT, AWS_BUCKET_NAME } from '../../config'

const PoliciesList = ({
	toggleAddEditPolicyModal,
	toggleConfirmationModal,
	policies,
	isAdmin
}) => {
	var timeStamp = + new Date()
	return (
		<>
			<Table striped hover bordered className="f-15">
				<thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
					<tr>
						<th className={!isAdmin ? "text-left" : ""}>Title</th>
						{isAdmin && <th>Status</th>}
						{isAdmin && <th>Discription</th>}
						{isAdmin && <th>Attachment</th>}
						{isAdmin && <th>Action</th>}
					</tr>
				</thead>
				<tbody>
					{
						policies && policies.length > 0 ? policies.map(policy => (
							<tr key={policy._id}>
								<td className={!isAdmin ? "text-left pl-0" : ""}>
									<Button color="link" onClick={() => window.location.href = `policy/${policy._id}`}>{policy.title}</Button>

								</td>
								{isAdmin && <td className="text-capitalize">{policy.status}</td>}
								{isAdmin && <td>{policy.description}</td>}
								{isAdmin && <td>{policy.fileUploadKeys && policy.fileUploadKeys.map(file => (
									<a
										key={file}
										rel="noopener noreferrer"
										href={`https://${AWS_BUCKET_NAME}.${AWS_END_POINT}/${file}?v=${timeStamp.toString()}`}
										className="text-primary"
										target="_blank" >
										<FontAwesomeIcon icon={faEye} />
									</a>

								))}</td>}
								{isAdmin && <td className="no-wrap">
									<Button
										color="link"
										className="text-primary"
										onClick={() => toggleAddEditPolicyModal(policy, 'edit')}>
										<FontAwesomeIcon icon={faPencilAlt} />
									</Button>
									<Button
										color="link"
										className="text-danger"
										onClick={() => toggleConfirmationModal(policy)}>
										<FontAwesomeIcon icon={faTrash} />
									</Button>
								</td>}
							</tr>
						)) :
							<tr><td colSpan="5">Nothing to show</td></tr>
					}
				</tbody>
			</Table>
		</>
	);
};

export default PoliciesList;