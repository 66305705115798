import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap'
import { createReimbursementType, fetchReimbursementsTypes, updateReimbursementType, deleteReimbursementType } from '../../middleware/reimbursement'
import { ToastContainer, toast } from 'react-toastify'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import 'react-toastify/dist/ReactToastify.css'
import ReimbursementTypeForm from './ReimbursementTypeForm'
import ConfirmationModal from '../content/ConfirmationModal'
class Settings extends Component {

    state = {
        openAddTypeModal: false,
        reimbursementTypes: [],
        selectedType: null,
        openConfirmationModal: false
    }

    componentDidMount = () => {
        this.refreshList()
    }

    toggleAddTypeModal = () => {
        this.setState((prevState => ({
            openAddTypeModal: !prevState.openAddTypeModal
        })))
    }

    onSubmit = async (values) => {
        values && values.reimbursements && values.reimbursements.map(async (type) => {
            if (type._id) {
                await updateReimbursementType(type._id, type)
                    .then(() => {
                        this.refreshList()
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Error: Something went wrong.")
                        return
                    })
            } else {
                await createReimbursementType(type)
                    .then(() => {
                        this.refreshList()
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error("Error: Something went wrong.")
                        return
                    })
            }
        })
        toast.success("Reimbursement types successfully updated.")
    }

    refreshList = async () => {
        await fetchReimbursementsTypes()
            .then(async (response) => {
                var result = await response.json()
                this.setState({
                    reimbursementTypes: result
                })
            })
    }

    toggleConfirmationModal = (selectedType) => {
        this.setState((prevState) => ({
            openConfirmationModal: !prevState.openConfirmationModal,
            selectedType
        }))
    }

    removeReimbursementType = async () => {
        const { selectedType } = this.state
        await deleteReimbursementType(selectedType._id)
            .then(() => {
                toast.success("Reimbursement type successfully removed.")
                this.toggleConfirmationModal()
                this.refreshList()
            })
            .catch((err) => {
                console.log(err)
                toast.error("Error: Something went wrong.")
            })
    }
    
    render() {
        const { reimbursementTypes, openConfirmationModal } = this.state
        const defReimbursements = [{
            name: "",
            status: "active",
            amount: 1,
        }]
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col>
                            <Form
                                component={ReimbursementTypeForm}
                                formId="reimbursements"
                                onSubmit={this.onSubmit}
                                subscription={{
                                    pristine: true,
                                    submitError: true,
                                    submitting: true,
                                    hasValidationErrors: true,
                                    values: true
                                }}
                                toggleConfirmationModal={this.toggleConfirmationModal}
                                mutators={{
                                    ...arrayMutators,
                                }}
                                initialValues={{ reimbursements: reimbursementTypes && reimbursementTypes.length > 0 ? reimbursementTypes : defReimbursements }}
                            />
                        </Col>
                    </Row>
                </Container>
                <ToastContainer />
                <ConfirmationModal
                    isOpen={openConfirmationModal}
                    toggle={this.toggleConfirmationModal}
                    onConfirm={this.removeReimbursementType}
                    message="Are you sure you want to remove this entry? Don't forgot to save your enteries before removing any entry."
                />
            </>
        );
    }
}

export default Settings;