import React from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
    Label,
    Row,
    Col,
    Table
} from 'reactstrap'
import Moment from 'react-moment'
const Heading = ({ title }) => (
    <Row>
        <Col className="border-bottom border-top py-2 font-weight-bold mb-2">
            <h2 className="text-center">{title}</h2>
        </Col>
    </Row>
)
const EmployeeDetailModal = ({ isOpen, toggle, userDetails }) => {
    return (
        <div>
            <Modal isOpen={isOpen} toggle={()=>toggle(null)}>
                <ModalHeader toggle={()=>toggle(null)} className="text-primary">{userDetails && userDetails.fname + " " + userDetails.lname}</ModalHeader>
                <ModalBody>
                    <Container>
                        <Heading title="General Info" />
                        <Row>
                            <Col>
                                <Label><b>Role: </b>{userDetails && userDetails.role}</Label>
                            </Col>
                            <Col>
                                <Label><b>Email: </b>{userDetails && userDetails.email}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label><b>Phone: </b>{userDetails && userDetails.phone}</Label>
                            </Col>
                            <Col>
                                <Label><b>Blood Group: </b>{userDetails && userDetails.bloodgroup}</Label>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Label><b>Date of Birth: </b>{userDetails && userDetails.birthday}</Label>
                            </Col>
                            <Col>
                                <Label><b>Date of Joining: </b>{userDetails && userDetails.doj}</Label>
                            </Col>
                        </Row>
                        <Row>
                            {userDetails && userDetails.dol && <Col>
                                <Label><b>Date of Leaving: </b>{userDetails && userDetails.dol}</Label>
                            </Col>}
                            {userDetails && userDetails.leavingreason && <Col>
                                <Label><b>Leaving Reason: </b>{userDetails && userDetails.leavingreason}</Label>
                            </Col>}
                        </Row>
                        <Heading title="Banking Info" />
                        <Row>
                            <Col>
                                <Label><b>Account Number: </b>{userDetails && userDetails.account}</Label>
                            </Col>
                            <Col>
                                <Label><b>Name in Bank Account: </b>{userDetails && userDetails.holdername}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label><b>Bank Name: </b>{userDetails && userDetails.bank}</Label>
                            </Col>
                            <Col>
                                <Label><b>Bank IFSC: </b>{userDetails && userDetails.ifsc}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label><b>PAN Number: </b>{userDetails && userDetails.panNumber}</Label>
                            </Col>
                            <Col>
                                <Label><b>Bank Address: </b>{userDetails && userDetails.bankaddress}</Label>
                            </Col>
                        </Row>
                        <Heading title="Contact Info" />
                        <Row>
                            <Col>
                                <Label><b>Phone: </b>{userDetails && userDetails.phone}</Label>
                            </Col>
                            <Col>
                                <Label><b>Personal Email: </b>{userDetails && userDetails.pemail}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label><b>Emergency Contact Person: </b>{userDetails && userDetails.emergencycontact}</Label>
                            </Col>
                            <Col>
                                <Label><b>Emergency Contact Number: </b>{userDetails && userDetails.emergencynumber}</Label>
                            </Col>
                        </Row>
                        <>
                            <Heading title="Family Details" />
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Relation</th>
                                                <th>Date of Birth</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {userDetails && userDetails.familyMembers && userDetails.familyMembers.length > 0 ?
                                                userDetails.familyMembers.map((member, index) => (
                                                    <tr key={'row_' + index}>
                                                        <td>{member.name}</td>
                                                        <td className="text-capitalize">{member.relation}</td>
                                                        <td><Moment format="DD/MM/YYYY">{member.dob}</Moment></td>
                                                    </tr>
                                                )) :
                                                <tr><td colSpan="3" className="text-center"> Nothing to show</td></tr>
                                            }
                                            </tbody>   
                                    </Table>
                                </Col>
                            </Row>
                        </>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={()=>toggle(null)}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EmployeeDetailModal