import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FieldArray } from 'react-final-form-arrays'
import {
    Button,
    Form,
    FormGroup,
    Table,
    Col
} from 'reactstrap'
import {
    DatePickerAdapter,
    FormFeedbackAdapter,
    InputAdapter,
    SelectAdapter
} from '../ffadapters'
import {
    SubmitErrorAlerts
} from '../ffhelpers'
import {
    validateRequired,
} from '../../lib/validators'
import moment from 'moment'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool.isRequired,
}
const billRequired = (reimbursementTypes,selectedItem) => {
   var reimbursement = reimbursementTypes && reimbursementTypes.filter(type => type.value === selectedItem)
   return reimbursement[0].billRequired
}
const ReimbursementRequestForm = (
    {
        values,
        handleSubmit,
        submitError,
        submitting,
        toggle,
        reimbursementType
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="pt-4">
            <Table id="teammember-table" className="f-15 table table-striped">
                <thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
                    <tr>
                        <th>Reimbursement Type</th>
                        <th title="Bill No is not required for certian types of reimbursements.">Bill No</th>
                        <th>Bill Date</th>
                        <th>Amount</th>
                        <th>File</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name="reimbursements">
                        {({ fields }) => <>
                            {fields.map((name, index) => (
                                <tr className="w-100" key={`${name}_${index}`}>
                                    <td key={`reimbursementType${name}_${index}`}>
                                        <Field
                                            name={`${name}.reimbursementType`}
                                            type="select"
                                            bsSize="lg"
                                            validate={validateRequired()}
                                            component={SelectAdapter}
                                            options={reimbursementType} />
                                        <FormFeedbackAdapter name={`${name}.reimbursementType`} />
                                    </td>
                                    <td key={`billNo${name}_${index}`}>
                                        <Field
                                            name={`${name}.billNo`}
                                            type="text"
                                            bsSize="lg"
                                            required={(values && values.reimbursements && values.reimbursements[index].reimbursementType && billRequired(reimbursementType,values.reimbursements[index].reimbursementType) === 'yes') ? true : false}     
                                            component={InputAdapter} />
                                        <FormFeedbackAdapter name={`${name}.billNo`} />
                                    </td>
                                    <td key={`billDate${name}_${index}`} className="w-10">
                                        <Field
                                            name={`${name}.billDate`}
                                            type="date"
                                            bsSize="lg"
                                            min={moment().startOf('month').format('YYYY-MM-DD')}
                                            max={moment().endOf('month').format('YYYY-MM-DD')}
                                            component={DatePickerAdapter}
                                            validate={validateRequired()}
                                            required="required" />
                                        <FormFeedbackAdapter name={`${name}.billDate`} />
                                    </td>
                                    <td key={`amount${name}_${index}`} className="w-10">
                                        <Field
                                            name={`${name}.amount`}
                                            type="number"
                                            bsSize="lg"
                                            component={InputAdapter}
                                            required="required"
                                            min="0" />
                                        <FormFeedbackAdapter name={`${name}.amount`} />
                                    </td>
                                    <td key={`file${name}_${index}`} className="w-10">
                                        <Field name={`${name}.file`}>
                                            {({ input: { value, onChange, ...input } }) => (
                                                <input
                                                    {...input}
                                                    type="file"
                                                    className="border-info form-control pb-2"
                                                    name={`${name}.file`}
                                                    required={(values && values.reimbursements && values.reimbursements[index].reimbursementType && billRequired(reimbursementType,values.reimbursements[index].reimbursementType) === 'yes') ? true : false}
                                                    onChange={({ target }) => onChange(target.files)}
                                                />
                                            )}
                                        </Field>

                                        <FormFeedbackAdapter name={`${name}.file`} />
                                    </td>
                                    <td key={`add${name}_${index}.`}>
                                        <Button
                                            color="link"
                                            onClick={() => fields.push()}
                                            disabled={submitting}>
                                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        </Button>
                                        {values && values.reimbursements && values.reimbursements.length > 1 &&
                                            <Button
                                                onClick={() => fields.remove(index)}
                                                color="link"
                                                className="text-danger"
                                            >
                                                <FontAwesomeIcon icon={faMinus} className="mr-2" />
                                            </Button>}

                                    </td>
                                </tr>
                            ))}
                        </>}
                    </FieldArray>
                </tbody>
            </Table>

            <SubmitErrorAlerts submitError={submitError} />
            <FormGroup row>
                <Col md>
                    <Button
                        block
                        color="primary"
                        className="p-3 mt-3"
                        disabled={submitting}
                        size="lg"
                        type="submit"
                    >Submit</Button>
                </Col>
                <Col md>
                    <Button
                        block
                        type="button"
                        color="outline-primary"
                        className="p-3 mt-3"
                        disabled={submitting}
                        size="lg"
                        onClick={() => toggle()}
                    >Cancel</Button>
                </Col>

            </FormGroup>

        </Form>

    )
}

ReimbursementRequestForm.propTypes = propTypes

export default ReimbursementRequestForm
