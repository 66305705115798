import React, { Component } from 'react'
import { Button, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faBan, faEye, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { projectStatus } from '../data/projectStatusData'
import { projectType } from '../data/projectTypesData'
import TeamMemberModal from '../components/projects/TeamMemberModal'
import { fetchUserName, fetchClientName } from '../data/dataFromApis'
import { updateProject, fetchProjects } from '../middleware/api'
import DeactivateModal from './projects/DeactivateModal'
import { ToastContainer, toast } from 'react-toastify'
import TableComponent from './elements/TableComponent'
import moment from 'moment'

class Projects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            deactivateOpen: false,
            teamMember: null,
            project: null,
            searchTerm: ""

        }
        this.toggleMemberModal = this.toggleMemberModal.bind(this)
        this.handleDeactivate = this.handleDeactivate.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleActivate = this.handleActivate.bind(this)
    }

    componentDidUpdate = (prevProps) => {
        const { projects } = this.props
        const { projectsPerPage } = this.state
        if (this.props.projects !== prevProps.projects && projects.length <= projectsPerPage)
            this.setState({ currentPage: 1 })
    }
    
    handleDeactivate(project) {
        if (project) {
            if (project.teammember && project.teammember.length !== 0) {
                this.setState(state => ({
                    deactivateOpen: !state.deactivateOpen,
                    project: project
                }))
            } else {
                var confirm = window.confirm('Are you sure you want to deactivate this project')
                if (confirm) {
                    project.status = 'inactive'
                    updateProject(project._id, project)
                        .then(async (response) => {
                            fetchProjects()
                                .then(async (response) => {
                                    const result = await response.json()
                                    this.setState({
                                        project: result
                                    })
                                })
                        })
                    toast.success('Project deactivated Succesfully')
                }
            }
        } else {
            this.setState(state => ({
                deactivateOpen: !state.deactivateOpen
            }))
        }
    }

    handleActivate(project) {
        project.status = 'active'
        updateProject(project._id, project)
            .then(async (response) => {
                fetchProjects()
                    .then(async (response) => {
                        const result = await response.json()
                        this.setState({
                            project: result
                        })
                    })
                toast.success('Project activated Succesfully')
            })
    }

    handleCancel() {
        this.setState(state => ({
            deactivateOpen: !state.deactivateOpen
        }))
    }

    toggleMemberModal(project) {
        if (project && project.status === 'inactive') {
            this.setState(state => ({
                isOpen: false,
                project: project
            }))
        } else {
            this.setState(state => ({
                isOpen: !state.isOpen,
                project: project,
            }))
        }

    }

    requestSearch = (searchTerm) => {
        this.setState({ searchTerm })
    }

    cancelSearch = () => {
        this.setState({ searchTerm: "" })
    }

    formatData = () => {
        const { users, clients, projects } = this.props
        if (!projects || (projects && projects.length === 0))
            return []

        return projects && projects.map((project, index) => {
            return (
                {
                    id: index + 1,
                    projectName: project.projectname,
                    startDate: moment(project.startdate).format('YYYY/MM/DD'),
                    endDate: moment(project.enddate).format('YYYY/MM/DD'),
                    type: projectType[project.type],
                    status: projectStatus[project.status],
                    projectManager: fetchUserName(users, project.projectmanager),
                    client: fetchClientName(clients, project.client) || 'N/A',
                    data: project
                }
            )
        })
    }

    render() {
        const { projects, toggleEditModal, fetchProjects, users, clients } = this.props
        const { searchTerm } = this.state
        const formattedData = users && projects && clients && this.formatData()
        const filteredRows = formattedData && formattedData.filter((row) => {
            return ((row && row.client && row.client.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.status && row.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.projectName && row.projectName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (row && row.projectManager && row.projectManager.toLowerCase().includes(searchTerm.toLowerCase()))
            )
        })

        const columns = [
            {
                id: 'projectName',
                label: 'Project Name'
            },
            {
                id: 'client',
                label: 'Client',
            },
            {
                id: 'startDate',
                label: 'Start Date',
            },
            {
                id: 'endDate',
                label: 'End Date',
            },
            {
                id: 'type',
                label: 'Type',
            },
            {
                id: 'status',
                label: 'Status',
            },
            {
                id: 'projectManager',
                label: 'Project Manager',
            },
        ]

        const actionMarkup = ({ data }) => {
            return (
                <React.Fragment>
                    {
                        data.teammember && data.teammember.length === 0 ?
                            <Button
                                title={data.status === 'inactive' ? "" : "Add Teammembers"}
                                onClick={() => this.toggleMemberModal(data)}
                                color="link"
                                disabled={data.status === "inactive"}
                            >
                                <FontAwesomeIcon
                                    icon={faPlus}
                                    className="mr-2" /></Button>

                            :
                            <Button
                                title={data.status === 'inactive' ? "" : "View Teammembers"}
                                onClick={() => this.toggleMemberModal(data)}
                                color="link">
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="mr-2" />
                            </Button>
                    }
                    <Button
                        title={data.status === "inactive" ? "" : "Edit Project"}
                        onClick={() => toggleEditModal(data)}
                        color="link"
                        disabled={data.status === "inactive"}
                    ><FontAwesomeIcon
                            icon={faPencilAlt}
                            className="mr-2" />
                    </Button>
                    {
                        (data.status === "active" || data.status === "completed") ?

                            <Button
                                title={data.status === 'inactive' ? "" : 'Deactivate Project'}
                                onClick={() => this.handleDeactivate(data)}
                                color="link"
                                disabled={data.status === "inactive"}>
                                <FontAwesomeIcon
                                    icon={faBan}
                                    className="mr-2" />
                            </Button>
                            :
                            <Button
                                title={data.status === 'active' ? " " : "Activate Current Project"}
                                onClick={() => this.handleActivate(data)}
                                color="link"
                                disabled={data.status === "active"}>
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="mr-2" />
                            </Button>
                    }
                </React.Fragment>
            )
        }

        return (
            <>
                <Row className="mt-5">
                    <Col>
                        {formattedData && <TableComponent
                            showCheckbox={false}
                            columns={columns}
                            data={filteredRows ? filteredRows : []}
                            perPage={10}
                            defaultSort="status"
                            requestSearch={this.requestSearch}
                            cancelSearch={this.cancelSearch}
                            showSerialNo={true}
                            action={actionMarkup}
                            searchTerm={searchTerm}
                        />}
                    </Col>
                </Row>

                <TeamMemberModal
                    isOpen={this.state.isOpen}
                    toggleModal={this.toggleMemberModal}
                    project={this.state.project}
                    fetchProjects={fetchProjects}
                />
                <DeactivateModal
                    deactivateOpen={this.state.deactivateOpen}
                    toggleModal={this.handleDeactivate}
                    project={this.state.project}
                    fetchProjects={fetchProjects}
                    handleCancel={this.handleCancel}
                    users={users && users}
                />
                <ToastContainer />
            </>
        )
    }
}

export default Projects