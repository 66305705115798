import React, { Component } from 'react'
import TimesheetForm from './timesheet/TimesheetForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {
    fetchCurrentTimesheet,
    createTimesheet,
    updateCompleteTimesheet
} from '../middleware/api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'


class Timesheet extends Component {

    state = {
        startOfWeek: null,
        timesheet: null,
        projects: [],
        totalHours: 0,
        openTimesheetModal: false
    }

    fetchTimesheet = (startOfWeek) => {
        const date = new Date(startOfWeek)
        const id = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
        fetchCurrentTimesheet({
            startofweek: id,
            user_id: localStorage.getItem('user_id')
        })
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    timesheet: result
                })
                this.countTotalHours(result)
            })
    }
    countTotalHours = (timesheet) => {
        let count = 0
        timesheet && timesheet[0] && timesheet[0].timesheet.forEach(timesheet => {
            count += !isNaN(timesheet.mon) ? parseFloat(timesheet.mon) : 0
            count += !isNaN(timesheet.tue) ? parseFloat(timesheet.tue) : 0
            count += !isNaN(timesheet.wed) ? parseFloat(timesheet.wed) : 0
            count += !isNaN(timesheet.thu) ? parseFloat(timesheet.thu) : 0
            count += !isNaN(timesheet.fri) ? parseFloat(timesheet.fri) : 0
        })
        this.setState({
            totalHours: count
        })
    }
    onSave = (values) => {
        const { startOfWeek, timesheet } = this.state
        var dummyData = false
        var dummyArray = []
        values.timesheet.forEach(sheet => {
            if (dummyArray.indexOf(sheet.projects) === -1 && dummyArray.indexOf(sheet.tasks) === -1) {
                dummyArray.push(sheet.projects, sheet.tasks)
            }
            else if (dummyArray.indexOf(sheet.projects) !== -1 && dummyArray.indexOf(sheet.tasks) !== -1) {
                dummyData = true
            }
        });
        if (dummyData) {
            toast.error("Error : Please, don't repeat same project and same task. In such a condition add time in single row.")
            return
        }

        values.user_id = localStorage.getItem('user_id')
        values.startOfWeek = startOfWeek
        const date = new Date(startOfWeek)
        const id = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`

        values.identifier = id
        values.status = "Saved"
        values.remark = ""
        values.submitted = false
        values.username = localStorage.getItem('fname') + " " + localStorage.getItem('lname')

        if (timesheet[0] && timesheet[0]._id) {
            updateCompleteTimesheet(timesheet[0]._id, values)
                .then(async () => {
                    await this.fetchTimesheet(startOfWeek)
                    toast.success("Timesheet has been saved.")
                })
        } else {
            createTimesheet(values)
                .then(async () => {
                    await this.fetchTimesheet(startOfWeek)
                })
        }
    }

    setStartOfWeek = (date) => {
        var finalDate = moment(new Date(date)).toDate()
        this.setState({
            startOfWeek: finalDate
        })
        this.fetchTimesheet(finalDate)
    }

    onSubmit = () => {
        const { timesheet, startOfWeek } = this.state
        timesheet[0].status = "Submitted"
        timesheet[0].submitted = true

        if (timesheet[0] && timesheet[0]._id) {
            updateCompleteTimesheet(timesheet[0]._id, timesheet[0])
                .then(async (response) => {
                    await this.fetchTimesheet(startOfWeek)
                    toast.success("Timesheet has been submitted.")
                })
        }
    }
    onCancel = () => {
        var confirm = window.confirm("Are you sure you want to go back?")
        if (confirm === true)
            window.location.href = '/timesheet-home'
    }
    componentDidMount() {
        var dt = moment().startOf('isoWeek').toDate();
        this.setStartOfWeek(dt)
        this.fetchTimesheet(dt)

    }
    render() {
        const { timesheet, startOfWeek, totalHours } = this.state
        const { projects, tasks } = this.props
        const defaultValues = [{
            projects: "",
            tasks: "",
            description: "",
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0
        }]
        const weeks = [{
            label: `Current Week`,
            value: moment().startOf('isoWeek').toDate()
        },
        {
            label: `${moment().subtract(1, 'week').startOf('isoWeek').format('DD/MM')}-${moment().subtract(1, 'week').endOf('isoWeek').format('DD/MM')}`,
            value: moment().subtract(1, 'week').startOf('isoWeek').toDate()
        },
        {
            label: `${moment().subtract(2, 'week').startOf('isoWeek').format('DD/MM')}-${moment().subtract(2, 'week').endOf('isoWeek').format('DD/MM')}`,
            value: moment().subtract(2, 'week').startOf('isoWeek').toDate()
        }]
        return (
            <div>
                <Form
                    component={TimesheetForm}
                    formId="timesheet"
                    onSubmit={this.onSave}
                    onSubmitForApproval={this.onSubmit}
                    userProjectsArray={projects}
                    taskArray={tasks}
                    subscription={{
                        pristine: true,
                        submitError: true,
                        submitting: true,
                        hasValidationErrors: true,
                    }}
                    weeks={weeks && weeks}
                    onCancel={this.onCancel}
                    hoursCompleted={totalHours >= 10 ? true : false}
                    saved={totalHours < 10 ? false : true}
                    submitted={(timesheet && timesheet[0] &&
                        (timesheet[0].status === 'Approved' ||
                            timesheet[0].status === 'Pending' || timesheet[0].status === 'Submitted')) ? true : false}
                    startOfWeek={startOfWeek}
                    setStartOfWeek={this.setStartOfWeek}
                    mutators={{
                        ...arrayMutators
                    }}
                    initialValues={
                        {
                            timesheet: timesheet && timesheet.length !== 0 ? timesheet[0].timesheet : defaultValues
                        }
                    }

                />
                <ToastContainer />
            </div>

        );
    }
}

export default Timesheet;