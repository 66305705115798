import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import AddEditReferralForm from './AddEditReferralForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { any, bool, func } from 'prop-types'

const AddEditReferralModal = ({ isOpen,
	toggle,
	data,
	onSubmit,
	handleSelectAll,
	handleSelectOption,
	selectedSkills,
	selectAll,
	skillsDropdownData,
	isAdmin
}) => {
	return (
		<>
			<Modal id="project-modal" size="lg" isOpen={isOpen} toggle={toggle}>
				<ModalHeader
					tag="h2"
					toggle={toggle}>Add/Edit Referral</ModalHeader>
				<ModalBody>
					<Form
						component={AddEditReferralForm}
						formId="addreferral"
						toggle={toggle}
						onSubmit={onSubmit}
						handleSelectAll={handleSelectAll}
						handleSelectOption={handleSelectOption}
						selectedSkills={selectedSkills}
						allOptions={skillsDropdownData}
						selectAll={selectAll}
						skillsDropdownData={skillsDropdownData}
						isAdmin={isAdmin}
						subscription={{
							pristine: true,
							submitError: true,
							submitting: true,
							hasValidationErrors: true,
							reset: true
						}}
						mutators={{
							...arrayMutators,
						}}
						initialValues={{referredByName: localStorage.getItem('fname') + " " + localStorage.getItem('lname'),referredBy: localStorage.getItem('user_id'),...data}}
					/>
				</ModalBody>
			</Modal>
		</>
	)
}

AddEditReferralModal.propTypes = {
	isOpen: bool,
	toggle: func.isRequired,
	kra: any,
	selectedSkills: any,
	formattedJobs:any.isRequired,
	skillsDropdownData:any.isRequired,
	locationsDropdownData:any.isRequired,
	isAdmin:bool
}

AddEditReferralModal.defaultProps = {
	isOpen: false,
	kra: null,
	selectedSkills: [],
	formattedJobs:[],
	skillsDropdownData:[],
	locationsDropdownData:[],
	isAdmin:false
}
export default AddEditReferralModal