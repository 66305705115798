import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default class ShortPagination extends React.Component {
  render() {
    const { setPageNumber, pageNumbers, currentPage } = this.props
    
    return (
      <Pagination aria-label="Page navigation example" className="rfloat mr-4">
          {
            currentPage === 1 ? null :
            <>
            <PaginationItem key={0} className={''}>
              <PaginationLink  id={1} onClick={setPageNumber}>
                {"first"}
              </PaginationLink>
            </PaginationItem>
            {currentPage > 2 ?
            <PaginationItem key={0.5} className={''}>
              <PaginationLink   >
                {"..."}
              </PaginationLink>
          </PaginationItem>
           : null
            } 
            <PaginationItem key={currentPage - 1} className={''}>
              <PaginationLink id={currentPage - 1} onClick={setPageNumber}>
                {currentPage - 1}
              </PaginationLink>
            </PaginationItem>
            </>
            }
            <PaginationItem key={currentPage} className={'active'}>
              <PaginationLink id={currentPage} onClick={setPageNumber}>
                {currentPage}
              </PaginationLink>
            </PaginationItem>
          {
            currentPage === pageNumbers.length ? null :
            <>
            <PaginationItem key={currentPage + 1} className={''}>
              <PaginationLink id={currentPage + 1} onClick={setPageNumber}>
                {currentPage + 1}
              </PaginationLink>
            </PaginationItem>
            {currentPage < pageNumbers.length - 1 ?
            <PaginationItem key={pageNumbers.length + 0.5} className={''}>
              <PaginationLink>
                {"..."}
              </PaginationLink>
            </PaginationItem>
           : null
            } 
            <PaginationItem key={pageNumbers.length + 1} className={''}>
              <PaginationLink  id={pageNumbers.length} onClick={setPageNumber}>
                {"last"}
              </PaginationLink>
          </PaginationItem>
          </>
          }
            
    </Pagination>
    );
  }
}