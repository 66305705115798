import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

// Reactstrap
import { Button, Form, FormGroup, Table, Row, Col, Alert } from 'reactstrap'
// Final-Form to Reactstrap Adapters
import { FormFeedbackAdapter, SelectAdapter, DatePickerAdapter, InputAdapter } from '../ffadapters'
// Final-Form Helpers
import { SubmitErrorAlerts } from '../ffhelpers'
// Validators
import { composeValidators, validateRequired, } from '../../lib/validators'

const propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitError: PropTypes.any,
	submitting: PropTypes.bool.isRequired,
}

const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (value !== "" ? children : null)}
	</Field>
)

const findUsers = (users, values, index) => {
	var result = []
	console.log(users)
	for (var i = 0; i < users.length; i++) {
		if (values && values.teammember && values.teammember[index] && (users[i].role === values.teammember[index].role)) {
			result.push({
				value: users[i]._id,
				label: users[i].fname + " " + users[i].lname
			})
		}
	}
	return result
}

const OffBoardForm = (
	// form states that trigger render need to be in container subscription
	{
		handleSubmit,
		submitError,
		submitSucceeded,
		submitting,
		toggle,
		adminUsers,
		devUsers,
		qaUsers,
		managerUsers,
		values,
		showOffBoardButton,
		roles,
		users
	}
) => {
	let usersData = users && findUsers(users, values)
	console.log(usersData, values)
	return (

		<Form onSubmit={handleSubmit}>

			{values && values.teammember && values.teammember.length > 0 && <Alert color="warning">You need to offboard all team members to deactivate the project.</Alert>}

			<Table id="teammember-table" className="f-15 table table-striped">
				<thead style={{ backgroundColor: "#A9B5C3", color: "#FFFFFF" }}>
					<tr>
						<th>Role</th>
						<th>User</th>
						<th>Start Date</th>
						<th>End date</th>
						<th>% Allocation</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<FieldArray name="teammember">
						{({ fields }) => <Fragment>
							{fields.map((name, index) => {
								let usersData = findUsers(users, values, index)
								return (

									<tr className="w-100" key={`${name}_${index}`}>
										<td key={`projects_${name}_${index}`}>
											<Field
												name={`${name}.role`}
												type="select"
												bsSize="lg"
												validate={composeValidators(validateRequired())}
												component={SelectAdapter}
												options={roles} />
											<FormFeedbackAdapter name={`${name}.role`} />
										</td>
										<td key={`tasks_${name}_${index}.`}>

											<Condition when={`${name}.role`}>
												<Field
													name={`${name}.user`}
													type="select"
													bsSize="lg"
													validate={composeValidators(validateRequired())}
													component={SelectAdapter}
													options={usersData} />
												<FormFeedbackAdapter name={`${name}.user`} />
											</Condition>

										</td>
										<td key={`startdate_${name}_${index}.`}>
											<Field
												name={`${name}.startdate`}
												type="date"
												bsSize="lg"
												validate={validateRequired()}
												component={DatePickerAdapter}
												required={true}
												min="2000-01-01" />
											<FormFeedbackAdapter name={`${name}.startdate`} />
										</td>
										<td key={`enddate_${name}_${index}`} className="w-10">
											<Field
												name={`${name}.enddate`}
												type="date"
												bsSize="lg"
												component={DatePickerAdapter}
												min={(values['teammember'] !== null && values['teammember'] !== undefined && values['teammember'][index]) ?
													values['teammember'][index].startdate :
													''} />
											<FormFeedbackAdapter name={`${name}.enddate`} />
										</td>
										<td key={`allocation_${name}_${index}`} className="w-10">
											<Field
												name={`${name}.allocation`}
												type="number"
												bsSize="lg"
												validate={validateRequired()}
												component={InputAdapter}
												min="0"
												max="100" />
											<FormFeedbackAdapter name={`${name}.allocation`} />
										</td>
										<td key={`delete_${name}_${index}.`}>
											{showOffBoardButton ?
												<Button
													title='OffBoard Teammember'
													color="link"
													onClick={() => fields.remove(index)}
												><FontAwesomeIcon
														icon={faTrash}
														className="ml-5" /></Button>

												:

												<>
													<Button
														onClick={() => fields.remove(index)}
														color="link"
													>
														<FontAwesomeIcon icon={faMinus} className="mr-2" />
													</Button>
													<Button
														className="add-button"
														style={{ display: "none" }}
														color="link"
														onClick={() => fields.push()}
														disabled={submitting} size="lg">
														<FontAwesomeIcon icon={faPlus} className="mr-2" />
													</Button>
												</>
											}
										</td>
									</tr>

								)
							})}

						</Fragment>}
					</FieldArray>
				</tbody>
			</Table>

			<SubmitErrorAlerts submitError={submitError} />
			<Row>
				<Col>

					{values && values.teammember && values.teammember.length === 0 && <Alert color="success">You can now deactivate the project.</Alert>}
				</Col>
			</Row>
			<Row>
				<Col md>
					<FormGroup row>
						<Button
							block
							color="primary"
							className="p-3 mr-2"
							disabled={submitting || submitSucceeded || (showOffBoardButton && values && values.teammember && values.teammember.length > 0)}
							size="lg"
							type="submit"

						>
							{showOffBoardButton ? "Deactivate" : "Save"}</Button>
					</FormGroup>
				</Col>
				<Col md>
					<FormGroup row>

						<Button
							block
							color="outline-primary"
							className="p-3 ml-2"
							disabled={submitting || submitSucceeded}
							size="lg"
							onClick={() => toggle(null)}>Cancel</Button>


					</FormGroup>
				</Col>
			</Row>


		</Form>)
}

OffBoardForm.propTypes = propTypes

export default OffBoardForm
