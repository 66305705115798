import React, { Component } from 'react'
import SideBar from '../components/content/Sidebar'
import Content from '../components/content/Content'
import { fetchAssets, loggingOut, fetchUserAssets, fetchTeammemberAsset, fetchUser } from '../middleware/api'
import { cryptInfo } from '../config'
import Assets from '../components/Assets'
import AddAssetModal from '../components/AddAssetModal'
import { Container, Row, Col, Button } from 'reactstrap'
import EmployeeDetailModal from '../components/EmployeeDetailModal'


class UserAssetContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAddProjectModal: false,
            isAdmin: cryptInfo.decrypt(localStorage.getItem('role')) === 'admin',
            isManager: cryptInfo.decrypt(localStorage.getItem('role')) === 'manager',
            isOpen: true,
            assets: null,
            asset: null,
            openApplyAssetModal: false,
            selectedEmployee: null,
            openDetailModal: false
        }
        this.toggle = this.toggle.bind(this)
        this.getAssets = this.getAssets.bind(this)
        this.getUserAssets = this.getUserAssets.bind(this)
    }
    componentDidMount() {
        loggingOut()
        const { isAdmin, isManager } = this.state
        isAdmin && this.getAssets()
        isManager && this.getTeammemberAsset()
        !isAdmin && !isManager && this.getUserAssets()
    }
    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    toggleModal = () => {
        this.setState(state => ({
            openApplyAssetModal: !state.openApplyAssetModal,

        }))
    }

    toggleEditModal = (asset) => {
        this.toggleModal()
        this.setState({
            asset: asset
        })
    }

    getAssets() {
        fetchAssets()
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    assets: result
                })
            })

    }

    getUserAssets() {
        const userId = localStorage.getItem('user_id')
        fetchUserAssets(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    assets: result
                })
            })
    }
    getTeammemberAsset() {
        console.log("j")
        const userId = localStorage.getItem('user_id')
        fetchTeammemberAsset(userId)
            .then(async (response) => {
                const result = await response.json()
                this.setState({
                    assets: result
                })
            })
    }


    toggleEmployeeDetail = (selectedEmployee) => {
        if (selectedEmployee) {
            fetchUser(selectedEmployee)
                .then(async (response) => {
                    var result = await response.json()
                    this.setState({ selectedEmployee: result })
                })
        }
        this.setState((prevStat) => ({
            openDetailModal: !prevStat.openDetailModal
        }))
    }
    render() {
        const { isOpen, isAdmin, isManager, openApplyAssetModal, assets, asset, selectedEmployee, openDetailModal } = this.state
        return (
            <>
                <div className="App wrapper">
                    <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
                    <Content toggle={this.toggle} isOpen={isOpen} >
                        <Container fluid>
                            <Row className="page-heading">
                                <Col className="text-left">
                                    <span>Assets</span>
                                </Col>
                                {(isAdmin || isManager) && <Col className="text-right">
                                    <Button color="warning" size="lg" onClick={this.toggleModal}>
                                        Add Asset
                                    </Button>
                                </Col>}
                            </Row>
                        </Container>
                        <div className="container-fluid">
                            <Assets
                                assets={assets}
                                toggleModal={this.toggleModal}
                                fetchAssets={this.getUserAssets}
                                fetchAllAssets={this.getAssets}
                                toggleEditModal={this.toggleEditModal}
                                toggleEmployeeDetail={this.toggleEmployeeDetail}
                            />
                        </div>
                        <AddAssetModal
                            isOpen={openApplyAssetModal}
                            toggle={this.toggleModal}
                            fetchAssets={this.getAssets}
                            assets={asset}
                        />
                        <EmployeeDetailModal
                            isOpen={openDetailModal}
                            toggle={this.toggleEmployeeDetail}
                            userDetails={selectedEmployee}
                        />
                    </Content>
                </div>
            </>
        );
    }
}

export default UserAssetContainer;