import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
// Reactstrap
import {
    Button,
    Form,
    FormGroup,
    Label,
    Row,
    Col
} from 'reactstrap'
// Final-Form to Reactstrap Adapters
import {
    FormFeedbackAdapter,
    InputAdapter,
} from '../ffadapters'
// Final-Form Helpers
import {
    SubmitErrorAlerts
} from '../ffhelpers'
// Validators
import {
    composeValidators,
    validateRequired,
} from '../../lib/validators'

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitError: PropTypes.any,
    submitting: PropTypes.bool,
}

const AddEditTypeForm = (
    // form states that trigger render need to be in container subscription
    {
        handleSubmit,
        submitError,
        submitSucceeded,
        submitting,
        toggle,
        
    }
) => {
    return (
        <Form onSubmit={handleSubmit} className="p-4" id="typeform">
            <Row>
                <Col md>
                    <FormGroup>
                        <Label htmlFor={`name`}>Name</Label>
                        <Field
                            name="name"
                            type="text"
                            bsSize="lg"
                            validate={composeValidators(validateRequired())}
                            component={InputAdapter} />
                        <FormFeedbackAdapter name="name" />
                    </FormGroup>
                </Col>
            </Row>
            
            <SubmitErrorAlerts submitError={submitError} />
            <Row>
                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="primary"
                            className="p-3 mr-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            type="submit">
                            {"Save"}</Button>
                    </FormGroup>
                </Col>

                <Col md>
                    <FormGroup>
                        <Button
                            block
                            color="outline-primary"
                            className="p-3 ml-2"
                            disabled={submitting || submitSucceeded}
                            size="lg"
                            onClick={() => toggle(null)}>Cancel</Button>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

AddEditTypeForm.propTypes = propTypes

export default AddEditTypeForm
